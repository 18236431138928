import { cn } from '@utils/cn';
import * as React from 'react';

interface LegendProps {
  color: string;
  className?: string;
  label?: string;
  subLabel?: string;
}

export const RecLegend: React.FunctionComponent<LegendProps> = (props) => {
  return (
    <div className={cn('tw-flex tw-items-start tw-gap-4', props.className)}>
      <div
        className="tw-h-16 tw-w-16 tw-shrink-0 tw-rounded-4"
        style={{ backgroundColor: props.color }}
      />
      <div className="tw-flex-auto tw-text-center tw-text-[14px] tw-leading-[16px]">
        <span>{props.label}</span>
        {props.subLabel ? (
          <>
            <br /> <span className="tw-text-[12px]">{props.subLabel}</span>
          </>
        ) : null}{' '}
      </div>
    </div>
  );
};

export const LineLegend: React.FunctionComponent<LegendProps> = (props) => {
  return (
    <div className={cn('tw-flex tw-items-start tw-gap-4', props.className)}>
      <div className="tw-flex tw-h-16 tw-items-center">
        <div
          className="tw-h-[2px] tw-w-40 tw-shrink-0"
          style={{ backgroundColor: props.color }}
        />
      </div>
      <div className="tw-flex-auto tw-text-center tw-text-[14px] tw-leading-[16px]">
        <span>{props.label}</span>
        {props.subLabel ? (
          <>
            <br /> <span className="tw-text-[12px]">{props.subLabel}</span>
          </>
        ) : null}{' '}
      </div>
    </div>
  );
};
