export const predictColor = '#efdce4';
export const predictLineColor = '#f59e0b';
export const predictColorCurrent = '#dc6c9c';

export const scope1ColorOld = '#8497b0';
export const scope2ColorOld = '#adb9ca';
export const scope3ColorOld = '#d6dce5';
export const scope1Color = '#047cac';
export const scope2Color = '#d4b44c';
export const scope3Color = ' #228b22';
export const scope3Colors = [
  '#3A5800',
  '#486A00',
  '#567C03',
  '#638C0B',
  '#709C12',

  '#7BAA17',
  '#86B81B',
  '#8FC31F',
  '#9DC93A',
  '#AACF52',

  '#B6D56A',
  '#C1DB81',
  '#CCE198',
  '#D7E7AF',
  '#E2EEC5',
];
