import { USER_LOCAL_STORAGE_NAME } from '@constants/consts';
import { useStorage } from '@hooks/useStorage';
import { LocalDataClass, UserLocal } from 'data-class/LocalDataClass';
import { ReactNode, createContext, useState } from 'react';

export type AuthenStatus =
  | 'LOGIN'
  | 'SUCCESS'
  | 'MUST_CHANGE_PASSWORD'
  | 'SHOULD_CHANGE_PASSWORD'
  | 'FIRST_GENERATE_PASSWORD'
  | 'SESSION_TIMEOUT'
  | 'LOGOUT';

type AuthContextType = {
  currentBankCode: string;
  setCurrenBankCode: (value: string) => void;
  logout: () => void;
  backToLogin: () => void;
  user: UserLocal;
  byPassChangePassword: () => void;
};

export const AuthContext = createContext<AuthContextType | null>(null);
type AuthProviderProps = {
  children: ReactNode;
};

export type SessionTimeRemain = {
  time: number;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const userStorage = useStorage<UserLocal>(USER_LOCAL_STORAGE_NAME);
  const [currentBankCode, setCurrenBankCode] = useState<string>('');
  // const { refetch: getCsrfToken } = useHgbQuery<{ token: string }, unknown>(
  //   API_URLS.CSRF_API_URL,
  //   undefined,
  //   {
  //     queryKey: [API_URLS.CSRF_API_URL],
  //     enabled: false,
  //     onSuccess: (data) => {
  //       LocalDataClass['X-XSRF-TOKEN'] = data?.result?.token;
  //     },
  //   },
  // );

  // useEffect(() => {
  //   if (!userStorage?.authenStatus) return;
  //   if (userStorage?.authenStatus !== 'LOGIN') {
  //     getCsrfToken();
  //   }
  // }, [userStorage?.authenStatus]);

  const logout = async () => {
    LocalDataClass.user = {
      ...userStorage,
      profileToken: '',
      refreshToken: '',
      authenStatus: 'LOGOUT',
    };
  };

  const backToLogin = () => {
    LocalDataClass.user = {
      ...userStorage,
      authenStatus: 'LOGIN',
    };
  };

  const byPassChangePassword = () => {
    LocalDataClass.user = {
      ...userStorage,
      authenStatus: 'SUCCESS',
    };
  };

  const authContextValue: AuthContextType = {
    currentBankCode,
    setCurrenBankCode,
    logout,
    backToLogin,
    user: userStorage!,
    byPassChangePassword,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
