import { cn } from '@utils/cn';
import * as React from 'react';

type TypographyProps = {
  className?: string;
  children?: React.ReactNode;
};

export const heading1ClassName =
  'tw-text-[26px] tw-leading-[36px] pc:tw-text-[32px] pc:tw-leading-[48px]';
export const Heading1: React.FunctionComponent<TypographyProps> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cn(heading1ClassName, className)} />;
};

export const heading2ClassName = `tw-text-[20px] tw-leading-[32px] pc:tw-text-[28px] pc:tw-leading-[42px]`;
export const Heading2: React.FunctionComponent<TypographyProps> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cn(heading2ClassName, className)} />;
};

export const heading3ClassName = `tw-text-[18px] tw-leading-[24px]`;
export const Heading3: React.FunctionComponent<TypographyProps> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cn(heading3ClassName, className)} />;
};

export const heading4ClassName = `tw-text-[16px] tw-leading-[24px]`;
export const Heading4: React.FunctionComponent<TypographyProps> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cn(heading4ClassName, className)} />;
};

export const paragraphClassName = `tw-text-[14px] tw-leading-[24px]`;
export const Paragraph: React.FunctionComponent<TypographyProps> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cn(paragraphClassName, className)} />;
};

export const tinyTextClassName = `tw-text-[12px] tw-leading-[18px]`;
export const TinyText: React.FunctionComponent<TypographyProps> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cn(tinyTextClassName, className)} />;
};

export const cellTooltipClassName = `tw-text-[14px] tw-leading-[24px] tw-text-primary-4 tw-underline tw-underline-offset-2 hover:tw-text-primary-6 active:tw-text-primary-5 tw-cursor-pointer`;
export const CellTooltip: React.FunctionComponent<TypographyProps> = ({
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cn(cellTooltipClassName, className)}
      style={{
        textDecorationSkipInk: 'none',
      }}
    />
  );
};

export const modalTitleClassName = `tw-text-[28px] tw-leading-[42px] tw-font-bold`;
export const ModalTitle: React.FunctionComponent<TypographyProps> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cn(modalTitleClassName, className)} />;
};
