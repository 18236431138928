import { USER_LOCAL_STORAGE_NAME } from '@constants/consts';
import { LocalDataClass } from 'data-class/LocalDataClass';
import {
  LocalStorageDataClass,
  LocalStorageName,
} from 'data-class/LocalStorageDataClass';
import { useEffect, useState } from 'react';

export const useStorage = <T = any>(name: LocalStorageName) => {
  const [value, setValue] = useState(() => {
    if (name === 'LANG') {
      return LocalDataClass.language as T;
    } else {
      const user = LocalDataClass.user;
      if (name === USER_LOCAL_STORAGE_NAME) {
        let newUser = { ...user };
        if (
          newUser.authenStatus === 'SESSION_TIMEOUT' ||
          newUser.authenStatus === 'LOGOUT' ||
          newUser.isMaintenance
        ) {
          newUser.authenStatus = 'LOGIN';
        }
        if (
          newUser.authenStatus === 'FIRST_GENERATE_PASSWORD' ||
          newUser.authenStatus === 'SHOULD_CHANGE_PASSWORD'
        ) {
          newUser.authenStatus = 'SUCCESS';
        }
        LocalDataClass.user = { ...newUser, isMaintenance: false };
      }
      return user as T;
    }
  });

  useEffect(() => {
    window.addEventListener(
      LocalStorageDataClass.changeEventName(name),
      (e: any) => {
        setValue(e.detail?.value as T);
      },
    );
  }, []);

  return value;
};
