import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { LanguageContext } from '@contexts/LanguageContext';
import { FunctionComponent, useContext } from 'react';
import { IDiscardMessageModalProps } from 'services/types/D13';
import './confirmModal.scss';

const DiscardMessageModal: FunctionComponent<IDiscardMessageModalProps> = ({
  onSubmitOk,
  ...props
}) => {
  const {
    text: { common, D13 },
  } = useContext(LanguageContext)!;

  return (
    <HgbAntdModal
      {...props}
      cancelText={common.button.cancel}
      okText={common.button.close}
      title={D13.modal.discardMessage}
      onOk={() => {
        onSubmitOk?.();
      }}
    >
      <p className="tw-pb-24 tw-text-paragraph tw-text-neutral-10">
        {D13.modal.discardContent}
      </p>
    </HgbAntdModal>
  );
};

export default DiscardMessageModal;
