import { HgbLink } from '@common/components';
import { routs_en } from '@constants/ROUT';
import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import * as React from 'react';

export const CM03: React.FC = () => {
  const {
    text: { common },
  } = React.useContext(LanguageContext)!;

  return (
    <AuthenTemplate title={common.message.tit_access_deny} logo={false}>
      <p className="tw-text-center">{common.message.mes_access_deny}</p>
      <HgbLink to={routs_en['/dashboard'].link}>
        {common.button.gotoDashboard}
      </HgbLink>
    </AuthenTemplate>
  );
};
