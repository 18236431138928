import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { useBooleanState } from '@hooks/useBooleanState';
import { cn } from '@utils/cn';
import { Modal } from 'antd';
import { ReactNode, useEffect, useRef } from 'react';

export const useConfirm = () => {
  const uniqName = useRef(0);

  const modalState = useBooleanState(false);
  let resolveOkFn = () => {};
  let resolveRejectFn = () => {};

  const rejectHandler = () => {
    window.dispatchEvent(new Event(`confirmReject${uniqName.current}`));
  };

  const okHandler = () => {
    window.dispatchEvent(new Event(`confirmOk${uniqName.current}`));
  };

  useEffect(() => {
    uniqName.current = new Date().getTime();
    window.removeEventListener(`confirmOk${uniqName.current}`, resolveOkFn);
    window.removeEventListener(
      `confirmReject${uniqName.current}`,
      resolveRejectFn,
    );
  }, []);

  const ConfirmModal = ({
    title,
    subTitle,
    children,
    okText,
    cancelText,
    className,
    classNameSubtitle,
  }: {
    title?: ReactNode;
    subTitle?: ReactNode;
    children?: ReactNode;
    okText?: ReactNode;
    cancelText?: ReactNode;
    className?: string;
    classNameSubtitle?: string;
  }) => {
    return (
      <Modal
        className="ant-modal-hgb-custom"
        title={<HgbAntdModalTitle>{title}</HgbAntdModalTitle>}
        centered
        open={modalState.value}
        footer={null}
        onCancel={rejectHandler}
      >
        {subTitle ? (
          <p
            className={cn(
              'tw-mb-24 tw-px-24 tw-text-h3 tw-font-regular',
              classNameSubtitle,
            )}
          >
            {subTitle}
          </p>
        ) : null}
        <HgbAntdModalFormBody className={className}>
          {children}
        </HgbAntdModalFormBody>
        <HgbAntdModalFormFooter>
          <HgbAntdButton
            className="tw-min-w-100"
            onClick={rejectHandler}
            htmlType="button"
          >
            {cancelText}
          </HgbAntdButton>
          <HgbAntdButton
            className="tw-min-w-100"
            type="primary"
            htmlType="button"
            onClick={okHandler}
          >
            {okText}
          </HgbAntdButton>
        </HgbAntdModalFormFooter>
      </Modal>
    );
  };

  const hgbConfirm = (): Promise<boolean> => {
    modalState.turnOn();
    return new Promise((resolve) => {
      resolveOkFn = () => {
        modalState.turnOff();
        resolve(true);
      };
      resolveRejectFn = () => {
        modalState.turnOff();
        resolve(false);
      };

      window.addEventListener(`confirmOk${uniqName.current}`, resolveOkFn);
      window.addEventListener(
        `confirmReject${uniqName.current}`,
        resolveRejectFn,
      );
    });
  };

  return {
    ConfirmModal,
    hgbConfirm,
    isOpen: modalState.value,
  };
};
