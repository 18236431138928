import { COLORS } from '@constants/colors';
import { AuthProvider } from '@contexts/AuthContext';
import { LanguageProvider } from '@contexts/LanguageContext';
import { PortalProvider } from '@contexts/PortalContext';
import { ConfigProvider, ThemeConfig } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RouterProvider } from 'react-router';
import { routers } from 'routes';

const App = () => {
  const theme: ThemeConfig = {
    token: {
      fontFamily: "'Noto Sans JP', sans-serif",
      colorPrimary: COLORS.primary,
      colorSuccess: COLORS.success,
      colorWarning: COLORS.warning,
      colorError: COLORS.error,
    },
    components: {
      Modal: {
        borderRadiusLG: 16,
      },
      Select: {
        fontSizeLG: 14,
        lineHeightLG: 21,
        colorTextDisabled: '#3F494F',
        colorBgContainerDisabled: '#fff',
      },
      Button: {
        fontSizeLG: 14,
        lineHeightLG: 21,
        fontSizeSM: 10,
      },
      Tabs: {
        margin: 0,
      },
      DatePicker: {
        fontSizeLG: 14,
        lineHeightLG: 21,
      },
      TreeSelect: {
        lineHeightLG: 21,
        fontSizeLG: 14,
      },
      Input: {
        fontSizeLG: 14,
        colorTextDisabled: '#3F494F',
        colorBgContainerDisabled: '#fff',
      },
    },
  };

  const queryClient = new QueryClient();
  return (
    <LanguageProvider>
      <ConfigProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <PortalProvider>
            <AuthProvider>
              <RouterProvider router={routers} />
            </AuthProvider>
          </PortalProvider>
          {!window.location.host.includes('10.98.100.120') ? (
            <ReactQueryDevtools />
          ) : null}
        </QueryClientProvider>
      </ConfigProvider>
    </LanguageProvider>
  );
};

export default App;
