import { HgbNavbar } from '@common/navigations';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { isMobileOs } from '@utils/os.util';
import { Spin } from 'antd';
import { useContext } from 'react';
import { MobilePDFReader } from 'react-read-pdf';
import { useHgbQuery } from 'services/common/query';

export const FAQ = () => {
  const {
    text: { F03 },
  } = useContext(LanguageContext)!;

  const { isFetching, data, isFetchedAfterMount } = useHgbQuery(
    API_URLS.FAQ,
    undefined,
    {
      type: 'blob',
      queryKey: [API_URLS.FAQ],
    },
  );

  const url = URL.createObjectURL(
    new Blob([data as BlobPart], { type: 'application/pdf' }),
  );

  return (
    <div className="tw-flex tw-h-screen tw-flex-col">
      <HgbNavbar />
      <section>
        <h3 className="tw-py-8 tw-font-bold">co2_system_faq.pdf</h3>
      </section>
      <div
        className="tw-relative -tw-mr-12 tw-flex tw-flex-auto tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden  
      [&_#viewerContainer]:tw-top-8 
      [&_#viewerContainer]:tw-px-16 
      pc:[&_#viewerContainer]:tw-px-40 
      large:[&_#viewerContainer]:tw-px-60
      [&_.page]:tw-m-0 [&_.page]:!tw-w-full
       [&_.page]:tw-overflow-hidden [&_.page]:tw-border [&_.pdfViewer]:tw-grid [&_.pdfViewer]:tw-gap-16
      "
      >
        {isFetching ? (
          <Spin />
        ) : (
          <>
            {!!data ? (
              isMobileOs ? (
                <MobilePDFReader
                  url={url}
                  showAllPage
                  isShowFooter={false}
                  isShowHeader={false}
                />
              ) : (
                <iframe
                  title="co2_system_manual"
                  className="tw-flex-auto tw-self-stretch"
                  src={url}
                />
              )
            ) : (
              isFetchedAfterMount && (
                <h1 className="tw-font-bold">{F03.message.fileNotFound}</h1>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};
