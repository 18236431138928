export type Bank = {
  id: number;
  bankCode: string;
  bankArea: string;
  bankProvince: string;
  bankName: string;
  personInCharge: string;
  personInChargeEmail: string;
  loginUrl: string;
  privacyPolicyUrl: string;
  emailSignature: string;
  billingStartDefault: string;
  enterpriseMonthlyFee: number;
  enterpriseTrialMonths: number;
  enterpriseTrialStartMonth: string;
  expirationPeriodHours: number;
  numberOfUser: number;
  subscriptionType: 'FREE_TRIAL' | 'COMPLIMENTARY';
};

export type GetBankRequest = {
  pageNo: number;
  pageSize: number;
  nameSearch?: string;
};

export type GetBankResponse = {
  currentPage: number;
  pageSize: number;
  totalPage: number;
  totalRecord: number;
  content: Bank[];
};

export type InvoiceIssueDate = {
  name: string;
  value: string;
};

export type InvoiceIssueDateRequest = null;
export type InvoiceIssueDateResponse = InvoiceIssueDate[];

export type CreateBankResponse = null;
export type CreateBankRequest = Omit<Bank, 'id' | 'loginUrl' | 'numberOfUser'>;

export type UpdateBankResponse = null;
export type UpdateBankRequest = Omit<Bank, 'numberOfUser'>;

export type DeleteBankRequest = string;
export type DeleteBankResponse = null;

export type BankListBox = {
  value: string;
  name: string;
};

export type StatusListBox = {
  value: string;
  name: string;
};

export type GetBankListBoxResponse = BankListBox[];
export type GetBankListBoxRequest = { isLoadAllOption?: boolean };

export type GetStatusListBoxResponse = {
  value: string;
  name: string;
  statusInGroup?: StatusListBox[];
}[];
export type GetStatusListBoxRequest = { hasChildStatuses?: boolean };

export const BANK_FORM_DEFAULT_VALUE: Bank = {
  bankCode: '',
  bankName: '',
  personInCharge: '',
  personInChargeEmail: '',
  bankArea: '',
  bankProvince: '',
  privacyPolicyUrl: '',
  emailSignature: '',
  subscriptionType: 'FREE_TRIAL',
  enterpriseTrialStartMonth: '',
  enterpriseTrialMonths: 12,
  enterpriseMonthlyFee: 2200,
  expirationPeriodHours: 72,
  id: -1,
  loginUrl: '',
  numberOfUser: 0,
  billingStartDefault:'',
};
// export const BANK_FORM_DEFAULT_VALUE: Bank = {
//   bankCode: '7777',
//   bankName: 'ylvn1',
//   personInCharge: 'ylvn1',
//   personInChargeEmail: 'ylvn1@fpt.com',
//   bankArea: '東北',
//   bankProvince: '宮城',
//   privacyPolicyUrl: 'google.com',
//   emailSignature: 'a',
//   subscriptionType: 'FREE_TRIAL',
//   enterpriseTrialStartMonth: '2024-05',
//   enterpriseTrialMonths: 12,
//   enterpriseMonthlyFee: 2200,
//   expirationPeriodHours: 72,
//   id: -1,
//   loginUrl: '',
//   numberOfUser: 0,
// };
