import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import HgbAntdUpload from '@common/antd/HgbAntdUpload';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import { HgbSelect, parseToHgbSelectOptions } from '@common/forms';
import {
  HgbTreeSelect,
  HgbTreeSelectOption,
} from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { hgbAxios } from '@utils/axios';
import { currentYearMonth } from '@utils/date.util';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { Radio } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import { FunctionComponent, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useHgbQuery } from 'services/common/query';
import { C02Step1Request } from 'services/types/C02';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetFiscalYearMonthListBoxRequest,
  GetFiscalYearMonthListBoxResponse,
  GetLastTenYearListBoxRequest,
  GetLastTenYearListBoxResponse,
} from 'services/types/year';
import { v4 } from 'uuid';
import { MANUAL_NAMEDDESTS } from '../Support';
import UploadModal, { UploadModalProps } from './UploadModal';

const C02: FunctionComponent = () => {
  const {
    text: { C01, common },
    language,
  } = useContext(LanguageContext)!;
  const { addMessage } = useContext(PortalContext)!;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const methods = useForm<C02Step1Request>({
    defaultValues: {
      baseId: '',
      file: undefined,
      radio: '1',
      scopeCode: '',
      targetFiscalYear: '',
      targetYearMonth: '',
    },
  });
  const { watch, setValue, handleSubmit } = methods;
  const formValues = watch();

  const [uploadModalProps, setUploadModalProps] = useState<UploadModalProps>({
    step1: undefined,
  });

  const { data: yearLBQuery, isFetching: isYearLBFetching } = useHgbQuery<
    GetLastTenYearListBoxResponse,
    GetLastTenYearListBoxRequest
  >(API_URLS.LAST_TEN_YEAR_API_URL, undefined);

  const { data: monthLBQuery, isFetching: isMonthLBFetching } = useHgbQuery<
    GetFiscalYearMonthListBoxResponse,
    GetFiscalYearMonthListBoxRequest
  >(
    API_URLS.FISCAL_YEAR_MONTH,
    { targetYear: formValues.targetFiscalYear, isLCIDBYear: false },
    {
      queryKey: [formValues.targetFiscalYear, API_URLS.FISCAL_YEAR_MONTH],
      enabled: !isEmpty(formValues.targetFiscalYear),
    },
  );

  const { data: basesQuery, isFetching: isbasesQueryFetching } = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(API_URLS.ORGANIZATION_BASE, undefined);

  const targetFiscalYearOptions = useMemo(() => {
    const rs = parseToHgbSelectOptions(yearLBQuery?.result).filter(
      (word) => Number(word.value) > 2018,
    );
    return rs;
  }, [yearLBQuery?.result, isYearLBFetching]);

  const targetYearMonthOptions = useMemo(() => {
    const value = (monthLBQuery?.result ?? [])
      .map((item) => item.value)
      .find((s) => s === currentYearMonth())
      ? currentYearMonth()
      : monthLBQuery?.result?.[0].value;
    setValue('targetYearMonth', value ?? '');
    return parseToHgbSelectOptions(monthLBQuery?.result);
  }, [monthLBQuery?.result, isMonthLBFetching]);

  const scopeOptions = useMemo(() => {
    setValue('scopeCode', formValues.radio === '1' ? '1' : '4');
    return formValues.radio === '1'
      ? [
          { label: common.label.scopeNote1, value: '1' },
          { label: common.label.scopeNote2, value: '2' },
        ]
      : [{ label: common.label.offset, value: '4' }];
  }, [formValues.radio, language]);

  const baseOptions: HgbTreeSelectOption[] = useMemo(() => {
    const bases = basesQuery?.result ?? [];
    return convertObjectToTree(
      bases.filter((item) => !getParentKey(item.hierarchicalPath ?? '')),
      bases,
    );
  }, [basesQuery?.result]);

  const onSubmit = () => {
    setOpenConfirm(true);
  };

  const submitFile = async () => {
    setLoading(true);
    const { baseId, targetFiscalYear, targetYearMonth, file, scopeCode } =
      formValues;
    const formData = new FormData();
    formData.append('baseId', baseId);
    formData.append('targetFiscalYear', targetFiscalYear);
    formData.append('targetYearMonth', targetYearMonth);
    formData.append('scopeCode', scopeCode ?? '');
    formData.append('file', file!);
    // mutate(formData);
    try {
      const response = await hgbAxios().post(
        API_URLS.PDF_UPLOAD_API_URL,
        formData,
      );
      if (response) {
        setLoading(false);
        const { result } = response.data;
        setOpen(true);
        setUploadModalProps({
          step1: formValues,
          scope: {
            activityVolume: result?.activityVolume,
            activityVolumeUnit: result?.activityVolumeUnit,
          },
          offset: {
            offsetAmount: result?.offsetAmount,
            offsetCertificateDate: result?.offsetCertificateDate,
            offsetAmountUnit: result?.offsetAmountUnit,
          },
          step1Options: {
            targetFiscalYearOptions,
            targetYearMonthOptions,
            baseOptions,
            scopeOptions,
          },
        });
      }
    } catch (error: any) {
      addMessage('error', error?.response?.data?.message);
      setLoading(false);
    }
  };

  const disableSubmit = useMemo(() => {
    const values = Object.values(formValues);
    return !values.every((item) => !isEmpty(item) && !isUndefined(item));
  }, [formValues]);

  const navigate = useNavigate();

  const radioOptions = useMemo(
    () => [
      { label: C01.label.invoice, value: '1' },
      { label: C01.label.certificate, value: '2' },
    ],
    [language],
  );

  return (
    <ManagementTemplate>
      <DataTableTemplate title={C01.label.uploadFile}>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="tw-mt-24 tw-flex tw-justify-center tw-overflow-y-scroll"
          >
            <div className="tw-flex tw-flex-col tw-gap-24">
              <div>
                <label
                  htmlFor={'radio'}
                  className="tw-mb-8 tw-block tw-text-paragraph tw-font-bold"
                >
                  {C01.label.fileType}
                </label>
                <Radio.Group
                  id="radio"
                  onChange={(e) => {
                    setValue('radio', e.target.value);
                  }}
                  value={watch('radio')}
                  className="tw-flex tw-flex-wrap tw-gap-24"
                >
                  {radioOptions.map((item) => (
                    <Radio value={item.value.toString()} key={v4()}>
                      <div className="-tw-mx-8 -tw-ml-4 tw-flex tw-w-180 tw-gap-8">
                        {item.label}
                        <HgbInfoLink
                          to={
                            '/support/manual/' +
                            (item.value === '1'
                              ? MANUAL_NAMEDDESTS.about_upload_invoice
                              : MANUAL_NAMEDDESTS.about_upload_offset)
                          }
                          target="_blank"
                        />
                      </div>
                    </Radio>
                  ))}
                </Radio.Group>
              </div>

              <div className="tw-grid tw-grid-cols-2 tw-gap-24 pc:tw-grid-cols-4">
                <HgbSelect
                  name={'targetFiscalYear'}
                  // placeholder={C01.label.fiscalYear}
                  className="tw-col-span-2 pc:tw-col-span-1"
                  label={C01.label.fiscalYear}
                  options={targetFiscalYearOptions}
                  defaultActiveFirstOption
                />
                <HgbSelect
                  name={'targetYearMonth'}
                  // placeholder={C01.label.targetYearMonth}
                  className="tw-col-span-2 pc:tw-col-span-1"
                  label={C01.label.targetYearMonth}
                  options={targetYearMonthOptions}
                  defaultActiveFirstOption
                />
                <HgbTreeSelect
                  name={'baseId'}
                  // placeholder={C01.label.baseId}
                  className="tw-col-span-2"
                  label={C01.label.baseId}
                  treeData={baseOptions}
                  required
                  showSearch
                />
                <HgbSelect
                  name={'scopeCode'}
                  // placeholder={C01.label.scopeCode}
                  className="tw-col-span-2"
                  label={C01.label.scopeCode}
                  options={scopeOptions}
                  defaultActiveFirstOption
                  disabled={formValues.radio === '2'}
                />
              </div>
              <HgbAntdUpload
                disabled={isEmpty(watch('scopeCode'))}
                name="file"
                size={5}
              />
              <div
                className={`tw-mt-24 tw-grid tw-grid-cols-1 tw-gap-24 tw-self-center pc:tw-grid-cols-2`}
              >
                <HgbAntdButton
                  onClick={() => {
                    return navigate(routs_en['/data-entry'].link);
                  }}
                  type="primary"
                  ghost
                  htmlType="submit"
                >
                  {C01.label.returnToDataList}
                </HgbAntdButton>
                <HgbAntdButton
                  type="primary"
                  htmlType="submit"
                  disabled={disableSubmit}
                  loading={loading}
                  className="-tw-order-1 pc:tw-order-1"
                >
                  {C01.label.upload}
                </HgbAntdButton>
              </div>
            </div>
          </form>
        </FormProvider>
        {open && (
          <UploadModal open={open} setOpen={setOpen} {...uploadModalProps} />
        )}
        {openConfirm && (
          <HgbAntdModal
            title={C01.modal.uploadConfirmModalTitle}
            okText={C01.modal.uploadConfirmModalOkButton}
            cancelText={common.button.cancel}
            onCancel={() => setOpenConfirm(false)}
            open
            onOk={() => {
              setOpenConfirm(false);
              submitFile();
            }}
          >
            <div className="tw-text-center">
              {C01.modal.uploadConfirmModalContent}
            </div>
          </HgbAntdModal>
        )}
      </DataTableTemplate>
    </ManagementTemplate>
  );
};

export default C02;
