import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { DataTableTemplate } from '@layouts/templates';
import { cn } from '@utils/cn';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { LocalDataClass } from 'data-class/LocalDataClass';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Bar, BarChart, XAxis, YAxis } from 'recharts';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import { RecLegend } from './components/Legend';
import {
  scope1Color,
  scope1ColorOld,
  scope2Color,
  scope2ColorOld,
  scope3Color,
  scope3ColorOld,
} from './const';
import {
  DashboardRequest,
  MonthlyChartInsertRequest,
  MonthlyChartRequest,
  MonthlyChartResponse,
  MonthlyItem,
} from './types';

const getMonthItem = (
  month: string,
  list: MonthlyItem[],
): MonthlyItem | undefined => {
  return list.find((item) => item.month === month);
};

const fulfillMonthlyChartData = (data: MonthlyItem[]): MonthlyItem[] => {
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  const startMonthIndex = months.findIndex((item) => item === data?.[0]?.month);
  const result = months.map((month) => {
    const findedData = getMonthItem(month, data);
    return {
      current: {
        '1': findedData?.current?.['1'] || 0,
        '2': findedData?.current?.['2'] || 0,
        '3': findedData?.current?.['3'] || 0,
      },
      last: {
        '1': findedData?.last?.['1'] || 0,
        '2': findedData?.last?.['2'] || 0,
        '3': findedData?.last?.['3'] || 0,
      },
      month,
    };
  });
  return [
    ...result.slice(startMonthIndex),
    ...result.slice(0, startMonthIndex),
  ];
};

export type MonthlyChartProps = {
  dashboardMethods: UseFormReturn<DashboardRequest, any, undefined>;
};

export const MonthlyChart: React.FunctionComponent<MonthlyChartProps> =
  React.memo(({ dashboardMethods }) => {
    const {
      text: { B01, common },
    } = React.useContext(LanguageContext)!;
    const { user } = React.useContext(AuthContext)!;
    const scopeCode = user.scopeCode || '';

    const options = [
      { label: common.label.scope1, value: '1' },
      { label: common.label.scope2, value: '2' },
      { label: common.label.scope3, value: '3' },
    ];

    const monthlyChartQuery = useHgbQuery<
      MonthlyChartResponse,
      MonthlyChartRequest
    >(
      API_URLS.DASHBOARD_MONTHLY_CHART,
      {
        ...dashboardMethods.watch(),
        scopeCode,
      },
      {
        queryKey: [API_URLS.DASHBOARD_MONTHLY_CHART, scopeCode],
        enabled: scopeCode !== '',
      },
    );

    const handleChangeScope = (checkedValues: CheckboxValueType[]) => {
      const scopeCodeString = checkedValues.join(',');
      LocalDataClass.user = {
        ...LocalDataClass.user,
        scopeCode: scopeCodeString,
      };
      insertMutate({ scopeCode: scopeCodeString });
    };

    const { mutateAsync: insertMutate } = useHgbMutationPost<
      undefined,
      MonthlyChartInsertRequest
    >(API_URLS.DASHBOARD_MONTHLY_CHART_INSERT, { isAlert: false });

    const data = fulfillMonthlyChartData(
      monthlyChartQuery.data?.result?.columnStatisticsChartDetails || [],
    ).map((item) => ({
      ...item,
      month: common.month[item.month as '01'],
    }));

    return (
      <DataTableTemplate
        className="tw-scroll-mt-64"
        title={B01.label.monthlyEmission}
      >
        <div className="tw-space-y-16">
          <div className="tw-mb-16 tw-flex tw-w-full tw-items-center tw-justify-center">
            <Checkbox.Group
              className="tw-flex tw-flex-wrap tw-gap-16"
              options={options}
              value={scopeCode.split(',')}
              onChange={handleChangeScope}
            />
          </div>
          <div className="tw-mx-auto tw-w-fit tw-max-w-[100%] tw-overflow-hidden tw-overflow-x-scroll">
            <BarChart
              width={900}
              height={408}
              data={data}
              margin={{
                left: 35,
                right: 35,
                bottom: 0,
                top: 40,
              }}
            >
              <XAxis dataKey="month" fontSize={14} />
              <YAxis
                fontSize={14}
                label={{ value: 'tCO2', position: 'top', offset: 20 }}
                tickCount={5}
                tickFormatter={(value) => {
                  if (
                    monthlyChartQuery.data?.result?.columnStatisticsChartDetails
                  ) {
                    return value;
                  }
                  return `${500 * value}`;
                }}
              />
              <Bar
                dataKey="last.1"
                stackId="a"
                barSize={25}
                style={{ fill: scope1ColorOld }}
              />
              <Bar
                dataKey="last.2"
                stackId="a"
                barSize={25}
                style={{ fill: scope2ColorOld }}
              />
              <Bar
                dataKey="last.3"
                stackId="a"
                barSize={25}
                style={{ fill: scope3ColorOld }}
              />
              <Bar
                dataKey="current.1"
                stackId="b"
                barSize={25}
                style={{ fill: scope1Color }}
              />
              <Bar
                dataKey="current.2"
                stackId="b"
                barSize={25}
                style={{ fill: scope2Color }}
              />
              <Bar
                dataKey="current.3"
                stackId="b"
                barSize={25}
                style={{ fill: scope3Color }}
              />
            </BarChart>
          </div>
          <div className="tw-mx-auto tw-flex tw-w-fit tw-flex-wrap tw-gap-16">
            <div className="tw-flex tw-flex-wrap tw-gap-16">
              <RecLegend
                color={scope1ColorOld}
                label={B01.label.scope1Last}
                className={cn({
                  'tw-hidden': !scopeCode.includes('1'),
                })}
              />
              <RecLegend
                color={scope2ColorOld}
                label={B01.label.scope2Last}
                className={cn({
                  'tw-hidden': !scopeCode.includes('2'),
                })}
              />
              <RecLegend
                color={scope3ColorOld}
                label={B01.label.scope3Last}
                className={cn({
                  'tw-hidden': !scopeCode.includes('3'),
                })}
              />

              <RecLegend
                color={scope1Color}
                label={B01.label.scope1Current}
                className={cn({
                  'tw-hidden': !scopeCode.includes('1'),
                })}
              />
              <RecLegend
                color={scope2Color}
                label={B01.label.scope2Current}
                className={cn({
                  'tw-hidden': !scopeCode.includes('2'),
                })}
              />
              <RecLegend
                color={scope3Color}
                label={B01.label.scope3Current}
                className={cn({
                  'tw-hidden': !scopeCode.includes('3'),
                })}
              />
            </div>
          </div>
        </div>
      </DataTableTemplate>
    );
  });
