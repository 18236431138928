import { routs_en } from '@constants/ROUT';
import { REACT_APP_BASE_NAME, ROLES } from '@constants/consts';
import { AuthGuard } from '@guards/AuthGuard';
import { RoleBasedGuard } from '@guards/RoleBasedGuard';
import { UnAuthGuard } from '@guards/UnAuthGuard';
import { A06Result } from '@pages/A06Result';
import {
  ChangePassword,
  Logout,
  ReIssue,
  SessionTimeout,
  SignIn,
} from '@pages/Authen';
import { CM02 } from '@pages/CM02';
import { CM03 } from '@pages/CM03';
import Common from '@pages/Common/Common';
import { B02 } from '@pages/Dashboard/B02/B02';
import Maintenance from '@pages/Maintenance';
import { C01 } from '@pages/Management/C01/C01';
import C02 from '@pages/Management/C02/C02';
import C03 from '@pages/Management/C03/C03';
import D15 from '@pages/Management/D15/D15';
import D17 from '@pages/Management/D17/D17';
import D19 from '@pages/Management/D19/D19';
import D22 from '@pages/Management/D22/D22';
import { E01 } from '@pages/Management/E01/E01';
import { E02 } from '@pages/Management/E02/E02';
import H01 from '@pages/Management/H01/H01';
import H02 from '@pages/Management/H02/H02';
import { C06, D02, D04, D06, D08, G01 } from '@pages/Management/Setting';
import { F01, F04, FAQ } from '@pages/Management/Support';
import { D13 } from '@pages/Management/Support/D13/D13';
import A06 from '@pages/UnAuthen/A06';
import A07 from '@pages/UnAuthen/A07';
import A07Result from '@pages/UnAuthen/A07Result';
import { createBrowserRouter } from 'react-router-dom';

export const routers = createBrowserRouter(
  [
    {
      path: '*',
      element: <CM02 />,
    },
    {
      path: routs_en['/not-found'].link,
      element: <CM02 />,
    },
    {
      path: routs_en['/register'].link,
      element: (
        <UnAuthGuard>
          <A06 />
        </UnAuthGuard>
      ),
    },
    {
      path: routs_en['/register/fail'].link,
      element: (
        <UnAuthGuard>
          <A06Result />
        </UnAuthGuard>
      ),
    },
    {
      path: routs_en['/temporarily-register'].link,
      element: (
        <UnAuthGuard>
          <A07 />
        </UnAuthGuard>
      ),
    },
    {
      path: routs_en['/temporarily-register/success'].link,
      element: (
        <UnAuthGuard>
          <A07Result />
        </UnAuthGuard>
      ),
    },
    {
      path: routs_en['/temporarily-register/fail'].link,
      element: <A07Result />,
    },
    {
      path: routs_en['/access-denied'].link,
      element: <CM03 />,
    },
    {
      path: routs_en['/reissue'].link,
      element: <ReIssue />,
    },
    {
      path: '/',
      element: <AuthGuard />,
      children: [
        {
          path: routs_en['/dashboard'].link,
          element: <B02 />,
        },
        {
          path: routs_en['/signin'].link,
          element: <SignIn />,
        },
        {
          path: routs_en['/logout'].link,
          element: <Logout />,
        },
        {
          path: routs_en['/session-timeout'].link,
          element: <SessionTimeout />,
        },
        {
          path: routs_en['/change-password'].link,
          element: <ChangePassword />,
        },
        { path: routs_en['/data-entry'].link, element: <C01 /> },
        {
          path: routs_en['/data-entry/ocr'].link,
          element: (
            <RoleBasedGuard
              roles={[ROLES.ROLE_ENTERPRISE_ADMIN, ROLES.ROLE_ENTERPRISE_USER]}
            >
              <C02 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/data-entry/csv'].link,
          element: (
            <RoleBasedGuard
              roles={[ROLES.ROLE_ENTERPRISE_ADMIN, ROLES.ROLE_ENTERPRISE_USER]}
            >
              <C03 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/report/emission'].link,
          element: <E01 />,
        },
        {
          path: routs_en['/report/company'].link,
          element: (
            <RoleBasedGuard
              roles={[ROLES.ROLE_PRESIDING_BANK, ROLES.ROLE_MANAGEMENT_BANK]}
            >
              <E02 />
            </RoleBasedGuard>
          ),
        },
        { path: routs_en['/support/manual'].link, element: <F01 /> },
        { path: routs_en['/support/faq'].link, element: <FAQ /> },
        { path: routs_en['/support/contact-us'].link, element: <F04 /> },
        { path: routs_en['/support/message'].link, element: <D13 /> },

        {
          path: routs_en['/management/company'].link,
          element: (
            <RoleBasedGuard
              roles={[
                ROLES.ROLE_PRESIDING_BANK,
                ROLES.ROLE_MANAGEMENT_BANK,
                ROLES.ROLE_ENTERPRISE_ADMIN,
              ]}
            >
              <D08 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/management/organization'].link,
          element: (
            <RoleBasedGuard
              roles={[
                ROLES.ROLE_PRESIDING_BANK,
                ROLES.ROLE_MANAGEMENT_BANK,
                ROLES.ROLE_ENTERPRISE_ADMIN,
              ]}
            >
              <D02 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/management/bank'].link,
          element: (
            <RoleBasedGuard
              roles={[ROLES.ROLE_PRESIDING_BANK, ROLES.ROLE_MANAGEMENT_BANK]}
            >
              <G01 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/management/user'].link,
          element: (
            <RoleBasedGuard
              roles={[
                ROLES.ROLE_PRESIDING_BANK,
                ROLES.ROLE_MANAGEMENT_BANK,
                ROLES.ROLE_ENTERPRISE_ADMIN,
              ]}
            >
              <D04 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/management/emission'].link,
          element: <D06 />,
        },
        {
          path: routs_en['/management/introducer'].link,

          element: (
            <RoleBasedGuard roles={[ROLES.ROLE_MANAGEMENT_BANK]}>
              <D22 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/management/supplychain-buyer'].link,
          element: (
            <RoleBasedGuard roles={[ROLES.ROLE_ENTERPRISE_ADMIN]}>
              <D15 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/management/supplychain-supplier'].link,
          element: (
            <RoleBasedGuard roles={[ROLES.ROLE_ENTERPRISE_ADMIN]}>
              <D17 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/management/supplier-product'].link,
          element: (
            <RoleBasedGuard roles={[ROLES.ROLE_ENTERPRISE_ADMIN]}>
              <D19 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/group/supplier-receive'].link,
          element: (
            <RoleBasedGuard roles={[ROLES.ROLE_ENTERPRISE_ADMIN]}>
              <H01 />
            </RoleBasedGuard>
          ),
        },
        {
          path: routs_en['/group/supplier-submission'].link,
          element: (
            <RoleBasedGuard roles={[ROLES.ROLE_ENTERPRISE_ADMIN]}>
              <H02 />
            </RoleBasedGuard>
          ),
        },
        { path: routs_en['/management/lci-db-pload'].link, element: <C06 /> },
        { path: '/maintenance', element: <Maintenance /> },
        {
          path: '/common',
          element: <Common />,
        },
      ],
    },
  ],
  {
    basename: REACT_APP_BASE_NAME || undefined,
  },
);
