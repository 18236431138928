import { AuthContext } from '@contexts/AuthContext';
import * as React from 'react';
import { EnterpriseAdmin } from './EnterpriseAdmin';
import { ManagementBank } from './ManagementBank';

export const D08: React.FC = () => {
  const { user } = React.useContext(AuthContext)!;
  return (
    <>
      {(user.isManagementBank || user.isPresidingBank) && <ManagementBank />}
      {user.isEnterpriseAdmin && <EnterpriseAdmin />}
    </>
  );
};
