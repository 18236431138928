import { HgbAntdSpin } from '@common/antd/HgbAntdSpin';
import { routs_en } from '@constants/ROUT';
import { AuthContext } from '@contexts/AuthContext';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface UnAuthGuardProps {
  children: ReactNode;
}

export function UnAuthGuard({ children }: UnAuthGuardProps) {
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext)!;

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setIsReady(true);
    }, 300);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (user.isMaintenance) {
      navigate('/maintenance');
      return;
    }
    if (user.authenStatus === 'SUCCESS') {
      navigate(routs_en['/dashboard'].link);
      return;
    }
  }, [user]);
  return <>{isReady ? children : <HgbAntdSpin placement="middle" />}</>;
}
