import { StopOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdSpin } from '@common/antd/HgbAntdSpin';
import { HgbCellTooltip } from '@common/contents';
import { HgbAntdInput } from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { useBooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { useDebounce } from '@hooks/useDebound';
import { PaginationProps, usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { hgbAxiosGetSingle } from '@utils/axios';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationDelete } from 'services/common/mutation';
import { useHgbQueryWithPaging } from 'services/common/query';
import { WithKey } from 'types/common';
import { SupplierEmissionInputModal } from '../H04/SupplierEmissionInputModal';

export const STATUS = {
  active: 'ACTIVE',
  inActive: 'INACTIVE',
  register: 'REGISTER',
  pending: 'PENDING',
};

export type SupplierResponse = {
  id: number;
  supplyChainGroupName: string;
  supplierName: string;
  buyerName: string;
  responseType: { value: string; name: string };
  targetStartMonth: string;
  targetEndMonth: string;
  productName: string;
  baseName: string;
  quantity: number;
  emissionIntensity: number;
  baseVolume: number;
  allocation: number;
  emissionVolume: number;
  sentTime: string;
  status: { value: string; name: string };
  responseSummary: string;
  category: Category;
  scope: string;
  supplierId: number;
  productId: number;
  productSerialNumber?: string;
  unit?: string;
  baseId?: number;
  emissionVolumeUnit?: string;
  supplyChainBuyerId?: number;
  buyerBaseOrganizationId?: Record<string, number>;
  allocationRates: Record<string, number>;
  fiscalYear?: string;
  targetYearMonth?: string;
  calculationTargetCode?: string;
  lastModifiedDateTime?: string;
};

export type Category = {
  value: string;
  name: string;
  note: string;
};
export type GetSupplierResponsesRequest = PaginationProps & {
  sortBy?: string;
  nameSearch?: string;
};

const H01 = () => {
  const {
    text: { common, H01, E0090 },
    language,
  } = useContext(LanguageContext)!;

  const { addMessage } = useContext(PortalContext)!;
  const registerModalState = useBooleanState(false);

  const supplierResponsesSearchMethods = useForm<{ nameSearch: string }>({
    values: { nameSearch: '' },
  });

  const { paginationProps, resetPaginations, PaginationComponent } = usePagin();
  const queryClient = useQueryClient();

  useEffect(() => {
    resetPaginations();
  }, [supplierResponsesSearchMethods.watch('nameSearch')]);

  const nameSearchDebounce = useDebounce(
    supplierResponsesSearchMethods.watch('nameSearch'),
    300,
  );

  const supplierResponsesRequest: GetSupplierResponsesRequest = {
    ...paginationProps,
    nameSearch: nameSearchDebounce,
  };

  const SupplierResponsesQuery = useHgbQueryWithPaging<
    SupplierResponse[],
    GetSupplierResponsesRequest
  >(API_URLS.BUYER_SUPPLIER_RESPONSE, supplierResponsesRequest, {
    queryKey: [QUERY_KEYS.EMISSION_LIST, supplierResponsesRequest, language],
  });

  const [selectedSupplier, setSelectedOwner] = useState<SupplierResponse>();

  const columns: ColumnsType<SupplierResponse> = [
    {
      title: common.columns.supplyChainGroupName,
      dataIndex: 'supplyChainGroupName',
      width: 240,
      render(value) {
        return <HgbCellTooltip>{value}</HgbCellTooltip>;
      },
    },
    {
      title: H01.columns.supplierName,
      dataIndex: 'supplierName',
      width: 200,
      render(value) {
        return <HgbCellTooltip>{value}</HgbCellTooltip>;
      },
    },
    {
      title: H01.columns.answerType,
      dataIndex: 'responseType',
      width: 180,
      render(value) {
        return <div>{value?.name}</div>;
      },
    },
    {
      title: H01.columns.responsePeriod,
      dataIndex: '_',
      width: 180,
      render(_, record) {
        return (
          <div>
            {moment(record.targetStartMonth, 'YYYYMM').format('YYYY/MM')} ~{' '}
            {moment(record.targetEndMonth, 'YYYYMM').format('YYYY/MM')}
          </div>
        );
      },
    },
    {
      title: common.label.productName,
      dataIndex: 'productName',
      width: 200,
      render(value) {
        return <HgbCellTooltip>{value || '－'}</HgbCellTooltip>;
      },
    },
    {
      title: common.label.emissions,
      dataIndex: 'emissionVolume',
      width: 150,
      render(value) {
        return (
          <div>
            {value}
            <br />
            tCO2
          </div>
        );
      },
    },
    {
      title: H01.columns.responseReceivedDateAndTime,
      dataIndex: 'sentTime',
      width: 150,
    },
    {
      title: H01.columns.answerReceivedStatus,
      dataIndex: 'status',
      width: 170,
      render(value) {
        return <div>{value?.name}</div>;
      },
    },
    {
      title: common.label.action,
      dataIndex: '_',
      align: 'center',
      render(_, record) {
        return (
          <div className="tw-flex tw-justify-center tw-gap-16">
            <HgbActionButton
              disabled={record.status?.value === STATUS.register}
              onClick={() => handleRegister(record)}
              icon={<UserSwitchOutlined />}
            >
              {common.button.add}
            </HgbActionButton>
            <HgbActionButton
              disabled={record.status?.value === STATUS.register}
              onClick={() => handleRemand(record)}
              icon={<StopOutlined />}
            >
              {H01.columns.return}
            </HgbActionButton>
          </div>
        );
      },
    },
  ];

  const rows: WithKey<SupplierResponse>[] = useMemo(() => {
    return (SupplierResponsesQuery?.data?.result?.content ?? []).map(
      (item) => ({
        ...item,
        key: item.id,
      }),
    );
  }, [SupplierResponsesQuery?.data?.result?.content]);

  const suspendSupplierProductMutate = useHgbMutationDelete<unknown, number>(
    API_URLS.BUYER_SUPPLIER_RESPONSE,
    {
      onSuccess: (data) => {
        if (data.statusCode === STATUS_CODE.deleteSuccess) {
          resetPaginations();
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.EMISSION_LIST],
          });
        }
      },
      isAlert: false,
    },
  );

  const { ConfirmModal, hgbConfirm } = useConfirm();
  const handleRemand = async (record: SupplierResponse) => {
    setSelectedOwner(record);
    const response = await hgbAxiosGetSingle<SupplierResponse>(
      API_URLS.BUYER_SUPPLIER_RESPONSE,
      record.id,
    );

    if (response?.status?.value !== record.status?.value) {
      addMessage('error', E0090);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.EMISSION_LIST],
      });
      return;
    }

    const result = await hgbConfirm();
    if (result) {
      const response = await hgbAxiosGetSingle<SupplierResponse>(
        API_URLS.BUYER_SUPPLIER_RESPONSE,
        record.id,
      );
      if (response?.status?.value !== record.status?.value) {
        addMessage('error', E0090);
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.EMISSION_LIST],
        });
        return;
      }
      suspendSupplierProductMutate.mutate(record?.id);
    }
  };

  const handleRegister = async (record: SupplierResponse) => {
    const response = await hgbAxiosGetSingle<SupplierResponse>(
      API_URLS.BUYER_SUPPLIER_RESPONSE,
      record.id,
    );
    if (
      response?.status?.value !== record.status?.value ||
      response?.lastModifiedDateTime !== record?.lastModifiedDateTime
    ) {
      addMessage('error', E0090);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.EMISSION_LIST],
      });
      return;
    }
    registerModalState.turnOn();
    setSelectedOwner(response);
  };

  return (
    <ManagementTemplate>
      <DataTableTemplate
        title={H01.title}
        paginationComponents={
          <PaginationComponent
            current={SupplierResponsesQuery.data?.result?.currentPage}
            total={SupplierResponsesQuery.data?.result?.totalRecord}
            pageSize={SupplierResponsesQuery.data?.result?.pageSize}
          />
        }
        inputsComponent={
          <div className="tw-rounded-8 tw-bg-primary-1/20 tw-p-16">
            <FormProvider {...supplierResponsesSearchMethods}>
              <HgbAntdInput
                {...supplierResponsesSearchMethods.register('nameSearch')}
                type="K"
                maxLength={12}
                search
                className="pc:tw-w-240"
                placeholder={common.placeholder.filter}
              />
            </FormProvider>
          </div>
        }
      >
        {rows.length > 0 ? (
          <Table
            columns={columns}
            dataSource={rows}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
        ) : null}

        {SupplierResponsesQuery.isLoading ? (
          <HgbAntdSpin placement="middle" />
        ) : null}

        {SupplierResponsesQuery.isFetched && rows.length === 0 ? (
          <HgbAntdEmpty description={common.message.noResult} />
        ) : null}
      </DataTableTemplate>

      {registerModalState.value && (
        <SupplierEmissionInputModal
          mode="ADD"
          pattern="PATTERN_1"
          modalState={registerModalState}
          supplier={selectedSupplier}
        />
      )}
      <ConfirmModal
        title={H01.modal.remandConfirmTitle}
        subTitle={H01.modal.remainConfirmSubtitle}
        okText={H01.columns.return}
        cancelText={common.button.cancel}
        className="tw-grid tw-grid-cols-[minmax(100px,_auto),_1fr] [&_p]:tw-font-regular"
      >
        <p>{H01.modal.enterpriseName}&nbsp;:</p>
        <p className="tw-break-all">&nbsp;{selectedSupplier?.supplierName}</p>
        <p>{H01.columns.responsePeriod}&nbsp;:</p>
        <p className="tw-break-all">
          &nbsp;{' '}
          {moment(selectedSupplier?.targetStartMonth, 'YYYYMM').format(
            'YYYY/MM',
          )}{' '}
          ~{' '}
          {moment(selectedSupplier?.targetEndMonth, 'YYYYMM').format('YYYY/MM')}
        </p>
        <p>{common.label.scope}&nbsp;:</p>{' '}
        <p className="tw-break-all">&nbsp;{common.label.scope3}</p>
        <p>{common.label.category}&nbsp;:</p>{' '}
        <p className="tw-break-all">&nbsp;{selectedSupplier?.category?.name}</p>
        <p>{common.label.emissions}&nbsp;:</p>{' '}
        <p className="tw-break-all">
          &nbsp;{selectedSupplier?.emissionVolume} tCO2{' '}
        </p>
      </ConfirmModal>
    </ManagementTemplate>
  );
};

export default H01;
