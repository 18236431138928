import { DivProps } from '@common/types';
import { cn } from '@utils/cn';
import { forwardRef } from 'react';

export const HgbBoxShadow = forwardRef<HTMLDivElement, DivProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(`tw-relative tw-bg-white ${className}`)}
        {...props}
      >
        {children}
        <div
          className="tw-absolute tw-inset-0 tw-rounded-[inherit]"
          style={{
            boxShadow: '0px 10px 40px #EFEFEF',
          }}
        />
      </div>
    );
  },
);
