import { PlusOutlined } from '@ant-design/icons';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import { HgbResponsive } from '@common/components';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellLink } from '@common/contents/HgbCellLink';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  parseScopeCategoryToHgbSelectOptions,
  parseToHgbSelectOptions,
} from '@common/forms';
import { ModalStatus, ModalTextWithType } from '@common/types';
import { API_URLS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import { MONTH_OF_YEAR_OPTIONS } from '@constants/options';
import { EMAIL_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddress } from '@hooks/useAddress';
import { useDebounce } from '@hooks/useDebound';
import { usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { MANUAL_NAMEDDESTS } from '@pages/Management/Support';
import {
  createMajorOptions,
  createMediumOptions,
} from '@utils/mapFixedDataD01';
import { emptyToUndefined } from '@utils/text';
import { Button, Spin, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import {
  FC,
  Key,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useHgbMutationDelete,
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import { useHgbQuery, useHgbQueryWithPaging } from 'services/common/query';
import {
  PaymentTypeRequest,
  PaymentTypeResponse,
  PaymentTypeResponseA07,
} from 'services/types/A07';
import {
  BANK_FORM_DEFAULT_VALUE,
  Bank,
  GetBankListBoxRequest,
  GetBankListBoxResponse,
} from 'services/types/bank';
import {
  GetCategoryListBoxRequest,
  GetCategoryListBoxResponse,
} from 'services/types/category';
import {
  CreateEnterpriseRequest,
  CreateEnterpriseResponse,
  ENTERPRISE_FORM_DEFAULT_VALUE,
  EmailByPicIDRequest,
  EmailByPicIDResponse,
  Enterprise,
  EnterpriseStatusRequest,
  EnterpriseStatusResponse,
  GetEnterpriseRequest,
  PicIDListboxRequest,
  PicIDListboxResponse,
  UpdateEnterpriseRequest,
  UpdateEnterpriseResponse,
} from 'services/types/enterprise';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import './D08.scss';
import usePendingEnterpriseTable from './PendingEnterpriseTable';
import { codeMajors, codeMediums } from './data';

const MODAL_STATUS_DEFAULT: ModalStatus = {
  isOpen: false,
  type: 'ADD',
};

const SEARCH_DEFAULT_VALUE: GetEnterpriseRequest = {
  status: 'INACTIVE,REGISTER,PENDING,ACTIVE',
};

interface DataType {
  key: Key;
  enterpriseName: Enterprise;
  billingStartDate: string;
  address1: string;
  representative: string;
  phoneNumber: string;
  number: Enterprise;
  picName: string;
  status?: Enterprise;
  action?: ReactNode | null;
}

export const ManagementBank: FC = () => {
  const {
    text: { D01, G01, common, E0000 },
    language,
  } = useContext(LanguageContext)!;

  const { user } = useContext(AuthContext)!;
  const { paginationProps, resetPaginations, PaginationComponent } = usePagin(
    10,
    true,
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pankId, setPankId] = useState<string>('');
  const [showViewPartnerInfoModal, setShowViewPartnerInfoModal] =
    useState(false);
  const [approveModal, setApproveModal] = useState({
    open: false,
    mode: 'ACTIVE',
  });
  const turnoffApproveModal = () =>
    setApproveModal({ mode: 'ACTIVE', open: false });

  const [modalStatus, setModalStatus] =
    useState<ModalStatus>(MODAL_STATUS_DEFAULT);
  const [tabActive, setTabActive] = useState('1');

  const tabItems: { label: string; key: string }[] = [
    { label: D01.label.activeTab, key: '1' },
    { label: D01.label.pendingTab, key: '2' },
  ];

  const enterpriseSchema = yup.object({
    enterpriseName: yup.string().required(E0000(common.label.enterpriseName)),
    enterpriseNameKana: yup
      .string()
      .required(E0000(common.label.enterpriseNameKana)),
    representative: yup.string().required(E0000(common.label.representative)),
    representativeKana: yup
      .string()
      .required(E0000(common.label.representativeKana)),
    postalCode: yup
      .string()
      .required(E0000(common.label.postalCode))
      .length(7, D01.message.postalCodeFormat),
    address1: yup.string().required(E0000(common.label.fullAddress)),
    // address2: yup.string().required(message.address2),
    startMonthOfYear: yup
      .string()
      .required(E0000(common.label.startMonthOfYear)),
    billingStartDate: yup
      .string()
      .required(E0000(common.label.billingDate))
      .when({
        is: () => user.subscriptionType !== 'COMPLIMENTARY',
        then: (schema) =>
          schema.test(
            D01.message.billingStartDateFormat,
            D01.message.billingStartDateFormat,
            () => validateBillingDate(),
          ),
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

    availableScope3CategoryArray: yup.array().when({
      is: () => enterprisePayload.groupStatus === 'ACTIVE',
      then: (schema) =>
        schema
          .min(1, E0000(D01.label.scope3DirectInput))
          .required(E0000(D01.label.scope3DirectInput))
          .typeError(E0000(D01.label.scope3DirectInput)),
    }),
    paymentType: yup.string().when({
      is: () =>
        modalStatus.type !== 'ADD' && user.subscriptionType !== 'COMPLIMENTARY',
      then: (schema) => schema.required(E0000(common.label.paymentMethod)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    hasAccount: yup.string().required(E0000(common.label.isCorporateAccount)),
    branchNumber: yup.string().when({
      is: () => enterprisePayload.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.branchNumber))
          .length(3, D01.message.branchNumberFormat);
      },
    }),
    corporateAccountNumber: yup.string().when({
      is: () => enterprisePayload.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.corporateAccountNumber))
          .length(7, D01.message.corporateAccountNumberFormat);
      },
    }),
    picName: yup.string().when({
      is: () => enterprisePayload.id?.toString() === '',
      then: (schema) => {
        return schema.required(E0000(common.label.picName));
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),

    picNameKana: yup.string().when({
      is: () => enterprisePayload.id?.toString() === '',
      then: (schema) => {
        return schema.required(E0000(common.label.picNameKana));
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    picEmail: yup
      .string()
      .required(E0000(common.label.picEmail))
      .matches(EMAIL_REGEX, common.message.emailFormat),
    isInvested: yup.string().required(E0000(D01.label.isInvested)),
    industryCodeMajorClassification: yup
      .string()
      .required(E0000(common.label.industryMajorCodeClassification)),
    industryCodeMediumClassification: yup
      .string()
      .required(E0000(common.label.industryMediumCodeClassification)),
  });

  const turnOffModal = () =>
    setModalStatus((prev) => ({ type: 'ADD', isOpen: false }));

  const searchMethods = useForm<GetEnterpriseRequest>({
    values: SEARCH_DEFAULT_VALUE,
  });
  const updateMethods = useForm<Enterprise>({
    resolver: yupResolver(enterpriseSchema),
    defaultValues: ENTERPRISE_FORM_DEFAULT_VALUE,
  });

  const updatePartnerInfos = useForm<Bank>({
    defaultValues: BANK_FORM_DEFAULT_VALUE,
  });

  const {
    watch: watchUpdatePartnerInfos,
    reset: resetUpdatePartnerInfos,
    register: registerUpdatePartnerInfos,
  } = updatePartnerInfos;

  const {
    watch: watchSearch,
    setValue: setValueSearch,
    register: registerSearch,
    reset: resetSearch,
  } = searchMethods;
  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    watch: watchUpdate,
    reset: resetUpdate,
    getValues: getUpdateValues,
    setValue: setUpdateValues,
    setError,
    clearErrors,
  } = updateMethods;
  const enterprisePayload = watchUpdate();

  const validateBillingDate: any = () => {
    const billingStartDefault =
      modalStatus.type === 'ADD'
        ? localStorage.getItem('billingStartDefault')
        : enterprisePayload.billingStartDateApproved
        ? enterprisePayload.billingStartDateApproved
        : localStorage.getItem('billingStartDefault');
    return (
      dayjs(billingStartDefault).isBefore(enterprisePayload.billingStartDate) ||
      dayjs(billingStartDefault).isSame(enterprisePayload.billingStartDate)
    );
  };

  const { data: banksQuery, isFetchedAfterMount: isBanksFetchedAfterMount } =
    useHgbQuery<GetBankListBoxResponse, GetBankListBoxRequest>(
      API_URLS.BANK_LIST_BOX_API_URL,
      {
        isLoadAllOption: true,
      },
      {
        enabled: user.isPresidingBank,
        queryKey: [API_URLS.BANK_LIST_BOX_API_URL, user.isPresidingBank],
      },
    );

  const { mutate: createEnterpriseMutate, isLoading: createLoading } =
    useHgbMutationPost<CreateEnterpriseResponse, CreateEnterpriseRequest>(
      API_URLS.ENTERPRISE_API_URL,
      {
        onSuccess: () => {
          turnOffModal();
          enterprisesRefetch();
        },
      },
    );

  const { data: categoryLBQuery } = useHgbQuery<
    GetCategoryListBoxResponse,
    GetCategoryListBoxRequest
  >(
    API_URLS.CATEGORY_LIST_BOX_API_URL,
    { scopeCode: '3' },
    {
      enabled: true,
      queryKey: [language],
    },
  );
  const categoryCodeOptions = useMemo(() => {
    const rs = parseScopeCategoryToHgbSelectOptions(categoryLBQuery?.result);
    return rs;
  }, [categoryLBQuery?.result]);

  useEffect(() => {
    setUpdateValues(
      'availableScope3CategoryArray',
      enterprisePayload?.availableScope3Category?.length > 0
        ? enterprisePayload?.availableScope3Category?.split(',')
        : [],
    );
  }, [modalStatus.isOpen]);

  useEffect(() => {
    resetSearch();
    setEnterpriseEmpty(false);
  }, [tabActive]);

  const { mutate: editEnterpriseMutate } = useHgbMutationPut<
    UpdateEnterpriseResponse,
    UpdateEnterpriseRequest
  >(API_URLS.ENTERPRISE_API_URL, {
    onSuccess: () => {
      turnOffModal();
      enterprisesRefetch();
    },
  });

  const { mutate: approveEnterpriseMutate, isLoading: approveLoading } =
    useHgbMutationPut<UpdateEnterpriseResponse, UpdateEnterpriseRequest>(
      API_URLS.APPROVE_ENTERPRISE_API_URL,
      {
        onSuccess: () => {
          turnoffApproveModal();
          turnOffModal();
          enterprisesRefetch();
        },
      },
    );

  const enterpriseParams: GetEnterpriseRequest = {
    ...watchSearch(),
    pageNo: paginationProps.pageNo,
    pageSize: paginationProps.pageSize,
  };

  const [enterpriseEmpty, setEnterpriseEmpty] = useState(false);
  const {
    data: enterprisesQuery,
    refetch: enterprisesRefetch,
    isFetching: enterprisesFetching,
  } = useHgbQueryWithPaging<Enterprise[], GetEnterpriseRequest>(
    API_URLS.ENTERPRISE_API_URL,
    {
      ...enterpriseParams,
      bankId: emptyToUndefined(enterpriseParams.bankId),
    },
    {
      enabled: false,
      onSuccess(data) {
        if (!data?.result?.content?.length) {
          setEnterpriseEmpty(true);
        } else {
          setEnterpriseEmpty(false);
        }
      },
      queryKey: [
        API_URLS.ENTERPRISE_API_URL,
        enterpriseParams.bankId,
        enterpriseParams.status,
        paginationProps.pageNo,
        tabActive,
      ],
    },
  );

  const enterpriseNameDebounce = useDebounce(enterpriseParams.nameSearch, 300);

  useEffect(() => {
    resetPaginations();
  }, [enterpriseNameDebounce]);

  useEffect(() => {
    if (user.isManagementBank || !isEmpty(enterpriseParams.bankId)) {
      enterprisesRefetch();
    }
  }, [
    enterpriseParams.bankId,
    enterpriseNameDebounce,
    enterpriseParams.pageNo,
    enterpriseParams.pageSize,
    enterpriseParams.status,
    tabActive,
  ]);

  const { mutate: deleteEnterpriseMutate } = useHgbMutationDelete(
    API_URLS.ENTERPRISE_API_URL,
    {
      onSuccess: () => {
        setShowDeleteModal(false);
        enterprisesRefetch();
        resetPaginations();
      },
    },
  );

  const [edited, setEdited] = useState(false);
  const [addressFromPostalCode] = useAddress(enterprisePayload.postalCode);
  useEffect(() => {
    if (edited) {
      setUpdateValues('address1', addressFromPostalCode.arch.full);
      if (addressFromPostalCode.error) {
        setError('postalCode', { message: D01.message.postalCodeFormat });
      } else {
        clearErrors('postalCode');
        clearErrors('address1');
      }
    }
  }, [addressFromPostalCode]);

  const handleSubmitDelete = () => {
    const request = getUpdateValues();
    deleteEnterpriseMutate(request.id.toString());
  };

  const resetEnterprisePayload = (item: Enterprise) => {
    const billingStartDate = isEmpty(item.billingStartDate)
      ? user.subscriptionType !== 'COMPLIMENTARY'
        ? (localStorage.getItem('billingStartDefault') as string)
        : ''
      : item.billingStartDate;
    resetUpdate({
      ...item,
      billingStartDate: billingStartDate,
    });
  };

  useHgbQuery<Bank, undefined>(
    `${API_URLS.BANK_API_URL}/${pankId}`,
    undefined,
    {
      onSuccess: (data) => {
        resetUpdatePartnerInfos({
          ...data.result,
          emailSignature: (data.result?.emailSignature || '')
            .split('<br />')
            .join('\n'),
          loginUrl: `${
            window.location.toString().split('/')[2]
          }/signin?bankCode=${data.result?.bankCode}`,
        });
      },
      queryKey: [`${API_URLS.BANK_API_URL}/${pankId}`],
      enabled: pankId !== '',
    },
  );

  const handleEdit = (item: Enterprise) => {
    resetEnterprisePayload(item);
    setModalStatus({ isOpen: true, type: 'UPDATE' });
  };

  const handleDelete = (item: Enterprise) => {
    resetEnterprisePayload(item);
    setShowDeleteModal(true);
  };

  const baseColumns: ColumnsType<DataType> = [
    {
      title: D01.table.id,
      dataIndex: 'id',
      width: 80,
    },
    ...(user.isPresidingBank
      ? [
          {
            title: common.placeholder.bankId,
            dataIndex: 'bankName',
            width: 160,
            render: (item: Enterprise) => (
              <HgbCellLink
                className="tw-inline-block tw-break-all tw-text-left"
                onClick={() => {
                  setPankId(item.bankId);
                  setShowViewPartnerInfoModal(true);
                }}
              >
                <HgbCellTooltip>{item.bankName}</HgbCellTooltip>
              </HgbCellLink>
            ),
          },
        ]
      : []),
    {
      title: common.label.enterpriseName,
      dataIndex: 'enterpriseName',
      width: 160,
      render: (item: Enterprise) =>
        user.isPresidingBank ? (
          <HgbCellTooltip>
            <p className="tw-break-all">{item.enterpriseName}</p>
          </HgbCellTooltip>
        ) : (
          <HgbCellLink
            className="tw-inline-block tw-break-all tw-text-left"
            onClick={() => {
              resetEnterprisePayload(item);
              setModalStatus({ isOpen: true, type: 'VIEW' });
            }}
          >
            <HgbCellTooltip>{item.enterpriseName}</HgbCellTooltip>
          </HgbCellLink>
        ),
    },
    {
      title: D01.table.representative,
      dataIndex: 'representative',
      width: 136,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },

    {
      title: D01.table.address1,
      dataIndex: 'address1',
      width: 190,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D01.table.phoneNumber,
      dataIndex: 'phoneNumber',
      width: 136,
    },
    {
      title: D01.table.billingDate,
      dataIndex: 'billingStartDate',
      width: 136,
    },
    {
      title: D01.table.number,
      dataIndex: 'number',
      width: 160,
      render: (item: Enterprise) =>
        `${item.branchNumber}-${item.corporateAccountNumber}`,
    },
    {
      title: D01.table.picName,
      dataIndex: 'picName',
      width: 136,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D01.table.status,
      dataIndex: 'status',
      width: 136,
      render: (item: Enterprise) => {
        if (user.isPresidingBank) {
          return item.status;
        }

        if (item.groupStatus !== 'PENDING') {
          return item.status;
        } else {
          return (
            <HgbCellLink
              className="tw-inline-block tw-text-left"
              onClick={() => {
                resetEnterprisePayload(item);
                setModalStatus({ isOpen: true, type: 'UPDATE' });
              }}
            >
              {item.status}
            </HgbCellLink>
          );
        }
      },
    },
  ];

  const activeColumns: ColumnsType<DataType> = user.isPresidingBank
    ? baseColumns
    : [
        ...baseColumns,
        {
          title: common.label.action,
          align: 'center',
          dataIndex: 'action',
          width: 160,
        },
      ];

  const activeRows = useMemo(() => {
    return (enterprisesQuery?.result?.content ?? []).map((item, index) => {
      return {
        key: index,
        id: item.id,
        bankName: item,
        billingStartDate: item.billingStartDate,
        address1: item.address1,
        enterpriseName: item,
        representative: item.representative,
        phoneNumber: item.phoneNumber,
        number: item,
        picName: item.picName,
        status: item,
        action: !user.isPresidingBank ? (
          <div className="tw-flex tw-justify-center tw-gap-24">
            <HgbActionButton
              onClick={() => handleEdit(item)}
              disabled={item.groupStatus !== 'ACTIVE'}
              icon={<EditOutlined />}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => handleDelete(item)}
              disabled={item.groupStatus !== 'ACTIVE'}
              icon={<DeleteOutlined />}
            >
              {common.button.delete}
            </HgbActionButton>
          </div>
        ) : null,
      };
    });
  }, [enterprisesQuery?.result?.content, user.isPresidingBank]);

  const bankOptions = parseToHgbSelectOptions(banksQuery?.result);

  const codeMajorOptions = createMajorOptions(codeMajors);

  const codeMediumOptions = useMemo(() => {
    const rs = createMediumOptions(
      codeMediums,
      enterprisePayload.industryCodeMajorClassification,
    );
    if (modalStatus.type !== 'ADD') {
      setUpdateValues(
        'industryCodeMediumClassification',
        enterprisePayload.industryCodeMediumClassification,
      );
    }
    return rs;
  }, [enterprisePayload.industryCodeMajorClassification, modalStatus.type]);

  const modalOkText: ModalTextWithType = {
    ADD: common.button.add,
    UPDATE: common.button.change,
  };

  const modalTitle: ModalTextWithType = {
    ADD: D01.modal.enterpriseAddTitle,
    UPDATE: D01.modal.enterpriseUpdateTitle,
    VIEW: D01.modal.enterpriseViewTitle,
  };

  const errorMessage = useMemo(() => {
    if (user.isPresidingBank) {
      if (isEmpty(bankOptions) && isBanksFetchedAfterMount) {
        return common.message.partnerBanksEmpty;
      }
      if (isEmpty(enterpriseParams.bankId)) {
        return common.message.choosePartnerBank;
      }
    }
  }, [user, enterpriseParams, bankOptions, language, isBanksFetchedAfterMount]);

  const { EnterpriseTableComponent, PendingPaginationComponent } =
    usePendingEnterpriseTable(
      enterpriseParams,
      enterpriseNameDebounce,
      tabActive,
      errorMessage,
    );

  const onError: SubmitErrorHandler<Enterprise> = () => {
    if (enterprisePayload.id?.toString() !== '') {
      if (
        !getUpdateValues('branchNumber') &&
        enterprisePayload.hasAccount !== '1'
      ) {
        setUpdateValues('branchNumber', '');
        clearErrors('branchNumber');
      }

      if (
        !getUpdateValues('corporateAccountNumber') &&
        enterprisePayload.hasAccount !== '1'
      ) {
        setUpdateValues('corporateAccountNumber', '');
        clearErrors('corporateAccountNumber');
      }

      if (!getUpdateValues('picName')) {
        setUpdateValues('picName', '');
        clearErrors('picName');
      }

      if (!getUpdateValues('picNameKana')) {
        setUpdateValues('picNameKana', '');
        clearErrors('picNameKana');
      }
    }
    if (addressFromPostalCode.error) {
      setError('postalCode', {
        message: D01.message.postalCodeFormat,
      });
      return;
    }
  };

  const { data: filterQuery } = useHgbQuery<
    EnterpriseStatusResponse,
    EnterpriseStatusRequest
  >(
    API_URLS.ENTERPRISE_STATUS_API_URL,
    { hasChildStatuses: false },
    {
      queryKey: [API_URLS.ENTERPRISE_STATUS_API_URL],
    },
  );

  const { data: paymentQuery } = useHgbQuery<
    PaymentTypeResponseA07,
    PaymentTypeRequest
  >(
    API_URLS.PAYMENT_TYPE_LISTBOX,
    {
      enterpriseId: enterprisePayload?.id,
    },
    {
      queryKey: [API_URLS.PAYMENT_TYPE_LISTBOX, enterprisePayload?.id],
    },
  );

  const paymentOption = useMemo(
    () => parseToHgbSelectOptions(paymentQuery?.result?.methodType),
    [paymentQuery?.result],
  );

  const { data: haveNoneQuery } = useHgbQuery<PaymentTypeResponse, undefined>(
    API_URLS.HAVE_NONE_LISTBOX,
    undefined,
    {
      queryKey: [API_URLS.HAVE_NONE_LISTBOX],
    },
  );

  const haveNoneOption = useMemo(
    () => parseToHgbSelectOptions(haveNoneQuery?.result),
    [haveNoneQuery?.result],
  );

  useEffect(() => {
    if (modalStatus.type === 'VIEW') {
      setUpdateValues('isInvested', enterprisePayload?.isInvested);
    }
  }, [enterprisePayload?.isInvested]);

  const { data: picIdQuery, refetch: picIdRefetch } = useHgbQuery<
    PicIDListboxResponse,
    PicIDListboxRequest
  >(
    API_URLS.PICID_LISTBOX_API_URL,
    {
      enterpriseId: enterprisePayload.id,
    },
    {
      enabled: modalStatus.isOpen === true && modalStatus.type !== 'ADD',
      queryKey: [
        API_URLS.PICID_LISTBOX_API_URL,
        enterprisePayload.groupStatus,
        enterprisePayload.id,
      ],
    },
  );

  const picIdOption = useMemo(() => {
    return parseToHgbSelectOptions(picIdQuery?.result);
  }, [picIdQuery?.result]);

  useEffect(() => {
    setUpdateValues('picId', (enterprisePayload?.picId ?? '').toString());
  }, [enterprisePayload.picId]);

  useHgbQuery<EmailByPicIDResponse, EmailByPicIDRequest>(
    API_URLS.PICEMAIL_API_URL,
    {
      picId: enterprisePayload.picId,
      enterpriseId: enterprisePayload.id,
    },
    {
      enabled: enterprisePayload.picId !== '',
      queryKey: [
        API_URLS.PICEMAIL_API_URL,
        enterprisePayload.picId,
        enterprisePayload.id,
      ],
      onSuccess: (data) => {
        if (data?.result) setUpdateValues('picEmail', data.result);
      },
    },
  );

  const onSubmit = (updatePayload: Enterprise) => {
    if (
      enterprisePayload.groupStatusSelect === 'ACTIVE' ||
      enterprisePayload.groupStatusSelect === 'INACTIVE'
    ) {
      setApproveModal({
        mode: enterprisePayload.groupStatusSelect,
        open: true,
      });
      setModalStatus((prev) => ({ ...prev, isOpen: false }));
      return;
    }

    if (enterprisePayload.groupStatusSelect === 'PENDING') {
      onSubmitApprove();
      return;
    }
    let passedPayload: any = { ...updatePayload };
    if (addressFromPostalCode.error) {
      setError('postalCode', {
        message: D01.message.postalCodeFormat,
      });
      return;
    }
    passedPayload = {
      ...passedPayload,
      availableScope3Category:
        passedPayload.availableScope3CategoryArray?.length > 0
          ? passedPayload.availableScope3CategoryArray.join(',')
          : '',
    };
    if (updatePayload.hasAccount === '2') {
      passedPayload.branchNumber = '';
      passedPayload.corporateAccountNumber = '';
    }
    const { id, ...createPayload } = passedPayload;
    modalStatus.type === 'ADD' &&
      !createLoading &&
      createEnterpriseMutate(createPayload);
    modalStatus.type === 'UPDATE' && editEnterpriseMutate(passedPayload);
  };

  const onSubmitApprove = () => {
    let passedPayload: Enterprise = {
      ...enterprisePayload,
      groupStatus: enterprisePayload.groupStatusSelect ?? '',
    };
    if (addressFromPostalCode.error) {
      setError('postalCode', {
        message: D01.message.postalCodeFormat,
      });
      return;
    }
    passedPayload = {
      ...passedPayload,
      availableScope3Category:
        passedPayload?.availableScope3CategoryArray &&
        passedPayload?.availableScope3CategoryArray?.length > 0
          ? (passedPayload?.availableScope3CategoryArray ?? []).join(',')
          : '',
    };
    if (enterprisePayload.hasAccount === '2') {
      passedPayload.branchNumber = '';
      passedPayload.corporateAccountNumber = '';
    }
    !approveLoading && approveEnterpriseMutate(passedPayload);
  };

  const onFilterClick = (status: string) => {
    const searchValues = watchSearch();
    if (searchValues.status.length === 0) {
      return setValueSearch('status', status);
    }
    const searchValuesArray = searchValues.status.split(',');
    const indexOfSelectedStatus = searchValuesArray.findIndex(
      (s) => s === status,
    );
    if (indexOfSelectedStatus > -1) {
      searchValuesArray.splice(indexOfSelectedStatus, 1);
    } else {
      searchValuesArray.push(status);
    }

    setValueSearch('status', searchValuesArray.join(','));
  };

  useEffect(() => {
    resetPaginations();
  }, [JSON.stringify(watchSearch().status)]);

  const groupStatusOption = useMemo(() => {
    return [
      { label: D01.label.pending, value: 'PENDING' },
      { label: D01.label.active, value: 'ACTIVE' },
      { label: D01.label.inactive, value: 'INACTIVE' },
    ];
  }, []);

  const tableRendered = useMemo(() => {
    if (tabActive === '1') {
      return enterprisesFetching ? (
        <Spin className="tw-absolute tw-left-1/2 tw-top-1/2 tw-z-50 -tw-translate-x-1/2 -tw-translate-y-1/2" />
      ) : (
        <>
          {enterpriseEmpty && (
            <HgbAntdEmpty description={common.message.noResult} />
          )}
          {!isEmpty(activeRows) && (
            <Table
              columns={activeColumns}
              dataSource={activeRows}
              pagination={false}
              className="tw-overflow-hidden"
              scroll={{
                x: activeColumns.reduce(
                  (prev, current) => prev + Number(current.width ?? 0),
                  0,
                ),
              }}
            />
          )}
          {errorMessage && <HgbAntdEmpty description={errorMessage} />}
        </>
      );
    } else {
      return <EnterpriseTableComponent />;
    }
  }, [
    tabActive,
    enterprisesFetching,
    enterpriseEmpty,
    activeRows,
    activeColumns,
  ]);

  const navigate = useNavigate();

  return (
    <ManagementTemplate pageName={D01.pageTitleBank}>
      <DataTableTemplate
        title={D01.pageTitleBank}
        inputsComponent={
          <FormProvider {...searchMethods}>
            <HgbResponsive
              gap
              className="tw-flex tw-flex-wrap tw-justify-between tw-bg-[#EFF7FB] tw-p-16"
            >
              <div className="tw-flex tw-flex-wrap tw-gap-24">
                {user.isPresidingBank && (
                  <HgbSelect
                    {...registerSearch('bankId')}
                    placeholder={common.placeholder.bankId}
                    options={bankOptions}
                    showSearch
                  />
                )}
                <HgbAntdInput
                  {...registerSearch('nameSearch')}
                  maxLength={100}
                  placeholder={common.placeholder.enterpriseNameSearch}
                  search
                />
              </div>

              {tabActive === '1' && (
                <div className="tw-flex tw-flex-wrap tw-gap-24">
                  {filterQuery?.result &&
                    filterQuery.result.map((filter, index) => {
                      return (
                        <Button
                          key={index}
                          onClick={() => {
                            onFilterClick(filter.value);
                          }}
                          type={
                            watchSearch()
                              .status.split(',')
                              .findIndex((s) => s === filter.value) >= 0
                              ? 'primary'
                              : 'link'
                          }
                        >
                          {filter.name}
                        </Button>
                      );
                    })}
                </div>
              )}
            </HgbResponsive>
          </FormProvider>
        }
        buttonComponent={
          <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between">
            <div className="tw-flex tw-flex-wrap tw-gap-8">
              {user.isManagementBank && (
                <HgbAntdButton
                  type="primary"
                  className="tw-ml-auto tw-shrink-0"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    resetEnterprisePayload({
                      ...ENTERPRISE_FORM_DEFAULT_VALUE,
                      paymentType: '',
                      groupStatus: 'ACTIVE',
                      groupStatusSelect: '',
                      availableScope3Category: categoryCodeOptions
                        ?.map((item) => item.value)
                        .join(','),
                      availableScope3CategoryArray: categoryCodeOptions?.map(
                        (item) => item.value,
                      ),
                    });
                    setModalStatus({ isOpen: true, type: 'ADD' });
                  }}
                >
                  <>{common.button.register}</>
                </HgbAntdButton>
              )}
              <HgbAntdButton
                type="primary"
                className="tw-ml-auto tw-shrink-0"
                onClick={() => {
                  return navigate(routs_en['/report/company'].link);
                }}
              >
                <>{common.button.enterpriseOutput}</>
              </HgbAntdButton>
            </div>
            <Tabs
              className="!tw-border-0 [&_.ant-tabs-tab-active_.ant-tabs-tab-btn]:tw-font-bold"
              rootClassName="enterprise-ant-tab"
              activeKey={tabActive}
              items={tabItems}
              onChange={(index) => {
                setTabActive(index.toString());
              }}
            />
          </div>
        }
        paginationComponents={
          tabActive === '1' ? (
            (enterprisesQuery?.result?.totalRecord ?? 0) > 0 && (
              <PaginationComponent
                current={enterprisesQuery?.result?.currentPage}
                total={enterprisesQuery?.result?.totalRecord}
                pageSize={enterprisesQuery?.result?.pageSize}
              />
            )
          ) : (
            <PendingPaginationComponent />
          )
        }
      >
        {tableRendered}
      </DataTableTemplate>

      <HgbAntdModal
        cancelText={common.button.cancel}
        okText={common.button.delete}
        open={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        formProps={{
          onSubmit: handleSubmitDelete,
        }}
        title={D01.modal.enterpriseDeleteTitle}
      >
        <p className="tw-text-center">
          {D01.message.deleteEnterpriseAlertSplit1}
          {enterprisePayload.enterpriseName}
          {D01.message.deleteEnterpriseAlertSplit2}
        </p>
      </HgbAntdModal>

      <HgbAntdModal
        cancelText={common.button.cancel}
        okText={
          approveModal.mode === 'ACTIVE'
            ? common.button.approve
            : common.button.denied
        }
        open={approveModal.open}
        onCancel={() => {
          turnoffApproveModal();
          setModalStatus((prev) => ({ ...prev, isOpen: true }));
        }}
        formProps={{
          onSubmit: handleSubmitUpdate(onSubmitApprove),
        }}
        reject={approveModal.mode !== 'ACTIVE'}
        title={
          approveModal.mode === 'ACTIVE'
            ? D01.modal.enterpriseApproveTitle
            : D01.modal.enterpriseDeniedTitle
        }
      >
        {approveModal.mode === 'ACTIVE'
          ? D01.label.approveContent(enterprisePayload.enterpriseName)
          : D01.label.deniedContent(enterprisePayload.enterpriseName)}
      </HgbAntdModal>

      <FormProvider {...updateMethods}>
        {modalStatus.isOpen && (
          <HgbAntdModal
            cancelText={common.button.cancel}
            okText={
              enterprisePayload.groupStatus === 'PENDING'
                ? modalStatus.type === 'VIEW'
                  ? modalOkText?.[modalStatus.type]
                  : common.button.save
                : modalOkText?.[modalStatus.type]
            }
            onCancel={turnOffModal}
            title={
              enterprisePayload.groupStatus === 'ACTIVE'
                ? modalTitle?.[modalStatus.type]
                : modalStatus.type !== 'UPDATE'
                ? modalTitle?.[modalStatus.type]
                : D01.modal.enterpriseAppropvalModalTitle
            }
            open
            formProps={{
              onSubmit: handleSubmitUpdate(onSubmit, onError),
            }}
          >
            <div className="tw-space-y-16">
              {(modalStatus.type === 'VIEW' ||
                modalStatus.type === 'UPDATE') && (
                <HgbAntdInput
                  disabled={true}
                  {...registerUpdate('enterpriseCode')}
                  maxLength={100}
                  type={'K'}
                  label={common.label.enterpriseCode}
                  placeholder={D01.placeholder.enterpriseCode}
                />
              )}
              <HgbAntdInput
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                {...registerUpdate('enterpriseName')}
                maxLength={100}
                type={'K'}
                required
                label={common.label.enterpriseName}
                placeholder={common.placeholder.enterpriseName}
              />
              <HgbAntdInput
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                {...registerUpdate('enterpriseNameKana')}
                maxLength={100}
                type={'K'}
                required
                label={common.label.enterpriseNameKana}
                placeholder={common.placeholder.enterpriseNameKana}
              />
              <HgbAntdInput
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                {...registerUpdate('representative')}
                maxLength={50}
                type={'K'}
                required
                label={common.label.representative}
                placeholder={D01.placeholder.representative}
              />
              <HgbAntdInput
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                {...registerUpdate('representativeKana')}
                maxLength={50}
                type={'K'}
                required
                label={common.label.representativeKana}
                placeholder={D01.placeholder.representativeKana}
              />
              <HgbAntdInput
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                {...registerUpdate('postalCode')}
                maxLength={7}
                type={'integer'}
                required
                label={common.label.postalCode}
                placeholder={common.placeholder.postalCode}
                onChangeValue={() => setEdited(true)}
              />
              <HgbAntdInput
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                {...registerUpdate('address1')}
                maxLength={100}
                type={'K'}
                required
                label={common.label.fullAddress}
                placeholder={D01.placeholder.address1}
              />
              <HgbAntdInput
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                {...registerUpdate('address2')}
                maxLength={100}
                type={'K'}
                // required
                label={common.label.address2}
                placeholder={D01.placeholder.address2}
              />
              <HgbAntdInput
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                {...registerUpdate('phoneNumber')}
                maxLength={11}
                type={'positiveInteger'}
                label={common.label.phoneNumber}
                placeholder={common.placeholder.phoneNumber}
              />

              <HgbSelect
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                {...registerUpdate('startMonthOfYear')}
                options={MONTH_OF_YEAR_OPTIONS}
                label={common.label.startMonthOfYear}
                placeholder={D01.placeholder.startMonthOfYear}
                required
              />
              <HgbSelect
                label={common.label.industryMajorCodeClassification}
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                className="tw-self-start"
                {...registerUpdate('industryCodeMajorClassification')}
                placeholder={''}
                options={codeMajorOptions}
                required
              />
              <HgbSelect
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING'
                }
                label={common.label.industryMediumCodeClassification}
                className="tw-self-start"
                {...registerUpdate('industryCodeMediumClassification')}
                placeholder={''}
                options={codeMediumOptions}
                required
              />
              <HgbDatePicker
                disabled={
                  modalStatus.type === 'VIEW' ||
                  user.subscriptionType === 'COMPLIMENTARY'
                }
                format="YYYY/MM"
                required={user.subscriptionType !== 'COMPLIMENTARY'}
                {...registerUpdate('billingStartDate')}
                label={common.label.billingDate}
                placeholder={D01.placeholder.billingDate}
                disabledDate
                modalStatus={modalStatus.type}
                info={
                  enterprisePayload.groupStatus === 'PENDING'
                    ? '/support/manual/' + MANUAL_NAMEDDESTS.about_freemium
                    : ''
                }
                allowClear={user.subscriptionType === 'COMPLIMENTARY'}
              />
              {user.subscriptionType !== 'COMPLIMENTARY' && (
                <HgbSelect
                  disabled={modalStatus.type === 'VIEW'}
                  {...registerUpdate('paymentType')}
                  defaultActiveFirstOption={
                    modalStatus.type !== 'ADD' &&
                    !isEmpty(enterprisePayload.paymentType)
                      ? true
                      : false
                  }
                  options={paymentOption}
                  label={common.label.paymentMethod}
                  required={
                    modalStatus.type !== 'ADD' &&
                    user.subscriptionType !== 'COMPLIMENTARY'
                  }
                  modalStatus={modalStatus.type}
                  placeholder={''}
                  info={
                    enterprisePayload.groupStatus === 'PENDING'
                      ? '/support/manual/' + MANUAL_NAMEDDESTS.about_payment
                      : ''
                  }
                  screenType={'C01'}
                />
              )}
              <HgbAntdRadioGroup
                modalStatus={modalStatus.type}
                disabled={modalStatus.type === 'VIEW'}
                {...registerUpdate('hasAccount')}
                options={haveNoneOption}
                label={common.label.isCorporateAccount}
                required
                info={
                  enterprisePayload.groupStatus === 'PENDING'
                    ? '/support/manual/' + MANUAL_NAMEDDESTS.about_account
                    : ''
                }
              />
              {enterprisePayload.hasAccount === '1' && (
                <HgbAntdInput
                  disabled={modalStatus.type === 'VIEW'}
                  {...registerUpdate('branchNumber')}
                  maxLength={3}
                  type={'integer'}
                  required
                  label={common.label.branchNumber}
                />
              )}
              {enterprisePayload.hasAccount === '1' && (
                <HgbAntdInput
                  disabled={modalStatus.type === 'VIEW'}
                  {...registerUpdate('corporateAccountNumber')}
                  maxLength={7}
                  type={'integer'}
                  required
                  label={common.label.corporateAccountNumber}
                />
              )}
              <HgbAntdInput
                modalStatus={modalStatus.type}
                disabled={modalStatus.type === 'VIEW'}
                {...registerUpdate('cif')}
                maxLength={15}
                type={'unicodeWithoutSpecicalCharacter'}
                label={D01.label.cif}
                info={
                  enterprisePayload.groupStatus === 'PENDING'
                    ? '/support/manual/' + MANUAL_NAMEDDESTS.about_cif
                    : ''
                }
              />
              <HgbAntdRadioGroup
                modalStatus={modalStatus.type}
                disabled={modalStatus.type === 'VIEW'}
                {...registerUpdate('isInvested')}
                options={haveNoneOption}
                label={D01.label.isInvested}
                required
                info={
                  enterprisePayload.groupStatus === 'PENDING'
                    ? '/support/manual/' + MANUAL_NAMEDDESTS.about_investment
                    : ''
                }
              />
              {enterprisePayload.groupStatus === 'ACTIVE' &&
                !isEmpty(enterprisePayload.id?.toString()) && (
                  <HgbSelect
                    disabled={modalStatus.type === 'VIEW'}
                    label={common.label.picName}
                    {...registerUpdate('picId')}
                    placeholder={''}
                    options={picIdOption}
                  />
                )}
              <HgbAntdInput
                disabled={
                  modalStatus.type === 'VIEW' ||
                  enterprisePayload.groupStatus === 'PENDING' ||
                  (enterprisePayload.groupStatus === 'ACTIVE' &&
                    !isEmpty(enterprisePayload.id?.toString()))
                }
                {...registerUpdate('picEmail')}
                label={common.label.picEmail}
                type="H"
                maxLength={100}
                required
              />
              {isEmpty(enterprisePayload.id?.toString()) && (
                <div className="tw-space-y-16">
                  <HgbAntdInput
                    disabled={modalStatus.type === 'VIEW'}
                    {...registerUpdate('picName')}
                    maxLength={50}
                    type={'K'}
                    required={isEmpty(enterprisePayload.id?.toString())}
                    label={common.label.picName}
                    className="tw-col-start-1"
                  />
                  <HgbAntdInput
                    disabled={modalStatus.type === 'VIEW'}
                    {...registerUpdate('picNameKana')}
                    maxLength={50}
                    type={'K'}
                    required={isEmpty(enterprisePayload.id?.toString())}
                    label={common.label.picNameKana}
                  />
                </div>
              )}

              {(enterprisePayload.groupStatus === 'ACTIVE' ||
                modalStatus.type === 'ADD') && (
                <HgbSelect
                  label={D01.label.scope3DirectInput}
                  disabled={modalStatus.type === 'VIEW'}
                  className="tw-self-start"
                  {...registerUpdate('availableScope3CategoryArray')}
                  placeholder={''}
                  options={categoryCodeOptions}
                  mode="multiple"
                  required
                />
              )}

              {enterprisePayload.groupStatus === 'PENDING' &&
                modalStatus.type !== 'VIEW' && (
                  <HgbAntdRadioGroup
                    modalStatus={modalStatus.type}
                    defaultValue={'PENDING'}
                    {...registerUpdate('groupStatusSelect')}
                    options={groupStatusOption}
                    label={D01.label.approve}
                    required
                    direction="vertical"
                    info={
                      enterprisePayload.groupStatus === 'PENDING'
                        ? '/support/manual/' +
                          MANUAL_NAMEDDESTS.about_enterprise_service_status
                        : ''
                    }
                  />
                )}
            </div>
          </HgbAntdModal>
        )}
      </FormProvider>

      <FormProvider {...updatePartnerInfos}>
        <HgbAntdModal
          cancelText={common.button.cancel}
          open={showViewPartnerInfoModal}
          onCancel={() => setShowViewPartnerInfoModal(false)}
          title={G01.modal.bankViewTitle}
        >
          <div className="tw-flex tw-flex-col tw-gap-24">
            <HgbAntdInput
              disabled
              required
              {...registerUpdatePartnerInfos('bankCode')}
              type="bankCode"
              maxLength={4}
              label={common.label.bankCode}
              placeholder={G01.placeholder.bankCode}
            />
            <HgbAntdInput
              disabled
              required
              {...registerUpdatePartnerInfos('bankName')}
              maxLength={20}
              type="K"
              label={G01.label.bankName}
              placeholder={G01.placeholder.bankName}
            />
            <HgbAntdInput
              required
              label={G01.label.area}
              placeholder={G01.placeholder.area}
              {...registerUpdatePartnerInfos('bankArea')}
              disabled
            />
            <HgbAntdInput
              required
              label={G01.label.province}
              placeholder={G01.placeholder.province}
              {...registerUpdatePartnerInfos('bankProvince')}
              disabled
            />
            <HgbAntdInput
              disabled
              {...registerUpdatePartnerInfos('loginUrl')}
              type="H"
              maxLength={100}
              label={G01.label.loginUrl}
              placeholder={G01.placeholder.loginUrl}
            />
            <HgbAntdInput
              {...registerUpdatePartnerInfos('personInCharge')}
              required
              maxLength={20}
              type="K"
              disabled
              label={G01.label.personInCharge}
              placeholder={G01.placeholder.personInCharge}
            />
            <HgbAntdInput
              {...registerUpdatePartnerInfos('personInChargeEmail')}
              maxLength={50}
              required
              type="hgbEmail"
              disabled
              label={G01.label.personInChargeEmail}
              placeholder={G01.placeholder.personInChargeEmail}
            />
            <HgbAntdInput
              {...registerUpdatePartnerInfos('privacyPolicyUrl')}
              required
              maxLength={255}
              type="H"
              disabled
              label={G01.label.privacyPolicyUrl}
              placeholder={G01.placeholder.privacyPolicyUrl}
            />
            <HgbAntdInput
              type="area"
              required
              maxLength={255}
              label={G01.label.signature}
              placeholder={G01.label.signature}
              {...registerUpdatePartnerInfos('emailSignature')}
              disabled
            />
          </div>
        </HgbAntdModal>
      </FormProvider>
    </ManagementTemplate>
  );
};
