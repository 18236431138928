import { getFiscalYearFromMonth } from '@utils/date.util';
import moment from 'moment';
import { SUPPLIER_RESPONSE_TYPE } from '../H02/H02';
import {
  BuyerManualInputPayload,
  BuyerSupplierProductPayload,
  Step1Fields,
  Step2Fields,
  SupplierAnswerModalMode,
  SupplierPattern,
  SupplierResponseOrganizationPayload,
  SupplierResponseProductPayload,
  ValueOf,
} from './SupplierEmissionInputModal';

export const getFieldsInfo = (
  mode: SupplierAnswerModalMode,
  pattern: SupplierPattern,
  supplierResponseType: ValueOf<typeof SUPPLIER_RESPONSE_TYPE>,
) => {
  let disabledFields: string[] = [];
  let availableFields: string[] = [];
  if (pattern === 'PATTERN_1') {
    if (mode === 'ADD') {
      disabledFields = [
        'calculationTarget',
        'supplyChainGroupName',
        'supplierId',
        'emissionVolume',
        'targetStartMonth',
        'targetEndMonth',
        'productId',
        'productSerialNumber',
        'quantity',
      ];
    }
    if (mode === 'EDIT') {
      disabledFields = [
        'category',
        'baseId',
        'calculationTarget',
        'supplyChainGroupName',
        'supplierId',
        'emissionVolume',
        'targetStartMonth',
        'targetEndMonth',
        'productId',
        'productSerialNumber',
        'quantity',
      ];
    }

    if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.ORG) {
      availableFields = [
        'supplyChainGroupName',
        'supplierId',
        'emissionVolume',
        'targetMonthPayload',
      ];
    }
    if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.PRODUCT) {
      availableFields = [
        'supplierId',
        'productId',
        'productSerialNumber',
        'quantity',
        'productEmissionIntensity',
        'emissionVolume',
        'targetMonthPayload',
      ];
    }
    if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL) {
      availableFields = [
        'supplierId',
        'emissionVolume',
        'targetMonthPayload',
      ];
    }
  }
  if (pattern === 'PATTERN_2') {
    if (mode === 'ADD') {
      if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.PRODUCT) {
        disabledFields = [
          'productSerialNumber',
          'emissionVolume',
        ];
      }
      if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL) {
        disabledFields = [];
      }
    }

    if (mode === 'EDIT') {
      if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.PRODUCT) {
        disabledFields = [
          'category',
          'baseId',
          'calculationTarget',
          'productSerialNumber',
          'emissionVolume',
        ];
      }
      if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL) {
        disabledFields = ['category', 'baseId', 'calculationTarget'];
      }
    }
    if (mode === 'DUPLICATE') {
      if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.PRODUCT) {
        disabledFields = [
          'calculationTarget',
          'productSerialNumber',
          'emissionVolume',
        ];
      }
      if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL) {
        disabledFields = ['calculationTarget'];
      }
    }
    // depend on calculation target?
    if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.PRODUCT) {
      availableFields = [
        'supplierId',
        'productId',
        'productSerialNumber',
        'quantity',
        'productEmissionIntensity',
        'emissionVolume',
        'targetMonthPayload',
      ];
    }
    if (supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL) {
      availableFields = [
        'Fiscal year',
        'supplierId',
        'emissionVolume',
      ];
    }
  }
  return { disabledFields, availableFields };
};

export const convert2StepsToSupplierResponsePayload = (
  step1Fields: Step1Fields,
  step2Fields: Step2Fields,
  enabledMonths: string[],
  pattern: SupplierPattern,
  responseType: ValueOf<typeof SUPPLIER_RESPONSE_TYPE>,
  answerMode: SupplierAnswerModalMode,
  status = '',
):
  | SupplierResponseProductPayload
  | SupplierResponseOrganizationPayload
  | BuyerManualInputPayload
  | BuyerSupplierProductPayload
  | null => {
  const category = step1Fields.category;
  const calculationTarget = step1Fields.calculationTarget;
  const baseId = Number(step1Fields.baseId);
  const targetStartMonth =
    moment(step2Fields.targetStartMonth, 'YYYY/MM').format('YYYYMM') || '';
  const targetEndMonth =
    moment(step2Fields.targetEndMonth, 'YYYY/MM').format('YYYYMM') || '';
  const targetYearMonth =
    moment(step2Fields.targetYearMonth, 'YYYY/MM').format('YYYYMM') || '';
  const fiscalYear = getFiscalYearFromMonth(targetStartMonth);
  const targetMonthPayload: Record<string, number> = {};
  enabledMonths.forEach((month) => {
    const value = step2Fields[('m' + month) as 'm01'];
    let key = `${fiscalYear}${month}`;
    if (['01', '02', '03'].includes(month)) {
      key = `${fiscalYear + 1}${month}`;
    }
    targetMonthPayload[key] = Number(value);
  });

  const supplierResponseId = step2Fields.supplierResponseId;
  const productEmissionIntensity = step2Fields.productEmissionIntensity;
  const productEmissionIntensityUnit = step2Fields.productEmissionIntensityUnit;
  const buyerInputEmissionVolume = step2Fields?.emissionVolume;
  const quantity = Number(step2Fields.quantity);
  const productId = Number(step2Fields.productId);
  const supplierId = Number(step2Fields.supplierId);
  const mode = answerMode === 'DUPLICATE' ? 'ADD' : answerMode;
  const lastModifiedDateTime = step2Fields?.lastModifiedDateTime;

  if (pattern === 'PATTERN_1' && responseType === SUPPLIER_RESPONSE_TYPE.ORG) {
    const supplierResponseOrganizationPayload: SupplierResponseOrganizationPayload =
      {
        category,
        calculationTarget,
        baseId,
        targetStartMonth,
        targetEndMonth,
        targetMonthPayload,
        supplierResponseId,
        buyerInputEmissionVolume,
        fiscalYear,
        supplierId,
        mode,
        status,
        lastModifiedDateTime,
      };
    return supplierResponseOrganizationPayload;
  }

  if (
    pattern === 'PATTERN_1' &&
    responseType === SUPPLIER_RESPONSE_TYPE.PRODUCT
  ) {
    const supplierResponseProductPayload: SupplierResponseProductPayload = {
      category,
      calculationTarget,
      baseId,
      targetStartMonth,
      targetEndMonth,
      targetMonthPayload,
      supplierResponseId,
      fiscalYear,
      productEmissionIntensity,
      productEmissionIntensityUnit,
      buyerInputEmissionVolume,
      quantity,
      productId,
      supplierId,
      mode,
      status,
      lastModifiedDateTime,
    };
    return supplierResponseProductPayload;
  }
  if (
    pattern === 'PATTERN_2' &&
    responseType === SUPPLIER_RESPONSE_TYPE.MANUAL
  ) {
    const supplierResponseProductPayload: BuyerManualInputPayload = {
      category,
      calculationTarget,
      baseId,
      fiscalYear: Number(step2Fields.fiscalYear) || fiscalYear,
      supplierId,
      mode,
      status,
      targetYearMonth,
      buyerInputEmissionVolume,
      ...(answerMode === 'DUPLICATE' ? {} : { supplierResponseId }),
    };
    return supplierResponseProductPayload;
  }

  if (
    pattern === 'PATTERN_2' &&
    responseType === SUPPLIER_RESPONSE_TYPE.PRODUCT
  ) {
    const supplierResponseProductPayload: BuyerSupplierProductPayload = {
      category,
      calculationTarget,
      baseId,
      fiscalYear: Number(step2Fields.fiscalYear) || fiscalYear,
      supplierId,
      mode,
      targetStartMonth,
      targetEndMonth,
      targetMonthPayload,
      quantity,
      productId,
      productEmissionIntensity,
      productEmissionIntensityUnit,
      buyerInputEmissionVolume,
      ...(answerMode === 'DUPLICATE' ? {} : { status }),
      ...(answerMode === 'DUPLICATE' ? {} : { supplierResponseId }),
    };
    return supplierResponseProductPayload;
  }

  return null;
};

export const convertDataAllocationRates = (
  allocationRates: Record<string, number> | undefined,
) => {
  if (!allocationRates || Object.keys(allocationRates).length === 0) {
    return allocationRates;
  }
  const entries = Object.entries(allocationRates);
  const transformedValuesMonth = entries.reduce<Record<string, number>>(
    (acc, [key, value]) => {
      const month = key.slice(-2);
      const newKey = `m${month.padStart(2, '0')}`;
      acc[newKey] = value;

      return acc;
    },
    {},
  );
  return transformedValuesMonth;
};

export const step1DefaultValues: Step1Fields = {
  baseId: '',
  scope: '',
  category: '',
  calculationTarget: '',
};

export const step2DefaultValues: Step2Fields = {
  m01: '',
  m02: '',
  m03: '',
  m04: '',
  m05: '',
  m06: '',
  m07: '',
  m08: '',
  m09: '',
  m10: '',
  m11: '',
  m12: '',
  targetStartMonth: '',
  targetEndMonth: '',
  supplierResponseId: '',
  fiscalYear: '',
  productEmissionIntensity: '',
  productEmissionIntensityUnit: '',
  quantity: '',
  productId: '',
  supplierId: '',
  requestTimestamp: '',
  signature: '',
  productSerialNumber: '',
  emissionIntensity: '',
  emissionVolume: '',
  supplyChainGroupName: '',
  total: '',
  lastModifiedDateTime: '',
};
