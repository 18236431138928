import { cn } from '@utils/cn';
import * as React from 'react';

const HgbAntdModalTitle: React.FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <span
      className={cn(
        'tw-block tw-px-32 tw-pb-24 tw-pt-16 tw-text-center tw-text-h1 tw-font-bold tw-leading-[1.2] pc:tw-text-h1Tablet pc:tw-leading-[1.2]',
        className,
      )}
      {...props}
    />
  );
};

export { HgbAntdModalTitle };

