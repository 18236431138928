export type RouteItem = {
  link: string;
  name: string;
};
export type RoutDef =
  | '/not-found'
  | '/access-denied'
  | '/components'
  | '/reissue'
  | '/signin'
  | '/logout'
  | '/session-timeout'
  | '/change-password'
  | '/dashboard'
  | '/data-entry'
  | '/data-entry/ocr'
  | '/data-entry/csv'
  | '/report'
  | '/report/emission'
  | '/report/company'
  | '/support'
  | '/support/manual'
  | '/support/faq'
  | '/management'
  | '/management/bank'
  | '/management/company'
  | '/management/organization'
  | '/management/user'
  | '/management/emission'
  | '/management/introducer'
  | '/management/supplychain-buyer'
  | '/management/supply-chain'
  | '/management/supplychain-supplier'
  | '/management/supplier-product'
  | '/management/lci-db-pload'
  | '/support/message'
  | '/register'
  | '/register/fail'
  | '/temporarily-register'
  | '/temporarily-register/success'
  | '/temporarily-register/fail'
  | '/support/contact-us'
  | '/group'
  | '/group/supplier-receive'
  | '/group/supplier-submission'
  | '/support/contact-us';

export const routs_jp: Record<RoutDef, RouteItem> = {
  '/not-found': { link: '/not-found', name: '' },
  '/access-denied': { link: '/access-denied', name: '' },
  '/components': { link: '/components', name: 'components' },
  '/reissue': { link: '/reissue', name: '' },
  '/signin': { link: '/signin', name: '' },
  '/logout': { link: '/logout', name: 'ログアウト' },
  '/session-timeout': { link: '/session-timeout', name: '' },
  '/change-password': { link: '/change-password', name: 'パスワード変更' },
  '/dashboard': { link: '/dashboard', name: 'ダッシュボード' },
  '/data-entry': {
    link: '/data-entry',
    name: '入力・アップロード',
  },
  '/data-entry/ocr': {
    link: '/data-entry/ocr',
    name: '入力・アップロード',
  },
  '/data-entry/csv': {
    link: '/data-entry/csv',
    name: '入力・アップロード',
  },
  '/report': { link: '', name: 'レポート' },
  '/report/emission': { link: '/report/emission', name: 'CO2排出量レポート' },
  '/report/company': { link: '/report/company', name: '企業情報一覧表' },
  '/support': {
    link: '',
    name: 'サポート',
  },
  '/support/manual': {
    link: '/support/manual/:manualFile',
    name: 'マニュアル',
  },
  '/support/contact-us': { link: '/support/contact-us', name: 'お問い合わせ' },
  '/support/faq': { link: '/support/faq', name: 'FAQ' },
  '/management': { link: '', name: '設定' },
  '/management/bank': { link: '/management/bank', name: 'パートナー銀行' },
  '/management/company': { link: '/management/company', name: '企業' },
  '/management/organization': {
    link: '/management/organization',
    name: '拠点・組織',
  },
  '/management/user': { link: '/management/user', name: 'ユーザー' },
  '/management/emission': { link: '/management/emission', name: '排出量目標' },
  '/management/introducer': {
    link: '/management/introducer',
    name: '紹介会社',
  },
  '/group': {
    link: '/group',
    name: 'グループ',
  },
  '/group/supplier-receive': {
    link: '/group/supplier-receive',
    name: '回答受信',
  },
  '/group/supplier-submission': {
    link: '/group/supplier-submission',
    name: '回答送信',
  },
  '/management/supply-chain': {
    link: '/management/supply-chain',
    name: 'サプライチェーングループ',
  },
  '/management/supplychain-buyer': {
    link: '/management/supplychain-buyer',
    name: 'グループ作成',
  },
  '/management/supplychain-supplier': {
    link: '/management/supplychain-supplier',
    name: 'グループ参加​',
  },
  '/management/supplier-product': {
    link: '/management/supplier-product',
    name: '製品情報登録',
  },
  '/management/lci-db-pload': {
    link: '/management/lci-db-pload',
    name: 'LCI-DB取込',
  },
  '/support/message': {
    link: '/support/message',
    name: 'メッセージ配信',
  },
  '/register': {
    link: '/register',
    name: '仮申込',
  },
  '/register/fail': {
    link: '/register/fail',
    name: '',
  },
  '/temporarily-register': {
    link: '/temporarily-register/:registerId',
    name: '企業利用申込情報入力（本申込）',
  },
  '/temporarily-register/success': {
    link: '/temporarily-register/success',
    name: '企業利用申込情報入力（本申込）',
  },
  '/temporarily-register/fail': {
    link: '/temporarily-register/fail',
    name: '企業利用申込情報入力（本申込）',
  },
};

export const routs_en: Record<RoutDef, RouteItem> = {
  '/not-found': { link: '/not-found', name: '' },
  '/access-denied': { link: '/access-denied', name: '' },
  '/components': { link: '/components', name: 'components' },
  '/reissue': { link: '/reissue', name: '' },
  '/signin': { link: '/signin', name: '' },
  '/logout': { link: '/logout', name: 'Logout' },
  '/session-timeout': { link: '/session-timeout', name: '' },
  '/change-password': { link: '/change-password', name: 'Change password' },
  '/dashboard': { link: '/dashboard', name: 'Dashboard' },
  '/data-entry': {
    link: '/data-entry',
    name: 'Input/Upload',
  },
  '/data-entry/ocr': {
    link: '/data-entry/ocr',
    name: 'Input/Upload',
  },
  '/data-entry/csv': {
    link: '/data-entry/csv',
    name: 'Input/Upload',
  },
  '/report': { link: '', name: 'Report' },
  '/report/emission': {
    link: '/report/emission',
    name: 'CO2 emissions report',
  },
  '/report/company': {
    link: '/report/company',
    name: 'Company information list',
  },
  '/support': {
    link: '',
    name: 'Support',
  },
  '/support/manual': {
    link: '/support/manual/:manualFile',
    name: 'Manual',
  },
  '/support/contact-us': { link: '/support/contact-us', name: 'Contact Us' },
  '/support/faq': { link: '/support/faq', name: 'FAQ' },
  '/management': { link: '', name: 'Management' },
  '/management/bank': { link: '/management/bank', name: 'Bank' },
  '/management/company': { link: '/management/company', name: 'Company' },
  '/management/organization': {
    link: '/management/organization',
    name: 'Bases-Organizations',
  },
  '/management/user': { link: '/management/user', name: 'User' },
  '/management/emission': {
    link: '/management/emission',
    name: 'Emission Target',
  },
  '/management/introducer': {
    link: '/management/introducer',
    name: 'Introducer​',
  },
  '/management/supply-chain': {
    link: '/management/supply-chain',
    name: 'Supply chain group',
  },
  '/management/supplychain-buyer': {
    link: '/management/supplychain-buyer',
    name: 'Administered Groups',
  },
  '/management/supplychain-supplier': {
    link: '/management/supplychain-supplier',
    name: 'Participating Groups',
  },
  '/management/supplier-product': {
    link: '/management/supplier-product',
    name: "Supplier's Product",
  },

  '/management/lci-db-pload': {
    link: '/management/lci-db-pload',
    name: 'LCI-DB Import',
  },
  '/group': {
    link: '/group',
    name: 'Group',
  },
  '/group/supplier-receive': {
    link: '/group/supplier-receive',
    name: 'Receive Response',
  },
  '/group/supplier-submission': {
    link: '/group/supplier-submission',
    name: 'Submit Response',
  },
  '/support/message': {
    link: '/support/message',
    name: 'Message Distribution List',
  },
  '/register': {
    link: '/register',
    name: 'Temporary Register',
  },
  '/register/fail': {
    link: '/register/fail',
    name: '',
  },
  '/temporarily-register': {
    link: '/temporarily-register/:registerId',
    name: 'Entering corporate usage application information (main application)',
  },
  '/temporarily-register/success': {
    link: '/temporarily-register/success',
    name: '企業利用申込情報入力（本申込）',
  },
  '/temporarily-register/fail': {
    link: '/temporarily-register/fail',
    name: '企業利用申込情報入力（本申込）',
  },
};
