import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbAntdInput } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { EMAIL_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddress } from '@hooks/useAddress';
import { BooleanState } from '@hooks/useBooleanState';
import { debug } from '@utils/debug';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPost } from 'services/common/mutation';
import * as yup from 'yup';

interface IAddIntroducerModalProps {
  modalState: BooleanState;
}

type AddIntroducerPayload = {
  bankCode: string;
  introducerName: string;
  introducerNameKana: string;
  postalCode: string;
  address: string;
  buildingName: string;
  phoneNumber?: string;
  picEmail: string;
  picName: string;
  picNameKana: string;
};

const defaultAddIntroducerPayload: AddIntroducerPayload = {
  bankCode: '',
  introducerName: '',
  introducerNameKana: '',
  postalCode: '',
  address: '',
  buildingName: '',
  picEmail: '',
  picName: '',
  picNameKana: '',
};

const AddIntroducerModal: React.FunctionComponent<IAddIntroducerModalProps> = ({
  modalState,
}) => {
  const {
    text: { common, D22, E0000 },
  } = React.useContext(LanguageContext)!;
  const queryClient = useQueryClient();
  const { user } = React.useContext(AuthContext)!;

  const addIntroducerSchema = yup.object({
    introducerName: yup.string().required(E0000(D22.label.introducerName)),
    introducerNameKana: yup
      .string()
      .required(E0000(D22.label.introducerNameKana)),
    postalCode: yup.string().required(E0000(common.label.postalCode)),
    address: yup.string().required(E0000(common.label.fullAddress)),
    buildingName: yup.string().required(E0000(D22.label.buildingName)),
    picEmail: yup
      .string()
      .required(E0000(D22.label.PICEmail))
      .matches(EMAIL_REGEX, common.message.emailFormat),
    picName: yup.string().required(E0000(D22.label.PICName)),
    picNameKana: yup.string().required(E0000(D22.label.PICNameKana)),
  });

  const addIntroducerMethods = useForm<AddIntroducerPayload>({
    resolver: yupResolver(addIntroducerSchema),
    values: defaultAddIntroducerPayload,
  });

  const addIntroducerMutate = useHgbMutationPost<unknown, AddIntroducerPayload>(
    API_URLS.CREATE_INTRODUCER,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [API_URLS.LIST_INTRODUCERS],
        });
        modalState.turnOff();
        addIntroducerMethods.reset(defaultAddIntroducerPayload);
      },
      onError: () => {},
    },
  );

  const handleSubmit = addIntroducerMethods.handleSubmit(
    (data, e) => {
      addIntroducerMutate.mutate({ ...data, bankCode: user?.bankCode ?? '' });
    },
    (error) => {
      debug.error(error);
    },
  );

  const [addressFromPostalCode] = useAddress(
    addIntroducerMethods.watch('postalCode'),
  );

  React.useEffect(() => {
    if (!!addressFromPostalCode.arch.full) {
      addIntroducerMethods.setValue('address', addressFromPostalCode.arch.full);
    }
  }, [addressFromPostalCode.arch.full]);

  return (
    <>
      <FormProvider {...addIntroducerMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={
            <HgbAntdModalTitle>{D22.label.addIntroducer}</HgbAntdModalTitle>
          }
          open={modalState.value}
          onCancel={modalState.turnOff}
          destroyOnClose={true}
          footer={null}
          closable={true}
          maskClosable={false}
          afterClose={() =>
            addIntroducerMethods.reset(defaultAddIntroducerPayload)
          }
        >
          <HgbAntdModalForm onSubmit={handleSubmit}>
            <HgbAntdModalFormBody>
              <HgbAntdInput
                {...addIntroducerMethods.register('introducerName')}
                maxLength={100}
                type={'K'}
                required
                label={D22.label.introducerName}
                placeholder={'XXX株式企業'}
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('introducerNameKana')}
                maxLength={100}
                type={'K'}
                required
                label={D22.label.introducerNameKana}
                placeholder={'XXXカブシキガイシャ'}
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('postalCode')}
                maxLength={7}
                type={'integer'}
                required
                label={common.label.postalCode}
                placeholder="8120011"
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('address')}
                maxLength={100}
                type={'K'}
                required
                label={common.label.fullAddress}
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('buildingName')}
                maxLength={100}
                type={'K'}
                required
                label={D22.label.buildingName}
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('phoneNumber')}
                maxLength={11}
                type={'positiveInteger'}
                label={D22.label.phoneNumber}
                placeholder={common.placeholder.phoneNumber}
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('picEmail')}
                maxLength={100}
                type="hgbEmail"
                required
                label={common.label.picEmail}
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('picName')}
                maxLength={50}
                type={'K'}
                label={D22.label.PICName}
                required
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('picNameKana')}
                maxLength={50}
                type={'K'}
                label={D22.label.PICNameKana}
                required
              />
              <div className="tw-space-y-4">
                <label className="tw-text-paragraph tw-font-bold">
                  {D22.label.supportCode}
                </label>
                <p>{D22.label.supportCodeDescription}</p>
              </div>
            </HgbAntdModalFormBody>
            <HgbAntdModalFormFooter>
              <HgbAntdButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
              >
                {common.button.cancel}
              </HgbAntdButton>
              <HgbAntdButton
                type="primary"
                className="tw-min-w-100"
                htmlType="submit"
              >
                {common.button.add}
              </HgbAntdButton>
            </HgbAntdModalFormFooter>
          </HgbAntdModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default AddIntroducerModal;
