import { AuthenTemplate } from '@layouts/templates';
import * as React from 'react';

interface IMaintenanceProps {}

const Maintenance: React.FunctionComponent<IMaintenanceProps> = (props) => {
  return (
    <AuthenTemplate>
      <br />
      <p className="tw-text-center tw-font-bold">
        ただいまメンテナンス中です。
      </p>
      <br />
      <p>
        平素は、Zero-Carbon-System（炭削くん）をご利用いただき、誠にありがとうございます。
      </p>
      <p>このたび、以下の日程でシステムメンテナンスを実施いたします。</p>
      <br />
      <p>システムメンテナンス日時：</p>
      <p>
        <b>2024年8月15日（木）12:00～14:00</b>
      </p>
      <p>※作業状況により終了時刻は前後する場合がございます。</p>
      <br />
      <p>なお、メンテナンス中は本サービスをご利用いただくことができません。</p>
      <br />
      <p>
        お客さまにはご不便をおかけいたしますが、ご理解賜りますようよろしくお願い申し上げます。
      </p>
    </AuthenTemplate>
  );
};

export default Maintenance;
