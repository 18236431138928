import { STATUS_CODE } from '@constants/consts';
import { PortalContext } from '@contexts/PortalContext';
import { hgbAxios } from '@utils/axios';
import { LocalDataClass } from 'data-class/LocalDataClass';
import { useContext } from 'react';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { PostRespone } from 'services/types/common';

export const getMessageType = (code: number): 'error' | 'success' => {
  if (
    code === STATUS_CODE.shouldChangePassword ||
    code === STATUS_CODE.mustChangePassword
  ) {
    return 'error';
  }
  const codeStr = code.toString().split('')[2];
  switch (codeStr) {
    case '0':
      return 'success';
    default:
      return 'error';
  }
};

export const useHgbMutationPost = <
  TData = unknown,
  TVariables = unknown,
  TError = unknown,
  TContext = unknown,
>(
  url: string,
  options?: UseMutationOptions<
    PostRespone<TData>,
    TError,
    TVariables,
    TContext
  > & { isAlert?: boolean; isDownload?: boolean, preventAlertStatusCode?: number },
) => {
  const { addMessage, setFileName } = useContext(PortalContext)!;
  return useMutation<PostRespone<TData>, TError, TVariables, TContext>({
    ...options,
    mutationFn: async (data) => {
      const isAlert = options?.isAlert ?? true;
      const isDownload = options?.isAlert ?? false;
      const language = LocalDataClass.language;
      const queryString = `${url}?lang=${language}`;
      try {
        let rs;
        if (isDownload) {
          rs = (await hgbAxios('download').post(queryString, data))?.data;
        } else {
          rs = (await hgbAxios().post(queryString, data))?.data;
        }
        const rsStamp = rs as PostRespone<TData>;
        const messageType = getMessageType(rsStamp.statusCode ?? 0);
        isAlert && addMessage(messageType, rsStamp.message ?? '');
        return rs;
      } catch (error: any) {
        if (error?.code === 'ERR_NETWORK') {
          addMessage('error', 'Network error');
          return;
        }
        const fileName = error.response?.headers?.['content-disposition']
          ?.toString()
          ?.split('; filename=')?.[1];
        setFileName(fileName ?? '');
        if(options?.preventAlertStatusCode === error?.response?.data?.statusCode){
          return error?.response?.data;
        }
        isAlert &&
          error?.response?.data?.message &&
          addMessage('error', error?.response?.data?.message);
        return error?.response?.data;
      }
    },
  });
};

export const useHgbMutationPut = <
  TData = unknown,
  TVariables = unknown,
  TError = unknown,
  TContext = unknown,
>(
  url: string,
  options?: UseMutationOptions<
    PostRespone<TData>,
    TError,
    TVariables,
    TContext
  > & { isAlert?: boolean; preventAlertStatusCode?: number },
) => {
  const { addMessage } = useContext(PortalContext)!;
  const queryClient = useQueryClient();
  return useMutation<PostRespone<TData>, TError, TVariables, TContext>({
    ...options,
    mutationFn: async (data) => {
      const isAlert = options?.isAlert ?? true;
      const dataPut: any = data;
      const { id, ...rest } = dataPut;
      const language = LocalDataClass.language;
      const queryString = `${url}/${id}?lang=${language}`;
      try {
        const rs = (await hgbAxios().put(queryString, rest))?.data;
        const rsStamp = rs as PostRespone<TData>;
        const messageType = getMessageType(rsStamp?.statusCode ?? 0);
        queryClient.invalidateQueries({ queryKey: options?.mutationKey });
        isAlert && addMessage(messageType, rsStamp?.message ?? '');
        return rs;
      } catch (error: any) {
        if (error?.code === 'ERR_NETWORK') {
          addMessage('error', 'Network error');
          return;
        }
        if (
          options?.preventAlertStatusCode === error?.response?.data?.statusCode
        ) {
          return error?.response?.data;
        }
        isAlert && addMessage('error', error?.response?.data?.message);
        return error?.response?.data;
      }
    },
  });
};

export const useHgbMutationDelete = <
  TData = unknown,
  TVariables = unknown,
  TError = unknown,
  TContext = unknown,
>(
  url: string,
  options?: UseMutationOptions<
    PostRespone<TData>,
    TError,
    TVariables,
    TContext
  > & { isAlert?: boolean },
) => {
  const { addMessage } = useContext(PortalContext)!;
  return useMutation<PostRespone<TData>, TError, TVariables, TContext>({
    ...options,
    mutationFn: async (data) => {
      const isAlert = options?.isAlert ?? true;
      const language = LocalDataClass.language;
      const queryString = `${url}/${data}?lang=${language}`;
      try {
        const rs = (await hgbAxios().delete(queryString))?.data;
        const rsStamp = rs as PostRespone<TData>;
        const messageType = getMessageType(rsStamp.statusCode ?? 0);
        isAlert && addMessage(messageType, rsStamp.message ?? '');
        return rs;
      } catch (error: any) {
        if (error?.code === 'ERR_NETWORK') {
          addMessage('error', 'Network error');
          return;
        }
        isAlert && addMessage('error', error?.response?.data?.message);
        return undefined;
      }
    },
  });
};
