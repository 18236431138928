import { Size, SvgProps } from '@common/types';
import { cn } from '@utils/cn';
import { FC, ReactNode } from 'react';

export const HgbErrorOutlinedIcon: FC<SvgProps> = ({
  className = '',
  size = '20',
  ...props
}) => {
  const Icons: Record<Size, ReactNode> = {
    '16': (
      <svg
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
    '20': (
      <svg
        width={20}
        height={21}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M9.16669 13H10.8334V14.6666H9.16669V13ZM9.16669 6.33329H10.8334V11.3333H9.16669V6.33329ZM9.99169 2.16663C5.39169 2.16663 1.66669 5.89996 1.66669 10.5C1.66669 15.1 5.39169 18.8333 9.99169 18.8333C14.6 18.8333 18.3334 15.1 18.3334 10.5C18.3334 5.89996 14.6 2.16663 9.99169 2.16663ZM10 17.1666C6.31669 17.1666 3.33335 14.1833 3.33335 10.5C3.33335 6.81663 6.31669 3.83329 10 3.83329C13.6834 3.83329 16.6667 6.81663 16.6667 10.5C16.6667 14.1833 13.6834 17.1666 10 17.1666Z"
          fill="currentColor"
        />
      </svg>
    ),
    '24': (
      <svg
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
  };
  return <>{Icons[size]}</>;
};
