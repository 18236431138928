import { cn } from '@utils/cn';
import { Button, ButtonProps } from 'antd';
import * as React from 'react';

interface HgbAntdButtonProps extends ButtonProps {
  endIcon?: React.ReactNode;
}

export const HgbAntdButton = React.forwardRef<
  HTMLButtonElement,
  HgbAntdButtonProps
>(({ endIcon, className = '', onClick, children, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      type="default"
      size="large"
      shape="round"
      className={cn(
        'tw-flex tw-items-center tw-justify-center tw-gap-4 [&_.ant-btn-icon]:!tw-mr-0',
        {
          'tw-relative !tw-pt-0 !tw-pb-0': endIcon,
          'tw-h-44 tw-min-w-[120px]': props.size === 'small',
          'tw-pointer-events-none': props.loading
        },
        className,
      )}
      {...props}
      onClick={endIcon ? undefined : onClick}
    >
      <span
        className={cn(
          'tw-flex-auto tw-text-inherit',
          {
            'tw-underline tw-underline-offset-4': props.type === 'link',
            '!tw-flex tw-h-full tw-items-center tw-justify-center tw-pl-16 tw-pr-32': endIcon,
          },
          className,
        )}
        onClick={endIcon ? onClick : undefined}
        style={{ textDecorationSkipInk: 'none' }}
      >
        {children}
      </span>
      {endIcon && (
        <div className="tw-absolute tw-right-8 tw-z-10 tw-shrink-0">
          {endIcon}
        </div>
      )}
    </Button>
  );
});
