import { LanguageContext } from '@contexts/LanguageContext';
import { Pagination } from 'antd';
import { useCallback, useContext, useState } from 'react';

const PAGE_SIZE_DEFAULT = 10;
export type PaginationProps = {
  pageSize: number;
  pageNo: number;
};
export const usePagin = (pageSize = PAGE_SIZE_DEFAULT, showDetail = false) => {
  const {
    text: {
      common: { label },
    },
  } = useContext(LanguageContext)!;

  const [paginationProps, setPaginationProps] = useState<PaginationProps>({
    pageSize,
    pageNo: 1,
  });

  const resetPaginations = () => {
    setPaginationProps((prev) => ({ ...prev, pageNo: 1 }));
  };

  const PaginationComponent = useCallback(
    ({
      current = 1,
      pageSize = PAGE_SIZE_DEFAULT,
      total = 0,
    }: {
      current?: number;
      pageSize?: number;
      total?: number;
    }) => {
      if (total === 0) return null;
      return (
        <div className="tw-flex tw-items-center tw-justify-center tw-gap-12">
          <Pagination
            className="tw-text-paragraph [&>.ant-pagination-item-active>a]:!tw-text-white [&>.ant-pagination-item-active]:tw-bg-[#81B646] [&>li]:!tw-rounded-full [&>li]:!tw-border-none "
            onChange={(pageNo: number, pageSize: number) => {
              setPaginationProps({
                pageNo,
                pageSize,
              });
            }}
            total={total}
            current={current}
            pageSize={pageSize}
            showSizeChanger={false}
          />
          {showDetail ? (
            <p>{label.pagingDetail(total, current, pageSize)}</p>
          ) : null}
        </div>
      );
    },
    [showDetail],
  );
  return {
    paginationProps,
    resetPaginations,
    setPaginationProps,
    PaginationComponent,
  };
};
