import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  HgbSelectOption,
} from '@common/forms';
import {
  HgbTreeSelect,
  HgbTreeSelectOption,
} from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { getIsoDate, validateMonthRange } from '@utils/date.util';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPost } from 'services/common/mutation';
import * as yup from 'yup';
import {
  AddSupplierEmissionInputPayload,
  GetSupplierEmissionInputRequest,
  defaultAddSupplierEmissionInputPayload,
} from 'services/types/emission';
import { hgbAxiosGetSingle } from '@utils/axios';
import { debug } from '@utils/debug';
import { SelectionInfo } from 'types/api/emission-input/emission-input.type';
import moment from 'moment';
import { SupplierSubmission } from '../H02/H02';
import { PortalContext } from '@contexts/PortalContext';
import { bigNumberToString } from '@utils/text';
import { GetSupplierEmissionInputResponse } from './AddSupplierAnswerModal';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { floorNumber } from '@utils/input.utils';
import { calculationFormula } from '@utils/number';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { STATUS_CODE } from '@constants/consts';
import { debounce } from 'lodash';

interface IDuplicateSupplierAnswerModalProps {
  modalState: BooleanState;
  id?: number;
  status?: string;
  detailData?: GetSupplierEmissionInputResponse;
}

const DuplicateSupplierAnswerModal: React.FunctionComponent<
  IDuplicateSupplierAnswerModalProps
> = ({ modalState, id, status, detailData }) => {
  const {
    text: { common, H03, E0000, E0101, E0049, E0100, E0091, E0106 },
    language,
  } = React.useContext(LanguageContext)!;
  const queryClient = useQueryClient();

  const { addMessage } = React.useContext(PortalContext)!;

  const { ConfirmModal: ConfirmModalSubmit, hgbConfirm: hgbConfirmSubmit } =
    useConfirm();

  const { ConfirmModal: ConfirmModal, hgbConfirm: hgbConfirm } = useConfirm();

  const [err, setErr] = React.useState<string>('');

  const duplicateSupplierAnswerSchema = yup.object({
    enterpriseId: yup.string().required(E0000(H03.groupBuyer)),
    supplierId: yup
      .string()
      .required(E0000(common.columns.supplyChainGroupName)),
    responseType: yup.string().required(E0000(H03.responseType)),
    baseVolume: yup.string().when({
      is: () => responseType !== '1',
      then: (schema) => schema.required(E0000(H03.scopeHalfEmissions)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    emissionIntensity: yup.string().when({
      is: () => responseType === '1',
      then: (schema) => schema.required(E0000(common.label.emissionIntensity)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    baseId: yup.string().when({
      is: () => responseType !== '1',
      then: (schema) =>
        schema.required(E0000(common.label.organizationBaseName)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    itemId: yup.string().when({
      is: () => responseType === '1',
      then: (schema) =>
        schema.required(E0000(H03.manufacturingCodeProductName)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    targetStartMonth: yup.string().required(E0000(H03.startDate)),
    targetEndMonth: yup.string().required(E0000(H03.endDate)),
    quantity: yup
      .string()
      .typeError(E0000(H03.quantity))
      .when({
        is: () => responseType === '1',
        then: (schema) => schema.required(E0000(H03.quantity)),
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    allocation: yup
      .string()
      .typeError(E0000(H03.allocation))
      .when({
        is: () => responseType !== '1',
        then: (schema) => schema.required(E0000(H03.allocation)),
        otherwise: (schema) => {
          return schema.notRequired();
        },
      })
      .test('validate_value', E0101, (value, schema) => {
        return Number(value) <= 100 || schema.parent.responseType === '1';
      }),
    emissionVolume: yup
      .string()
      .typeError(E0000(common.label.emissionVolume))
      .required(E0000(common.label.emissionVolume))
      .max(14, E0106),
  });

  const duplicateSupplierAnswerMethods =
    useForm<AddSupplierEmissionInputPayload>({
      resolver: yupResolver(duplicateSupplierAnswerSchema),
    });

  const responseType = duplicateSupplierAnswerMethods.watch('responseType');

  const [enterpriseOptions, setEnterpriseOptions] = React.useState<
    HgbSelectOption[]
  >([]);
  const [suplierOptions, setSuplierOptions] = React.useState<HgbSelectOption[]>(
    [],
  );
  const [responseTypeOptions, setResponseTypeOptions] = React.useState<
    HgbSelectOption[]
  >([]);

  const [emissionUnit, setEmissionUnit] = React.useState<string | undefined>(
    H03.correspondingUnit,
  );
  const [quantityUnit, setQuantityUnit] = React.useState<string | undefined>(
    H03.quantityUnit,
  );

  const [orgOptions, setOrgOptions] = React.useState<HgbTreeSelectOption[]>([]);
  const [orgBaseArr, setOrgBaseArr] = React.useState<any>([]);
  const [itemOptions, setItemOptions] = React.useState<HgbSelectOption[]>([]);
  const [supplierResponseUsed, setSupplierResponseUsed] = React.useState<
    number | null | undefined
  >(0);
  const [orgName, setOrgName] = React.useState<string>('');

  React.useEffect(() => {
    if (id === undefined || !modalState.value) return;
    getDetailSupplierEmission(id);
  }, [modalState.value, id]);

  const getDetailSupplierEmission = async (dataId: number) => {
    detailData?.nextStepSelectionInfos?.forEach(
      (selectionInfo: SelectionInfo) => {
        switch (selectionInfo.fieldTarget) {
          case 'enterpriseId':
            duplicateSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setEnterpriseOptions(
              selectionInfo.data.map(({ name: label, value }) => ({
                label,
                value,
              })) || [],
            );
            break;
          case 'supplierId':
            duplicateSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setSuplierOptions(
              selectionInfo.data.map(({ name: label, value }) => ({
                label,
                value,
              })) || [],
            );
            break;

          case 'responseType':
            duplicateSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setResponseTypeOptions(
              selectionInfo.data.map(({ name: label, value }) => ({
                label,
                value,
              })) || [],
            );
            break;
          case 'itemId':
            duplicateSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setItemOptions(
              selectionInfo.data.map(({ name: label, value }) => ({
                label,
                value,
              })) || [],
            );
            break;
          case 'baseId':
            duplicateSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              selectionInfo.selectedValue,
            );
            setOrgOptions(
              convertObjectToTree(
                (selectionInfo.dataOrg || []).filter(
                  (item: any) => !getParentKey(item.hierarchicalPath ?? ''),
                ),
                selectionInfo.dataOrg || [],
              ),
            );
            setOrgBaseArr(selectionInfo.dataOrg);
            break;

          case 'targetStartMonth':
          case 'targetEndMonth':
            duplicateSupplierAnswerMethods.setValue(
              selectionInfo.fieldTarget,
              moment(selectionInfo.selectedValue).format('YYYY/MM') || '',
            );
            break;

          default:
            break;
        }
        detailData?.flowInputDetails?.forEach((flowInputDetail: any) => {
          flowInputDetail.inputGroup.forEach((input: any) => {
            switch (input.fieldTarget) {
              case 'allocation':
                duplicateSupplierAnswerMethods.setValue(input.fieldTarget, '');
                break;
              case 'quantity':
                duplicateSupplierAnswerMethods.setValue(input.fieldTarget, '');
                setQuantityUnit(input.unit);
                break;
              case 'responseSummary':
                duplicateSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                break;
              default:
                break;
            }
          });

          switch (flowInputDetail.intensity.fieldTarget) {
            case 'baseVolume':
            case 'emissionIntensity':
              duplicateSupplierAnswerMethods.setValue(
                flowInputDetail.intensity.fieldTarget,
                bigNumberToString(flowInputDetail.intensity.value),
              );
              setEmissionUnit(flowInputDetail.intensity.unit);
              break;
            default:
              break;
          }
        });
      },
    );
    setSupplierResponseUsed(detailData?.supplierResponseUsed);
    duplicateSupplierAnswerMethods.setValue(
      'emissionVolume',
      bigNumberToString(detailData?.flowResultItem?.value),
    );
  };

  const duplicateSupplierAnswerMutate = useHgbMutationPost<
    unknown,
    AddSupplierEmissionInputPayload
  >(API_URLS.SUPPLIER_CHAIN_RESPONSE_SAVE, {});

  const validationPayloadMutation = useHgbMutationPost<
    unknown,
    AddSupplierEmissionInputPayload
  >(API_URLS.CHECK_SUPPLIER_RESPONSE, {
    isAlert: false,
  });

  const handleSubmit = duplicateSupplierAnswerMethods.handleSubmit(
    async (data) => {
      const value = validateMonthRange(
        moment(data.targetStartMonth, 'YYYYMM').format('YYYY/MM') || '',
        moment(data.targetEndMonth, 'YYYYMM').format('YYYY/MM') || '',
      );

      if (value !== 'PASS') {
        if (value === 'ORDER') {
          setErr(E0049);
        } else setErr(E0100);
        return;
      }
      setErr('');
      if (id === undefined) return;
      const response = await hgbAxiosGetSingle<SupplierSubmission>(
        API_URLS.SUPPLIER_SUPPLIER_RESPONSE,
        id,
      );
      if (response?.status?.value !== status) {
        addMessage('error', E0091);
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
        });
        return;
      }
      const hierarchicalPath =
        responseType === '2'
          ? orgBaseArr.filter((item: any) => item.value === data.baseId)[0]
              .hierarchicalPath
          : '';
      setOrgName(
        responseType === '2'
          ? orgBaseArr.filter((item: any) => item.value === data.baseId)[0].name
          : '',
      );
      const payload = {
        ...data,
        hierarchicalPath: hierarchicalPath,
        targetStartMonth: data.targetStartMonth?.replace('/', ''),
        targetEndMonth: data.targetEndMonth?.replace('/', ''),
        supplierResponseIdCheck: id,
        sentTime:  getIsoDate(),
      };
      let result: boolean = false;
      validationPayloadMutation.mutateAsync(payload).then(async (data) => {
        if (data.statusCode === STATUS_CODE.success) {
          result = await hgbConfirmSubmit();
        } else if (data.statusCode === STATUS_CODE.orgBaseAllocation) {
          result = await hgbConfirm();
        } else {
          modalState.turnOff();
          addMessage('error', data.message ?? '');
          return;
        }
        result &&
          duplicateSupplierAnswerMutate.mutateAsync(payload).then(() => {
            handleSuccess();
          });
      });
    },
    (error) => {
      debug.log(error);
    },
  );

  const handleSubmitDebounce = debounce(handleSubmit, 500);

  const handleSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
    });
    modalState.turnOff();
  };

  React.useEffect(() => {
    duplicateSupplierAnswerMethods.setValue(
      'emissionVolume',
      floorNumber(
        Number(
          calculationFormula(
            responseType,
            duplicateSupplierAnswerMethods.watch('allocation'),
            duplicateSupplierAnswerMethods.watch('quantity'),
            duplicateSupplierAnswerMethods.watch('emissionIntensity'),
            duplicateSupplierAnswerMethods.watch('baseVolume'),
          ),
        ),
        3,
      ).toString(),
    );
    if (
      duplicateSupplierAnswerMethods.watch('quantity') === '' &&
      duplicateSupplierAnswerMethods.watch('allocation') === ''
    ) {
      duplicateSupplierAnswerMethods.setValue('emissionVolume', '');
    }
  }, [
    responseType,
    duplicateSupplierAnswerMethods.watch('quantity'),
    duplicateSupplierAnswerMethods.watch('allocation'),
    duplicateSupplierAnswerMethods.watch('baseVolume'),
    duplicateSupplierAnswerMethods.watch('emissionIntensity'),
  ]);

  const supplierEmissionMutation = useHgbMutationPost<
    GetSupplierEmissionInputResponse,
    GetSupplierEmissionInputRequest | null
  >(API_URLS.SUPPLIER_CHAIN_RESPONSE_FLOW, {
    isAlert: false,
  });
  /*watch enterpriseId */
  const changeEnterpriseId = () => {
    supplierEmissionMutation
      .mutateAsync({
        enterpriseId: duplicateSupplierAnswerMethods.watch('enterpriseId'),
      })
      .then((rs) => {
        rs?.result?.nextStepSelectionInfos?.forEach(
          (selectionInfo: SelectionInfo) => {
            switch (selectionInfo.fieldTarget) {
              case 'supplierId':
                duplicateSupplierAnswerMethods.setValue(
                  selectionInfo.fieldTarget,
                  selectionInfo.selectedValue,
                );
                setSuplierOptions(
                  selectionInfo.data.map(({ name: label, value }) => ({
                    label,
                    value,
                  })) || [],
                );
                break;
              default:
                break;
            }
          },
        );
      });
    duplicateSupplierAnswerMethods.setValue('supplierId', '');
  };

  /* watch responseType */
  const changeResponseType = () => {
    supplierEmissionMutation
      .mutateAsync({
        enterpriseId: duplicateSupplierAnswerMethods.watch('enterpriseId'),
        supplierId: duplicateSupplierAnswerMethods.watch('supplierId'),
        responseType: duplicateSupplierAnswerMethods.watch('responseType'),
      })
      .then((rs) => {
        rs?.result?.nextStepSelectionInfos?.forEach(
          (selectionInfo: SelectionInfo) => {
            switch (selectionInfo.fieldTarget) {
              case 'itemId':
                duplicateSupplierAnswerMethods.setValue(
                  selectionInfo.fieldTarget,
                  selectionInfo.selectedValue,
                );
                setItemOptions(
                  selectionInfo.data.map(({ name: label, value }) => ({
                    label,
                    value,
                  })) || [],
                );
                break;
              case 'baseId':
                duplicateSupplierAnswerMethods.setValue(
                  selectionInfo.fieldTarget,
                  selectionInfo.selectedValue,
                );
                setOrgOptions(
                  convertObjectToTree(
                    (selectionInfo.dataOrg || []).filter(
                      (item: any) => !getParentKey(item.hierarchicalPath ?? ''),
                    ),
                    selectionInfo.dataOrg || [],
                  ),
                );
                setOrgBaseArr(selectionInfo.dataOrg);
                break;

              default:
                break;
            }
          },
        );
      });
    duplicateSupplierAnswerMethods.setValue('emissionIntensity', '');
    duplicateSupplierAnswerMethods.setValue('baseVolume', '');
    duplicateSupplierAnswerMethods.setValue('quantity', '');
    duplicateSupplierAnswerMethods.setValue('allocation', '');
    duplicateSupplierAnswerMethods.setValue('itemId', '');
    duplicateSupplierAnswerMethods.setValue('baseId', '');
    setEmissionUnit(H03.correspondingUnit);
    setQuantityUnit(H03.quantityUnit);
  };

  /* watch org or item*/
  const changeOrgOrItem = () => {
    supplierEmissionMutation
      .mutateAsync({
        enterpriseId: duplicateSupplierAnswerMethods.watch('enterpriseId'),
        supplierId: duplicateSupplierAnswerMethods.watch('supplierId'),
        responseType: duplicateSupplierAnswerMethods.watch('responseType'),
        itemId: duplicateSupplierAnswerMethods.watch('itemId'),
        baseId: duplicateSupplierAnswerMethods.watch('baseId'),
        targetStartMonth: duplicateSupplierAnswerMethods
          .watch('targetStartMonth')
          ?.replace('/', ''),
        targetEndMonth: duplicateSupplierAnswerMethods
          .watch('targetEndMonth')
          ?.replace('/', ''),
      })
      .then((rs) => {
        rs.result?.flowInputDetails?.forEach((flowInputDetail: any) => {
          flowInputDetail.inputGroup.forEach((input: any) => {
            switch (input.fieldTarget) {
              case 'allocation':
              case 'quantity':
                duplicateSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                setQuantityUnit(input.unit);
                break;
              case 'responseSummary':
                duplicateSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                break;
              default:
                break;
            }
          });

          switch (flowInputDetail.intensity.fieldTarget) {
            case 'baseVolume':
            case 'emissionIntensity':
              duplicateSupplierAnswerMethods.setValue(
                flowInputDetail.intensity.fieldTarget,
                bigNumberToString(flowInputDetail.intensity.value),
              );
              setEmissionUnit(flowInputDetail.intensity.unit);
              break;
            default:
              break;
          }
        });
        setSupplierResponseUsed(rs.result?.supplierResponseUsed);
      });
  };

  return (
    <>
      <FormProvider {...duplicateSupplierAnswerMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={
            <HgbAntdModalTitle className="">{H03.titleAdd}</HgbAntdModalTitle>
          }
          open={modalState.value}
          onCancel={modalState.turnOff}
          footer={null}
          closable={true}
          maskClosable={false}
          destroyOnClose={true}
          afterClose={() => {
            duplicateSupplierAnswerMethods.reset(
              defaultAddSupplierEmissionInputPayload,
            );
            setEnterpriseOptions([]);
            setItemOptions([]);
            setOrgOptions([]);
            setSuplierOptions([]);
            setOrgBaseArr([]);
            setErr('');
          }}
        >
          <HgbAntdModalForm onSubmit={handleSubmitDebounce}>
            <HgbAntdModalFormBody>
              {/* blank */}
              <HgbSelect
                label={H03.groupBuyer}
                {...duplicateSupplierAnswerMethods.register('enterpriseId')}
                options={enterpriseOptions}
                required
                onChangeValue={() => changeEnterpriseId()}
              />
              {/* blank */}
              <HgbSelect
                label={common.columns.supplyChainGroupName}
                {...duplicateSupplierAnswerMethods.register('supplierId')}
                options={suplierOptions}
                required
              />
              {/* blank */}
              <HgbSelect
                label={H03.responseType}
                {...duplicateSupplierAnswerMethods.register('responseType')}
                options={responseTypeOptions}
                required
                infoTooltip={H03.responseTooltip}
                palcement={language === 'jp' ? 'topLeft' : 'topRight'}
                onChangeValue={() => changeResponseType()}
              />
              {/* org */}
              {responseType === '2' ? (
                <HgbTreeSelect
                  label={common.label.organizationBaseName}
                  {...duplicateSupplierAnswerMethods.register('baseId')}
                  treeData={orgOptions}
                  required
                  showSearch
                  onChangeValue={() => changeOrgOrItem()}
                />
              ) : null}
              {/* blank */}
              <div>
                <div className="tw-flex tw-justify-between tw-gap-36">
                  <HgbDatePicker
                    label={H03.startDate}
                    picker="month"
                    {...duplicateSupplierAnswerMethods.register(
                      'targetStartMonth',
                    )}
                    required
                    infoTooltip={H03.startDateTooltip}
                    palcement="topLeft"
                    allowClear
                    format="YYYY/MM"
                    onChangeValue={() => changeOrgOrItem()}
                  />
                  <HgbDatePicker
                    label={H03.endDate}
                    picker="month"
                    {...duplicateSupplierAnswerMethods.register(
                      'targetEndMonth',
                    )}
                    required
                    infoTooltip={H03.endDateTooltip}
                    palcement="topRight"
                    allowClear
                    format="YYYY/MM"
                    onChangeValue={() => changeOrgOrItem()}
                  />
                </div>
                {err ? <HgbFieldError>{err}</HgbFieldError> : null}
              </div>
              {/* product */}
              {responseType === '1' ? (
                <>
                  <HgbSelect
                    label={H03.manufacturingCodeProductName}
                    {...duplicateSupplierAnswerMethods.register('itemId')}
                    options={itemOptions}
                    required
                    allowClear
                    showSearch
                    infoTooltip={H03.manufacturingCodeProductNameTootip}
                    palcement={language === 'jp' ? 'topLeft' : 'topRight'}
                    onChangeValue={() => changeOrgOrItem()}
                  />
                  <HgbAntdInput
                    label={H03.quantity}
                    {...duplicateSupplierAnswerMethods.register('quantity')}
                    maxLength={13}
                    required
                    type="positiveInteger"
                    suffix={quantityUnit}
                  />
                  <HgbAntdInput
                    label={common.label.emissionIntensity}
                    {...duplicateSupplierAnswerMethods.register(
                      'emissionIntensity',
                    )}
                    required
                    readOnly
                    suffix={emissionUnit}
                  />
                </>
              ) : null}

              {/* org */}
              {responseType === '2' ? (
                <>
                  <HgbAntdInput
                    label={H03.scopeHalfEmissions}
                    {...duplicateSupplierAnswerMethods.register('baseVolume')}
                    required
                    readOnly
                    suffix={'tCO2'}
                  />
                  <HgbAntdInput
                    label={H03.allocation}
                    {...duplicateSupplierAnswerMethods.register('allocation')}
                    required
                    maxLength={4}
                    infoTooltip={H03.allocationTooltip}
                    palcement="topLeft"
                    type={'positiveDecimal'}
                    suffix={'%'}
                  />
                </>
              ) : null}

              {/* blank */}
              <HgbAntdInput
                label={common.label.emissionVolume}
                {...duplicateSupplierAnswerMethods.register('emissionVolume')}
                readOnly
                required
                suffix={'tCO2'}
              />
              {/* blank */}
              <HgbAntdInput
                label={H03.responseSummary}
                {...duplicateSupplierAnswerMethods.register('responseSummary')}
                type="text"
              />
            </HgbAntdModalFormBody>
            <HgbAntdModalFormFooter>
              <HgbAntdButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
              >
                {common.button.cancel}
              </HgbAntdButton>
              <HgbAntdButton
                type="primary"
                className="tw-min-w-100"
                htmlType="submit"
                loading={validationPayloadMutation.isLoading}
              >
                {common.button.respond}
              </HgbAntdButton>
            </HgbAntdModalFormFooter>
          </HgbAntdModalForm>
        </Modal>
      </FormProvider>
      <ConfirmModalSubmit
        title={H03.modalCreate.title}
        subTitle={H03.modalCreate.subTitle}
        okText={common.button.respond}
        cancelText={common.button.cancel}
        className="tw-grid tw-grid-cols-[minmax(100px,_auto),_1fr] [&_p]:tw-font-regular"
      ></ConfirmModalSubmit>
      <ConfirmModal
        title={
          <ExclamationCircleOutlined className="tw-text-[50px] tw-text-[#fd7e14]" />
        }
        okText={common.button.confirm}
        cancelText={common.button.cancel}
        className="tw-font-regular"
      >
        {H03.modalSubmit.content(
          orgName,
          supplierResponseUsed as number,
          duplicateSupplierAnswerMethods.getValues('targetStartMonth') || '',
          duplicateSupplierAnswerMethods.getValues('targetEndMonth') || '',
        )}
      </ConfirmModal>
    </>
  );
};

export { DuplicateSupplierAnswerModal };
