import { ButtonProps } from '@common/types';
import { AuthContext } from '@contexts/AuthContext';
import { cn } from '@utils/cn';
import React, { FC, useEffect } from 'react';
import { HgbInlineLogo } from './HgbInlineLogo';

export const HgbNavbarLogo: FC<ButtonProps> = ({
  className = '',
  ...props
}) => {
  const { user } = React.useContext(AuthContext)!;
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [user.bankName]);

  return (
    <button
      style={{ pointerEvents: 'none' }}
      className={cn(
        `tw-flex tw-h-48 tw-min-w-140 tw-max-w-400 tw-shrink-0 tw-cursor-pointer tw-gap-8 ${className}`,
      )}
      {...props}
    >
      <HgbInlineLogo className="tw-h-48 tw-shrink-0" />
      <p className="tw-self-end tw-text-start tw-text-paragraph tw-font-bold tw-text-gradient-from ">
        {user.bankName}
      </p>
    </button>
  );
};
