import { PlusOutlined } from '@ant-design/icons';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdSpin } from '@common/antd/HgbAntdSpin';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellLink } from '@common/contents/HgbCellLink';
import { HgbAntdInput } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { useBooleanState } from '@hooks/useBooleanState';
import { useDebounce } from '@hooks/useDebound';
import { PaginationProps, usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbQueryWithPaging } from 'services/common/query';
import { WithKey } from 'types/common';
import AddIntroducerModal from './AddIntroducerModal';
import CopyElement from './CopyComponent';
import ViewIntroducerModal from './ViewIntroducerModal';

export type Introducer = {
  id: number;
  approvedAmount: number;
  createdDate: string;
  inviteCode: string;
  inviteUrl: string;
  introducerName: string;
  introducerNameKana: string;
  picName: string;
  picNameKana: string;
  address: string;
  buildingName: string;
  email: string;
  phoneNumber: string;
  postalCode: string;
};

export type GetIntroducersRequest = PaginationProps & {
  sortBy?: string;
  nameSearch?: string;
};

const D22 = () => {
  const {
    text: { common, D22 },
  } = useContext(LanguageContext)!;

  const introducerSearchMethods = useForm<{ nameSearch: string }>({
    values: { nameSearch: '' },
  });

  const { paginationProps, resetPaginations, PaginationComponent } = usePagin();

  useEffect(() => {
    resetPaginations();
  }, [introducerSearchMethods.watch('nameSearch')]);

  const nameSearchDebounce = useDebounce(
    introducerSearchMethods.watch('nameSearch'),
    300,
  );

  const [viewData, setViewData] = useState<Introducer>();
  const viewModalState = useBooleanState(false);
  const addModalState = useBooleanState(false);

  const showViewModal = useCallback((record: Introducer) => {
    setViewData(record);
    viewModalState.turnOn();
  }, []);

  const introducerRequest: GetIntroducersRequest = {
    ...paginationProps,
    nameSearch: nameSearchDebounce,
  };

  const introducersQuery = useHgbQueryWithPaging<
    Introducer[],
    GetIntroducersRequest
  >(API_URLS.LIST_INTRODUCERS, introducerRequest, {
    queryKey: [API_URLS.LIST_INTRODUCERS, introducerRequest],
  });

  const columns: ColumnsType<Introducer> = [
    {
      title: D22.label.supportCode,
      dataIndex: 'inviteCode',
      render: (value = '', record) => (
        <CopyElement value={record.inviteUrl} label={value} />
      ),
      width: 160,
    },
    {
      title: D22.label.introducerName,
      dataIndex: 'introducerName',
      width: 160,
      render: (value, record) => (
        <HgbCellLink onClick={() => showViewModal(record)}>
          <HgbCellTooltip>{value}</HgbCellTooltip>
        </HgbCellLink>
      ),
    },
    {
      title: D22.label.PICName,
      dataIndex: 'picName',
      width: 160,
      render: (value) => <HgbCellTooltip>{value}</HgbCellTooltip>,
    },
    {
      title: D22.label.numberOfApplications,
      dataIndex: 'approvedAmount',
      width: 160,
    },
    {
      title: D22.label.lastApplicationDate,
      dataIndex: 'createdDate',
      width: 160,
    },
  ];

  const rows: WithKey<Introducer>[] = useMemo(() => {
    return (introducersQuery?.data?.result?.content ?? []).map((item) => ({
      ...item,
      key: item.id,
    }));
  }, [introducersQuery?.data?.result?.content]);

  return (
    <ManagementTemplate>
      <DataTableTemplate
        title={D22.pageTitle}
        buttonComponent={
          <HgbAntdButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={addModalState.turnOn}
          >
            {common.button.register}
          </HgbAntdButton>
        }
        paginationComponents={
          <PaginationComponent
            current={introducersQuery.data?.result?.currentPage}
            total={introducersQuery.data?.result?.totalRecord}
            pageSize={introducersQuery.data?.result?.pageSize}
          />
        }
        inputsComponent={
          <div className="tw-rounded-8 tw-bg-primary-1/20 tw-p-16">
            <FormProvider {...introducerSearchMethods}>
              <HgbAntdInput
                {...introducerSearchMethods.register('nameSearch')}
                type="K"
                maxLength={100}
                search
                className="pc:tw-w-240"
                placeholder={D22.placeholder.introducerName}
              />
            </FormProvider>
          </div>
        }
      >
        {rows.length > 0 ? (
          <Table
            columns={columns}
            dataSource={rows}
            pagination={false}
            className="tw-overflow-hidden"
            scroll={{
              x: columns.reduce(
                (prev, current) => prev + Number(current.width ?? 0),
                0,
              ),
            }}
          />
        ) : null}

        {introducersQuery.isFetched && rows.length === 0 ? (
          <HgbAntdEmpty description={common.message.noResult} />
        ) : null}

        {introducersQuery.isLoading ? (
          <HgbAntdSpin
            className={
              'tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2'
            }
          />
        ) : null}
      </DataTableTemplate>
      <AddIntroducerModal modalState={addModalState} />
      <ViewIntroducerModal modalState={viewModalState} data={viewData} />
    </ManagementTemplate>
  );
};

export default D22;
