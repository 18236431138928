import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import {
  HgbCheckCircleOutlineIcon,
  HgbErrorOutlinedIcon,
} from '@common/icons/outlined';
import { routs_en } from '@constants/ROUT';
import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import { FC, useContext, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const A07Result: FC = (props) => {
  const location = useLocation();
  const {
    text: { A07, common },
  } = useContext(LanguageContext)!;

  useEffect(() => {
    const sonyFrame = document.getElementById('SPSVviewIframe');
    const htmlEl = document.querySelector('html');
    if (htmlEl?.style) {
      htmlEl.style.overflow = 'unset';
    }
    sonyFrame?.remove();
  }, []);

  const navigate = useNavigate();

  return location.state && location.state?.length > 0 ? (
    <AuthenTemplate
      title={
        location.state === 'success' ? (
          <HgbCheckCircleOutlineIcon className="tw-h-56 tw-w-56 tw-text-[#81B646]" />
        ) : (
          <HgbErrorOutlinedIcon className="!tw-mb-0 tw-h-56 tw-w-56 tw-text-[#FF302D]" />
        )
      }
    >
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-24">
        {location.state === 'success' ? (
          <>
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-24">
              <p className="tw-text-h3 tw-font-bold">{A07.label.success}</p>
              <div className="tw-rounded-16 tw-bg-[#FFF4C9] tw-p-12">
                <p>{A07.label.successMsg}</p>
              </div>
            </div>
            <p>{A07.label.successMsg2}</p>
          </>
        ) : location.state.length > 0 &&
          !location.state.toString().includes('fail') ? (
          <>
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-24">
              <p className="tw-text-h3 tw-font-bold">{A07.label.A09_fail}</p>
              <p>
                {A07.label.A09_failMsg(location.state.split(';')[0] + '' ?? '')}
              </p>
            </div>
            <HgbAntdButton
              type="primary"
              ghost
              htmlType="button"
              onClick={() => {
                if (location.state.toString().includes('enterpriseAdmin')) {
                  return navigate(-1);
                } else {
                  return navigate(location.state.split(';')[1]);
                }
              }}
            >
              {common.button.close}
            </HgbAntdButton>
          </>
        ) : (
          <>
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-24">
              <p className="tw-text-h3 tw-font-bold">{A07.label.fail}</p>
              <p>{A07.label.failMsg(location.state.split('fail')[1])}</p>
            </div>
            <HgbAntdButton
              type="primary"
              ghost
              htmlType="button"
              onClick={() => {
                return navigate(routs_en['/register'].link);
              }}
            >
              {common.button.backToA06}
            </HgbAntdButton>
          </>
        )}
      </div>
    </AuthenTemplate>
  ) : (
    <Navigate to={routs_en['/not-found'].link} />
  );
};

export default A07Result;
