import { Size, SvgProps } from '@common/types';
import { cn } from '@utils/cn';
import { FC, ReactNode } from 'react';

export const HgbMenuOutlinedIcon: FC<SvgProps> = ({
  className = '',
  size = '20',
  ...props
}) => {
  const Icons: Record<Size, ReactNode> = {
    '16': (
      <svg
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
    '20': (
      <svg
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
    '24': (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z"
          fill="currentColor"
        />
      </svg>
    ),
  };
  return <>{Icons[size]}</>;
};
