import { ButtonDemo } from './ButtonDemo';
import { TypographyDemo } from './TypographyDemo';

const Common = () => {
  return (
    <div className="tw-p-16">
      <TypographyDemo />
      <ButtonDemo />
    </div>
  );
};

export default Common;
