import { cn } from '@utils/cn';
import { Empty, EmptyProps } from 'antd';
import * as React from 'react';

export const HgbAntdEmpty: React.FC<EmptyProps> = ({
  className = '',
  ...props
}) => {
  return (
    <Empty
      className={cn(
        'hgb-ant-empty tw-mx-0 tw-flex tw-flex-col tw-place-content-center tw-items-center tw-justify-center tw-self-stretch tw-rounded-[16px] tw-bg-white tw-p-24',
        className,
      )}
      {...props}
    />
  );
};
