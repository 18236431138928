import { AuthContext } from '@contexts/AuthContext';
import { useContext } from 'react';
import { BankInfo } from './BankInfo';
import { BankList } from './BankList';

export const G01 = () => {
  const { user } = useContext(AuthContext)!;

  return (
    <>
      {user.isManagementBank ? <BankInfo /> : null}
      {user.isPresidingBank ? <BankList /> : null}
    </>
  );
};
