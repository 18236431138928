import { REACT_APP_ENV } from '@constants/consts';
import dayjs from 'dayjs';
import moment from 'moment';

const formatMonth = (month: number) => {
  if (month < 10) {
    return '0' + month.toString();
  }
  return month.toString();
};

export const currentYearMonth = () => {
  const current = new Date();
  return current.getFullYear() + formatMonth(current.getUTCMonth() + 1);
};

export const checkDateRange = (startDate: string, endDate: string) => {
  const start = dayjs(startDate);
  const current = dayjs(new Date());
  const end = dayjs(endDate);
  if (current.diff(end) >= 0) return 1;
  if (current.diff(start) >= 0 && current.diff(end) <= 0) return 0;
  return -1;
};

export const getFiscalYearFromMonth = (month: string) => {
  const momentDate = moment(month.replace('/', ''), 'YYYYMM').toISOString();
  const date = new Date(momentDate);

  if (date.getMonth() < 3) {
    return date.getFullYear() - 1;
  }
  return date.getFullYear();
};

export const validateMonthRange = (
  startMonth: string,
  endMonth: string,
): 'RANGE' | 'ORDER' | 'PASS' => {
  if (new Date(startMonth).getTime() > new Date(endMonth).getTime()) {
    return 'ORDER';
  }

  if (getFiscalYearFromMonth(startMonth) !== getFiscalYearFromMonth(endMonth)) {
    return 'RANGE';
  }

  return 'PASS';
};

export const getIsoDate = () => {
  let isoDate;
  var now = new Date();
  if (REACT_APP_ENV === 'staging' || REACT_APP_ENV === 'production') {
    return (isoDate = new Date(now).toISOString());
  }
  return new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000,
  ).toISOString();
};
