import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import {
  HgbAntdRadioGroup,
  HgbAntdRadioOption,
} from '@common/antd/HgbAntdRadioGroup';
import { HgbAntdSpin } from '@common/antd/HgbAntdSpin';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellTooltipElement } from '@common/contents/HgbCellTooltipElement/HgbCellTooltipElement';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import {
  HgbDatePicker,
  HgbSelect,
  parseScopeToHgbSelectOptions,
  parseToHgbSelectOptions,
} from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import { STATUS_CODE } from '@constants/consts';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGet } from '@hooks/useApi';
import { useBooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { hgbAxiosGetSingle } from '@utils/axios';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { JSON_TO_KEY, emptyToUndefined } from '@utils/text';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery, useHgbQueryWithPaging } from 'services/common/query';
import {
  GetBankListBoxRequest,
  GetBankListBoxResponse,
} from 'services/types/bank';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetCategoryListBoxRequest,
  GetCategoryListBoxResponse,
} from 'services/types/category';
import {
  GetEnterpriseListBoxRequest,
  GetEnterpriseListBoxResponse,
} from 'services/types/enterprise';
import { GetSupplyChainListBoxResponse } from 'services/types/supply_chain';
import {
  GetLast10FiscalYearListBoxRequest,
  GetLast10FiscalYearListBoxResponse,
} from 'services/types/year';
import {
  EmissionInputScope1Response,
  OffsetFields,
  Step1Fields,
} from 'types/api/emission-input/emission-input.type';
import {
  EmissionOutput,
  EmissionOutputRequest,
  EmissionVolume,
  GetAllOffsetRequest,
  OffsetDetails,
} from 'types/api/emission-output/emission-output.type';
import * as yup from 'yup';
import { SupplierResponse } from '../H01/H01';
import {
  SupplierAnswerModalMode,
  SupplierEmissionInputModal,
  SupplierPattern,
} from '../H04/SupplierEmissionInputModal';
import { MANUAL_NAMEDDESTS } from '../Support';
import { EmissionCreateModal } from './EmissionCreateModal';
import {
  EmissionDeleteAllData,
  EmissionDeleteAllModal,
  EmissionDeleteAllModalProps,
} from './EmissionDeleteAllModal';
import {
  EmissionDeleteModal,
  EmissionDeleteModalProps,
} from './EmissionDeleteModal';
import { EmissionUpdateModal } from './EmissionUpdateModal';

const validateMonthRange = (startMonth: string, endMonth: string) => {
  if (startMonth === '' || endMonth === '') return true;
  const start = dayjs(startMonth);
  const end = dayjs(endMonth);
  return end.diff(start) >= 0;
};
interface EmissionOutputDataType {
  key: React.Key;
  targetYearMonth: string;
  enterpriseName: string;
  organizationBaseName: string;
  calculationMethodName: string;
  types: string[];
  amount: EmissionVolume[];
  intensity: EmissionOutput;
  emissionVolume: EmissionOutput;
  createdBy: string;
  createdDate: string;
  createdMethod: string;
  action?: React.ReactNode | null;
  insertMethodCode: string;
}

interface OffsetDataType {
  key: React.Key;
  targetYearMonth: string;
  enterpriseName: string;
  organizationBaseName: string;
  offsetAmount: string;
  offsetCertificateDate: string;
  offsetTarget: string;
  projectName: string;
  creditType: string;
  createdBy: string;
  createdDate: string;
  insertMethodCode: string;
  insertMethodName: string;
  action?: React.ReactNode | null;
  scopeCode: string;
  targetYear: string;
  categoryCode: string;
  baseId: string;
  targetLciDbYear: string;
  id: number;
}

type ScopeCode = '1' | '2' | '3' | '4';

type DateType = 'FISCAL_YEAR' | 'MONTH';
type EmissionDataSearchForm = {
  bankId: string;
  enterpriseId: string;
  baseId: string;
  dateType: DateType;
  scopeCode: ScopeCode;
  categoryCode: string;
  startMonth: string;
  endMonth: string;
  targetYear: string;
  supplierId: string;
};

export const C01: React.FunctionComponent = (props) => {
  const queryClient = useQueryClient();
  React.useEffect(() => {
    queryClient.clear();
  }, []);

  const {
    text: { C01, common, E0000, E0049 },
  } = React.useContext(LanguageContext)!;

  const { user } = React.useContext(AuthContext)!;
  const navigate = useNavigate();

  const { PaginationComponent, paginationProps, resetPaginations } =
    usePagin(25);

  const hiddenInputName = (arr1: any, arr2: any) =>
    arr1.length > 1 || arr2.length > 1;

  const tabItems: { label: string; key: ScopeCode; note: string }[] = [
    { label: common.label.scope1, key: '1', note: common.label.scopeNote1 },
    { label: common.label.scope2, key: '2', note: common.label.scopeNote2 },
    { label: common.label.scope3, key: '3', note: common.label.scopeNote3 },
    { label: common.label.offset, key: '4', note: '' },
  ];

  const FISCAL_YEAR_OPTIONS: HgbAntdRadioOption<DateType>[] = [
    {
      label: C01.label.fiscalYear,
      value: 'FISCAL_YEAR',
    },
    {
      label: C01.label.targetYearMonth,
      value: 'MONTH',
    },
  ];

  const floorByMethod = (method: string): number => {
    const electronicUse = ['Electricity usage', '電気使用量'];
    if (
      electronicUse.includes(method.trim()) ||
      searchValues.scopeCode === '2'
    ) {
      return 6;
    }
    return 2;
  };

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: OffsetDetails | EmissionOutput) => {
      return {
        disabled: record.isCanDelete === false,
      };
    },
  };

  const EMISSION_OUTPUT_COLUMNS: ColumnsType<EmissionOutputDataType> = [
    {
      title: C01.label.targetYearMonth,
      dataIndex: 'targetYearMonth',
      width: 100,
    },
    {
      title: C01.label.enterpriseName,
      dataIndex: 'enterpriseName',
      width: 170,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: common.label.organizationBaseName,
      dataIndex: 'organizationBaseName',
      width: 170,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: C01.label.calculationTargetName,
      dataIndex: 'calculationTargetName',
      width: 170,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: C01.label.types,
      dataIndex: 'types',
      width: 150,
      render: (types: string[]) => {
        return (
          <div className="tw-flex tw-flex-col">
            {(types ?? []).map((item) => (
              <p key={JSON_TO_KEY(item) + 'table-cell_'}>{item}</p>
            ))}
          </div>
        );
      },
    },
    {
      title: C01.label.amount,
      dataIndex: 'amount',
      align: 'center',
      width: 170,
      render: (input: EmissionVolume[]) => {
        let inputs: EmissionVolume[][] = [];
        if (!isEmpty(input)) {
          const maxIndex = input[input.length - 1].rowIndex ?? 0;
          for (let i = 0; i <= maxIndex; i++) {
            inputs = [...inputs, input.filter((k) => k.rowIndex === i)];
          }
        }
        return inputs.map((item) => {
          if (item.length > 2) {
            return (
              <HgbCellTooltipElement
                item={item}
                key={JSON_TO_KEY(item) + 'inputsmap'}
              >
                <div className="tw-grid tw-max-w-440 tw-grid-cols-[repeat(auto-fit,minmax(120px,auto))] tw-gap-24">
                  {item.map((s, index) => (
                    <div
                      key={s.name + JSON_TO_KEY(item)}
                      className="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center"
                    >
                      {hiddenInputName(inputs, item) && (
                        <p className="tw-min-h-[21px]">{s.name}</p>
                      )}
                      {typeof s.value === 'string' ? (
                        <p className="tw-min-h-[21px]">
                          {s.value} {s.unit}
                        </p>
                      ) : (
                        <p className="tw-min-h-[21px]">
                          {new BigNumber(s.value ?? '').toFixed()} {s.unit}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </HgbCellTooltipElement>
            );
          }
          return (
            <div
              className="tw-grid tw-grid-cols-[repeat(auto-fit,minmax(120px,auto))] tw-gap-24"
              key={JSON_TO_KEY(item) + 'inputsmap'}
            >
              {item.map((s, index) => (
                <div
                  className="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center"
                  key={s.name + JSON_TO_KEY(item) + 'inputsmap'}
                >
                  {hiddenInputName(inputs, item) && (
                    <HgbCellTooltip>
                      <p className="tw-min-h-[21px]">{s.name}</p>
                    </HgbCellTooltip>
                  )}
                  {typeof s.value === 'string' ? (
                    <p className="tw-min-h-[21px]">
                      {s.value} {s.unit}
                    </p>
                  ) : (
                    <p className="tw-min-h-[21px]">
                      {new BigNumber(s.value ?? '').toFixed()} {s.unit}
                    </p>
                  )}
                </div>
              ))}
            </div>
          );
        });
      },
    },
    {
      title: C01.label.intensity,
      dataIndex: 'intensity',
      align: 'center',
      width: 150,
      render: (output: EmissionOutput) => {
        const {
          intensities,
          inputs,
          selects,
          scopeCode,
          calculationTargetName,
        } = output;
        let intensity: EmissionVolume[][] = [];
        if (!isEmpty(inputs) && !isEmpty(intensities)) {
          const inputMaxLength = inputs[inputs.length - 1].rowIndex;
          const intensityMaxLength =
            intensities[intensities.length - 1].rowIndex;
          const maxIndex =
            inputMaxLength > intensityMaxLength
              ? inputMaxLength
              : intensityMaxLength;
          for (let i = 0; i <= maxIndex; i++) {
            const tmp = intensities.filter((k) => k.rowIndex === i);
            if (!isEmpty(tmp)) {
              intensity = [...intensity, tmp];
            } else {
              intensity = [
                ...intensity,
                [{ name: '', value: undefined, unit: '', rowIndex: i }],
              ];
            }
          }
        }
        return intensity.map((item, index) => {
          if (
            scopeCode === '2' &&
            calculationTargetName !== 'Direct input' &&
            scopeCode === '2' &&
            calculationTargetName !== '直接手入力'
          ) {
            item = item.filter((item) => {
              if ((item.name?.split(' ') as string[]).length > 1) {
                return selects.includes(item.name?.split(' ')[0] as string);
              } else {
                return selects.includes(
                  item.name?.replace('基準', '') as string,
                );
              }
            });
          }
          return (
            <div
              className="tw-flex tw-h-auto tw-justify-between"
              key={JSON_TO_KEY(item) + 'intensitymap'}
            >
              {item.map((s) => (
                <React.Fragment
                  key={JSON_TO_KEY(s) + JSON_TO_KEY(item) + 'intensitymap'}
                >
                  <div className="tw-flex tw-flex-auto tw-flex-col tw-items-center tw-justify-start">
                    {hiddenInputName(intensity, item) && (
                      <HgbCellTooltip>
                        <p className="tw-min-h-[21px]">
                          {s.rowIndex !== index ? '' : s.name}
                        </p>
                      </HgbCellTooltip>
                    )}
                    <div className="tw-min-h-[21px]">
                      <HgbCellTooltip>
                        {s.rowIndex !== index
                          ? ''
                          : s.value !== null
                          ? (s.value ?? 0).toFixed(
                              floorByMethod(output.calculationMethodName),
                            )
                          : ''}{' '}
                        {s.unit ?? ''}
                      </HgbCellTooltip>
                    </div>
                  </div>
                  {index !== item.length && <div />}
                </React.Fragment>
              ))}
            </div>
          );
        });
      },
    },
    {
      title: common.label.emissions,
      dataIndex: 'emissionVolume',
      align: 'center',
      width: 120,
      render: (output: EmissionOutput) => {
        const { emissionVolume, selects, scopeCode, calculationTargetName } =
          output;
        let emissionVolumes: EmissionVolume[][] = [];

        if (!isEmpty(emissionVolume)) {
          const maxIndex =
            emissionVolume[emissionVolume.length - 1].rowIndex ?? 0;
          for (let i = 0; i <= maxIndex; i++) {
            emissionVolumes = [
              ...emissionVolumes,
              emissionVolume.filter((k) => k.rowIndex === i),
            ];
          }
        }
        return emissionVolumes.map((item) => {
          if (item.length > 2) {
            return (
              <HgbCellTooltipElement
                item={item}
                key={JSON_TO_KEY(item) + 'emissionVolumes'}
              >
                <div className="tw-grid tw-max-w-440 tw-grid-cols-[repeat(auto-fit,minmax(120px,auto))] tw-gap-24">
                  {item.map((s, index) => (
                    <>
                      <div
                        key={index}
                        className="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center"
                      >
                        {hiddenInputName(emissionVolumes, item) && (
                          <p className="tw-min-h-[21px]">{s.name}</p>
                        )}
                        <p className="tw-min-h-[21px]">
                          {s.value?.toString
                            ? new BigNumber(s.value).toFixed()
                            : ''}{' '}
                          {s.unit}
                        </p>
                      </div>
                    </>
                  ))}
                </div>
              </HgbCellTooltipElement>
            );
          }
          if (
            scopeCode === '2' &&
            calculationTargetName !== 'Direct input' &&
            scopeCode === '2' &&
            calculationTargetName !== '直接手入力'
          ) {
            item = item.filter((item) => {
              if ((item.name?.split(' ') as string[]).length > 1) {
                return selects.includes(item.name?.split(' ')[0] as string);
              } else {
                return selects.includes(
                  item.name?.replace('基準', '') as string,
                );
              }
            });
          }
          return (
            <div
              className="tw-grid tw-grid-cols-[repeat(auto-fit,minmax(120px,auto))] tw-gap-24"
              key={JSON_TO_KEY(item) + 'emissionVolumes'}
            >
              {item.map((s, index) => (
                <div
                  className="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center"
                  key={JSON_TO_KEY(s) + 'emissionVolumes__children'}
                >
                  {hiddenInputName(emissionVolumes, item) && (
                    <HgbCellTooltip>
                      <p className="tw-min-h-[21px]">{s.name}</p>
                    </HgbCellTooltip>
                  )}
                  <p className="tw-min-h-[21px]">
                    {s.value?.toString ? new BigNumber(s.value).toFixed() : ''}{' '}
                    {s.unit}
                  </p>
                </div>
              ))}
            </div>
          );
        });
      },
    },
    {
      title: common.label.action,
      align: 'center',
      dataIndex: 'action',
      width: 300,
    },
  ];

  const OFFSET_COLUMNS: ColumnsType<OffsetDataType> = [
    {
      title: C01.label.targetYearMonth,
      dataIndex: 'targetYearMonth',
      width: 100,
    },
    {
      title: C01.label.enterpriseName,
      dataIndex: 'enterpriseName',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: common.label.organizationBaseName,
      dataIndex: 'organizationBaseName',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: C01.label.offsetAmountWithUnit,
      dataIndex: 'offsetAmount',
      width: 120,
      render: (text) => {
        return <HgbCellTooltip>{text}</HgbCellTooltip>;
      },
    },
    {
      title: C01.label.offsetCertificateDate,
      dataIndex: 'offsetCertificateDate',
      width: 120,
    },
    {
      title: C01.label.offsetTarget,
      dataIndex: 'offsetTarget',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: C01.label.projectName,
      dataIndex: 'projectName',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: C01.label.creditType,
      dataIndex: 'creditType',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: common.label.action,
      align: 'center',
      dataIndex: 'action',
      width: 300,
    },
  ];

  const { language } = React.useContext(LanguageContext)!;
  const [step1, setStep1] = React.useState<Step1Fields>();
  const registerModalState = useBooleanState(false);
  const [activityName, setActivityName] = React.useState<string>('');
  const [id, setId] = React.useState<number>(0);
  const [offset, setOffset] = React.useState<OffsetFields>();
  const [enableAutoSearch, setEnableAutoSearch] = React.useState(false);
  const [selectedSupplier, setSelectedOwner] =
    React.useState<SupplierResponse>();
  const [pattern, setPattern] = React.useState<SupplierPattern>('PATTERN_1');
  const [mode, setMode] = React.useState<SupplierAnswerModalMode>('ADD');

  const [emissionDeleteModalProps, setEmissionDeleteModalProps] =
    React.useState<EmissionDeleteModalProps>({
      open: false,
    });

  const [emissionDeleteAllModalProps, setEmissionDeleteAllModalProps] =
    React.useState<EmissionDeleteAllModalProps>({
      open: false,
      quantities: '',
      hasSupplierEmission: false,
    });

  const getSchema = yup.object().shape({
    startMonth: yup
      .string()
      .when('dateType', (dateType: DateType[], schemaBuilder) => {
        if (dateType.includes('MONTH') === false) {
          return schemaBuilder.notRequired();
        }
        return schemaBuilder.required(E0000(C01.label.startMonth));
      }),
    endMonth: yup
      .string()
      .when('dateType', (dateType: DateType[], schemaBuilder) => {
        if (dateType.includes('MONTH') === false) {
          return schemaBuilder.notRequired();
        }
        return schemaBuilder.required(E0000(C01.label.endMonth));
      }),
  });

  const searchMethods = useForm<EmissionDataSearchForm>({
    resolver: yupResolver(getSchema),
    defaultValues: {
      dateType: 'FISCAL_YEAR',
      bankId: '',
      enterpriseId: '',
      baseId: '',
      scopeCode: '1',
      categoryCode: '',
      startMonth: '',
      endMonth: '',
      targetYear: '',
      supplierId: '',
    },
  });

  const {
    register: searchRegister,
    watch: searchWatch,
    setValue: searchSetValue,
  } = searchMethods;

  const searchValues = searchWatch();

  const { mutate: recalculation, isLoading: recalculatetionLoading } =
    useHgbMutationPost<any, undefined>(
      API_URLS.RECALCULATION_EMISSION_DATA_API,
      {
        onSuccess: (data) => {
          if (data.statusCode === STATUS_CODE.success) {
            refetchRelatedData();
          }
        },
      },
    );

  const { ConfirmModal, hgbConfirm } = useConfirm();
  const recalculationHandler = async () => {
    const result = await hgbConfirm();
    if (result) {
      recalculation(undefined);
    }
  };

  const emissionParams: EmissionOutputRequest | undefined =
    React.useMemo(() => {
      const rs: EmissionOutputRequest = {
        baseId: searchValues.baseId,
        enterpriseId: searchValues.enterpriseId,
        scopeCode: searchValues.scopeCode,
        supplierId:
          user.isBuyer && user.isEnterpriseAdmin ? searchValues.supplierId : '',
        categoryCode:
          searchValues.scopeCode === '3' ? searchValues.categoryCode : '00',
        fromYearMonth:
          searchValues.dateType === 'MONTH'
            ? searchValues.startMonth.split('/').join('')
            : undefined,
        toYearMonth:
          searchValues.dateType === 'MONTH'
            ? searchValues.endMonth.split('/').join('')
            : undefined,
        targetYear:
          searchValues.dateType === 'FISCAL_YEAR'
            ? searchValues.targetYear
            : undefined,
        ...paginationProps,
      };
      return rs;
    }, [searchValues, paginationProps]);

  const { data: bankLBQuery, isFetchedAfterMount: bankLBFetchedAfterMount } =
    useHgbQuery<GetBankListBoxResponse, GetBankListBoxRequest>(
      API_URLS.BANK_LIST_BOX_API_URL,
      undefined,
      {
        enabled: user.isPresidingBank,
      },
    );

  const { data: enterpriseLBQuery, refetch: enterpriseLBRefetch } = useHgbQuery<
    GetEnterpriseListBoxResponse,
    GetEnterpriseListBoxRequest
  >(
    API_URLS.ENTERPRISE_LIST_BOX_API_URL,
    {
      bankId: emptyToUndefined(searchValues.bankId),
    },
    {
      enabled: false,
    },
  );

  React.useEffect(() => {
    if (
      (user.isPresidingBank && !isEmpty(searchValues.bankId)) ||
      user.isManagementBank
    ) {
      enterpriseLBRefetch();
    }
  }, [user.isPresidingBank, user.isManagementBank, searchValues.bankId]);

  //  Query supply chain list

  const supplyChainListboxQuery = useHgbQuery<
    GetSupplyChainListBoxResponse,
    undefined
  >(API_URLS.LISTBOX_COMPANY_SUPPLIER, undefined, {
    enabled: user.isEnterpriseAdmin && user.isBuyer,
    queryKey: [API_URLS.LISTBOX_COMPANY_SUPPLIER],
  });

  const BOsQuery = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_BASE,
    {
      enterpriseId: emptyToUndefined(searchValues.enterpriseId),
      supplierId:
        user.isBuyer && user.isEnterpriseAdmin ? searchValues.supplierId : '',
      isLoadAllOption: true,
    },
    {
      enabled: false,
      queryKey: [searchValues.supplierId, API_URLS.BASE_LISTBOX_API_URL],
    },
  );

  const { data: yearLBQuery, refetch: yearLBRefetch } = useHgbQuery<
    GetLast10FiscalYearListBoxResponse,
    GetLast10FiscalYearListBoxRequest
  >(
    API_URLS.LAST_TEN_FISCAL_YEAR,
    {
      enterpriseId: emptyToUndefined(searchValues.enterpriseId),
    },
    {
      enabled: false,
    },
  );

  React.useEffect(() => {
    if (
      user.isEnterpriseAdmin ||
      user.isEnterpriseUser ||
      !isEmpty(searchValues.enterpriseId) ||
      !isEmpty(searchValues.supplierId)
    ) {
      BOsQuery.refetch();
      // BOsRefetch();
      yearLBRefetch();
    }
  }, [
    searchValues.enterpriseId,
    searchValues.supplierId,
    user.isEnterpriseAdmin,
    user.isEnterpriseUser,
  ]);

  const { data: categoryLBQuery, refetch: categoryLBRefetch } = useHgbQuery<
    GetCategoryListBoxResponse,
    GetCategoryListBoxRequest
  >(
    API_URLS.CATEGORY_LIST_BOX_API_URL,
    { scopeCode: searchValues.scopeCode },
    {
      enabled: false,
    },
  );

  const { data: categoryScope3LBQuery, refetch: categoryScope3LBRefetch } =
    useHgbQuery<GetCategoryListBoxResponse, GetCategoryListBoxRequest>(
      API_URLS.SCOPE_3_CATEGORY_LIST_BOX_API_URL,
      { scopeCode: searchValues.scopeCode },
      {
        enabled: false,
      },
    );

  React.useEffect(() => {
    if (searchValues.scopeCode === '3') {
      categoryLBRefetch();
      categoryScope3LBRefetch();
    }
  }, [searchValues.scopeCode, language]);

  const [scopeEditFlow, getScopeEditFlow] = useGet<
    EmissionInputScope1Response,
    undefined
  >();

  const scopeEditClonedHandle = React.useMemo(() => {
    let scopeEditCloned: string[] = [];
    if (scopeEditFlow.data?.nextStepSelectionInfos) {
      scopeEditFlow.data.nextStepSelectionInfos?.forEach((item) => {
        if (item.selectedValue) {
          scopeEditCloned.push(item.selectedValue as string);
        }
      });
    }
    return scopeEditCloned;
  }, [scopeEditFlow.data]);

  const clickEditScopeHandler = async (item: EmissionOutput) => {
    if (item?.insertMethodCode === '3') {
      const response = (await hgbAxiosGetSingle<SupplierResponse>(
        API_URLS.BUYER_SUPPLIER_RESPONSE,
        item.supplierResponseId,
      )) as SupplierResponse;
      // có supplyChainBuyerId => pattern 1
      if (response?.supplyChainBuyerId) {
        setPattern('PATTERN_1');
      } else {
        setPattern('PATTERN_2');
      }
      setSelectedOwner(response);
      setMode('EDIT');
      return registerModalState.turnOn();
    }
    setStep1({
      baseId: item.baseId,
      categoryCode: item.categoryCode,
      scopeCode: item.scopeCode,
      targetFiscalYear: item.targetFiscalYear,
      targetYearMonth: item.targetYearMonth,
    });
    const url = `/api/v1/emission-input/scope-${item.scopeCode}/flow${
      item.categoryCode !== '00' ? `/category-${Number(item.categoryCode)}` : ''
    }/${item.id}`;
    setId(item.id ?? '');
    setActivityName(item.activityName);
    getScopeEditFlow(url);
  };
  const clickDuplicateScopeHandler = async (item: EmissionOutput) => {
    if (item?.insertMethodCode === '3') {
      const response = (await hgbAxiosGetSingle<SupplierResponse>(
        API_URLS.BUYER_SUPPLIER_RESPONSE,
        item.supplierResponseId,
      )) as SupplierResponse;

      setPattern('PATTERN_2');
      setSelectedOwner(response);
      setMode('DUPLICATE');
      return registerModalState.turnOn();
    }

    setStep1({
      baseId: item.baseId,
      categoryCode: item.categoryCode,
      scopeCode: item.scopeCode,
      targetFiscalYear: item.targetFiscalYear,
      targetYearMonth: item.targetYearMonth,
    });
    const url = `/api/v1/emission-input/scope-${item.scopeCode}/flow${
      item.categoryCode !== '00' ? `/category-${Number(item.categoryCode)}` : ''
    }/${item.id}`;
    setId(0);
    setActivityName(item.activityName);
    getScopeEditFlow(url);
  };

  const clickEditOffsetHandler = (item: OffsetDetails) => {
    setStep1({
      baseId: item.baseId,
      categoryCode: item.categoryCode,
      scopeCode: item.scopeCode,
      targetFiscalYear: item.targetFiscalYear,
      targetYearMonth: item.targetYearMonth,
    });
    setOffset({
      creditType: item.creditType,
      offsetAmount: item.offsetAmount,
      offsetAmountUnit: item.offsetAmountUnit,
      offsetCertificateDate: item.offsetCertificateDate ?? '',
      offsetTarget: item.offsetTarget,
      projectName: item.projectName,
    });
    setId(item.id);
  };

  const clickDuplicateOffsetHandler = (item: OffsetDetails) => {
    setStep1({
      baseId: item.baseId,
      categoryCode: item.categoryCode,
      scopeCode: item.scopeCode,
      targetFiscalYear: item.targetFiscalYear,
      targetYearMonth: item.targetYearMonth,
    });
    setOffset({
      creditType: item.creditType,
      offsetAmount: item.offsetAmount,
      offsetAmountUnit: item.offsetAmountUnit,
      offsetCertificateDate: item.offsetCertificateDate ?? '',
      offsetTarget: item.offsetTarget,
      projectName: item.projectName,
    });
    setId(0);
  };

  const clickDeleteEmissionHandler = (item: OffsetDetails | EmissionOutput) => {
    setEmissionDeleteModalProps({
      data: item,
      open: true,
    });
  };

  const clickDeleteAllEmissionHandler = (item: EmissionDeleteAllData) => {
    setEmissionDeleteAllModalProps({
      data: item,
      open: true,
      quantities: selectedRowKeys.length.toString(),
      hasSupplierEmission: hasSupplierEmissionHandle,
    });
  };

  const supplyChainOptions = React.useMemo(() => {
    const rs = parseToHgbSelectOptions(supplyChainListboxQuery.data?.result);
    searchSetValue('supplierId', rs?.[0]?.value);
    return rs;
  }, [supplyChainListboxQuery.data?.result]);

  const bankIdOptions = React.useMemo(
    () => parseToHgbSelectOptions(bankLBQuery?.result),
    [bankLBQuery?.result],
  );

  const enterpriseIdOptions = React.useMemo(
    () => parseToHgbSelectOptions(enterpriseLBQuery?.result),
    [enterpriseLBQuery?.result],
  );

  const targetYearOptions = React.useMemo(
    () =>
      parseToHgbSelectOptions(yearLBQuery?.result).filter(
        (word) => Number(word.value) > 2018,
      ),
    [yearLBQuery?.result],
  );

  const baseIdOptions = React.useMemo(() => {
    if (
      searchValues.supplierId !== '0' &&
      searchValues.supplierId !== '-1' &&
      searchValues.supplierId !== undefined &&
      searchValues.supplierId !== ''
    ) {
      searchSetValue('baseId', '');
      return [];
    }
    const BOs = BOsQuery?.data?.result ?? [];
    return convertObjectToTree(
      BOs.filter((item) => !getParentKey(item.hierarchicalPath ?? '')),
      BOs,
    );
  }, [JSON.stringify(BOsQuery?.data?.result)]);

  const categoryCodeOptions = React.useMemo(
    () => parseScopeToHgbSelectOptions(categoryLBQuery?.result),
    [categoryLBQuery?.result],
  );

  const dateErrorMessage = React.useMemo(() => {
    if (!enableAutoSearch) return false;
    if (searchValues.dateType === 'MONTH') {
      if (isEmpty(searchValues.startMonth)) {
        return common.message.startMonthEmpty;
      }
      if (isEmpty(searchValues.endMonth)) {
        return common.message.endMonthEmpty;
      }
      if (!validateMonthRange(searchValues.startMonth, searchValues.endMonth)) {
        return E0049;
      }
    }
    return undefined;
  }, [searchValues, language, enableAutoSearch]);

  const errorMessage = React.useMemo(() => {
    if (user.isPresidingBank) {
      if (isEmpty(bankIdOptions) && bankLBFetchedAfterMount) {
        return common.message.partnerBanksEmpty;
      }
      if (searchValues.bankId === '') {
        return common.message.choosePartnerBankAndCompany;
      }
      if (isEmpty(enterpriseIdOptions)) {
        return common.message.companyEmpty;
      }
      if (isEmpty(searchValues.enterpriseId)) {
        return common.message.chooseCompany;
      }
    }

    if (user.isManagementBank) {
      if (isEmpty(enterpriseIdOptions)) {
        return common.message.companyEmpty;
      }
      if (isEmpty(searchValues.enterpriseId)) {
        return common.message.chooseCompany;
      }
    }
    return undefined;
  }, [
    user,
    searchValues,
    enterpriseIdOptions,
    bankIdOptions,
    bankLBFetchedAfterMount,
    language,
  ]);

  const offsetQuery = useHgbQueryWithPaging<
    OffsetDetails[],
    GetAllOffsetRequest
  >(API_URLS.OFFSET_API_URL, emissionParams, {
    enabled: enableAutoSearch && emissionParams.scopeCode === '4',
    queryKey: [
      QUERY_KEYS.EMISSION_LIST,
      '4',
      API_URLS.OFFSET_API_URL,
      emissionParams,
      user.accountId,
    ],
  });

  const emissionQuery = useHgbQueryWithPaging<
    EmissionOutput[],
    GetAllOffsetRequest
  >(API_URLS.EMISSION_OUTPUT_DATA_LIST, emissionParams, {
    enabled:
      enableAutoSearch &&
      emissionParams.scopeCode !== '4' &&
      ((emissionParams.scopeCode === '3' && !!emissionParams.categoryCode) ||
        emissionParams.scopeCode !== '3'),
    queryKey: [
      QUERY_KEYS.EMISSION_LIST,
      emissionParams.scopeCode,
      API_URLS.EMISSION_OUTPUT_DATA_LIST,
      emissionParams,
      user.accountId,
    ],
  });

  React.useEffect(() => {
    resetPaginations();
  }, [
    emissionParams.bankId,
    emissionParams.baseId,
    emissionParams.categoryCode,
    emissionParams.enterpriseId,
    emissionParams.fromYearMonth,
    emissionParams.toYearMonth,
    emissionParams.targetYear,
    emissionParams.scopeCode,
    emissionParams.supplierId,
  ]);

  React.useEffect(() => {
    if (!registerModalState.value) {
      setSelectedOwner(undefined);
    }
  }, [registerModalState.value]);

  const emissionData = React.useMemo(() => {
    return (emissionQuery?.data?.result?.content ?? []).map((item, index) => {
      return {
        key: item.id,
        targetYearMonth: item.targetYearMonth,
        enterpriseName: item.enterpriseName,
        organizationBaseName: item.organizationBaseName,
        calculationMethodName: item.calculationMethodName,
        calculationTargetName: item.calculationTargetName,
        types: item.selects,
        amount: item.inputs,
        intensity: item,
        emissionVolume: item,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
        createdMethod: item.createdMethod || '',
        targetLciDbYear: item,
        isCanDelete: item.isCanDelete,
        insertMethodCode: item.insertMethodCode,
        action: (
          <div className="tw-flex tw-justify-center tw-gap-24">
            <HgbActionButton
              onClick={() => clickDuplicateScopeHandler(item)}
              disabled={!item.isCanUpdate || !!item.supplyChainBuyerId}
              icon={<CopyOutlined />}
            >
              {common.button.duplicate}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => clickEditScopeHandler(item)}
              disabled={!item.isCanUpdate}
              icon={<EditOutlined />}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => clickDeleteEmissionHandler(item)}
              disabled={!item.isCanDelete}
              icon={<DeleteOutlined />}
            >
              {common.button.delete}
            </HgbActionButton>
          </div>
        ),
      };
    });
  }, [
    JSON.stringify(emissionQuery?.data?.result?.content),
    selectedRowKeys,
    language,
  ]);

  const offsetData: OffsetDataType[] = React.useMemo(() => {
    return (offsetQuery?.data?.result?.content ?? []).map((item, index) => {
      return {
        key: item.id,
        targetYearMonth: item.targetYearMonth,
        enterpriseName: item.enterpriseName,
        organizationBaseName: item.organizationBaseName,
        offsetAmount: `${(item.offsetAmount ?? 0).toFixed(3)} ${
          item.offsetAmountUnit
        }`,
        offsetCertificateDate: item.offsetCertificateDate,
        offsetTarget: item.offsetTarget,
        projectName: item.projectName,
        creditType: item.creditType,
        createdBy: item.createBy,
        createdDate: item.createDate,
        insertMethodCode: item.insertMethodCode,
        insertMethodName: item.insertMethodName,
        baseId: item.baseId,
        scopeCode: item.scopeCode,
        targetYear: item.targetYear,
        categoryCode: item.categoryCode,
        targetLciDbYear: item.targetLciDbYear,
        id: item.id,
        isCanDelete: item.isCanDelete,
        action: (
          <div className="tw-flex tw-justify-center tw-gap-24">
            <HgbActionButton
              onClick={() => {
                clickDuplicateOffsetHandler({
                  ...item,
                  offsetAmount: undefined,
                  offsetCertificateDate: '',
                });
              }}
              disabled={!item.isCanUpdate}
              icon={<CopyOutlined />}
            >
              {common.button.duplicate}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => clickEditOffsetHandler(item)}
              disabled={!item.isCanUpdate}
              icon={<EditOutlined />}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => clickDeleteEmissionHandler(item)}
              disabled={!item.isCanDelete}
              icon={<DeleteOutlined />}
            >
              {common.button.delete}
            </HgbActionButton>
          </div>
        ),
      };
    });
  }, [
    JSON.stringify(offsetQuery?.data?.result?.content),
    selectedRowKeys,
    language,
  ]);

  const closeEmissionDeleteModal = () => {
    setEmissionDeleteModalProps((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const closeEmissionDeleteAllModal = () => {
    setEmissionDeleteAllModalProps((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const deleteSuccessHandler = () => {
    closeEmissionDeleteModal();
    resetPaginations();
    offsetQuery.refetch();
    emissionQuery.refetch();
    // emissionQueryRefetch();
  };

  const deleteFailHandler = () => {
    closeEmissionDeleteModal();
    resetPaginations();
    offsetQuery.refetch();
    emissionQuery.refetch();
  };

  const deleteAllSuccessHandler = () => {
    closeEmissionDeleteAllModal();
    setSelectedRowKeys([]);
    resetPaginations();
    offsetQuery.refetch();
    emissionQuery.refetch();
    // emissionQueryRefetch();
  };

  const dataTable = React.useMemo(() => {
    const isHighRole = user.isPresidingBank || user.isManagementBank;
    let OFFSET_COLUMNS_BY_ROLE: ColumnsType<OffsetDataType> = [];
    let EMISSION_COLUMNS_BY_ROLE: ColumnsType<EmissionOutputDataType> = [];

    if (isHighRole) {
      OFFSET_COLUMNS_BY_ROLE = OFFSET_COLUMNS.slice(
        0,
        OFFSET_COLUMNS.length - 1,
      );
      EMISSION_COLUMNS_BY_ROLE = EMISSION_OUTPUT_COLUMNS.slice(
        0,
        EMISSION_OUTPUT_COLUMNS.length - 1,
      );
    } else {
      OFFSET_COLUMNS_BY_ROLE = OFFSET_COLUMNS.filter(
        (s) => s.title !== C01.label.enterpriseName,
      );
      EMISSION_COLUMNS_BY_ROLE = EMISSION_OUTPUT_COLUMNS.filter(
        (s) => s.title !== C01.label.enterpriseName,
      );
    }

    if (searchValues.scopeCode === '4') {
      if (!isEmpty(offsetData))
        return (
          <Table
            rowSelection={
              user.isEnterpriseAdmin || user.isEnterpriseUser
                ? {
                    ...(rowSelection as any),
                  }
                : ''
            }
            columns={OFFSET_COLUMNS_BY_ROLE}
            dataSource={offsetData}
            pagination={false}
            scroll={{
              x: OFFSET_COLUMNS_BY_ROLE.reduce(
                (prev, current) => prev + Number(current.width ?? 0),
                0,
              ),
              y: 500,
            }}
            rowKey={(record) => record.key}
          />
        );
    }
    if (!isEmpty(emissionData)) {
      return (
        <Table
          rowSelection={
            user.isEnterpriseAdmin || user.isEnterpriseUser
              ? {
                  ...(rowSelection as any),
                }
              : ''
          }
          columns={EMISSION_COLUMNS_BY_ROLE}
          dataSource={emissionData}
          pagination={false}
          scroll={{
            x: EMISSION_COLUMNS_BY_ROLE.reduce(
              (prev, current) => prev + Number(current.width ?? 0),
              0,
            ),
            y: 500,
          }}
          rowKey={(record) => record.key}
        />
      );
    }

    return offsetQuery.isFetchedAfterMount ||
      emissionQuery.isFetchedAfterMount ? (
      <HgbAntdEmpty
        className={'tw-h-full tw-items-center tw-justify-center'}
        description={common.message.noResult}
      />
    ) : null;
  }, [
    emissionData,
    offsetData,
    language,
    offsetQuery.isFetchedAfterMount,
    emissionQuery.isFetchedAfterMount,
    selectedRowKeys,
  ]);

  const Pagination = React.useCallback(() => {
    if (emissionParams.scopeCode === '4') {
      return (offsetQuery?.data?.result?.totalRecord ?? 0) > 0 ? (
        <PaginationComponent
          current={offsetQuery?.data?.result?.currentPage}
          total={offsetQuery?.data?.result?.totalRecord}
          pageSize={offsetQuery?.data?.result?.pageSize}
        />
      ) : null;
    }
    return (emissionQuery?.data?.result?.totalRecord ?? 0) > 0 ? (
      <PaginationComponent
        current={emissionQuery?.data?.result?.currentPage}
        total={emissionQuery?.data?.result?.totalRecord}
        pageSize={emissionQuery?.data?.result?.pageSize}
      />
    ) : null;
  }, [
    emissionParams.scopeCode,
    offsetQuery?.data?.result,
    emissionQuery?.data?.result,
    PaginationComponent,
  ]);

  const hasSupplierEmissionHandle = React.useMemo(() => {
    const supplierEmissionArr = emissionQuery?.data?.result?.content?.filter(
      (item) => item.insertMethodCode === '3',
    );
    if (isEmpty(supplierEmissionArr)) return false;
    const array = supplierEmissionArr
      ?.map((item) => item.id)
      ?.filter((i) => selectedRowKeys.includes(i));
    if (isEmpty(array)) return false;
    return true;
  }, [selectedRowKeys, emissionQuery?.data?.result?.content]);

  const refetchRelatedData = React.useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.EMISSION_LIST, emissionParams?.scopeCode],
    });
  }, [emissionParams?.scopeCode]);

  const handleCreateSupplierInput = () => {
    setMode('ADD');
    setPattern('PATTERN_2');
    registerModalState.turnOn();
  };

  return (
    <ManagementTemplate pageName={C01.pageTitle}>
      <DataTableTemplate
        title={C01.pageTitle}
        paginationComponents={<Pagination />}
      >
        <FormProvider {...searchMethods}>
          <div className="tw-flex tw-flex-col tw-gap-24">
            {(user.isEnterpriseAdmin || user.isEnterpriseUser) && (
              <div className="tw-flex tw-flex-wrap tw-gap-16 tw-border-0 tw-border-b tw-border-dashed tw-border-neutral-1 tw-pb-16 large:tw-gap-24 large:tw-pb-24">
                <h3 className="tw-self-center tw-font-bold">
                  {C01.label.newRegistrationEmissionData}
                </h3>
                <div className="tw-flex tw-flex-auto tw-flex-wrap tw-gap-16 large:tw-gap-24">
                  <HgbAntdButton
                    type="primary"
                    ghost
                    endIcon={
                      <HgbInfoLink
                        to={
                          '/support/manual/' +
                          MANUAL_NAMEDDESTS.about_upload_file
                        }
                        target="_blank"
                      />
                    }
                    onClick={() => navigate(routs_en['/data-entry/ocr'].link)}
                    className="tw-w-full pc:tw-w-220"
                  >
                    <>{C01.label.uploadFile}</>
                  </HgbAntdButton>

                  <HgbAntdButton
                    type="primary"
                    ghost
                    endIcon={
                      <HgbInfoLink
                        to={
                          '/support/manual/' +
                          MANUAL_NAMEDDESTS.about_import_csv
                        }
                        target="_blank"
                      />
                    }
                    onClick={() => navigate(routs_en['/data-entry/csv'].link)}
                    className="tw-w-full pc:tw-w-220"
                  >
                    <>{C01.label.uploadCSV}</>
                  </HgbAntdButton>

                  <EmissionCreateModal reset={refetchRelatedData} />

                  <HgbAntdButton
                    type="primary"
                    ghost
                    onClick={handleCreateSupplierInput}
                    className="tw-w-full pc:tw-w-220"
                  >
                    <>{C01.label.supplierEmissionsInput}</>
                  </HgbAntdButton>
                </div>
              </div>
            )}
            <h3 className="tw-font-bold">{C01.label.emissionDataSearch}</h3>
            <div className="tw-space-y-16">
              <div className="tw-flex tw-flex-wrap tw-gap-16 pc:tw-gap-24">
                {user.isPresidingBank ? (
                  <HgbSelect
                    className="tw-flex tw-w-full tw-flex-col pc:tw-w-240"
                    label={common.placeholder.bankId}
                    placeholder={C01.placeholder.bankId}
                    options={bankIdOptions}
                    disabled={isEmpty(bankIdOptions)}
                    {...searchRegister('bankId')}
                    onChangeValue={() => {
                      searchSetValue(
                        'enterpriseId',
                        enterpriseIdOptions?.[0]?.value,
                      );
                    }}
                    showSearch
                  />
                ) : null}
                {user.isPresidingBank || user.isManagementBank ? (
                  <HgbSelect
                    className="tw-flex tw-w-full tw-flex-col pc:tw-w-240"
                    label={C01.label.enterpriseId}
                    placeholder={C01.placeholder.enterpriseId}
                    disabled={isEmpty(enterpriseIdOptions)}
                    options={enterpriseIdOptions}
                    {...searchRegister('enterpriseId')}
                    showSearch
                  />
                ) : null}

                {user.isEnterpriseAdmin && user.isBuyer ? (
                  <HgbSelect
                    className="tw-flex tw-w-full tw-flex-col pc:tw-w-240"
                    label={C01.label.enterpriseId}
                    placeholder={''}
                    disabled={isEmpty(supplyChainOptions)}
                    options={supplyChainOptions}
                    {...searchRegister('supplierId')}
                    showSearch
                  />
                ) : null}

                <HgbTreeSelect
                  className="tw-flex tw-w-full tw-flex-col pc:tw-w-240"
                  label={C01.label.baseId}
                  placeholder={C01.placeholder.baseId}
                  disabled={isEmpty(baseIdOptions)}
                  treeData={baseIdOptions}
                  {...searchRegister('baseId')}
                  showSearch
                  onChangeValue={() => {
                    setSelectedRowKeys([]);
                  }}
                  autoInitValue
                />
              </div>

              <div className="tw-space-y-8">
                <h4 className="tw-font-bold">
                  {C01.label.displayRangeSpecification}
                </h4>
                <div className="tw-flex tw-flex-wrap tw-gap-x-32 tw-gap-y-24">
                  <div className="tw-flex tw-flex-col tw-gap-16 -tw-space-y-16 large:tw-flex-row large:tw-items-start large:tw-space-y-0">
                    <HgbAntdRadioGroup
                      options={[FISCAL_YEAR_OPTIONS[0]]}
                      {...searchRegister('dateType')}
                      disabled={isEmpty(targetYearOptions)}
                      onChangeValue={() => {
                        setSelectedRowKeys([]);
                      }}
                    />
                    <HgbSelect
                      disabled={
                        isEmpty(targetYearOptions) ||
                        searchValues.dateType === 'MONTH'
                      }
                      options={targetYearOptions}
                      {...searchRegister('targetYear')}
                      placeholder={C01.placeholder.targetYear}
                      className="tw-w-120"
                      onChangeValue={() => {
                        setSelectedRowKeys([]);
                      }}
                      autoInitValue
                    />
                    <HgbAntdRadioGroup
                      options={[FISCAL_YEAR_OPTIONS[1]]}
                      {...searchRegister('dateType')}
                      disabled={isEmpty(targetYearOptions)}
                      onChangeValue={() => {
                        setSelectedRowKeys([]);
                      }}
                    />
                    <div className="grid">
                      <div className="tw-flex tw-gap-16">
                        <HgbDatePicker
                          disabled={
                            isEmpty(targetYearOptions) ||
                            searchValues.dateType === 'FISCAL_YEAR'
                          }
                          picker="month"
                          {...searchRegister('startMonth')}
                          placeholder="2022/03"
                          format="YYYY/MM"
                          className="tw-w-120"
                          onChangeValue={() => {
                            setSelectedRowKeys([]);
                          }}
                        />
                        <div className="tw-mt-20 tw-h-1 tw-w-8 tw-shrink-0 tw-bg-neutral-8"></div>
                        <HgbDatePicker
                          disabled={
                            isEmpty(targetYearOptions) ||
                            searchValues.dateType === 'FISCAL_YEAR'
                          }
                          picker="month"
                          {...searchRegister('endMonth')}
                          placeholder="2022/04"
                          format="YYYY/MM"
                          className="tw-w-120"
                          onChangeValue={() => {
                            setSelectedRowKeys([]);
                          }}
                        />
                      </div>
                      {dateErrorMessage && (
                        <HgbFieldError>{dateErrorMessage}</HgbFieldError>
                      )}
                    </div>
                    <div className="tw-h-16 large:tw-hidden"></div>
                    <HgbAntdButton
                      className="tw-w-120"
                      type="primary"
                      onClick={() => {
                        setEnableAutoSearch(true);
                        setSelectedRowKeys([]);
                      }}
                    >
                      <>{C01.label.search}</>
                    </HgbAntdButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-items-end tw-gap-16 large:tw-flex-row">
              <div className="tw-flex tw-flex-auto tw-flex-wrap tw-gap-16 tw-overflow-hidden large:tw-gap-24">
                <Tabs
                  className="tw-min-w-320 !tw-border-0 [&_.ant-tabs-tab-active_.ant-tabs-tab-btn]:tw-font-bold"
                  activeKey={searchValues.scopeCode}
                  onChange={(index) => {
                    searchSetValue('scopeCode', index.toString() as ScopeCode);
                    setSelectedRowKeys([]);
                  }}
                  items={tabItems.map((item) => ({
                    label: <div title={item.note}>{item.label}</div>,
                    key: item.key,
                  }))}
                />
                {searchValues.scopeCode === '3' && (
                  <HgbSelect
                    className="tw-w-full pc:tw-w-400"
                    {...searchRegister('categoryCode')}
                    options={categoryCodeOptions}
                    disabled={isEmpty(categoryCodeOptions)}
                    onChangeValue={() => {
                      setSelectedRowKeys([]);
                    }}
                    autoInitValue
                  />
                )}
              </div>

              <div className="tw-flex tw-gap-16 large:tw-gap-24">
                {(user.isEnterpriseAdmin || user.isEnterpriseUser) && (
                  <HgbAntdButton
                    className="tw-w-[calc(50%_-_8px)] pc:tw-w-160"
                    type="primary"
                    onClick={() =>
                      clickDeleteAllEmissionHandler({
                        id: selectedRowKeys.join(),
                        scopeCode: searchWatch('scopeCode'),
                        insertMethodCode: '3',
                        categoryCode:
                          searchWatch('scopeCode') !== '3'
                            ? '00'
                            : searchWatch('categoryCode'),
                        hasSupplierEmission: hasSupplierEmissionHandle,
                      })
                    }
                    disabled={isEmpty(selectedRowKeys)}
                  >
                    <>{C01.label.deleteAll}</>
                  </HgbAntdButton>
                )}
                {user.isEnterpriseAdmin && (
                  <HgbAntdButton
                    className="tw-w-[calc(50%_-_8px)] pc:tw-w-160"
                    type="primary"
                    loading={recalculatetionLoading}
                    onClick={recalculationHandler}
                    endIcon={
                      <HgbInfoLink
                        to={
                          '/support/manual/' +
                          MANUAL_NAMEDDESTS.about_reload_emission
                        }
                        target="_blank"
                      />
                    }
                  >
                    <>{C01.label.lcidb}</>
                  </HgbAntdButton>
                )}
              </div>
            </div>
            <div className="tw-relative tw-flex-auto">
              {!!errorMessage || !!dateErrorMessage ? (
                errorMessage ? (
                  <HgbAntdEmpty
                    description={errorMessage}
                    className={
                      'tw-flex tw-h-full tw-items-center tw-justify-center'
                    }
                  />
                ) : null
              ) : offsetQuery.isFetching || emissionQuery.isFetching ? (
                <HgbAntdSpin
                  className={
                    'tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2'
                  }
                />
              ) : (
                dataTable
              )}
            </div>
            {!isEmpty(scopeEditFlow.data) ||
            !isEmpty(offset) ||
            !isEmpty(scopeEditClonedHandle) ? (
              <div className="tw-hidden">
                <EmissionUpdateModal
                  step1={step1}
                  scopeEdit={scopeEditFlow.data ?? undefined}
                  activityName={activityName}
                  id={id}
                  offset={offset}
                  reset={refetchRelatedData}
                  scopeEditCloned={scopeEditClonedHandle}
                />
              </div>
            ) : null}

            <EmissionDeleteModal
              {...emissionDeleteModalProps}
              onCancel={closeEmissionDeleteModal}
              onSuccess={deleteSuccessHandler}
              onFail={deleteFailHandler}
            />
            <EmissionDeleteAllModal
              {...emissionDeleteAllModalProps}
              onCancel={closeEmissionDeleteAllModal}
              onSuccess={deleteAllSuccessHandler}
            />
          </div>
        </FormProvider>
      </DataTableTemplate>
      <ConfirmModal
        title={C01.modal.recalculationConfirmModalTitle}
        cancelText={common.button.cancel}
        okText={C01.modal.recalculationConfirmModalOk}
      >
        <p>{C01.modal.recalculationConfirmModalContent}</p>
      </ConfirmModal>

      {registerModalState?.value && (
        <SupplierEmissionInputModal
          modalState={registerModalState}
          supplier={selectedSupplier}
          pattern={pattern}
          mode={mode}
        />
      )}
    </ManagementTemplate>
  );
};
