import { ReactNode } from 'react';
import { isHalfSize } from './input.utils';
import BigNumber from 'bignumber.js';

export const emptyToUndefined = (str?: string) => {
  if ((str ?? '').trim() === '') return undefined;
  return str;
};

export const emptyToString = (str: null | string | undefined) => {
  if (str === null) {
    return '';
  }
  return (str ?? '').trim().toString();
};

export const strWithCondition = (str: string, condition: boolean) => {
  return condition ? str : '';
};

export function parseFullWidthToHalfWidth(text: string) {
  let characters = getCharacters(
    text
      .replace(/[\uff01-\uff5e]/g, (fullwidthChar) =>
        String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0),
      )
      .replace(/\u3000/g, '\u0020'),
  );
  let halfWidthString = '';
  characters.forEach((character) => {
    halfWidthString += mapToHankaku(character);
  });
  return halfWidthString;
}

function getCharacters(text: string) {
  return text.split('');
}

function mapToHankaku(character: string) {
  let zenHanMap = getZenkakuToHankakuMap();
  if (typeof zenHanMap[character] === 'undefined') {
    return toASCII(character);
  } else {
    return zenHanMap[character];
  }
}

function toASCII(char: string) {
  if (isHalfSize(char)) {
    return char;
  } else {
    let c = char.charCodeAt(0);
    if (c >= 0xff00 && c <= 0xffef) {
      c = 0xff & (c + 0x20);
    }
    return String.fromCharCode(c);
  }
}

export function joinSafe(arr: (string | undefined)[], splitText: string) {
  return arr
    .filter((item) => item !== '' && item !== undefined && item !== null)
    .join(splitText);
}

export function trimWithEn(value = '') {
  let v = value;
  do {
    v = v.replaceAll('  ', ' ');
  } while (v.indexOf('  ') !== -1);

  if (v.indexOf(' ') === 0) {
    v = v.slice(1);
  }

  if (v.indexOf(' ') === v.length - 1) {
    v = v.slice(0, -1);
  }

  return v;
}

function getZenkakuToHankakuMap() {
  let zenHanMap: Record<string, string> = {
    ァ: 'ｧ',
    ア: 'ｧ',
    ｱ: 'ｧ',
    ィ: 'ｨ',
    イ: 'ｨ',
    ｲ: 'ｨ',
    ゥ: 'ｩ',
    ウ: 'ｩ',
    ｳ: 'ｩ',
    ェ: 'ｪ',
    エ: 'ｪ',
    ｴ: 'ｪ',
    ォ: 'ｫ',
    オ: 'ｫ',
    ｵ: 'ｫ',
    カ: 'ｶ',
    ガ: 'ｶﾞ',
    キ: 'ｷ',
    ギ: 'ｷﾞ',
    ク: 'ｸ',
    グ: 'ｸﾞ',
    ケ: 'ｹ',
    ゲ: 'ｹﾞ',
    コ: 'ｺ',
    ゴ: 'ｺﾞ',
    サ: 'ｻ',
    ザ: 'ｻﾞ',
    シ: 'ｼ',
    ジ: 'ｼﾞ',
    ス: 'ｽ',
    ズ: 'ｽﾞ',
    セ: 'ｾ',
    ゼ: 'ｾﾞ',
    ソ: 'ｿ',
    ゾ: 'ｿﾞ',
    タ: 'ﾀ',
    ダ: 'ﾀﾞ',
    チ: 'ﾁ',
    ヂ: 'ﾁﾞ',
    ッ: 'ｯ',
    ツ: 'ｯ',
    ﾂ: 'ｯ',
    ヅ: 'ﾂﾞ',
    テ: 'ﾃ',
    デ: 'ﾃﾞ',
    ト: 'ﾄ',
    ド: 'ﾄﾞ',
    ナ: 'ﾅ',
    ニ: 'ﾆ',
    ヌ: 'ﾇ',
    ネ: 'ﾈ',
    ノ: 'ﾉ',
    ハ: 'ﾊ',
    バ: 'ﾊﾞ',
    パ: 'ﾊﾟ',
    ヒ: 'ﾋ',
    ビ: 'ﾋﾞ',
    ピ: 'ﾋﾟ',
    フ: 'ﾌ',
    ブ: 'ﾌﾞ',
    プ: 'ﾌﾟ',
    ヘ: 'ﾍ',
    ベ: 'ﾍﾞ',
    ペ: 'ﾍﾟ',
    ホ: 'ﾎ',
    ボ: 'ﾎﾞ',
    ポ: 'ﾎﾟ',
    マ: 'ﾏ',
    ミ: 'ﾐ',
    ム: 'ﾑ',
    メ: 'ﾒ',
    モ: 'ﾓ',
    ャ: 'ｬ',
    ヤ: 'ｬ',
    ﾔ: 'ｬ',
    ュ: 'ｭ',
    ユ: 'ｭ',
    ﾕ: 'ｭ',
    ョ: 'ｮ',
    ヨ: 'ｮ',
    ﾖ: 'ｮ',
    ラ: 'ﾗ',
    リ: 'ﾘ',
    ル: 'ﾙ',
    レ: 'ﾚ',
    ロ: 'ﾛ',
    ヮ: '',
    ワ: 'ﾜ',
    // 'ヰ': 'ﾞ  ﾟ',
    // 'ヱ': '',
    ヲ: 'ｦ',
    ン: 'ﾝ',
    ヴ: 'ｳﾞ',
    // 'ヵ': '',
    // 'ヶ': '',
    // 'ヷ': '',
    // 'ヸ': '',
    // 'ヹ': '',
    // 'ヺ': '',
    '・': '･',
    ー: 'ｰ',
    // 'ヽ': '',
    // 'ヾ': '',
    // 'ヿ': '',
  };
  return zenHanMap;
}

export function JSON_TO_KEY(object: any = {}): string {
  return JSON.stringify(object).replaceAll(/[^a-zA-Z0-9\-]+/g, '');
}

export function createMessage(pattern: string, ...params: string[]) {
  for (let index = 0; index < params.length; index++) {
    pattern = pattern.replace(`{${index}}`, params[index]);
  }
  return pattern;
}

export function createNode(pattern: string, node: ReactNode) {
  const text1 = pattern.split('{0}')?.[0] ?? '';
  const text2 = pattern.split('{0}')?.[1] ?? '';
  return (
    <>
      {text1} {node} {text2}
    </>
  );
}

export function bigNumberToString(value?: string) {
  if (value === undefined || value === null) return '';
  return new BigNumber(value ?? '').toFixed();
}
