import { SelectionInfo } from 'types/api/emission-input/emission-input.type';

export const objectToPairsNameValue = (
  originObject: object,
): { name: string; value: number }[] => {
  return Object.entries(originObject).map(([name, value]: [string, any]) => ({
    name,
    value: Number(value),
  }));
};

const methodSwitchCase = (method: string) => {
  const methodList = [
    'M00005',
    'M00018',
    'M00019',
    'M00035',
    'M00036',
    'M00037',
    'M00038',
  ];

  if (!methodList.includes(method)) return 0;

  return method === 'M00018' ? 3 : 2;
};

export const mapSelectToSelectIndex = (selects: SelectionInfo[]) => {
  let index = 0;
  let marked = true;

  selects.forEach((item, i) => {
    if (
      item.fieldTarget === 'calculationTargetCode' &&
      (item.selectedValue === 'ST034' ||
        item.selectedValue === 'ST054' ||
        item.selectedValue === 'ST035')
    ) {
      item.index = i + 1;
      index = 2;
      marked = false;
    } else if (item.fieldTarget === 'calculationMethodCode' && marked) {
      index = methodSwitchCase(item.selectedValue ?? '');
      if (index === 2 && item.selectedValue === 'M00019') {
        if (
          selects[i + 1].selectedValue !== 'K0001' ||
          selects[i + 2].selectedValue !== 'DK0001'
        ) {
          index = 0;
        }
      }
      item.index = i + 1;
    } else {
      item.index = i + index;
      if (index > 0) --index;
      else item.index += 1;
    }
  });
  return selects;
};

export const sumOfObject = (obj: Record<string, number>) => {
  return Object.values(obj).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );
};