import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbLink } from '@common/components';
import { HgbBulletItem } from '@common/contents';
import { HgbAntdInput } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { EMAIL_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthenTemplate } from '@layouts/templates';
import { LocalDataClass } from 'data-class/LocalDataClass';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { getMessageType, useHgbMutationPost } from 'services/common/mutation';
import { ReissueRequest, reissueRequestDefault } from 'services/types/authen';
import * as yup from 'yup';

export const ReIssue: React.FC = () => {
  const {
    routs,
    text: { A03, common, E0000 },
  } = useContext(LanguageContext)!;

  const { currentBankCode } = useContext(AuthContext)!;
  const [isReissueSuccess, setReissueSuccess] = useState(false);
  const [disabledBankCode, setDisabledBankCode] = useState(true);

  const reissueSchema = yup.object({
    bankCode: yup.string().required(E0000(common.label.bankCode)),
    username: yup.string().required(E0000(common.label.loginID)),
    email: yup
      .string()
      .required(E0000(A03.label.email))
      .matches(EMAIL_REGEX, common.message.emailFormat),
  });

  const methods = useForm<ReissueRequest>({
    resolver: yupResolver(reissueSchema),
    defaultValues: reissueRequestDefault,
  });
  const { handleSubmit, register, setFocus, reset } = methods;

  const reissueFailHandler = () => {
    setFocus('bankCode');
    setDisabledBankCode(false);
    reset(reissueRequestDefault);
  };

  const { mutate: reissueMutate } = useHgbMutationPost<any, ReissueRequest>(
    API_URLS.PASSWORD_REISSUE,
    {
      onError: reissueFailHandler,
      onSuccess: (data) => {
        if (getMessageType(data?.statusCode ?? 10000) === 'error') {
          reissueFailHandler();
          return;
        }
        if (!!data) setReissueSuccess(true);
      },
    },
  );

  const onSubmit: SubmitHandler<ReissueRequest> = (data) => {
    reissueMutate(data);
  };

  useEffect(() => {
    reset({ ...reissueRequestDefault, bankCode: currentBankCode });
  }, [currentBankCode]);
  const bankCode = LocalDataClass.user.bankCode;
  const param = bankCode === '0000' ? '' : '?bankCode=' + bankCode;

  return (
    <AuthenTemplate
      title={isReissueSuccess ? A03.pageTitleSuccess : A03.pageTitle}
    >
      <FormProvider {...methods}>
        <form
          className="tw-flex tw-flex-col tw-gap-24"
          onSubmit={handleSubmit(onSubmit)}
        >
          {isReissueSuccess ? (
            <>
              <div>
                <HgbBulletItem className="tw-font-bold">
                  {A03.label.theNewPasswordIsSent}
                </HgbBulletItem>
                <HgbBulletItem className="tw-font-regular">
                  <span>{A03.label.recommendSplit1}</span>
                  <span className="tw-font-bold">
                    {A03.label.recommendSplit2}
                  </span>
                  {A03.label.recommendSplit3}
                  <span className="tw-font-bold tw-text-error-7">
                    {A03.label.recommendSplit4}
                  </span>
                </HgbBulletItem>
              </div>
              <HgbLink
                to={routs['/signin'].link}
                className="tw-w-full tw-text-center"
              >
                {common.button.backToLogin}
              </HgbLink>
            </>
          ) : (
            <>
              <div>
                {/* <HgbBulletItem className="tw-font-regular">
                  {A03.label.passwordRule}
                </HgbBulletItem> */}
                <HgbBulletItem className="tw-font-regular">
                  {A03.label.thePasswordWillBeSent}
                </HgbBulletItem>
                <HgbBulletItem className="tw-font-regular">
                  {A03.label.contactCompany}
                </HgbBulletItem>
              </div>
              <HgbAntdInput
                {...register('bankCode')}
                disabled={
                  (currentBankCode ?? '')?.trim() !== '' && disabledBankCode
                }
                placeholder={A03.placeholder.bankCode}
                label={common.label.bankCode}
                required
                maxLength={4}
                type="bankCode"
              />
              <HgbAntdInput
                {...register('username')}
                placeholder={A03.placeholder.username}
                label={common.label.loginID}
                required
                type="H"
                maxLength={8}
              />
              <HgbAntdInput
                {...register('email')}
                placeholder={A03.placeholder.email}
                label={A03.label.email}
                required
                type="hgbEmail"
                maxLength={100}
              />
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-8">
                <HgbAntdButton htmlType="submit" type="primary">
                  {common.button.reissue}
                </HgbAntdButton>
                <HgbLink to={`${routs['/signin'].link}${param}`}>
                  <>{common.button.backToLogin}</>
                </HgbLink>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </AuthenTemplate>
  );
};
