import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import * as React from 'react';

export const A06Result: React.FC = () => {
  const {
    text: {
      A06,
    },
  } = React.useContext(LanguageContext)!;

  return (
    <AuthenTemplate title={A06.label.titleResult} logo={false}>
      <p className="tw-text-center">{A06.label.contentResult}</p>
    </AuthenTemplate>
  );
};
