import { Tooltip, TooltipProps } from 'antd';
import BigNumber from 'bignumber.js';
import * as React from 'react';
import { v4 } from 'uuid';

export type HgbCellTooltipElementProps = {
  count?: number;
  item?: any;
} & TooltipProps;
export const HgbCellTooltipElement: React.FC<HgbCellTooltipElementProps> = ({
  children,
  count = 120,
  item,
  ...props
}) => {
  const difRef = React.useRef<HTMLDivElement>(null);
  const [isToolTip, setIsTooltip] = React.useState(false);

  React.useEffect(() => {
    setIsTooltip((difRef.current?.clientWidth ?? 0) > count);
  }, [children]);

  return (
    <>
      <div
        ref={difRef}
        className="tw-absolute tw-left-16 tw-right-16 -tw-z-10 tw-break-all tw-text-inherit tw-opacity-0"
      >
        {children}
      </div>
      {isToolTip ? (
        <Tooltip
          id={v4()}
          placement="topLeft"
          trigger="hover"
          color="#fff"
          title={children}
          overlayInnerStyle={{
            color: '#3F494F',
            width: 'fit-content',
          }}
          autoAdjustOverflow
          {...props}
        >
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-16">
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
              <p>{(item?.[0]?.name || '').slice(0, 15)}</p>
              {typeof item?.[0]?.value === 'string' ? (
                <p>
                  {item?.[0]?.value ?? ''} {item?.[0]?.unit}
                </p>
              ) : (
                <p>
                  ({new BigNumber(item?.[0]?.value ?? '').toFixed()}
                  {item?.[0]?.unit})
                </p>
              )}
            </div>
            <span>...</span>
          </div>
        </Tooltip>
      ) : (
        <div className="tw-text-left tw-text-inherit">{children}</div>
      )}
    </>
  );
};
