export const sizeList = [
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '36px',
  '48px',
  '72px',
];

export const formats = [
  // 'font',
  'size',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'color',
  'align',
  'background',
  'script',
  'indent',
  // 'direction',
];

export const modules = {
  toolbar: [
    // [{ font: ['sans'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [
      {
        size: sizeList,
      },
    ],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: [] }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    // [{ direction: 'rtl' }],
    ['link'],
    [{ color: [] }],
    [{ background: [] }],
  ],
};

export const default_font_size = '16px';
