import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import HgbEditor from '@common/editor/HgbEditor';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useConfirm } from '@hooks/useConfirm';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  CreateMessageRequest,
  IAddMessageModalProps,
  IPreviewMessageModalProps,
  MESSAGE_RANGE_REQUEST,
  MESSAGE_RANGE_RESPONSE,
  MESSAGE_TYPE_REQUEST,
  MESSAGE_TYPE_RESPONSE,
} from 'services/types/D13';
import * as yup from 'yup';
import PreviewMessageModal from './PreviewMessageModal';

const MESSAGE_DEFAULT_VALUE: CreateMessageRequest = {
  range: '',
  type: '',
  title: '',
  description: '',
  status: '',
  displayStartDate: '',
  displayEndDate: '',
};

const AddMessageModal: FC<IAddMessageModalProps> = ({
  onSubmitOk,
  item,
  isDuplicated = false,
  ...props
}) => {
  const {
    text: { common, D13, E0000 },
  } = useContext(LanguageContext)!;

  const [previewModalProps, setPreviewModalProps] =
    useState<IPreviewMessageModalProps>({
      open: false,
      title: '',
      description: '',
    });

  const { ConfirmModal, hgbConfirm } = useConfirm();

  const messageSchema = yup.object({
    range: yup.string().required(E0000(D13.label.delivery)),
    type: yup.string().required(E0000(D13.label.type)),
    title: yup.string().required(E0000(D13.label.title)),
    description: yup.string().when({
      is: () => isEmpty(messageWatch.description),
      then: (schema) => schema.required(E0000(D13.label.description.trim())),
      otherwise: (schema) => {
        let checkEmpty = messageWatch.description.toString();
        if (checkEmpty === '<p><br></p>') {
          return schema.matches(
            /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
            E0000(D13.label.description),
          );
        }

        return schema;
      },
    }),
    displayStartDate: yup.string().when({
      is: () => {
        return isEmpty(messageWatch.displayStartDate);
      },
      then: (schema) => {
        return schema.required(E0000(D13.label.displayStartDate));
      },
      otherwise: (schema) => {
        const start = dayjs(messageWatch.displayStartDate);
        const end = dayjs(messageWatch.displayEndDate);
        if (start.diff(end) >= 0) {
          return schema.matches(
            /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
            D13.message.startDateTimeEarlier,
          );
        }

        return schema;
      },
    }),
    displayEndDate: yup.string().when({
      is: () => {
        return isEmpty(messageWatch.displayEndDate);
      },
      then: (schema) => {
        return schema.required(E0000(D13.label.displayEndDate));
      },
      otherwise: (schema) => {
        const start = dayjs(new Date());
        const end = dayjs(messageWatch.displayEndDate);
        if (start.diff(end) >= 0) {
          return schema.matches(
            /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
            D13.message.endDateTimeEarlier,
          );
        }

        return schema;
      },
    }),
  });

  const methods = useForm<CreateMessageRequest>({
    values: isDuplicated ? item : MESSAGE_DEFAULT_VALUE,
    resolver: yupResolver(messageSchema),
  });

  const { handleSubmit, register, watch, setValue } = methods;
  const messageWatch = watch();

  const defaultStartDate = dayjs(new Date())
    .add(1, 'day')
    .format('YYYY/MM/DD 08:00');

  useEffect(() => {
    if (!isDuplicated) setValue('displayStartDate', defaultStartDate);
  }, []);

  useEffect(() => {
    if (!isDuplicated)
      setValue(
        'displayEndDate',
        dayjs(messageWatch.displayStartDate)
          .add(6, 'day')
          .format('YYYY/MM/DD 23:00'),
      );
  }, [messageWatch.displayStartDate]);

  const { data: messageTypeQuery } = useHgbQuery<
    MESSAGE_TYPE_RESPONSE,
    MESSAGE_TYPE_REQUEST
  >(API_URLS.MESSAGE_TYPE_API_URL, undefined, {
    queryKey: [API_URLS.MESSAGE_TYPE_API_URL],
    onSuccess: () => setValue('type', item?.type ?? ''),
  });
  const { data: messageRangeQuery } = useHgbQuery<
    MESSAGE_RANGE_RESPONSE,
    MESSAGE_RANGE_REQUEST
  >(API_URLS.MESSAGE_RANGE_API_URL, undefined, {
    queryKey: [API_URLS.MESSAGE_RANGE_API_URL],
    onSuccess: () => setValue('range', item?.range ?? ''),
  });

  const { mutate: messageMutate, isLoading } = useHgbMutationPost<
    undefined,
    CreateMessageRequest
  >(API_URLS.MESSAGE_API_URL, { onSuccess: onSubmitOk });

  const messageTypeOptions = useMemo(() => {
    return parseToHgbSelectOptions(messageTypeQuery?.result ?? []);
  }, [messageTypeQuery?.result]);

  const messageRangeOptions = useMemo(() => {
    return parseToHgbSelectOptions(messageRangeQuery?.result ?? []);
  }, [messageRangeQuery?.result]);

  const handleAddMessage = async (...params: any) => {
    const isDraft = params?.[1]?.target?.getAttribute('isdraft');
    if (isDraft === '1')
      messageMutate({
        ...messageWatch,
        status: 'DRAFT',
        description: encodeURIComponent(messageWatch.description),
      });
    else {
      const result = await hgbConfirm();
      if (result) {
        messageMutate({
          ...messageWatch,
          status: 'ACTIVE',
          description: encodeURIComponent(messageWatch.description),
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <HgbAntdModal
        {...props}
        width={800}
        cancelText={common.button.cancel}
        okText={common.button.delivery}
        title={D13.modal.addMessage}
        draftText={common.button.draft}
        disableDraft={isLoading}
        formProps={{
          onSubmit: handleSubmit(handleAddMessage),
        }}
      >
        <div className="tw-grid tw-grid-cols-1 tw-gap-24 pc:tw-grid-cols-2">
          <HgbDatePicker
            format="YYYY/MM/DD HH:mm"
            picker="date"
            showTime
            {...register('displayStartDate')}
            popupClassname="timepicker"
            label={D13.modal.displayStartDate}
          />

          <HgbDatePicker
            format="YYYY/MM/DD HH:mm"
            picker="date"
            showTime
            {...register('displayEndDate')}
            popupClassname="timepicker"
            label={D13.modal.displayEndDate}
          />
          <HgbSelect
            options={messageTypeOptions}
            label={D13.modal.messageType}
            {...register('type')}
          />
          <HgbSelect
            options={messageRangeOptions}
            label={D13.modal.messageRange}
            {...register('range')}
          />
          <HgbAntdInput
            {...register('title')}
            className="tw-col-span-1 pc:tw-col-span-2"
            label={D13.modal.messageTitle}
            type="K"
            maxLength={50}
          />
          <div className="tw-col-span-1 tw-flex tw-flex-col tw-gap-16 pc:tw-col-span-2">
            <div className="tw-flex tw-items-center tw-justify-between">
              <h3 className="tw-font-bold">{D13.modal.messageBody}</h3>
              <HgbAntdButton
                htmlType="button"
                ghost
                type="primary"
                onClick={() => {
                  setPreviewModalProps({
                    open: true,
                    title: messageWatch.title,
                    description: messageWatch.description,
                    type: messageWatch.type?.toString(),
                  });
                }}
              >
                {common.button.preview}
              </HgbAntdButton>
            </div>
            <HgbEditor name="description" />
          </div>
        </div>
      </HgbAntdModal>

      <ConfirmModal
        cancelText={common.button.cancel}
        okText={common.button.delivery}
        title={D13.modal.confirmMessage}
      >
        <p className="tw-pb-12 tw-text-paragraph">{D13.modal.confirmWarning}</p>
        <div className="tw-grid tw-grid-cols-[auto_1fr] tw-gap-8">
          <p>{D13.modal.confirmTitle}</p>
          <p className="tw-font-bold">{messageWatch.title}</p>

          <p>{D13.modal.confirmType}</p>
          <p className="tw-font-bold">
            {messageTypeOptions.find((s) => s.value === messageWatch.type)
              ?.label ?? ''}
          </p>

          <p>{D13.modal.confirmRange}</p>
          <p className="tw-font-bold">
            {messageRangeOptions.find((s) => s.value === messageWatch.range)
              ?.label ?? ''}
          </p>

          <p>{D13.modal.confirmStartDate}</p>
          <p className="tw-font-bold">{messageWatch.displayStartDate}</p>

          <p>{D13.modal.confirmEndDate}</p>
          <p className="tw-font-bold">{messageWatch.displayEndDate}</p>
        </div>
      </ConfirmModal>

      {previewModalProps.open && (
        <PreviewMessageModal
          {...previewModalProps}
          onCancel={() => {
            setPreviewModalProps((prev) => ({ ...prev, open: false }));
          }}
        />
      )}
    </FormProvider>
  );
};

export default AddMessageModal;
