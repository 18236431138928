import { LanguageContext } from '@contexts/LanguageContext';
import { cn } from '@utils/cn';
import { message } from 'antd';
import * as React from 'react';

interface ICopyElementProps {
  value: string;
  label?: string;
}

const CopyElement = (props: ICopyElementProps) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    text: { E0002 },
  } = React.useContext(LanguageContext)!;

  const turnOffCopied = () => {
    setIsCopied(false);
  };

  const copyHandler = () => {
    navigator.clipboard?.writeText(props.value).then((rs) => {
      window.dispatchEvent(new Event('copy'));
      setIsCopied(true);
      messageApi.open({
        type: 'success',
        duration: 2,
        content: E0002(<span className="tw-font-bold">{props.value}</span>),
        className: '!tw-text-end',
      });
    });
  };

  const visibilitychangeHandler = () => {
    if (document.visibilityState == 'visible') {
      document.dispatchEvent(new Event('focus'));
    }
  };

  const detectIsCopied = () => {
    navigator?.clipboard?.readText().then((v: any) => {
      setIsCopied(v === props.value);
    });
  };

  React.useEffect(() => {
    document.addEventListener('visibilitychange', visibilitychangeHandler);
    window.addEventListener('copy', turnOffCopied);
    window.addEventListener('focus', detectIsCopied);
    return () => {
      document.removeEventListener('visibilitychange', visibilitychangeHandler);
      window.removeEventListener('copy', turnOffCopied);
      window.removeEventListener('focus', detectIsCopied);
    };
  }, []);

  return (
    <>
      {contextHolder}
      <div
        className="tw-flex tw-cursor-pointer tw-justify-between tw-gap-4"
        onClick={copyHandler}
      >
        <span>{props.label ?? props.value}</span>
        <button
          className={cn('tw-cursor-pointer tw-text-neutral-2', {
            'tw-text-blue-2': isCopied,
          })}
        >
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
              stroke="currentColor"
              strokeWidth="1.5"
            />
            <path
              d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
              stroke="currentColor"
              strokeWidth="1.5"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default CopyElement;
