import { LanguageContext } from '@contexts/LanguageContext';
import { getFiscalYearFromMonth } from '@utils/date.util';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const useTargetMonths = () => {
  const {
    text: { common },
  } = React.useContext(LanguageContext)!;

  return [
    { key: '04', label: common.month.Apr },
    { key: '05', label: common.month.May },
    { key: '06', label: common.month.Jun },
    { key: '07', label: common.month.Jul },
    { key: '08', label: common.month.Aug },
    { key: '09', label: common.month.Sep },
    { key: '10', label: common.month.Oct },
    { key: '11', label: common.month.Nov },
    { key: '12', label: common.month.Dec },
    { key: '01', label: common.month.Jan },
    { key: '02', label: common.month.Feb },
    { key: '03', label: common.month.Mar },
  ];
};

export const useEnabledMonths = (startMonth?: string, endMonth?: string) => {
  const [enabledMonths, setEnabledMonths] = useState<string[]>([]);
  const months = useTargetMonths();

  useEffect(() => {
    let rs: string[] = [];
    if (!startMonth || !endMonth) return;
    const fiscalYear = getFiscalYearFromMonth(startMonth);
    months.forEach((item) => {
      let currentTime = moment();
      currentTime = moment(`${fiscalYear}${item.key}`, 'YYYYMM');
      if (['01', '02', '03'].includes(item.key)) {
        currentTime = moment(`${fiscalYear + 1}${item.key}`, 'YYYYMM');
      }

      const startTime = moment(startMonth, 'YYYYMM');
      const endTime = moment(endMonth, 'YYYYMM');

      if (
        currentTime.isSameOrBefore(endTime) &&
        currentTime.isSameOrAfter(startTime)
      ) {
        rs.push(item.key);
      }
      setEnabledMonths(rs);
    });
  }, [startMonth, endMonth, JSON.stringify(months)]);
  return enabledMonths;
};
