import exp from 'constants';

export interface Emission {
  id: number;
  baseId: string;
  baseName: string;
  year: string;
  scopeCode: string;
  scopeName: string;
  categoryCode: string;
  categoryName: string;
  target: number;
}

export type GetEmissionResponse = {
  totalPage: number;
  currentPage: number;
  pageSize: number;
  totalRecord: number;
  content: Emission[];
};

export type GetEmissionRequest = {
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortName?: string;
  year?: string;
  enterpriseId?: string;
  bankId?: string;
};

export type AddSupplierEmissionInputPayload = {
  enterpriseId?: string | null;
  supplierId?: string | null;
  responseType?: string | null;
  itemId?: string | null;
  baseId?: string | null;
  targetStartMonth?: string;
  targetEndMonth?: string;
  quantity?: string | null;
  emissionIntensity?: string;
  baseVolume?: string;
  allocation?: string | null;
  emissionVolume?: string;
  responseSummary?: string;
  hierarchicalPath?: string;
  sentTime?: string;
  supplierResponseIdCheck: number | null;
};

export interface ModifySupplierEmissionInputPayload
  extends AddSupplierEmissionInputPayload {
  id?: number;
}
export type GetSupplierEmissionInputRequest = {
  enterpriseId?: string | null;
  supplierId?: string | null;
  responseType?: string | null;
  itemId?: string | null;
  baseId?: string | null;
  startDate?: string;
  endDate?: string;
  hierarchicalPath?: string;
  targetStartMonth?: string;
  targetEndMonth?: string;
};

export type EditSupplierEmissionInputRequest = {
  id?: number;
};

export const defaultAddSupplierEmissionInputPayload: AddSupplierEmissionInputPayload =
  {
    supplierId: '',
    enterpriseId: '',
    responseType: '',
    targetStartMonth: '',
    targetEndMonth: '',
    itemId: '',
    baseId: '',
    quantity: '',
    emissionIntensity: '',
    baseVolume: '',
    allocation: '',
    emissionVolume: '',
    responseSummary: '',
    hierarchicalPath: '',
    supplierResponseIdCheck: null,
  };

export type UpdateEmissionRequest = Partial<Emission>;
export type UpdateEmissionResponse = null;

export type CreateEmissionRequest = UpdateEmissionRequest;
export type CreateEmissionResponse = UpdateEmissionResponse;

export type DeleteEmissionRequest = string;
export type DeleteEmissionResponse = null;
