import { HgbAntdModal, HgbAntdModalProps } from '@common/antd/HgbAntdModal';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import * as React from 'react';
import { useHgbMutationDelete } from 'services/common/mutation';
import { DeleteBaseRequest, DeleteBaseResponse } from 'services/types/base';

export interface OrganizationDeleteModalProps extends HgbAntdModalProps {
  onSubmitOk?: () => void;
  id: number;
  name: string;
}

export const OrganizationDeleteModal: React.FunctionComponent<
  OrganizationDeleteModalProps
> = ({ id, name, onSubmitOk, ...props }) => {
  const {
    text: { D02, common },
  } = React.useContext(LanguageContext)!;

  const { mutate: deleteMutate } = useHgbMutationDelete<
    DeleteBaseResponse,
    DeleteBaseRequest
  >(API_URLS.BASE_ORGANIZATION_API_URL, {
    onSuccess: (data) => {
      !!data && onSubmitOk?.();
    },
  });

  return (
    <HgbAntdModal
      cancelText={common.button.cancel}
      okText={common.button.delete}
      formProps={{
        onSubmit: () => deleteMutate(id),
      }}
      title={D02.modal.organizationDeleteTitle}
      {...props}
    >
      <p className="tw-text-center">
        {D02.message.deleteBaseAlertSplit1}
        {name}
        {D02.message.deleteBaseAlertSplit2}
      </p>
    </HgbAntdModal>
  );
};
