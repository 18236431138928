export type Reference = {
  label: string;
  value: string;
  more: string;
};
export type RegisterRequest = {
  bankArea: string;
  bankName: string;
  bankCode: string;
  email: string;
  emailConfirm: string;
  references: Reference[];
  fromBankDescription?: string;
  fromOthersDescription?: string;
  inviteCode: string;
};

export type StepType = {
  bankName: string;
  step: number;
};

export const REGISTER_DEFAULT_VALUE: RegisterRequest = {
  bankArea: '',
  bankName: '',
  email: '',
  emailConfirm: '',
  bankCode: '',
  references: [],
  fromBankDescription: '',
  fromOthersDescription: '',
  inviteCode: '',
};

type OptionType = {
  value: string;
  name: string;
};

export type BankNameListBoxRequest = {
  bankArea: string;
};

export type BankNameListBoxResponse = OptionType[];

export type HigoBankLandingPageResponse = {
  bankCode: string;
  bankName: string;
};

export type HigoBankLandingPageRequest = unknown;
