import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbAntdInput } from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { debug } from '@utils/debug';
import { bigNumberToString } from '@utils/text';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPut } from 'services/common/mutation';
import * as yup from 'yup';
import { SupplierProduct } from './D19';

interface IEditSupplierProductModalProps {
  modalState: BooleanState;
  data: SupplierProduct;
}

const EditSupplierProductModal: React.FunctionComponent<
  IEditSupplierProductModalProps
> = ({ modalState, data }) => {
  const {
    text: { common, D19, E0000, E0104 },
  } = React.useContext(LanguageContext)!;
  const queryClient = useQueryClient();

  const editSupplierProductSchema = yup.object({
    serialNumber: yup.string().required(E0000(D19.serialNumber)),
    productName: yup.string().required(E0000(D19.productName)),
    unit: yup.string().required(E0000(D19.modal.unit)),
    emissionIntensity: yup.string().required(E0000(D19.emissionsPerUnit)),
    gtinCode: yup
      .string()
      .test('is-valid-length', E0104(8, 13, 14), (value) => {
        if (!value) return true;
        const length = value.length;
        return length === 8 || length === 13 || length === 14;
      }),
    upcCode: yup.string().test('is-valid-length', E0104(8, 12), (value) => {
      if (!value) return true;
      const length = value.length;
      return length === 8 || length === 12;
    }),
  });

  const editSupplierProductMethods = useForm<SupplierProduct>({
    resolver: yupResolver(editSupplierProductSchema),
    values: {
      ...data,
      emissionIntensity: bigNumberToString(data.emissionIntensity),
    },
  });

  const editSupplierProductMutate = useHgbMutationPut<unknown, SupplierProduct>(
    API_URLS.SUPPLIER_PRODUCT,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
        });
        modalState.turnOff();
      },
      onError: () => {},
    },
  );

  const handleSubmit = editSupplierProductMethods.handleSubmit(
    (data, e) => {
      editSupplierProductMutate.mutate({ ...data });
    },
    (error) => {
      debug.error(error);
    },
  );

  return (
    <>
      <FormProvider {...editSupplierProductMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={
            <HgbAntdModalTitle>
              {D19.modal.supplierProductsEdit}
            </HgbAntdModalTitle>
          }
          open={modalState.value}
          onCancel={modalState.turnOff}
          footer={null}
          afterClose={() => editSupplierProductMethods.reset(data)}
          closable={true}
          maskClosable={false}
          destroyOnClose
        >
          <HgbAntdModalForm onSubmit={handleSubmit}>
            <HgbAntdModalFormBody>
              <HgbAntdInput
                {...editSupplierProductMethods.register('serialNumber')}
                maxLength={50}
                type={'K'}
                label={D19.serialNumber}
                required
              />
              <HgbAntdInput
                {...editSupplierProductMethods.register('productName')}
                maxLength={100}
                type={'K'}
                label={D19.productName}
                required
              />
              <HgbAntdInput
                {...editSupplierProductMethods.register('unit')}
                maxLength={15}
                type={'K'}
                label={D19.modal.unit}
                required
              />
              <HgbAntdInput
                {...editSupplierProductMethods.register('emissionIntensity')}
                label={D19.emissionsPerUnit}
                maxLength={10}
                type="positiveDecimal"
                required
                suffix="tCO2"
              />
              <HgbAntdInput
                {...editSupplierProductMethods.register('gtinCode')}
                label={D19.gtinCode}
                maxLength={14}
                type="positiveInteger"
              />
              <HgbAntdInput
                {...editSupplierProductMethods.register('upcCode')}
                label={D19.upcCode}
                maxLength={12}
                type="positiveInteger"
              />
            </HgbAntdModalFormBody>
            <HgbAntdModalFormFooter>
              <HgbAntdButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
              >
                {common.button.cancel}
              </HgbAntdButton>
              <HgbAntdButton
                type="primary"
                className="tw-min-w-100"
                htmlType="submit"
              >
                {common.button.change}
              </HgbAntdButton>
            </HgbAntdModalFormFooter>
          </HgbAntdModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default EditSupplierProductModal;
