import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import HgbInfoTooltip from '@common/contents/HgbInfoTooltip';
import { LanguageContext } from '@contexts/LanguageContext';
import { cn } from '@utils/cn';
import { joinSafe, parseFullWidthToHalfWidth } from '@utils/text';
import { Select, SelectProps } from 'antd';
import { RenderFunction, TooltipPlacement } from 'antd/lib/tooltip';
import { forwardRef, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';

export type HgbSelectOption = {
  label: string;
  value: string;
  desc?: string;
};

export const parseToHgbSelectOptions = (
  os?:
    | ({ name: string; value: string | number; note?: string } & Record<
        any,
        any
      >)[]
    | undefined
    | null,
): HgbSelectOption[] => {
  if (os === null || os === undefined) return [];
  return os.map((item) => ({
    label: item?.name,
    value: item?.value !== null ? item?.value?.toString() : '0',
  }));
};

export const parseScopeToHgbSelectOptions = (
  os?:
    | ({ name: string; value: string | number; note?: string } & Record<
        any,
        any
      >)[]
    | undefined
    | null,
): HgbSelectOption[] => {
  if (os === null || os === undefined) return [];
  return os.map((item) => ({
    label: joinSafe([item.name, item.note], ' : '),
    value: item?.value !== null ? item?.value?.toString() : '0',
  }));
};

export const parseScopeCategoryToHgbSelectOptions = (
  os?:
    | ({ name: string; value: string | number; note?: string } & Record<
        any,
        any
      >)[]
    | undefined
    | null,
): HgbSelectOption[] => {
  if (os === null || os === undefined) return [];
  return os.map((item) => ({
    label: item.name,
    value: item?.value !== null ? item?.value?.toString() : '0',
    desc: joinSafe([item.name, item.note], ' : '),
  }));
};

interface HgbSelectProps extends SelectProps {
  name: string;
  label?: string;
  className?: string;
  directionType?: 'col' | 'row';
  required?: boolean;
  onChangeValue?: (name: string, value: string) => void;
  hidden?: boolean;
  info?: string;
  modalStatus?: string;
  screenType?: string;
  readOnly?: boolean;
  autoInitValue?: boolean;
  infoTooltip?: React.ReactNode | RenderFunction;
  palcement?: TooltipPlacement;
}

export const HgbSelect = forwardRef<any, HgbSelectProps>(
  (
    {
      label,
      className = '',
      defaultActiveFirstOption,
      directionType = 'row',
      status,
      required,
      onChangeValue,
      hidden,
      modalStatus,
      info,
      screenType,
      disabled,
      autoInitValue,
      options = [],
      infoTooltip,
      palcement,
      ...props
    },
    ref,
  ) => {
    const selectId = v4();
    const {
      setValue,
      watch,
      formState: { errors },
      clearErrors,
    } = useFormContext();
    const { name } = props;
    const error = errors[name];
    const value = watch(name) ?? '';
    const {
      text: {
        common: { message },
      },
    } = useContext(LanguageContext)!;

    useEffect(() => {
      if (defaultActiveFirstOption && options.length > 0 && !value) {
        setValue(name, options?.[0]?.value?.toString());
      }
    }, [options, defaultActiveFirstOption, value]);

    useEffect(() => {
      if (autoInitValue && options.length > 0) {
        setValue(name, options[0].value?.toString());
      }
    }, [options, autoInitValue]);

    return (
      <div
        className={cn(
          'tw-flex tw-flex-col tw-justify-between',
          {
            'tw-flex tw-flex-col tw-justify-end tw-gap-4':
              directionType === 'row',
            'tw-flex tw-items-center tw-gap-8': directionType === 'col',
            'tw-hidden': hidden,
          },
          className,
        )}
      >
        <div>
          {label && (
            <label
              htmlFor={selectId}
              className={cn(
                'tw-mb-4 tw-inline-block tw-select-none tw-text-paragraph tw-font-bold tw-leading-[1]',
                { 'tw-pointer-events-none': props.readOnly || disabled },
              )}
            >
              {label}

              {required && (
                <span className="tw-ml-4 tw-text-paragraph tw-font-bold tw-text-error-7">
                  *
                </span>
              )}
              {modalStatus !== 'VIEW' && !!info ? (
                <HgbInfoLink to={info} target="_blank" />
              ) : null}

              {infoTooltip && (
                <HgbInfoTooltip title={infoTooltip} placement={palcement} />
              )}
            </label>
          )}
          <div
            className={cn('tw-min-w-[120px] tw-cursor-pointer', {
              'tw-cursor-not-allowed': disabled,
            })}
          >
            <Select
              onClick={(e) => {
                if (disabled) return;
                e.preventDefault();
                clearErrors([name]);
              }}
              notFoundContent={<HgbAntdEmpty description={message.noData} />}
              status={error ? 'error' : status}
              id={selectId}
              ref={ref}
              className={cn('tw-w-full', {
                'tw-pointer-events-none': disabled,
              })}
              size="large"
              filterOption={(input, option) => {
                return parseFullWidthToHalfWidth(
                  (option?.label ?? '').toString().toLocaleLowerCase(),
                ).includes(
                  parseFullWidthToHalfWidth(input?.trim().toLocaleLowerCase()),
                );
              }}
              {...props}
              value={value === '' || !options.length ? undefined : value}
              onChange={(value) => {
                if (props.readOnly) return;
                setValue(name, value);
                onChangeValue?.(name, value);
              }}
              optionLabelProp="label"
            >
              {options?.map((item) => {
                return (
                  <Select.Option
                    value={item.value}
                    label={item.label}
                    key={item.value}
                  >
                    <div
                      className="demo-option-label-item"
                      title={item.desc ?? item.label}
                    >
                      {item.desc ?? item.label}
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          {error && <HgbFieldError>{error.message?.toString()}</HgbFieldError>}
        </div>
      </div>
    );
  },
);
