import { Size, SvgProps } from '@common/types';
import { FC, ReactNode } from 'react';

export const HgbErrorModalOutlinedIcon: FC<SvgProps> = ({
  className = '',
  size = '24',
  ...props
}) => {
  const Icons: Record<Size, ReactNode> = {
    '16': (
      <svg
        width={56}
        height={56}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6665 34.9993H30.3332V39.666H25.6665V34.9993ZM25.6665 16.3327H30.3332V30.3327H25.6665V16.3327ZM27.9765 4.66602C15.0965 4.66602 4.6665 15.1193 4.6665 27.9993C4.6665 40.8793 15.0965 51.3327 27.9765 51.3327C40.8798 51.3327 51.3332 40.8793 51.3332 27.9993C51.3332 15.1193 40.8798 4.66602 27.9765 4.66602ZM27.9998 46.666C17.6865 46.666 9.33317 38.3127 9.33317 27.9993C9.33317 17.686 17.6865 9.33268 27.9998 9.33268C38.3132 9.33268 46.6665 17.686 46.6665 27.9993C46.6665 38.3127 38.3132 46.666 27.9998 46.666Z"
          fill="#FF302D"
        />
      </svg>
    ),
    '20': (
      <svg
        width={56}
        height={56}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6665 34.9993H30.3332V39.666H25.6665V34.9993ZM25.6665 16.3327H30.3332V30.3327H25.6665V16.3327ZM27.9765 4.66602C15.0965 4.66602 4.6665 15.1193 4.6665 27.9993C4.6665 40.8793 15.0965 51.3327 27.9765 51.3327C40.8798 51.3327 51.3332 40.8793 51.3332 27.9993C51.3332 15.1193 40.8798 4.66602 27.9765 4.66602ZM27.9998 46.666C17.6865 46.666 9.33317 38.3127 9.33317 27.9993C9.33317 17.686 17.6865 9.33268 27.9998 9.33268C38.3132 9.33268 46.6665 17.686 46.6665 27.9993C46.6665 38.3127 38.3132 46.666 27.9998 46.666Z"
          fill="#FF302D"
        />
      </svg>
    ),
    '24': (
      <svg
        width={56}
        height={56}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6665 34.9993H30.3332V39.666H25.6665V34.9993ZM25.6665 16.3327H30.3332V30.3327H25.6665V16.3327ZM27.9765 4.66602C15.0965 4.66602 4.6665 15.1193 4.6665 27.9993C4.6665 40.8793 15.0965 51.3327 27.9765 51.3327C40.8798 51.3327 51.3332 40.8793 51.3332 27.9993C51.3332 15.1193 40.8798 4.66602 27.9765 4.66602ZM27.9998 46.666C17.6865 46.666 9.33317 38.3127 9.33317 27.9993C9.33317 17.686 17.6865 9.33268 27.9998 9.33268C38.3132 9.33268 46.6665 17.686 46.6665 27.9993C46.6665 38.3127 38.3132 46.666 27.9998 46.666Z"
          fill="#FF302D"
        />
      </svg>
    ),
  };
  return <>{Icons[size]}</>;
};
