import { Role, USER_LOCAL_STORAGE_NAME } from '@constants/consts';
import { LocalStorageDataClass } from './LocalStorageDataClass';

export type AuthenStatus =
  | 'LOGIN'
  | 'SUCCESS'
  | 'MUST_CHANGE_PASSWORD'
  | 'SHOULD_CHANGE_PASSWORD'
  | 'FIRST_GENERATE_PASSWORD'
  | 'SESSION_TIMEOUT'
  | 'LOGOUT';

export type UserLocal = {
  profileToken: string;
  refreshToken: string;
  accountId: string;
  email: string;
  username: string;
  nameKanji: string;
  nameKana: string;
  isPresidingBank: boolean;
  isManagementBank: boolean;
  isEnterpriseAdmin: boolean;
  isEnterpriseUser: boolean;
  bankId: number;
  enterpriseId: number;
  baseId: number;
  role: Role;
  departmentName: string;
  authenStatus: AuthenStatus;
  bankName?: string;
  bankCode?: string;
  scopeCode: string | null;
  isFirstGeneratePassword: boolean;
  isMaintenance: boolean;
  subscriptionType: string;
  billingStartDefault: string | null;
  isBuyer: boolean;
};

export const userLocalDefault: UserLocal = {
  profileToken: '',
  refreshToken: '',
  accountId: '',
  email: '',
  username: '',
  nameKanji: '',
  nameKana: '',
  isPresidingBank: false,
  isManagementBank: false,
  isEnterpriseAdmin: false,
  isEnterpriseUser: false,
  bankId: 0,
  enterpriseId: 0,
  baseId: 0,
  role: 'ROLE_ENTERPRISE_USER',
  departmentName: '',
  authenStatus: 'LOGIN',
  scopeCode: null,
  isFirstGeneratePassword: false,
  isMaintenance: false,
  subscriptionType: '',
  billingStartDefault: null,
  isBuyer: false,
};

const csrfTokenDefault =
  'U2FsdGVkX18FSM05+wq9YIv3xXQsTe/O7tDosPm4mT16LqeBGbd7Rg0u789N79Nxqix/TvDS9dsEBN4p2rK8rcTyYnMLGkPdJ+xZkh/+yTlquheZriUjUc4uEvXMf2YXYc2AXLmII++UjSeIX1d6kPiY+ozZdFo3wMXQ/OJEy3k=';

export type Language = 'jp' | 'en';
export class LocalDataClass {
  static get user() {
    return LocalStorageDataClass.getStorage(
      USER_LOCAL_STORAGE_NAME,
      userLocalDefault,
    ) as UserLocal;
  }
  static set user(u: UserLocal) {
    LocalStorageDataClass.setItem(USER_LOCAL_STORAGE_NAME, u);
  }

  static get language(): Language {
    return LocalStorageDataClass.getStorage('LANG', 'jp') as Language;
  }

  static set language(lang: Language) {
    LocalStorageDataClass.setItem('LANG', lang);
  }

  static get 'X-XSRF-TOKEN'() {
    return LocalStorageDataClass.getStorage('X-XSRF-TOKEN', 'X-XSRF-TOKEN');
  }

  static set 'X-XSRF-TOKEN'(value: string | undefined) {
    LocalStorageDataClass.setItem('X-XSRF-TOKEN', value || csrfTokenDefault);
  }
}
