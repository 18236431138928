import { ButtonProps } from '@common/types';
import { cn } from '@utils/cn';
import { FC } from 'react';

export const SquareButton: FC<ButtonProps> = ({ className = '', ...props }) => (
  <button
    className={cn(
      `tw-flex tw-h-24 tw-w-24 tw-cursor-pointer tw-items-center tw-justify-center ${className}`,
    )}
    {...props}
  />
);
