import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { usePagin } from '@hooks/usePagin';
import { emptyToUndefined } from '@utils/text';
import { Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHgbQuery } from 'services/common/query';
import {
  EnterprisePendingResponse,
  EnterprisePendingType,
  GetEnterpriseRequest,
} from 'services/types/enterprise';

interface DataType {
  key: React.Key;
  status?: string;
  id: string;
  email: string;
  expireDate: string;
}

const usePendingEnterpriseTable = (
  enterpriseParams: GetEnterpriseRequest,
  enterpriseNameDebounce: string = '',
  tabActive: string,
  errorMessage?: string,
) => {
  const {
    text: { D01, common },
    language,
  } = useContext(LanguageContext)!;
  const { paginationProps, resetPaginations, PaginationComponent } = usePagin(
    10,
    true,
  );

  const { user } = useContext(AuthContext)!;

  const [enterpriseEmpty, setEnterpriseEmpty] = useState(false);

  const pendingColumns: ColumnsType<DataType> = [
    {
      title: D01.table.id,
      dataIndex: 'id',
      width: 80,
    },
    {
      title: D01.table.email,
      dataIndex: 'email',
      width: 300,
    },
    {
      title: D01.table.url,
      dataIndex: 'expireDate',
      width: 300,
    },
    {
      title: D01.table.status,
      dataIndex: 'status',
      width: 300,
    },
  ];

  const {
    data: enterprisesQuery,
    refetch: enterprisesRefetch,
    isFetching: enterprisesFetching,
  } = useHgbQuery<EnterprisePendingResponse, GetEnterpriseRequest>(
    API_URLS.ENTERPRISE_PENDING_API_URL,
    {
      ...enterpriseParams,
      bankId: emptyToUndefined(enterpriseParams.bankId),
      status: '',
      pageNo: undefined,
      pageSize: undefined,
    },
    {
      enabled: false,
      onSuccess(data) {
        if (!data?.result?.length) {
          setEnterpriseEmpty(true);
        } else {
          setEnterpriseEmpty(false);
        }
      },
      queryKey: [API_URLS.ENTERPRISE_PENDING_API_URL, enterpriseParams.bankId],
    },
  );

  const enterpirses: EnterprisePendingResponse = useMemo(() => {
    const enterpriseData: EnterprisePendingResponse = [];
    (enterprisesQuery?.result ?? []).map((ent) => {
      const ents = {
        ...ent,
        expireDate: dayjs(ent.expireDate)
          .locale(language === 'en' ? 'en' : 'ja')
          .format('MM/DD(ddd) HH:mm'),
      };

      for (let key in ents) {
        if (
          ents[key as keyof EnterprisePendingType]
            .toString()
            .includes(enterpriseNameDebounce)
        ) {
          enterpriseData.push(ents);
          break;
        }
      }
      return ents;
    });
    return enterpriseData;
  }, [enterprisesQuery?.result, enterprisesFetching]);

  const pendingRows = useMemo(() => {
    const enterpirsesPaging =
      enterpirses.slice(
        (paginationProps.pageNo - 1) * paginationProps.pageSize,
        paginationProps.pageNo * paginationProps.pageSize,
      ) ?? [];
    return enterpirsesPaging.map((item, index) => {
      return {
        key: index,
        id: item.id,
        email: item.email,
        expireDate: item.expireDate,
        status: item.status,
      };
    });
  }, [
    enterpirses,
    user.isPresidingBank,
    paginationProps.pageNo,
    paginationProps.pageSize,
  ]);

  useEffect(() => {
    if (
      (user.isManagementBank || !isEmpty(enterpriseParams.bankId)) &&
      tabActive === '2'
    ) {
      enterprisesRefetch();
    }
  }, [enterpriseParams.bankId, enterpriseNameDebounce, tabActive]);

  useEffect(() => {
    resetPaginations();
  }, [enterpriseNameDebounce]);

  const EnterpriseTableComponent = useCallback(() => {
    return enterprisesFetching ? (
      <Spin className="tw-absolute tw-left-1/2 tw-top-1/2 tw-z-50 -tw-translate-x-1/2 -tw-translate-y-1/2" />
    ) : (
      <>
        {enterpriseEmpty && (
          <HgbAntdEmpty description={common.message.noResult} />
        )}
        {!isEmpty(pendingRows) && (
          <Table
            columns={pendingColumns}
            dataSource={pendingRows}
            pagination={false}
            className="tw-overflow-hidden"
            scroll={{
              x: pendingColumns.reduce(
                (prev, current) => prev + Number(current.width ?? 0),
                0,
              ),
            }}
          />
        )}
        {errorMessage && <HgbAntdEmpty description={errorMessage} />}
      </>
    );
  }, [pendingRows]);

  const PendingPaginationComponent = useCallback(() => {
    return enterpirses.length > 0 ? (
      <PaginationComponent
        current={paginationProps.pageNo}
        pageSize={paginationProps.pageSize}
        total={enterpirses.length}
      />
    ) : null;
  }, [
    paginationProps.pageNo,
    language,
    user,
    paginationProps.pageSize,
    enterpirses,
  ]);

  return { EnterpriseTableComponent, PendingPaginationComponent };
};

export default usePendingEnterpriseTable;
