import { MY_SECRET_KEY, USER_LOCAL_STORAGE_NAME } from '@constants/consts';
import { debug } from '@utils/debug';
import CryptoJS from 'crypto-js';

function capitalizeFirstLetter(str: string) {
  if (!str.length) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export type LocalStorageName =
  | 'LANG'
  | 'X-XSRF-TOKEN'
  | typeof USER_LOCAL_STORAGE_NAME;

export class LocalStorageDataClass {
  static getStorage<T>(name: LocalStorageName, defaultValue: T) {
    try {
      const data = window.localStorage.getItem(name);
      if (!data) return defaultValue;
      const jsonData = JSON.parse(
        CryptoJS.AES.decrypt(data, MY_SECRET_KEY).toString(CryptoJS.enc.Utf8) ??
          {},
      ) as T;
      return jsonData;
    } catch (error) {
      debug.log('Have problem with get local storage ' + name);
      return defaultValue;
    }
  }

  static setItem(name: LocalStorageName, value: any, silent = false) {
    try {
      localStorage.setItem(
        name,
        CryptoJS.AES.encrypt(JSON.stringify(value), MY_SECRET_KEY).toString(),
      );
      const e = new CustomEvent(LocalStorageDataClass.changeEventName(name), {
        bubbles: true,
        detail: { value },
      });
      if (silent) return;
      setTimeout(() => {
        window.dispatchEvent(e);
      }, 0);
    } catch (err) {
      const error = new Error(`setLocalStorage as ${name} is failed!`);
      throw error;
    }
  }

  static removeItem(name: LocalStorageName, silent = false) {
    try {
      const e = new CustomEvent(LocalStorageDataClass.changeEventName(name), {
        bubbles: true,
        detail: { value: null },
      });
      if (silent) return;
      setTimeout(() => {
        window.dispatchEvent(e);
      }, 0);
    } catch (_) {
      const error = new Error(`Remove LocalStorage as ${name} failed!`);
      throw error;
    }
  }

  static changeEventName(name: LocalStorageName) {
    return 'onChange' + capitalizeFirstLetter(name);
  }
}
