import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdSpin } from '@common/antd/HgbAntdSpin';
import { HgbMaintainIcon } from '@common/icons/filled';
import { HgbNavbar } from '@common/navigations';
import { AnchorProps } from '@common/types';
import { API_URLS } from '@constants/API_URLS';
import { COLORS } from '@constants/colors';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { useDashboardFormData } from '@hooks/useDashboardFormData';
import { notification } from 'antd';
import parse from 'html-react-parser';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import { General } from './General';
import { MonthlyChart } from './MonthlyChart';
import { PredictChart } from './PredictChart';
import { ScopesChart } from './ScopesChart';
import { HomeMessage } from './types';


export const B02: React.FunctionComponent = () => {
  const [api, contextHolder] = notification.useNotification();

  const {
    text: { B01, I0005 },
  } = React.useContext(LanguageContext)!;
  const { user } = React.useContext(AuthContext)!;
  const {
    BankIdsSelectbox,
    EnterprisesSelectbox,
    SupplyChainSelectbox,
    TargetYearSelectbox,
    BaseSelectbox,
    MarketLocationRadioGroup,
    ...dashboardFormData
  } = useDashboardFormData();

  const title = user.isPresidingBank ? B01.label.higoBank : user.departmentName;
  const result = dashboardFormData.dashboardQuery.data?.result;

  const generalRef = React.useRef<HTMLDivElement>(null);
  const monthlyRef = React.useRef<HTMLDivElement>(null);
  const predictRef = React.useRef<HTMLDivElement>(null);
  const scopesRef = React.useRef<HTMLDivElement>(null);

  const ChartsComponent = React.useCallback(() => {
    if (result) {
      return (
        <div className="tw-flex tw-flex-col tw-gap-24">
          <div ref={generalRef}>
            <General
              total={result.total}
              total3Scopes={result.total3Scopes}
              totalOffset={result.totalOffset}
              target={result.target}
              compareToLastYear={result.compareToLastYear}
              companySupplier={dashboardFormData.dashboardMethods.watch(
                'supplierId',
              )}
            />
          </div>
          <div ref={monthlyRef}>
            <MonthlyChart
              dashboardMethods={dashboardFormData.dashboardMethods}
            />
          </div>
          <div ref={predictRef}>
            <PredictChart data={result.predictChartDetails} />
          </div>
          <div ref={scopesRef}>
            <ScopesChart
              categoryStatisticsDetails={result.categoryStatisticsDetails}
              pieStatisticsChartDetailsForScope1={
                result.pieStatisticsChartDetailsForScope1
              }
              fuelDashboardDisplayInfos={result.fuelDashboardDisplayInfos}
              companySupplier={dashboardFormData.dashboardMethods.watch(
                'supplierId',
              )}
            />
          </div>
        </div>
      );
    }
    if (dashboardFormData.isLoading) {
      return <HgbAntdSpin className="tw-flex-auto tw-rounded-16 tw-bg-white" />;
    }
    return (
      <HgbAntdEmpty
        description={dashboardFormData.emptyText || I0005}
        className="tw-flex-auto"
      />
    );
  }, [
    JSON.stringify(result),
    dashboardFormData.emptyText,
    I0005,
    dashboardFormData.isLoading,
  ]);

  const isBankRole =
    (user.isManagementBank || user.isPresidingBank) &&
    dashboardFormData.dashboardMethods.watch('enterpriseId') === '0';

  const scrollToElement = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  const insertMessageMutation = useHgbMutationPost<
    undefined,
    { messageId: string }
  >(API_URLS.MESSAGE_INSERT_API_URL, {
    isAlert: false,
  });

  useHgbQuery<HomeMessage[]>(API_URLS.HOME_MESSAGE, undefined, {
    onSuccess(data) {
      data?.result?.forEach((item) => {
        item.type == '1'
          ? api.warning({
              message: (
                <DropdownMessage
                  title={item.title}
                  message={item.description}
                />
              ),
              placement: 'topRight',
              duration: 100000,
              onClose: () => {
                insertMessageMutation.mutate({ messageId: item.id });
              },
              icon: <HgbMaintainIcon size="24" />,
            })
          : api.info({
              message: (
                <DropdownMessage
                  title={item.title}
                  message={item.description}
                />
              ),
              placement: 'topRight',
              duration: 100000,
              onClose: () => {
                insertMessageMutation.mutate({ messageId: item.id });
              },
            });
      });
    },
  });
  return (
    <div
      className="tw-flex tw-min-h-screen tw-flex-col"
      style={{ backgroundColor: COLORS.mainBackground }}
    >
      {contextHolder}
      <HgbNavbar />
      <section className="tw-flex tw-flex-auto tw-flex-col tw-gap-24 tw-py-24">
        <h1 className="tw-font-bold">{title}</h1>
        <div className="tw-flex tw-flex-wrap tw-items-end tw-justify-between tw-gap-24">
          <FormProvider {...dashboardFormData.dashboardMethods}>
            <div className="tw-flex tw-flex-wrap tw-items-end tw-gap-24">
              <BankIdsSelectbox className="tw-min-w-160" />
              <EnterprisesSelectbox className="tw-min-w-160" />
              <SupplyChainSelectbox className="tw-min-w-160" />
              <TargetYearSelectbox className="tw-min-w-160" />
              <BaseSelectbox className="tw-min-w-160" />
              <MarketLocationRadioGroup className="tw-w-auto tw-gap-0" />
            </div>
          </FormProvider>
          <div className="tw-flex tw-w-fit tw-flex-wrap tw-gap-16">
            <Anchor onClick={() => scrollToElement(generalRef)}>
              {B01.label.generalAnchor}
            </Anchor>
            {isBankRole ? null : (
              <>
                <Anchor onClick={() => scrollToElement(monthlyRef)}>
                  {B01.label.monthlyEmissionAnchor}
                </Anchor>
                <Anchor onClick={() => scrollToElement(predictRef)}>
                  {B01.label.thisYearsSimulation}
                </Anchor>
              </>
            )}
            <Anchor onClick={() => scrollToElement(scopesRef)}>
              {B01.label.breakdownEmissionAnchor}
            </Anchor>
          </div>
        </div>
        <ChartsComponent />
      </section>
    </div>
  );
};

const Anchor: React.FC<AnchorProps> = ({ className = '', ...props }) => {
  return (
    <a
      {...props}
      className={
        'tw-cursor-pointer tw-font-bold tw-underline tw-underline-offset-[6px] ' +
        className
      }
    />
  );
};

interface IDropdownMessageProps {
  title: string;
  message?: string;
  isShow?: boolean;
  className?: string;
}

export const DropdownMessage: React.FunctionComponent<
  IDropdownMessageProps
> = ({ title, message, isShow = false, className }) => {
  const [show, setShow] = React.useState(isShow);

  return (
    <div className={`tw-flex tw-flex-col tw-gap-8 ${className}`}>
      <p
        className="tw-cursor-pointer tw-text-h3 tw-font-bold"
        onClick={() => setShow((prev) => !prev)}
      >
        {title}
      </p>

      {show && message && (
        <div className="ql-editor tw-max-h-[40vh] tw-overflow-y-scroll">
          {parse(message!)}
        </div>
      )}
    </div>
  );
};
