import { cn } from '@utils/cn';
import * as React from 'react';

interface ICategoryItemProps {
  title: string;
  subTitle?: string;
  value: string;
  subValue?: string;
  className?: string;
  color: string;
}

export const CategoryItem: React.FunctionComponent<ICategoryItemProps> =
  React.memo((props) => {
    return (
      <div
        className={cn(
          'tw-flex tw-min-w-300 tw-min-h-132 tw-relative tw-shadow-md tw-flex-col tw-gap-12 tw-overflow-hidden tw-rounded-12 tw-p-16 tw-pl-24',
          props.className,
        )}
      >
        <div
          className="tw-absolute tw-bottom-0 tw-left-0 tw-top-0 tw-w-6"
          style={{ backgroundColor: props.color }}
        ></div>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <h3 className="tw-font-bold">{props.title}</h3>
          {props.subTitle ? <p>{props.subTitle}</p> : null}
        </div>
        <div className="tw-gap-2 tw-flex tw-flex-col tw-items-end">
          <p className="tw-font-bold"> {props.value}</p>
          {props.subValue ? <small>{props.subValue}</small> : null}
        </div>
      </div>
    );
  });
