import { PlusOutlined } from '@ant-design/icons';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellLink } from '@common/contents/HgbCellLink';
import { HgbAntdInput, HgbDatePicker, HgbSelect } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import { areaOptions } from '@constants/area';
import { EMAIL_REGEX } from '@constants/regex';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDebounce } from '@hooks/useDebound';
import { usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { locale } from '@utils/emptyMessage.util';
import { toJPFormat } from '@utils/number';
import { Spin } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useHgbMutationDelete,
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import { useHgbQueryWithPaging } from 'services/common/query';
import {
  BANK_FORM_DEFAULT_VALUE,
  Bank,
  CreateBankRequest,
  CreateBankResponse,
  GetBankRequest,
  UpdateBankRequest,
  UpdateBankResponse,
} from 'services/types/bank';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import * as yup from 'yup';

const calculateBillingStartMonth = (trialMonth: number, startMonth: string) => {
  if (!startMonth) {
    return '_';
  }
  return moment(startMonth)
    .add(trialMonth || 0, 'months')
    .format('YYYY/MM');
};

type ModalMode = 'VIEW' | 'UPDATE' | 'ADD';

export const BankList = () => {
  const {
    text: { G01, common, E0000, E0087, E0086 },
  } = useContext(LanguageContext)!;

  const subscriptionOptions = [
    {
      label: G01.label.freeTrial,
      value: 'FREE_TRIAL',
    },
    {
      label: G01.label.complimentary,
      value: 'COMPLIMENTARY',
    },
  ];

  const [modalStatus, setIsModalStatus] = useState<{
    isOpen: boolean;
    mode: ModalMode;
  }>({
    isOpen: false,
    mode: 'ADD',
  });
  const [isDelete, setIsDelete] = useState(false);

  const enterpriseMonthlyFeeMsg = E0086(
    G01.label.enterpriseMonthlyFee,
    '0',
    '99999',
  );
  const expirationPeriodHoursMsg = E0086(
    G01.label.URLExpirationPeriodHours,
    '24',
    '720',
  );

  const enterpriseTrialStartMonthMsg = E0087(
    G01.label.freeTrial,
    G01.label.enterpriseTrialStartMonths,
  );

  const bankSchema = yup.object({
    bankCode: yup.string().required(E0000(common.label.bankCode)),
    bankName: yup.string().required(E0000(G01.label.bankName)),
    personInCharge: yup.string().required(E0000(G01.label.personInCharge)),
    bankArea: yup.string().required(E0000(G01.label.area)),
    bankProvince: yup.string().required(E0000(G01.label.province)),
    personInChargeEmail: yup
      .string()
      .required(E0000(G01.label.personInChargeEmail))
      .matches(EMAIL_REGEX, common.message.emailFormat),
    privacyPolicyUrl: yup.string().required(E0000(G01.label.privacyPolicyUrl)),
    emailSignature: yup.string().required(E0000(G01.label.signature)),
    enterpriseTrialStartMonth: yup
      .string()
      .typeError(enterpriseTrialStartMonthMsg)
      .test(
        '',
        enterpriseTrialStartMonthMsg,
        (value, schema) =>
          !!value || schema.parent.subscriptionType === 'COMPLIMENTARY',
      ),
    enterpriseTrialMonths: yup
      .number()
      .typeError(enterpriseMonthlyFeeMsg)
      .test('', enterpriseMonthlyFeeMsg, (value, schema) => {
        return (
          (Number(value) >= 0 && Number(value) <= 99) ||
          schema.parent.subscriptionType === 'COMPLIMENTARY'
        );
      }),
    enterpriseMonthlyFee: yup
      .number()
      .typeError(expirationPeriodHoursMsg)
      .test('', expirationPeriodHoursMsg, (value, schema) => {
        return (
          (Number(value) >= 0 && Number(value) <= 99999) ||
          schema.parent.subscriptionType === 'COMPLIMENTARY'
        );
      }),
    expirationPeriodHours: yup
      .number()
      .typeError(expirationPeriodHoursMsg)
      .min(24, expirationPeriodHoursMsg)
      .max(720, expirationPeriodHoursMsg),
  });

  const updateMethods = useForm<Bank>({
    resolver: yupResolver(bankSchema),
    values: BANK_FORM_DEFAULT_VALUE,
  });
  const { paginationProps, resetPaginations, PaginationComponent } = usePagin();

  const {
    handleSubmit: handleSubmitUpdateForm,
    watch: watchUpdateForm,
    reset: resetUpdateForm,
    register: registerUpdateForm,
    setFocus: setFocusUpdateForm,
  } = updateMethods;

  useEffect(() => {
    const subscriptionType = updateMethods.watch('subscriptionType');
    if (subscriptionType === 'COMPLIMENTARY') {
      updateMethods.clearErrors('enterpriseTrialStartMonth');
      updateMethods.clearErrors('enterpriseMonthlyFee');
      updateMethods.clearErrors('enterpriseTrialMonths');
      updateMethods.setValue('enterpriseMonthlyFee', 0);
      updateMethods.setValue('enterpriseTrialMonths', 0);
      updateMethods.setValue('enterpriseTrialStartMonth', '');
    }
    if (subscriptionType === 'FREE_TRIAL') {
      updateMethods.setValue('enterpriseMonthlyFee', 2200);
      updateMethods.setValue('enterpriseTrialMonths', 12);
    }
  }, [updateMethods.watch('subscriptionType')]);

  const currentBank = watchUpdateForm();
  const searchMethods = useForm<GetBankRequest>({
    defaultValues: {
      nameSearch: '',
    },
  });

  const { register: registerSearchForm, watch: watchSearchForm } =
    searchMethods;

  const searchValue = watchSearchForm();

  const nameSearchDebounce = useDebounce(searchValue.nameSearch, 300);
  const {
    data: banksQuery,
    isFetching: banksFetching,
    isFetchedAfterMount,
    refetch: banksRefetch,
  } = useHgbQueryWithPaging<Bank[], GetBankRequest>(
    API_URLS.BANK_API_URL,
    { ...searchValue, ...paginationProps },
    {
      keepPreviousData: true,
      queryKey: [
        API_URLS.BANK_API_URL,
        paginationProps.pageNo,
        nameSearchDebounce,
      ],
    },
  );

  const banksRefreshWithPagin = () => {
    if (paginationProps.pageNo > 1) {
      resetPaginations();
    } else {
      banksRefetch();
    }
  };

  const { mutate: updateBankMutate } = useHgbMutationPut<
    UpdateBankResponse,
    UpdateBankRequest
  >(API_URLS.BANK_API_URL, {
    onSuccess() {
      closeModal();
      banksRefetch();
    },
  });

  const { mutate: createBankMutation } = useHgbMutationPost<
    CreateBankResponse,
    CreateBankRequest
  >(API_URLS.BANK_API_URL, {
    onSuccess() {
      closeModal();
      banksRefetch();
    },
    onError() {
      setFocusUpdateForm('bankCode');
      resetUpdateForm({
        ...BANK_FORM_DEFAULT_VALUE,
      });
    },
  });

  const { mutate: deleteBankMutation } = useHgbMutationDelete(
    API_URLS.BANK_API_URL,
    {
      onSuccess() {
        setIsDelete(false);
        banksRefreshWithPagin();
      },
    },
  );

  const handleEdit = (item: Bank) => {
    resetUpdateForm({
      ...item,
      emailSignature: item.emailSignature?.split('<br />').join('\n'),
      loginUrl: `${customLink}${item.bankCode}`,
      enterpriseTrialStartMonth: (
        item.enterpriseTrialStartMonth || ''
      ).replaceAll('-', '/'),
    });
    setIsModalStatus({
      isOpen: true,
      mode: 'UPDATE',
    });
  };

  const handleDelete = (item: Bank) => {
    resetUpdateForm(item);
    setIsDelete(true);
  };

  type DataType = {
    bankName?: string;
    loginUrl?: string;
    action?: React.ReactNode | null;
    bankArea?: string;
    bankProvince?: string;
    enterpriseMonthlyFee?: number;
    enterpriseTrialMonths?: number;
    enterpriseTrialStartMonth?: string;
    expirationPeriodHours?: number;
    numberOfUser?: number;
  };

  const headers: ColumnsType<DataType> = [
    {
      title: G01.label.area,
      dataIndex: 'bankArea',
      key: 'bankArea',
      width: 160,
    },
    {
      title: G01.label.province,
      dataIndex: 'bankProvince',
      key: 'bankProvince',
      width: 160,
    },
    {
      title: common.label.bankCode,
      dataIndex: 'item',
      key: 'item',
      width: 120,
      align: 'center',
      render: (item: Bank) => <>{item.bankCode}</>,
    },
    {
      title: G01.label.bankName,
      dataIndex: 'item',
      key: 'item',
      width: 160,
      render: (item: Bank) => (
        <HgbCellLink
          className="tw-inline-block tw-text-left"
          onClick={() => {
            resetUpdateForm({
              ...item,
              emailSignature: item.emailSignature?.split('<br />').join('\n'),
              loginUrl: `${customLink}${item.bankCode}`,
            });
            setIsModalStatus({ isOpen: true, mode: 'VIEW' });
          }}
        >
          <HgbCellTooltip>{item.bankName}</HgbCellTooltip>
        </HgbCellLink>
      ),
    },
    {
      title: G01.label.numberOfActiveEnterprises,
      key: 'numberOfUser',
      dataIndex: 'numberOfUser',
      width: 120,
      align: 'right',
      render: (text) => <>{text.toString() ? `${text}` : '_'}</>,
    },
    {
      title: G01.label.enterpriseTrialMonths,
      key: 'enterpriseTrialMonths',
      dataIndex: 'enterpriseTrialMonths',
      width: 200,
      align: 'center',
      render: (text) => <>{text ? `${text} ${G01.label.months}` : '_'}</>,
    },
    {
      title: G01.label.enterpriseMonthlyFee,
      key: 'enterpriseMonthlyFee',
      dataIndex: 'enterpriseMonthlyFee',
      width: 180,
      align: 'center',
      render: (text) => (
        <>
          {text ? `${toJPFormat(text, 0, false, 'G01')} ${G01.label.yen}` : '_'}
        </>
      ),
    },
    {
      title: G01.label.billingStartMonth,
      key: 'enterpriseTrialStartMonth',
      dataIndex: 'enterpriseTrialStartMonth',
      width: 180,
      align: 'center',
      render: (text) => <>{text}</>,
    },
    {
      title: G01.label.URLExpirationPeriodHours,
      key: 'expirationPeriodHours',
      dataIndex: 'expirationPeriodHours',
      width: 180,
      align: 'center',
      render: (text) => <>{text ? `${text} ${G01.label.hours}` : '_'}</>,
    },
    {
      title: common.label.action,
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      width: 160,
    },
  ];

  const customLink = window.location.href.replace(
    routs_en['/management/bank'].link,
    '/signin?bankCode=',
  );

  const rows: DataType[] = useMemo(() => {
    const contents = banksQuery?.result?.content ?? [];
    return contents.map((item, index) => ({
      key: index,
      bankArea: item.bankArea,
      bankProvince: item.bankProvince,
      bankName: item.bankName,
      loginUrl: `${customLink}${item.bankCode}`,
      enterpriseMonthlyFee: item.enterpriseMonthlyFee,
      enterpriseTrialMonths: item.enterpriseTrialMonths,
      enterpriseTrialStartMonth: calculateBillingStartMonth(
        item.enterpriseTrialMonths,
        item.enterpriseTrialStartMonth,
      ),
      expirationPeriodHours: item.expirationPeriodHours,
      numberOfUser: item.numberOfUser,
      item: item,
      action: (
        <div className="tw-flex tw-justify-center tw-gap-24">
          <HgbActionButton
            onClick={() => handleEdit(item)}
            icon={<EditOutlined />}
          >
            {common.button.update}
          </HgbActionButton>
          <HgbActionButton
            onClick={() => handleDelete(item)}
            icon={<DeleteOutlined />}
          >
            {common.button.delete}
          </HgbActionButton>
        </div>
      ),
    }));
  }, [banksQuery?.result?.content]);

  const isView = modalStatus.mode === 'VIEW';
  const isUpdate = modalStatus.mode === 'UPDATE';
  const isAdd = modalStatus.mode === 'ADD';

  const submitBank = () => {
    const payload = structuredClone({
      ...currentBank,
      emailSignature: currentBank.emailSignature.replaceAll(
        new RegExp('\r?\n', 'g'),
        '<br />',
      ),
      enterpriseTrialStartMonth: (
        currentBank.enterpriseTrialStartMonth || ''
      ).replaceAll('-', '/'),
      privacyPolicyUrl: encodeURIComponent(currentBank.privacyPolicyUrl),
    });
    if (modalStatus.mode === 'ADD') {
      const { id, loginUrl, numberOfUser, ...addPayload } = payload;
      createBankMutation(addPayload);
    }
    if (modalStatus.mode === 'UPDATE') {
      const { numberOfUser, ...updatePayload } = payload;
      updateBankMutate(updatePayload);
    }
  };

  const closeModal = () => {
    setIsModalStatus((prev) => ({ ...prev, isOpen: false }));
  };

  const modalText: Record<ModalMode, string> = {
    VIEW: G01.modal.bankViewTitle,
    ADD: G01.modal.bankAddTitle,
    UPDATE: G01.modal.bankUpdateTitle,
  };
  const okText: Record<ModalMode, ReactNode> = {
    VIEW: '',
    ADD: common.button.add,
    UPDATE: common.button.change,
  };

  return (
    <ManagementTemplate pageName={G01.pageTitleBankList}>
      <FormProvider {...searchMethods}>
        <DataTableTemplate
          title={G01.pageTitleBankList}
          inputsComponent={
            <HgbAntdInput
              {...registerSearchForm('nameSearch')}
              className="tw-w-full tablet:tw-w-220"
              type="K"
              maxLength={20}
              search
              placeholder={G01.placeholder.nameSearch}
              onChangeValue={() => banksRefreshWithPagin()}
            />
          }
          paginationComponents={
            banksQuery?.result?.totalRecord ? (
              <PaginationComponent
                current={banksQuery?.result?.currentPage}
                total={banksQuery?.result?.totalRecord}
                pageSize={banksQuery?.result?.pageSize}
              />
            ) : null
          }
          buttonComponent={
            <HgbAntdButton
              type="primary"
              className="tw-ml-auto tw-shrink-0"
              icon={<PlusOutlined />}
              onClick={() => {
                resetUpdateForm({
                  ...BANK_FORM_DEFAULT_VALUE,
                });
                setIsModalStatus({ isOpen: true, mode: 'ADD' });
              }}
            >
              <>{common.button.register}</>
            </HgbAntdButton>
          }
        >
          {banksFetching ? (
            <Spin />
          ) : rows.length !== 0 ? (
            <Table
              locale={locale}
              dataSource={rows}
              columns={headers}
              pagination={false}
              scroll={{
                x: headers.reduce((prev, curr) => prev + Number(curr.width), 0),
              }}
            />
          ) : isFetchedAfterMount ? (
            <HgbAntdEmpty description={common.message.noResult} />
          ) : null}
        </DataTableTemplate>
      </FormProvider>

      {isDelete ? (
        <HgbAntdModal
          cancelText={common.button.cancel}
          okText={common.button.delete}
          open
          onCancel={() => {
            setIsDelete(false);
          }}
          title={G01.modal.bankDeleteTitle}
          formProps={{
            onSubmit: () => {
              deleteBankMutation(currentBank.id.toString());
            },
          }}
          preventSubmitTime={2000}
        >
          <p>
            {G01.message.deleteBankAlertSplit1}
            {currentBank.bankCode}_{currentBank.bankName}
            {G01.message.deleteBankAlertSplit2}
          </p>
        </HgbAntdModal>
      ) : null}

      <FormProvider {...updateMethods}>
        {modalStatus.isOpen ? (
          <HgbAntdModal
            cancelText={common.button.cancel}
            okText={okText[modalStatus.mode]}
            open
            onCancel={closeModal}
            title={modalText[modalStatus.mode]}
            formProps={{
              onSubmit: handleSubmitUpdateForm(submitBank),
            }}
            preventSubmitTime={2000}
          >
            <div className="tw-flex tw-flex-col tw-gap-24">
              <HgbAntdInput
                readOnly={!isAdd}
                required
                {...registerUpdateForm('bankCode')}
                type="bankCode"
                maxLength={4}
                label={common.label.bankCode}
                placeholder={G01.placeholder.bankCode}
              />
              <HgbAntdInput
                readOnly={!isAdd}
                required
                {...registerUpdateForm('bankName')}
                maxLength={20}
                type="K"
                label={G01.label.bankName}
                placeholder={G01.placeholder.bankName}
              />
              <HgbSelect
                required
                label={G01.label.area}
                placeholder={G01.placeholder.area}
                {...registerUpdateForm('bankArea')}
                options={areaOptions}
                disabled={isView}
              />
              <HgbSelect
                required
                label={G01.label.province}
                placeholder={G01.placeholder.province}
                {...registerUpdateForm('bankProvince')}
                options={
                  areaOptions.find((s) => s.label === currentBank.bankArea)
                    ?.childrens
                }
                disabled={isEmpty(currentBank.bankArea) || isView}
              />
              {!isAdd ? (
                <HgbAntdInput
                  readOnly
                  {...registerUpdateForm('loginUrl')}
                  type="H"
                  maxLength={100}
                  label={G01.label.loginUrl}
                  placeholder={G01.placeholder.loginUrl}
                />
              ) : null}
              <HgbAntdInput
                {...registerUpdateForm('personInCharge')}
                required
                maxLength={20}
                type="K"
                readOnly={isView}
                label={G01.label.personInCharge}
                placeholder={G01.placeholder.personInCharge}
              />
              <HgbAntdInput
                {...registerUpdateForm('personInChargeEmail')}
                maxLength={50}
                required
                type="hgbEmail"
                readOnly={isView}
                label={G01.label.personInChargeEmail}
                placeholder={G01.placeholder.personInChargeEmail}
              />
              <HgbAntdRadioGroup
                {...registerUpdateForm('subscriptionType')}
                options={subscriptionOptions}
                label={G01.label.subscriptionType}
                className="tw-gap-0 [&>div]:tw-grid [&>div]:tw-grid-cols-[auto_1fr]"
                readOnly={modalStatus.mode !== 'ADD'}
              />
              <HgbDatePicker
                required={watchUpdateForm('subscriptionType') === 'FREE_TRIAL'}
                label={G01.label.enterpriseTrialStartMonths}
                format="YYYY/MM"
                {...registerUpdateForm('enterpriseTrialStartMonth')}
                disabled={
                  watchUpdateForm('subscriptionType') === 'COMPLIMENTARY' ||
                  !isAdd
                }
              />
              <HgbAntdInput
                type="positiveInteger"
                required={watchUpdateForm('subscriptionType') === 'FREE_TRIAL'}
                label={G01.label.enterpriseTrialMonthsG02}
                {...registerUpdateForm('enterpriseTrialMonths')}
                addonAfter={G01.label.months}
                disabled
              />
              <HgbAntdInput
                addonAfter={G01.label['yen/month']}
                required={watchUpdateForm('subscriptionType') === 'FREE_TRIAL'}
                label={G01.label.enterpriseMonthlyFeeG02}
                {...registerUpdateForm('enterpriseMonthlyFee')}
                disabled
              />
              <HgbAntdInput
                addonAfter={G01.label.hours}
                label={G01.label.URLExpirationPeriodHours}
                {...registerUpdateForm('expirationPeriodHours')}
                required
                disabled
              />
              <HgbAntdInput
                {...registerUpdateForm('privacyPolicyUrl')}
                required
                maxLength={255}
                type="H"
                readOnly={isView}
                label={G01.label.privacyPolicyUrl}
                placeholder={G01.placeholder.privacyPolicyUrl}
              />
              <HgbAntdInput
                type="area"
                required
                maxLength={255}
                label={G01.label.signature}
                placeholder={G01.label.signature}
                {...registerUpdateForm('emailSignature')}
                readOnly={isView}
              />
            </div>
          </HgbAntdModal>
        ) : null}
      </FormProvider>
    </ManagementTemplate>
  );
};
