import { RoutDef, RouteItem, routs_en, routs_jp } from '@constants/ROUT';
import { en } from '@constants/languages/en';
import { jp } from '@constants/languages/jp';
import { useStorage } from '@hooks/useStorage';
import { ReactNode, createContext } from 'react';

export type LanguageType = 'en' | 'jp';
interface LanguageContextType {
  language: LanguageType;
  text: typeof en;
  routs: Record<RoutDef, RouteItem>;
}

export const LanguageContext = createContext<LanguageContextType | null>(null);
export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const language= useStorage<LanguageType>('LANG');
  const text = language === 'en' ? en : jp;
  const routs = language === 'en' ? routs_en : routs_jp;

  return (
    <LanguageContext.Provider value={{ language, text, routs }}>
      {children}
    </LanguageContext.Provider>
  );
};
