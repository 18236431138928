import * as React from 'react';
import { HgbAntdButton } from './HgbAntdButton';

interface IHgbActionButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  icon?: React.ReactNode;
}

const HgbActionButton: React.FunctionComponent<IHgbActionButtonProps> = (
  props,
) => {
  return (
    <HgbAntdButton
      type="link"
      className="!tw-px-0 tw-font-bold tw-no-underline"
      disabled={props.disabled}
      onClick={props.onClick}
      icon={props.icon}
    >
      {props.children}
    </HgbAntdButton>
  );
};

export { HgbActionButton };

