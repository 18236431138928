import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import { HgbDatePicker, HgbSelect, HgbSelectOption } from '@common/forms';
import { HgbAntdInput } from '@common/forms/HgbAntdInput/HgbAntdInput';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGet, usePost } from '@hooks/useApi';
import { useConfirm } from '@hooks/useConfirm';
import { currentYearMonth } from '@utils/date.util';
import { debug } from '@utils/debug';
import { floorNumber, parseFormula, toBigDecimal } from '@utils/input.utils';
import { mapSelectToSelectIndex } from '@utils/object';
import {
  convertObjectToTree,
  getFieldsFromFormula,
  getParentKey,
} from '@utils/object.utils';
import { JSON_TO_KEY, joinSafe } from '@utils/text';
import BigNumber from 'bignumber.js';
import parse from 'html-react-parser';
import { isEmpty, isNaN } from 'lodash';
import * as React from 'react';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbQuery } from 'services/common/query';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetCategoryListBoxRequest,
  GetCategoryListBoxResponse,
} from 'services/types/category';
import {
  GetScopeListBoxRequest,
  GetScopeListBoxResponse,
} from 'services/types/scope';
import {
  GetFiscalYearMonthListBoxRequest,
  GetFiscalYearMonthListBoxResponse,
  GetLastTenYearListBoxRequest,
  GetLastTenYearListBoxResponse,
} from 'services/types/year';
import {
  Emission,
  EmissionInputScope1Request,
  EmissionInputScope1Response,
  EmissionOffset,
  FlowInputDetail,
  InputInfo,
  OffsetFields,
  SelectionInfo,
  Step1Fields,
  Step2Fields,
} from 'types/api/emission-input/emission-input.type';
import * as yup from 'yup';
import { MANUAL_NAMEDDESTS } from '../Support';

type ModalType = 'STEP_1' | 'STEP_2';
// type ModalMode = 'CREATE' | 'UPDATE';
type ModalStatus = {
  isOpen: boolean;
  type: ModalType;
  // mode: ModalMode;
};

type ObjectModal = {
  title: ReactNode;
  content: ReactNode;
  cancel: ReactNode;
  ok: ReactNode;
};

const STEP1_DEFAULT_VALUE: Step1Fields = {
  targetYearMonth: '',
  targetFiscalYear: '',
  baseId: '',
  scopeCode: '',
  categoryCode: '',
};

const STEP2_DEFAULT_VALUE: Step2Fields = {
  emissionUnit: '',
  calculationTargetCode: '',
  calculationMethodCode: '',
  scope2MethodSelected: '',
  fuelCode: '',
  activityVolume: undefined,
  activityVolumeUnit: '',
  emissionIntensity: undefined,
  emissionIntensityUnit: '',
  emissionVolume: '',
  emissionVolumeUnit: '',
  modelCode: '',
  refrigerantNo: '',
  fillingVolume: undefined,
  fillingVolumeUnit: '',
  recoveryVolume: undefined,
  recoveryVolumeUnit: '',
  useEmissionCoefficient: undefined,
  useEmissionCoefficientUnit: '',
  gwp: undefined,
  gwpUnit: '',
  activityName: '',
  supplyMenuCode: '',
  retailElectricCalculationMethodCode: '',
  operatorGraspRate: undefined,
  operatorGraspRateUnit: '',
  heatUsageCode: '',
  departmentCode: '',
  rawActivityVolume: undefined,
  rawEmissionIntensity: undefined,
  rawTransportActivityVolume: undefined,
  rawTransportEmissionIntensity: undefined,
  capitalWasteActivityVolume: undefined,
  capitalWasteEmissionIntensity: undefined,
  energyTypeCode: '',
  electricCompanyNo: '',
  transportTypeCode: '',
  transportDistance: undefined,
  transportDistanceUnit: '',
  transportCargoWeight: undefined,
  transportCargoWeightUnit: '',
  loadPercentageCode: '',
  transportLoadRate: undefined,
  transportLoadRateUnit: '',
  consumptionTransportTypeCode: '',
  consumption: undefined,
  consumptionUnit: '',
  radioButton1: '',
  radioButton2: '',

  numberOfPeople: undefined,
  numberOfPeopleUnit: '',
  numberOfNight: undefined,
  numberOfNightUnit: '',
  numberOfDay: undefined,
  numberOfDayUnit: '',
  businessTravelTypeCode: '',

  transportProduct: '',
  employeeNumberBasedTypeCode: '',
  nightStayTypeCode: '',

  fuelEconomyBasic: undefined,
  fuelEconomyBasicUnit: '',

  officeCityClassificationCode: '',

  buildingUseCode: '',
  distribution: undefined,
  distributionUnit: '',

  expectedLifetimeUsagePer: undefined,
  expectedLifetimeUsagePerUnit: '',
  saleCount: undefined,
  saleCountUnit: '',
  perLessonUsage: undefined,
  perLessonUsageUnit: '',
  capitalGoodsTypeCode: '',
  emissionLifetimeUse: undefined,
  greenHouseGasCode: '',
  businessDaysYear: undefined,
  expectedLifetimeUsage: undefined,
  expectedLifetimeUsagePercentage: undefined,
  unclearActivityVolume1: undefined,
  unclearActivityVolume2: undefined,
  unclearActivityVolume3: undefined,
  unclearActivityVolume4: undefined,
  unclearActivityVolume5: undefined,
  unclearActivityVolume6: undefined,
  unclearActivityVolume7: undefined,
  unclearActivityVolume8: undefined,
  unclearActivityVolume9: undefined,
  unclearActivityVolume10: undefined,
  unclearActivityVolume11: undefined,
  unclearActivityVolume12: undefined,
  unclearEmissionIntensity1: undefined,
  unclearEmissionIntensity2: undefined,
  unclearEmissionIntensity3: undefined,
  unclearEmissionIntensity4: undefined,
  unclearEmissionIntensity5: undefined,
  unclearEmissionIntensity6: undefined,
  manualIdeaInput: undefined,
  emissionIntensityLocation: undefined,
  emissionVolumeLocation: '',
  emissionIntensityLocationUnit: '',
  emissionVolumeLocationUnit: '',
};
export const OFFSET_DEFAULT: OffsetFields = {
  offsetAmount: undefined,
  offsetAmountUnit: '',
  offsetCertificateDate: '',
  offsetTarget: '',
  projectName: '',
  creditType: '',
};

interface EmissionCreateModalProp {
  scopeEdit?: EmissionInputScope1Response;
  step1?: Step1Fields;
  activityName?: string;
  id?: number;
  reset?: () => void;
  offset?: OffsetFields;
}

export const EmissionCreateModal: FunctionComponent<
  EmissionCreateModalProp
> = ({ scopeEdit, step1, activityName, id, offset, reset }) => {
  const {
    text: { C01, common, E0000 },
    language,
  } = useContext(LanguageContext)!;

  const [isEdit, setIsEdit] = useState(false);

  const { addMessage } = useContext(PortalContext)!;

  const confirmModalText: ObjectModal = {
    title: C01.modal.emissionCreateConfirmTitle,
    content: C01.message.emissionCreateConfirm,
    ok: common.button.add,
    cancel: common.button.cancel,
  };

  const [categoryListBox, setCategoryListBox] = useState<HgbSelectOption[]>([]);
  const [modalStatus, setModalStatus] = useState<ModalStatus>({
    isOpen: false,
    type: 'STEP_1',
  });
  // const [confirmModalStatus, setConfirmModalStatus] = useState(false);
  const okText: Record<ModalType, ReactNode> = {
    STEP_1: common.button.next,
    STEP_2: common.button.add,
  };
  const [selectionInfos, setSelectionInfos] = useState<SelectionInfo[]>([]);
  const [params, setParams] = useState<EmissionInputScope1Request>({});
  const [index, setIndex] = useState<number>(1);

  const [scope1Result, requestScope1] = usePost<
    EmissionInputScope1Response,
    EmissionInputScope1Request
  >(
    (data) => {
      setSelectionInfos((prev) => [
        ...prev,
        ...(data?.nextStepSelectionInfos?.map((item) => ({
          ...item,
          index: index + 1,
        })) ?? []),
      ]);
      setIndex(index + 1);

      setModalStatus((prev) => ({ ...prev, type: 'STEP_2' }));
    },
    undefined,
    { isAlert: false },
  );

  const resetModal = () => {
    setSelectionInfos([...[selectionInfos[0]]]);
    step1Methods.reset(STEP1_DEFAULT_VALUE);
    offsetMethods.reset(OFFSET_DEFAULT);
    step2Methods.reset(STEP2_DEFAULT_VALUE);
    setModalStatus({ isOpen: false, type: 'STEP_1' });
    setParams({});
    reset?.();
    // setConfirmModalStatus(false);
    setIsEdit(false);
  };

  const resetModalCancel = () => {
    setSelectionInfos([...[selectionInfos[0]]]);
    step1Methods.reset(STEP1_DEFAULT_VALUE);
    offsetMethods.reset(OFFSET_DEFAULT);
    step2Methods.reset(STEP2_DEFAULT_VALUE);
    setModalStatus({ isOpen: false, type: 'STEP_1' });
    setParams({});
    setIsEdit(false);
  };

  const [, submitScope1] = usePost<
    undefined,
    Emission & { targetLciDbYear?: string }
  >((data) => {
    resetModal();
  });

  const [lastTenYearResult, getLastTenYearResult] = useGet<
    GetLastTenYearListBoxResponse,
    GetLastTenYearListBoxRequest
  >(() => {
    if (step1?.targetFiscalYear)
      step1Methods.setValue('targetFiscalYear', step1.targetFiscalYear);
  });

  const { data: baseQuery } = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_BASE,
    {
      isLoadInactive: false,
    },
    {
      onSuccess(data) {
        if (step1?.baseId) {
          step1Methods.setValue('baseId', step1.baseId);
        }
      },
      queryKey: [API_URLS.ORGANIZATION_BASE, 'create-model'],
    },
  );

  const [scopeListBoxResult, getScopeListBoxResult] = useGet<
    GetScopeListBoxResponse,
    GetScopeListBoxRequest
  >(() => {
    if (step1?.scopeCode) {
      step1Methods.setValue('scopeCode', step1.scopeCode);
    }
  });

  const step1Schema = yup.object({
    targetFiscalYear: yup.string().required(E0000(C01.label.targetFiscalYear)),
    targetYearMonth: yup.string().required(E0000(C01.label.targetYearMonth)),
    scopeCode: yup.string().required(E0000(C01.label.scopeCode)),
    categoryCode: yup.string().when({
      is: () => Emission.scopeCode !== '4',
      then: (schema) => schema.required(E0000(common.label.category)),
    }),
    baseId: yup.string().required(E0000(C01.label.baseId)),
  });
  const step2Schema = yup.object({
    activityName: yup.string().when({
      is: () => step1Methods.watch('scopeCode') === '3',
      then: (schema) => schema.required(E0000(C01.label.activityName)),
      otherwise: (schema) => schema,
    }),
    activityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('activityVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.activityVolume))
          .min(0, E0000(C01.label.activityVolume))
          .typeError(E0000(C01.label.activityVolume)),

      otherwise: (schema) => schema,
    }),
    fillingVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('fillingVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.fillingVolume))
          .min(0, E0000(C01.label.fillingVolume))
          .typeError(E0000(C01.label.fillingVolume)),
      otherwise: (schema) => schema,
    }),
    recoveryVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('recoveryVolume'),
      then: (schema) =>
        schema
          .min(0, C01.message.recoveryVolumeFormat)
          .required(C01.message.recoveryVolume)
          .typeError(C01.message.recoveryVolumeFormat),
      otherwise: (schema) => schema,
    }),
    emissionVolume: yup.string().when({
      is: () => step2Methods.watch('emissionIntensityLocation') === undefined,
      then: (schema) =>
        schema
          .min(0, C01.message.emissionVolumeNegativeCreate)
          .required(E0000(common.label.emissions))
          .typeError(E0000(common.label.emissions)),
      otherwise: (schema) => schema,
    }),
    emissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('emissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.emissionIntensity))
          .min(0, E0000(C01.label.emissionIntensity))
          .typeError(E0000(C01.label.emissionIntensity)),
      otherwise: (schema) => schema,
    }),
    rawActivityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('rawActivityVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawActivityVolume))
          .min(0, E0000(C01.label.rawActivityVolume))
          .typeError(E0000(C01.label.rawActivityVolume)),
      otherwise: (schema) => schema,
    }),
    rawEmissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('rawEmissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawEmissionIntensity))
          .min(0, E0000(C01.label.rawEmissionIntensity))
          .typeError(E0000(C01.label.rawEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    rawTransportActivityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('rawTransportActivityVolume'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.rawTransportActivityVolume))
          .required(E0000(C01.label.rawTransportActivityVolume))
          .typeError(E0000(C01.label.rawTransportActivityVolume)),
      otherwise: (schema) => schema,
    }),
    rawTransportEmissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('rawTransportEmissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawTransportEmissionIntensity))
          .min(0, E0000(C01.label.rawTransportEmissionIntensity))
          .typeError(E0000(C01.label.rawTransportEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    capitalWasteActivityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('capitalWasteActivityVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.capitalWasteActivityVolume))
          .min(0, C01.message.capitalWasteActivityVolumeFormat)
          .typeError(C01.message.capitalWasteActivityVolumeFormat),
      otherwise: (schema) => schema,
    }),
    capitalWasteEmissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('capitalWasteEmissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.capitalWasteEmissionIntensity))
          .min(0, E0000(C01.label.capitalWasteEmissionIntensity))
          .typeError(E0000(C01.label.capitalWasteEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    transportCargoWeight: yup.number().when({
      is: () => preValidateInputInfoCondition('transportCargoWeight'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.transportCargoWeight))
          .min(0, E0000(C01.label.transportCargoWeight))
          .typeError(E0000(C01.label.transportCargoWeight)),
      otherwise: (schema) => schema,
    }),
    transportDistance: yup.number().when({
      is: () => preValidateInputInfoCondition('transportDistance'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.transportDistance))
          .min(0, E0000(C01.label.transportDistance))
          .typeError(E0000(C01.label.transportDistance)),
      otherwise: (schema) => schema,
    }),
    numberOfPeople: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfPeople'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.numberOfPeople))
          .required(E0000(C01.label.numberOfPeople))
          .typeError(E0000(C01.label.numberOfPeople)),
      otherwise: (schema) => schema,
    }),
    numberOfNight: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfNight'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.numberOfNight))
          .min(0, E0000(C01.label.numberOfNight))
          .typeError(E0000(C01.label.numberOfNight)),
      otherwise: (schema) => schema,
    }),
    numberOfDay: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfDay'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.numberOfDay))
          .min(0, E0000(C01.label.numberOfDay))
          .typeError(E0000(C01.label.numberOfDay)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsagePer: yup.number().when({
      is: () => preValidateInputInfoCondition('expectedLifetimeUsagePer'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsagePer))
          .required(E0000(C01.label.expectedLifetimeUsagePer))
          .typeError(E0000(C01.label.expectedLifetimeUsagePer)),
      otherwise: (schema) => schema,
    }),
    saleCount: yup.number().when({
      is: () => preValidateInputInfoCondition('saleCount'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.saleCount))
          .min(0, E0000(C01.label.saleCount))
          .typeError(E0000(C01.label.saleCount)),
      otherwise: (schema) => schema,
    }),
    perLessonUsage: yup.number().when({
      is: () => preValidateInputInfoCondition('perLessonUsage'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.perLessonUsage))
          .required(E0000(C01.label.perLessonUsage))
          .typeError(E0000(C01.label.perLessonUsage)),
      otherwise: (schema) => schema,
    }),
    emissionLifetimeUse: yup.number().when({
      is: () => preValidateInputInfoCondition('emissionLifetimeUse'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.emissionLifetimeUse))
          .required(E0000(C01.label.emissionLifetimeUse))
          .typeError(E0000(C01.label.emissionLifetimeUse)),
      otherwise: (schema) => schema,
    }),
    distribution: yup.number().when({
      is: () => !isEmpty(validateByRange('distribution')),
      then: () =>
        validateByRange('distribution') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    fuelEconomyBasic: yup.number().when({
      is: () => preValidateInputInfoCondition('fuelEconomyBasic'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.fuelEconomy))
          .required(E0000(C01.label.fuelEconomy))
          .typeError(E0000(C01.label.fuelEconomy)),
      otherwise: (schema) => schema,
    }),
    transportLoadRate: yup.number().when({
      is: () => preValidateInputInfoCondition('transportLoadRate'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.transportLoadRate))
          .required(E0000(C01.label.transportLoadRate))
          .typeError(E0000(C01.label.transportLoadRate)),
      otherwise: (schema) => schema,
    }),
    businessDaysYear: yup.number().when({
      is: () => preValidateInputInfoCondition('businessDaysYear'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.businessDaysYear))
          .required(E0000(C01.label.businessDaysYear))
          .typeError(E0000(C01.label.businessDaysYear)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsage: yup.number().when({
      is: () => preValidateInputInfoCondition('expectedLifetimeUsage'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsage))
          .required(E0000(C01.label.expectedLifetimeUsage))
          .typeError(E0000(C01.label.expectedLifetimeUsage)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsagePercentage: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('expectedLifetimeUsagePercentage'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsagePercentage))
          .required(E0000(C01.label.expectedLifetimeUsagePercentage))
          .typeError(E0000(C01.label.expectedLifetimeUsagePercentage)),
      otherwise: (schema) => schema,
    }),
    useEmissionCoefficient: yup.number().when({
      is: () => !isEmpty(validateByRange('useEmissionCoefficient')),
      then: () =>
        validateByRange('useEmissionCoefficient') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume1: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume1'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume1))
          .required(E0000(C01.label.unclearActivityVolume1))
          .typeError(E0000(C01.label.unclearActivityVolume1)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume2: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume2'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume2))
          .required(E0000(C01.label.unclearActivityVolume2))
          .typeError(E0000(C01.label.unclearActivityVolume2)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume3: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume3'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume3))
          .required(E0000(C01.label.unclearActivityVolume3))
          .typeError(E0000(C01.label.unclearActivityVolume3)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume4: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume4'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume4))
          .required(E0000(C01.label.unclearActivityVolume4))
          .typeError(E0000(C01.label.unclearActivityVolume4)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume5: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume5'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume5))
          .required(E0000(C01.label.unclearActivityVolume5))
          .typeError(E0000(C01.label.unclearActivityVolume5)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume6: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume6'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume6))
          .required(E0000(C01.label.unclearActivityVolume6))
          .typeError(E0000(C01.label.unclearActivityVolume6)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume7: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume7'),
      then: (schema) =>
        schema
          .min(0, C01.message.unclearActivityVolume7Format)
          .required(C01.message.unclearActivityVolume7)
          .typeError(C01.message.unclearActivityVolume7Format),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume8: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume8'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume8))
          .required(E0000(C01.label.unclearActivityVolume8))
          .typeError(E0000(C01.label.unclearActivityVolume8)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume9: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume9'),
      then: (schema) =>
        schema
          .min(0, C01.message.unclearActivityVolume9Format)
          .required(C01.message.unclearActivityVolume9)
          .typeError(C01.message.unclearActivityVolume9Format),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume10: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume10'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume10))
          .required(E0000(C01.label.unclearActivityVolume10))
          .typeError(E0000(C01.label.unclearActivityVolume10)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume11: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume11'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume11))
          .required(E0000(C01.label.unclearActivityVolume11))
          .typeError(E0000(C01.label.unclearActivityVolume11)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume12: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume12'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume12))
          .required(E0000(C01.label.unclearActivityVolume12))
          .typeError(E0000(C01.label.unclearActivityVolume12)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity1: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity1'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity1))
          .required(E0000(C01.label.unclearEmissionIntensity1))
          .typeError(E0000(C01.label.unclearEmissionIntensity1)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity2: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity2'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity2))
          .required(E0000(C01.label.unclearEmissionIntensity2))
          .typeError(E0000(C01.label.unclearEmissionIntensity2)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity3: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity3'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity3))
          .required(E0000(C01.label.unclearEmissionIntensity3))
          .typeError(E0000(C01.label.unclearEmissionIntensity3)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity4: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity4'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity4))
          .required(E0000(C01.label.unclearEmissionIntensity4))
          .typeError(E0000(C01.label.unclearEmissionIntensity4)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity5: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity5'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity5))
          .required(E0000(C01.label.unclearEmissionIntensity5))
          .typeError(E0000(C01.label.unclearEmissionIntensity5)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity6: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity6'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity6))
          .required(E0000(C01.label.unclearEmissionIntensity6))
          .typeError(E0000(C01.label.unclearEmissionIntensity6)),
      otherwise: (schema) => schema,
    }),
    manualIdeaInput: yup.number().when({
      is: () => preValidateInputInfoCondition('manualIdeaInput'),
      then: (schema) =>
        schema
          .min(0, C01.message.manualIdeaInput)
          .required(C01.message.manualIdeaInput)
          .typeError(C01.message.manualIdeaInput),
      otherwise: (schema) => schema,
    }),
  });

  const offsetSchema = yup.object({
    offsetCertificateDate: yup
      .string()
      .required(E0000(C01.label.offsetCertificateDate))
      .typeError(E0000(C01.label.offsetCertificateDate)),
    offsetAmount: yup
      .number()
      .min(0, E0000(C01.label.offsetAmount))
      .required(E0000(C01.label.offsetAmount))
      .typeError(E0000(C01.label.offsetAmount)),
  });

  // tách các fields thành 2 step
  const step1Methods = useForm<Step1Fields>({
    defaultValues: STEP1_DEFAULT_VALUE,
    resolver: yupResolver(step1Schema),
  });

  const step2Methods = useForm<Step2Fields>({
    values: STEP2_DEFAULT_VALUE,
    resolver: yupResolver(step2Schema),
  });

  const offsetMethods = useForm<OffsetFields>({
    defaultValues: OFFSET_DEFAULT,
    resolver: yupResolver(offsetSchema),
  });

  const Emission = { ...step1Methods.watch(), ...step2Methods.watch() };
  const [, submitOffset] = usePost<undefined, EmissionOffset>(() => {
    resetModal();
  });

  const [, getCategoryListBox] = useGet<
    GetCategoryListBoxResponse,
    GetCategoryListBoxRequest
  >(
    (data) => {
      setCategoryListBox([
        ...data?.map(({ name: label, value, note }) => ({
          label:
            step1Methods.watch('scopeCode') !== '3'
              ? ''
              : joinSafe([label, note], ' : '),
          value,
        })),
      ]);

      if (step1?.categoryCode) {
        step1Methods.setValue('categoryCode', step1?.categoryCode);
      } else {
        if (step1Methods.watch('scopeCode') !== '3') {
          step1Methods.setValue('categoryCode', '00');
        }
      }
    },
    undefined,
    { keepPreviousData: false },
  );

  const { data: lciDBYear, refetch: checkLcidbYear } = useHgbQuery<
    string,
    { lciDBYear: string }
  >(
    API_URLS.CHECK_LCIDB_YEAR_API_URL,
    {
      lciDBYear: step1Methods.watch('targetYearMonth'),
    },
    {
      enabled: false,
      isAlert: false,
      onSuccess: (data) => {
        if (isEmpty(data?.result)) {
          addMessage('error', data?.message ?? '');
          return;
        }
        const targetLciDbYear = data.result ?? '';
        if (isEmpty(scopeEdit)) {
          setParams({ targetLciDbYear });
          step2Methods.reset(STEP2_DEFAULT_VALUE);
          setSelectionInfos([]);
          if (Emission.scopeCode !== '4') {
            requestScope1(
              `api/v1/emission-input/scope-${step1Methods.watch(
                'scopeCode',
              )}/flow${
                step1Methods.watch('categoryCode') !== '00'
                  ? `/category-${Number(step1Methods.watch('categoryCode'))}`
                  : ''
              }`,
              { targetLciDbYear },
            );
          } else {
            setModalStatus((prev) => ({ ...prev, type: 'STEP_2' }));
          }
        } else {
          if (Emission.scopeCode !== '4') {
            let objectParam: Record<string, any> = {};
            scopeEdit?.nextStepSelectionInfos?.forEach((s) => {
              objectParam[s.fieldTarget] =
                s.selectedValue ?? Emission[s.fieldTarget as keyof Step2Fields];
            });
            objectParam.targetLciDbYear = targetLciDbYear;

            if (!isEmpty(params)) {
              objectParam = { ...params, targetLciDbYear };
            }

            if (selectionInfos.length < Object.values(objectParam).length)
              requestScope1(
                `api/v1/emission-input/scope-${step1Methods.watch(
                  'scopeCode',
                )}/flow${
                  step1Methods.watch('categoryCode') !== '00'
                    ? `/category-${Number(step1Methods.watch('categoryCode'))}`
                    : ''
                }`,
                objectParam,
              );
            setModalStatus((prev) => ({ ...prev, type: 'STEP_2' }));
          } else {
            setModalStatus((prev) => ({ ...prev, type: 'STEP_2' }));
          }
        }
      },
      queryKey: [
        API_URLS.CHECK_LCIDB_YEAR_API_URL,
        step1Methods.watch('targetFiscalYear'),
      ],
    },
  );

  useEffect(() => {
    getLastTenYearResult(API_URLS.LAST_TEN_YEAR_API_URL);
    getScopeListBoxResult(API_URLS.SCOPE_LIST_BOX_API_URL);
  }, [language]);

  const [yearResult, getYearResult] = useGet<
    GetFiscalYearMonthListBoxResponse,
    GetFiscalYearMonthListBoxRequest
  >();

  useEffect(() => {
    step1Methods.setValue('targetYearMonth', currentYearMonth());
    const { targetFiscalYear } = step1Methods.watch();
    if (
      step1?.targetYearMonth &&
      step1?.targetFiscalYear === targetFiscalYear
    ) {
      step1Methods.setValue(
        'targetYearMonth',
        step1?.targetYearMonth.split('/').join(''),
      );
    }

    if (
      targetFiscalYear !== step1?.targetFiscalYear &&
      currentYearMonth().indexOf(targetFiscalYear) < 0
    ) {
      step1Methods.setValue(
        'targetYearMonth',
        yearResult?.data?.[0]?.value ?? '',
      );
    }
  }, [yearResult.data]);

  const targetYearMonthOptions = useMemo(() => {
    let years = yearResult.data;
    if (id !== 0) {
      if (
        !years?.find(
          (s) => s.value === step1?.targetYearMonth.split('/').join(''),
        )
      ) {
        years?.push({
          name: step1?.targetYearMonth.split('/').join('-') ?? '',
          value: step1?.targetYearMonth.split('/').join('') ?? '',
        });
      }
    }
    return years;
  }, [yearResult.data]);

  useEffect(() => {
    let targetFiscalYear = step1Methods.watch('targetFiscalYear');
    if (targetFiscalYear) {
      getYearResult(API_URLS.FISCAL_YEAR_MONTH, {
        targetYear: targetFiscalYear,
        isLCIDBYear: false,
      });
    }
  }, [step1Methods.watch('targetFiscalYear'), lastTenYearResult.data]);

  useEffect(() => {
    let scopeCode = step1Methods.watch('scopeCode') ?? Emission.scopeCode;
    if (!isEmpty(step1?.scopeCode)) {
      scopeCode = step1?.scopeCode ?? '';
    }
    if (scopeCode) {
      getCategoryListBox(API_URLS.CATEGORY_LIST_BOX_API_URL, {
        scopeCode,
      });
    }
  }, [step1Methods.watch('scopeCode')]);
  useEffect(() => {
    if (!isEmpty(scopeEdit)) {
      const selects = mapSelectToSelectIndex(
        scopeEdit?.nextStepSelectionInfos ?? [],
      );
      setSelectionInfos(selects);
      setIndex(selects?.[selects?.length - 1]?.index ?? 0);
      step2Methods.setValue('activityName', activityName ?? '');
      if (isEmpty(scopeEdit?.formula)) {
        step2Methods.setValue(
          scopeEdit?.flowResultItem?.fieldTarget === 'emissionVolumeLocation'
            ? 'emissionVolumeLocation'
            : 'emissionVolume',
          scopeEdit.flowResultItem?.value,
        );
      }
    }
    if (!isEmpty(step1)) {
      step1Methods.reset({
        ...step1,
        targetYearMonth: step1?.targetYearMonth.split('/').join(''),
      });
    }
    if (!isEmpty(offset)) {
      offsetMethods.reset({ ...offset });
    }
  }, [step1, scopeEdit, activityName, offset]);

  const flowInputDetails: FlowInputDetail[] = useMemo(() => {
    let objectParam: string[] = [];
    let arrayParam: string[] = ['ST005', 'M00006', 'ML0001'];
    scopeEdit?.nextStepSelectionInfos?.forEach((s) => {
      objectParam.push(s?.selectedValue ?? '');
    });

    const flowInputs: FlowInputDetail[] =
      scope1Result.data?.flowInputDetails ?? [];
    const inputs: InputInfo[] = [];
    scopeEdit?.flowInputDetails
      ?.map((s) => s.inputGroup)
      .map((input) => {
        input?.forEach((k) => {
          inputs?.push(k);
        });
      });
    if (flowInputs) {
      if (!isEmpty(flowInputs[0]?.inputGroup)) {
        flowInputs
          ?.map((s) => s.inputGroup)
          .filter((s) => s)
          .forEach((item1) =>
            item1.forEach((item) => {
              step2Methods.setValue(
                `${item.fieldTarget}Unit` as keyof Step2Fields,
                item.unit,
              );
              const valueflowInputs = inputs?.find(
                (l) => l.fieldTarget === item.fieldTarget,
              )?.value;
              const manualflowInputs = new BigNumber(valueflowInputs);
              step2Methods.setValue(
                item.fieldTarget as keyof Step2Fields,
                !isEmpty(item.value?.toString())
                  ? Number(item.value)
                  : valueflowInputs
                  ? manualflowInputs?.toFixed() ?? ''
                  : valueflowInputs,
              );
            }),
          );
      }
      flowInputs
        ?.map((s) => s.intensity)
        .filter((k) => k)
        .forEach((item) => {
          step2Methods.setValue(
            `${item.fieldTarget}Unit` as keyof Step2Fields,
            item.unit,
          );

          const valueflowInputs = scopeEdit?.flowInputDetails
            ?.map((s) => s.intensity)
            .filter((k) => k)
            .find((item1) => item1.fieldTarget === item.fieldTarget)?.value;

          const manualIdeaInput = new BigNumber(
            scopeEdit?.flowInputDetails
              ?.map((s) => s.intensity)
              .filter((k) => k)
              .find((item1) => item1.fieldTarget === item.fieldTarget)?.value,
          );

          step2Methods.setValue(
            item.fieldTarget as keyof Step2Fields,
            !isEmpty(item.value?.toString()) &&
              !arrayParam.every((item) => objectParam.includes(item))
              ? Number(item.value)
              : valueflowInputs
              ? manualIdeaInput?.toFixed() ?? ''
              : valueflowInputs,
          );
        });
      if (!isEmpty(scopeEdit) && id === 0) {
        if (!isEmpty(flowInputs[0]?.inputGroup)) {
          flowInputs
            ?.map((s) => s.inputGroup)
            .filter((s) => s)
            .forEach((item1) =>
              item1.forEach((item) => {
                if (!item.disabled)
                  step2Methods.setValue(
                    item.fieldTarget as keyof Step2Fields,
                    '',
                  );
              }),
            );
        }
        flowInputs
          ?.map((s) => s.intensity)
          .filter((k) => k)
          .forEach((item) => {
            if (
              !item.disabled &&
              !(
                Emission.calculationMethodCode === 'M00006' &&
                Emission.calculationTargetCode === 'ST005' &&
                (Emission.scope2MethodSelected === 'ML0001' ||
                  Emission.scope2MethodSelected === 'ML0002')
              )
            ) {
              step2Methods.setValue(item.fieldTarget as keyof Step2Fields, '');
            }
          });
      }
    }
    return flowInputs;
  }, [scope1Result.data?.flowInputDetails, selectionInfos, scopeEdit]);

  const flowResultItem: InputInfo | undefined = useMemo(() => {
    const flowItem: InputInfo | undefined =
      scope1Result.data?.flowResultItem ?? undefined;
    if (flowItem) {
      step2Methods.setValue(
        `${flowItem.fieldTarget}Unit` as keyof Step2Fields,
        flowItem.unit,
      );
      step2Methods.setValue(
        `${flowItem.fieldTarget}` as keyof Step2Fields,
        Number(flowItem.value) ?? null,
      );
    }
    return flowItem;
  }, [
    selectionInfos,
    flowInputDetails,
    scope1Result.data?.flowResultItem,
    scopeEdit,
  ]);
  const preValidateInputInfoCondition = (name: keyof Step2Fields) => {
    const checkers: InputInfo[] = [];
    flowInputDetails
      .map((flow) => flow.inputGroup)
      .map((input) => {
        input?.forEach((rs) => {
          if (rs.fieldTarget === name) {
            checkers.push(rs);
          }
        });
      });
    return checkers.length > 0;
  };

  const preValidateIntensityCondition = (name: keyof Step2Fields) => {
    const checkers: InputInfo[] = [];
    flowInputDetails
      .map((flow) => flow.intensity)
      .filter((s) => s)
      .forEach((intensity) => {
        if (intensity.disabled === false && intensity.fieldTarget === name) {
          checkers.push(intensity);
        }
      });
    return checkers.length > 0;
  };

  const validateByRange = (name: string) => {
    let validate: Partial<InputInfo> = {};
    flowInputDetails
      .map((flow) => flow.inputGroup)
      .filter((s) => s)
      .map((input) =>
        input?.forEach((rs) => {
          if (rs.fieldTarget === name) {
            validate = { ...rs };
          }
        }),
      );
    if (validate && validate.disabled === false) {
      return yup
        .number()
        .min(
          validate.constraints?.MIN_VALUE ?? 0,
          validate.fieldTarget + common.message.isRequired,
        )
        .max(
          validate.constraints?.MAX_VALUE ?? 0,
          validate.fieldTarget + common.message.isRequired,
        )
        .required(E0000(C01.label.fuelUsagePerUse))
        .typeError(E0000(C01.label.fuelUsagePerUse));
    }
    return {};
  };

  useEffect(() => {
    selectionInfos.forEach((item) => {
      if (!isEmpty(item?.selectedValue)) {
        if (isEmpty(scopeEdit?.nextStepSelectionInfos)) {
          handleChangeScope1Params(
            item.fieldTarget ?? '',
            item?.selectedValue ?? '',
          );
        } else {
          step2Methods.setValue(
            item.fieldTarget as keyof Step2Fields,
            item.selectedValue ?? '',
          );
          if (isEdit) {
            handleChangeScope1Params(
              item.fieldTarget ?? '',
              item?.selectedValue ?? '',
            );
          }
        }
      }
    });
  }, [selectionInfos]);

  const handleChangeScope1Params = (name: string, value: string) => {
    setIsEdit(true);
    let newParams = Object.assign({});
    newParams = { ...newParams, targetLciDbYear: lciDBYear?.result };
    // prepare new array for selects
    let tmpSelects: SelectionInfo[] = [];
    step2Methods.setValue(name as keyof Step2Fields, value);

    // find index of current changed select
    const indexOfChangeField = selectionInfos.find(
      (item: SelectionInfo) => item.fieldTarget === name,
    )?.index;

    let resetObject: Step2Fields = { ...STEP2_DEFAULT_VALUE };
    tmpSelects = selectionInfos.map((item) => {
      if (!isEmpty(item?.selectedValue)) {
        step2Methods.setValue(
          item.fieldTarget as keyof Step2Fields,
          item?.selectedValue ?? '',
        );
        if (Number(item.index) <= Number(indexOfChangeField))
          resetObject = {
            ...resetObject,
            [item.fieldTarget]: item?.selectedValue,
          };
        // newParams = { ...newParams, [item.fieldTarget]: item?.selectedValue };
        item.selectedValue = null;
      }
      return item;
    });
    // get select has index <= indexOfChangeField
    tmpSelects = tmpSelects.filter((item) => {
      if (Number(item?.index) <= Number(indexOfChangeField)) {
        newParams = {
          ...newParams,
          [item.fieldTarget]:
            name !== item.fieldTarget
              ? Emission[item.fieldTarget as keyof Step2Fields]
              : value,
        };
        return item;
      }
    });

    // render new array select
    setSelectionInfos(tmpSelects);

    // because just cleared item in select array, need to reset index equal current maximum index
    setIndex(Number(indexOfChangeField ?? 0 + 1) ?? 0);

    resetObject = {
      ...resetObject,
      ...newParams,
      activityName: Emission.activityName,
    };

    step2Methods.reset({ ...Emission, ...resetObject });

    requestScope1(
      `api/v1/emission-input/scope-${step1Methods.watch('scopeCode')}/flow${
        step1Methods.watch('categoryCode') !== '00'
          ? `/category-${Number(step1Methods.watch('categoryCode'))}`
          : ''
      }`,
      newParams,
    );

    setParams(newParams);
  };

  useEffect(() => {
    if (
      scope1Result.data?.flowFinished &&
      (scope1Result.data.formula ?? '' !== '')
    ) {
      let emissionsCalculation = { ...Emission };
      const emissionKeys = Object.keys(Emission);
      const keysFromFormula = getFieldsFromFormula(
        scope1Result.data?.formula ?? '',
      );
      emissionKeys.forEach((item) => {
        if (
          keysFromFormula.includes(item) &&
          !isEmpty(Emission[item as keyof Emission])
        ) {
          emissionsCalculation = {
            ...emissionsCalculation,
            [item]: Number(Emission[item as keyof Emission]),
          };
        }
      });
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const {
        activityVolume,
        emissionIntensity,
        gwp,
        fillingVolume,
        recoveryVolume,
        useEmissionCoefficient,
        operatorGraspRate,
        rawActivityVolume,
        rawEmissionIntensity,
        rawTransportActivityVolume,
        rawTransportEmissionIntensity,
        capitalWasteActivityVolume,
        capitalWasteEmissionIntensity,
        transportCargoWeight,
        transportDistance,
        numberOfPeople,
        numberOfNight,
        numberOfDay,
        expectedLifetimeUsagePer,
        saleCount,
        perLessonUsage,
        emissionLifetimeUse,
        distribution,
        fuelEconomyBasic,
        transportLoadRate,
        businessDaysYear,
        expectedLifetimeUsage,
        expectedLifetimeUsagePercentage,
        unclearActivityVolume1,
        unclearActivityVolume2,
        unclearActivityVolume3,
        unclearActivityVolume4,
        unclearActivityVolume5,
        unclearActivityVolume6,
        unclearActivityVolume7,
        unclearActivityVolume8,
        unclearActivityVolume9,
        unclearActivityVolume10,
        unclearActivityVolume11,
        unclearActivityVolume12,
        unclearEmissionIntensity1,
        unclearEmissionIntensity2,
        unclearEmissionIntensity3,
        unclearEmissionIntensity4,
        unclearEmissionIntensity5,
        unclearEmissionIntensity6,
        consumption,
        manualIdeaInput,
        emissionIntensityLocation,
      } = emissionsCalculation;
      /* eslint-disable @typescript-eslint/no-unused-vars */
      try {
        if (!isEmpty(scope1Result.data?.formula)) {
          step2Methods.setValue(
            Emission.emissionIntensityLocation
              ? 'emissionVolumeLocation'
              : 'emissionVolume',
            isNaN(Number(eval(parseFormula(scope1Result.data?.formula))))
              ? ''
              : toBigDecimal(eval(parseFormula(scope1Result.data?.formula)), 3),
          );
        }
      } catch (error) {
        debug.log(error);
      }
    }
  }, [
    scope1Result.data?.flowFinished,
    scope1Result.data?.formula,
    Emission.activityVolume,
    Emission.emissionIntensity,
    Emission.emissionIntensityLocation,
    Emission.gwp,
    Emission.fillingVolume,
    Emission.recoveryVolume,
    Emission.useEmissionCoefficient,
    Emission.operatorGraspRate,
    Emission.rawActivityVolume,
    Emission.rawEmissionIntensity,
    Emission.rawTransportActivityVolume,
    Emission.rawTransportEmissionIntensity,
    Emission.capitalWasteActivityVolume,
    Emission.capitalWasteEmissionIntensity,
    Emission.transportCargoWeight,
    Emission.transportDistance,
    Emission.numberOfPeople,
    Emission.numberOfNight,
    Emission.numberOfDay,
    Emission.expectedLifetimeUsagePer,
    Emission.saleCount,
    Emission.perLessonUsage,
    Emission.emissionLifetimeUse,
    Emission.distribution,
    Emission.fuelEconomyBasic,
    Emission.transportLoadRate,
    Emission.businessDaysYear,
    Emission.expectedLifetimeUsage,
    Emission.expectedLifetimeUsagePercentage,
    Emission.unclearActivityVolume1,
    Emission.unclearActivityVolume2,
    Emission.unclearActivityVolume3,
    Emission.unclearActivityVolume4,
    Emission.unclearActivityVolume5,
    Emission.unclearActivityVolume6,
    Emission.unclearActivityVolume7,
    Emission.unclearActivityVolume8,
    Emission.unclearActivityVolume9,
    Emission.unclearActivityVolume10,
    Emission.unclearActivityVolume11,
    Emission.unclearActivityVolume12,
    Emission.unclearEmissionIntensity1,
    Emission.unclearEmissionIntensity2,
    Emission.unclearEmissionIntensity3,
    Emission.unclearEmissionIntensity4,
    Emission.unclearEmissionIntensity5,
    Emission.unclearEmissionIntensity6,
    Emission.consumption,
    Emission.manualIdeaInput,
    scopeEdit,
  ]);

  const step1SubmitHandler = () => {
    checkLcidbYear();
  };

  const resetObjectBeforeSubmit = (formula?: string) => {
    const fields = getFieldsFromFormula(formula ?? '');
    const keys = Object.keys(Emission);
    let emissions = { ...Emission };
    keys.forEach((item) => {
      if (
        !fields.includes(item) &&
        item !==
          (emissions?.emissionIntensityLocation
            ? 'emissionVolumeLocation'
            : 'emissionVolume') &&
        typeof emissions[item as keyof Emission] === 'number'
      ) {
        emissions = { ...emissions, [item]: null };
        emissions = {
          ...emissions,
          [`${item}Unit` as keyof Emission]: null,
        };
      }
      // if (
      //   item ===
      //   (emissions?.emissionIntensityLocation
      //     ? 'emissionVolumeLocation'
      //     : 'emissionVolume')
      // ) {
      //   const volume = floorNumber(
      //     Number(
      //       (emissions?.emissionIntensityLocation
      //         ? emissions?.emissionVolumeLocation
      //         : emissions?.emissionVolume) ?? '',
      //     ) ?? 0,
      //     3,
      //   );
      //   if (emissions?.emissionIntensityLocation) {
      //     emissions = {
      //       ...emissions,
      //       emissionVolumeLocation: volume.toString(),
      //     };
      //   }
      //   if (!emissions?.emissionIntensityLocation) {
      //     emissions = {
      //       ...emissions,
      //       emissionVolume: volume.toString(),
      //     };
      //   }
      // }
    });
    step2Methods.reset({ ...emissions });
    return emissions;
  };

  // const scopeSubmitAfterConfirm = () => {
  //   const emissions = {
  //     ...resetObjectBeforeSubmit(scope1Result.data?.formula ?? ''),
  //     baseId: step1Methods.watch('baseId'),
  //   };
  //   submitScope1(
  //     `/api/v1/emission-input/scope-${step1Methods.watch('scopeCode')}${
  //       step1Methods.watch('categoryCode') !== '00'
  //         ? `/category-${Number(step1Methods.watch('categoryCode'))}/save`
  //         : ''
  //     }`,
  //     emissions,
  //   );
  // };

  const unitByRange = (intensity: InputInfo) => {
    if (!isEmpty(intensity.constraints)) {
      return `${intensity.constraints.MIN_VALUE}-${intensity.constraints.MAX_VALUE}`;
    }
    return '';
  };

  // const offsetSubmitAfterConfirm = () => {
  //   const offsetData = { ...step1Methods.watch(), ...offsetMethods.watch() };
  //   submitOffset(API_URLS.OFFSET_API_URL, offsetData);
  // };

  const { ConfirmModal, hgbConfirm } = useConfirm();
  const offsetSubmit = async () => {
    setModalStatus((prev) => ({ ...prev, isOpen: false }));
    const result = await hgbConfirm();
    if (result) {
      const offsetData = { ...step1Methods.watch(), ...offsetMethods.watch() };
      submitOffset(API_URLS.OFFSET_API_URL, offsetData);
    }
    setModalStatus((prev) => ({ ...prev, isOpen: true }));
  };
  const scopeSubmit = async () => {
    setModalStatus((prev) => ({ ...prev, isOpen: false }));
    const result = await hgbConfirm();
    if (result) {
      const emissions = {
        ...resetObjectBeforeSubmit(scope1Result.data?.formula ?? ''),
        baseId: step1Methods.watch('baseId'),
      };
      submitScope1(
        `/api/v1/emission-input/scope-${step1Methods.watch('scopeCode')}${
          step1Methods.watch('categoryCode') !== '00'
            ? `/category-${Number(step1Methods.watch('categoryCode'))}/save`
            : ''
        }`,
        emissions,
      );
      return;
    }
    setModalStatus((prev) => ({ ...prev, isOpen: true }));
  };

  useEffect(() => {
    if (isEmpty(flowInputDetails)) {
      step2Methods.setValue(
        flowResultItem?.fieldTarget === 'emissionVolumeLocation'
          ? 'emissionVolumeLocation'
          : 'emissionVolume',
        isEmpty(scope1Result.data?.formula)
          ? id === 0
            ? undefined
            : isEdit === false
            ? scopeEdit?.flowResultItem?.value
            : undefined
          : undefined,
      );
    }
  }, [flowResultItem]);

  const disableOkButton = () => {
    let disabled = false;
    if (step1Methods.watch('scopeCode') !== '4') {
      if (modalStatus.type === 'STEP_1') {
        const step1Tmp = !isEmpty(step1) ? step1 : step1Methods.watch();
        const values = Object.values(step1Tmp);
        values.forEach((s1) => {
          if (isEmpty((s1 ?? '').toString())) {
            disabled = true;
          }
        });
      }

      if (modalStatus.type === 'STEP_2') {
        const keyFormula = getFieldsFromFormula(
          scope1Result.data?.formula ?? '',
        );
        const keyEmission = Object.keys(Emission);
        keyEmission.forEach((key) => {
          if (
            key === 'emissionVolume' &&
            isEmpty(Emission?.emissionIntensityLocation?.toString()) &&
            isEmpty(Emission[key]?.toString())
          ) {
            disabled = true;
          }
          if (
            key === 'emissionVolumeLocation' &&
            Emission.scope2MethodSelected === 'ML0002' &&
            step1Methods.watch('scopeCode') === '2' &&
            isEmpty(Emission[key]?.toString())
          ) {
            disabled = true;
          }
          if (
            keyFormula.includes(key) &&
            isEmpty(Emission[key as keyof Emission]?.toString())
          ) {
            disabled = true;
          }
          if (
            key === 'activityName' &&
            step1Methods.watch('scopeCode') === '3' &&
            isEmpty(Emission[key]?.toString())
          ) {
            disabled = true;
          }
        });
        if (!scope1Result.data?.flowFinished) {
          disabled = true;
        }
      }
    } else {
      if (modalStatus.type === 'STEP_1') {
        const step1Tmp = !isEmpty(step1) ? step1 : step1Methods.watch();
        const { targetFiscalYear, targetYearMonth, scopeCode, baseId } =
          step1Tmp;
        if (
          isEmpty(targetFiscalYear) ||
          isEmpty(targetYearMonth) ||
          isEmpty(scopeCode.toString()) ||
          isEmpty(baseId.toString())
        ) {
          disabled = true;
        }
      }
      if (modalStatus.type === 'STEP_2') {
        const { offsetAmount, offsetCertificateDate } = offsetMethods.watch();
        if (
          isEmpty(offsetAmount?.toString()) ||
          isEmpty(offsetCertificateDate)
        ) {
          disabled = true;
        }
      }
    }

    return disabled;
  };

  const typeOfInput = (unit: string) => {
    const listUnitInteger = ['人', '日', '泊'];
    return listUnitInteger.includes(unit)
      ? 'positiveInteger'
      : 'positiveDecimal';
  };

  const isSearchSelect = useCallback((name: string) => {
    const { scopeCode, categoryCode } = step1Methods.watch();
    const searchList = [
      '燃料の種類',
      'Fuel type',
      '電力会社',
      '電気事業者',
      'Electric company',
      '勤務形態/都市区分',
      'Work style/City division',
      'ガスの種類',
      'Type of gas',
      '種類',
      'Type',
      '計算方法',
      'Capital formation sector',
      '資産形成部門（自社の業種）',
    ];
    if (scopeCode === '1' || scopeCode === '2') {
      return searchList.includes(name);
    } else {
      if (name === '種類' || name === 'Type') return categoryCode === '01';

      return searchList.includes(name);
    }
  }, []);

  return (
    <>
      <HgbAntdButton
        type="primary"
        ghost
        className="tw-w-full pc:tw-w-220"
        onClick={() => {
          setModalStatus((prev) => ({ ...prev, isOpen: true }));
        }}
        endIcon={
          <HgbInfoLink
            to={'/support/manual/' + MANUAL_NAMEDDESTS.about_input_direct}
            target="_blank"
          />
        }
      >
        <>{C01.modal.emissionCreateTitle}</>
      </HgbAntdButton>
      {modalStatus.isOpen && (
        <HgbAntdModal
          maskClosable={false}
          enterForSubmit={false}
          title={C01.modal.emissionCreateTitle}
          onBack={
            modalStatus.type === 'STEP_1'
              ? undefined
              : () => {
                  setModalStatus((prev) => ({ ...prev, type: 'STEP_1' }));
                }
          }
          cancelText={common.button.cancel}
          okText={okText[modalStatus.type]}
          open
          disableOk={disableOkButton()}
          onCancel={resetModalCancel}
          formProps={{
            onSubmit:
              modalStatus.type === 'STEP_1'
                ? step1Methods.handleSubmit(step1SubmitHandler)
                : Emission.scopeCode === '4'
                ? offsetMethods.handleSubmit(offsetSubmit)
                : step2Methods.handleSubmit(scopeSubmit),
          }}
        >
          <div className="tw-grid tw-grid-cols-2 tw-gap-24">
            {/* step 1 */}
            <FormProvider {...step1Methods}>
              {modalStatus.type === 'STEP_1' && (
                <>
                  <HgbSelect
                    {...step1Methods.register('targetFiscalYear')}
                    options={(lastTenYearResult.data ?? [])
                      .map(({ name: label, value }) => ({ label, value }))
                      .filter((word) => Number(word.value) > 2018)}
                    label={C01.label.fiscalYear}
                    placeholder={C01.placeholder.fiscalYear}
                    required
                    defaultActiveFirstOption
                  />
                  <HgbSelect
                    {...step1Methods.register('targetYearMonth')}
                    options={(targetYearMonthOptions ?? [])
                      ?.filter((item) => item.value !== '')
                      .map(({ name: label, value }) => ({
                        label,
                        value,
                      }))}
                    label={C01.label.targetYearMonth}
                    placeholder={C01.placeholder.targetYearMonth}
                    required
                    // defaultActiveFirstOption
                  />
                  <HgbTreeSelect
                    {...step1Methods.register('baseId')}
                    placeholder={C01.placeholder.baseId}
                    label={C01.label.baseId}
                    className="tw-col-span-2"
                    required
                    treeData={convertObjectToTree(
                      (baseQuery?.result ?? []).filter(
                        (item) => !getParentKey(item.hierarchicalPath ?? ''),
                      ) as any,
                      baseQuery?.result as any,
                    )}
                    showSearch
                  />
                  <HgbSelect
                    {...step1Methods.register('scopeCode')}
                    required
                    className="tw-col-span-2"
                    label={C01.label.scopeCode}
                    placeholder={C01.placeholder.scopeCode}
                    options={[
                      ...(scopeListBoxResult.data ?? []).map((item) => ({
                        label: joinSafe([item.name, item.note], ' : '),
                        value: item.value,
                      })),
                      { label: common.label.offset, value: '4' },
                    ]}
                  />

                  {step1Methods.watch('scopeCode') === '3' && (
                    <HgbSelect
                      {...step1Methods.register('categoryCode')}
                      className="tw-col-span-2"
                      options={categoryListBox}
                      label={common.label.category}
                      placeholder={C01.placeholder.categoryCode}
                      required
                    />
                  )}
                </>
              )}
            </FormProvider>
            {/* step 2 */}
            {Emission.scopeCode !== '4' && modalStatus.type === 'STEP_2' && (
              <FormProvider {...step2Methods}>
                <>
                  <div className="tw-col-span-2">
                    <h4 className="tw-mb-8 tw-font-bold">
                      {C01.label.dataEntry}
                    </h4>
                    <small className="tw-text-error-7">
                      {parse(C01.message.dataEntryScopeNotice)}
                    </small>
                  </div>
                  {selectionInfos &&
                    selectionInfos
                      ?.filter((item) => item.data)
                      .map((selectionInfo, index) =>
                        selectionInfo.type === 'LIST_BOX' ? (
                          <HgbSelect
                            key={
                              JSON_TO_KEY(selectionInfo) +
                              index +
                              'selectionInfo_LIST_BOX'
                            }
                            className="tw-col-span-2"
                            options={(selectionInfo.fieldTarget ===
                            'supplyMenuCode'
                              ? selectionInfo.data?.sort((p1, p2) =>
                                  p1.value > p2.value
                                    ? 1
                                    : p1.value < p2.value
                                    ? -1
                                    : 0,
                                )
                              : selectionInfo.data
                            ).map(({ value, name: label }) => ({
                              value,
                              label,
                            }))}
                            required
                            label={selectionInfo.name}
                            defaultValue={
                              Emission[
                                selectionInfo.fieldTarget as keyof Emission
                              ]
                            }
                            name={
                              selectionInfo.fieldTarget as keyof Step2Fields
                            }
                            onChangeValue={handleChangeScope1Params}
                            showSearch={isSearchSelect(selectionInfo.name)}
                          />
                        ) : (
                          <HgbAntdRadioGroup
                            key={
                              JSON_TO_KEY(selectionInfo) +
                              index +
                              'selectionInfo_LIST_BOX'
                            }
                            className="tw-col-span-2"
                            options={selectionInfo.data.map(
                              ({ value, name: label }) => ({ value, label }),
                            )}
                            label={selectionInfo.name}
                            name={selectionInfo.fieldTarget}
                            onChangeValue={(e) =>
                              handleChangeScope1Params(
                                e.target.name ?? '',
                                e.target.value,
                              )
                            }
                            info={
                              selectionInfo.fieldTarget ===
                              'scope2MethodSelected'
                                ? '/support/manual/' +
                                  MANUAL_NAMEDDESTS.about_market_location_base
                                : ''
                            }
                          />
                        ),
                      )}
                  {(flowInputDetails ?? []).map(({ inputGroup, intensity }) => {
                    return (
                      <React.Fragment
                        key={JSON_TO_KEY(inputGroup) + 'flowInputDetails'}
                      >
                        {(inputGroup ?? []).map((input) => {
                          return (
                            <HgbAntdInput
                              key={
                                JSON_TO_KEY(input) +
                                'inputGroup-flowInputDetails'
                              }
                              required
                              className="tw-col-span-2"
                              label={input.name}
                              suffix={(input.unit ?? '').replaceAll('null', '')}
                              subLabel={unitByRange(input).replaceAll(
                                'null',
                                '',
                              )}
                              disabled={input.disabled}
                              type={typeOfInput(input.unit)}
                              minValue={
                                input.constraints?.MIN_VALUE ?? undefined
                              }
                              maxValue={
                                input.constraints?.MAX_VALUE ?? undefined
                              }
                              maxLength={input.maxLength}
                              {...step2Methods.register(
                                input.fieldTarget as keyof Step2Fields,
                              )}
                              decimalLength={
                                input.constraints?.DECIMAL_LENGTH ?? undefined
                              }
                            />
                          );
                        })}
                        {intensity && (
                          <HgbAntdInput
                            required
                            className="tw-col-span-2"
                            label={intensity.name}
                            suffix={(intensity.unit ?? '').replaceAll(
                              'null',
                              '',
                            )}
                            subLabel={unitByRange(intensity).replaceAll(
                              'null',
                              '',
                            )}
                            disabled={intensity.disabled}
                            maxLength={intensity.maxLength}
                            name={intensity.fieldTarget}
                            type={typeOfInput(intensity.unit)}
                            minValue={
                              intensity.constraints?.MIN_VALUE ?? undefined
                            }
                            maxValue={
                              intensity.constraints?.MAX_VALUE ?? undefined
                            }
                            value={step2Methods.watch(
                              intensity.fieldTarget as keyof Step2Fields,
                            )}
                            decimalLength={
                              intensity.constraints?.DECIMAL_LENGTH ?? undefined
                            }
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                  {flowResultItem && (
                    <HgbAntdInput
                      required
                      className="tw-col-span-2"
                      name={flowResultItem.fieldTarget}
                      label={flowResultItem.name}
                      suffix={(flowResultItem.unit ?? '').replaceAll(
                        'null',
                        '',
                      )}
                      subLabel={unitByRange(flowResultItem).replaceAll(
                        'null',
                        '',
                      )}
                      disabled={flowResultItem.disabled}
                      maxLength={flowResultItem.maxLength}
                      type={'positiveDecimal'}
                      minValue={
                        flowResultItem.constraints?.MIN_VALUE ?? undefined
                      }
                      maxValue={
                        flowResultItem.constraints?.MAX_VALUE ?? undefined
                      }
                      decimalLength={
                        flowResultItem.constraints?.DECIMAL_LENGTH ?? undefined
                      }
                    />
                  )}
                  <HgbAntdInput
                    {...step2Methods.register('activityName')}
                    label={
                      step1Methods.watch('scopeCode') === '3'
                        ? C01.label.activityName
                        : C01.label.note
                    }
                    placeholder={C01.placeholder.activityName}
                    className="tw-col-span-2"
                    required={step1Methods.watch('scopeCode') === '3'}
                    maxLength={200}
                  />
                </>
              </FormProvider>
            )}
            {Emission.scopeCode === '4' && modalStatus.type === 'STEP_2' && (
              <FormProvider {...offsetMethods}>
                <>
                  <div className="tw-col-span-2">
                    <h4 className="tw-mb-8 tw-font-bold">
                      {C01.label.dataEntry}
                    </h4>
                    <small className="tw-text-error-7">
                      {parse(C01.message.dataEntryOffsetNotice)}
                    </small>
                  </div>
                  <HgbAntdInput
                    {...offsetMethods.register('offsetAmount')}
                    label={C01.label.offsetAmount}
                    placeholder={C01.placeholder.offsetAmount}
                    className="tw-col-span-2"
                    type="positiveDecimal"
                    maxLength={14}
                    required
                    decimalLength={3}
                    maxValue={9999999999}
                  />
                  <HgbSelect
                    {...offsetMethods.register('offsetAmountUnit')}
                    className="tw-col-span-2"
                    required
                    defaultActiveFirstOption
                    options={[
                      { label: 'tCO2', value: 'tCO2' },
                      { label: 'kgCO2', value: 'kgCO2' },
                    ]}
                    label={C01.label.offsetAmountUnit}
                    placeholder={C01.placeholder.offsetAmountUnit}
                  />
                  <HgbDatePicker
                    {...offsetMethods.register('offsetCertificateDate')}
                    className="tw-col-span-2"
                    required
                    format="YYYY/MM/DD"
                    picker="date"
                    label={C01.label.offsetCertificateDate}
                    placeholder={C01.placeholder.offsetCertificateDate}
                  />
                  <HgbAntdInput
                    {...offsetMethods.register('offsetTarget')}
                    className="tw-col-span-2"
                    maxLength={255}
                    label={C01.label.offsetTarget + common.label.optional}
                    placeholder={C01.placeholder.offsetTarget}
                  />
                  <HgbAntdInput
                    {...offsetMethods.register('projectName')}
                    className="tw-col-span-2"
                    maxLength={255}
                    label={C01.label.projectName + common.label.optional}
                    placeholder={C01.placeholder.projectName}
                  />
                  <HgbAntdInput
                    {...offsetMethods.register('creditType')}
                    className="tw-col-span-2"
                    maxLength={255}
                    label={C01.label.creditType + common.label.optional}
                    placeholder={C01.placeholder.creditType}
                  />
                </>
              </FormProvider>
            )}
          </div>
        </HgbAntdModal>
      )}

      <ConfirmModal
        okText={confirmModalText.ok}
        cancelText={confirmModalText.cancel}
        title={confirmModalText.title}
      >
        <div>{confirmModalText.content}</div>
      </ConfirmModal>
      {/* <HgbAntdModal
        preventSubmitTime={500}
        open={confirmModalStatus}
        okText={confirmModalText.ok}
        cancelText={confirmModalText.cancel}
        title={confirmModalText.title}
        formProps={{
          onSubmit:
            Emission.scopeCode === '4'
              ? offsetSubmitAfterConfirm
              : scopeSubmitAfterConfirm,
        }}
        onCancel={() => {
          setConfirmModalStatus(false);
        }}
      >
        <div>{confirmModalText.content}</div>
      </HgbAntdModal> */}
    </>
  );
};
