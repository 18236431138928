import { isMobile } from '@hooks/useMobile';
import { cn } from '@utils/cn';
import { Tooltip, TooltipProps } from 'antd';
import * as React from 'react';
import { v4 } from 'uuid';

export type HgbCellTooltipProps = {
  count?: number;
  row?: number;
} & TooltipProps;
export const HgbCellTooltip: React.FC<HgbCellTooltipProps> = ({
  children,
  title,
  count = 24,
  row = 1,
  ...props
}) => {
  const difRef = React.useRef<HTMLDivElement>(null);
  const [isToolTip, setIsTooltip] = React.useState(false);

  React.useEffect(() => {
    setIsTooltip((difRef.current?.clientHeight ?? 0) > count);
  }, [children]);

  return (
    <>
      <div
        ref={difRef}
        className="tw-absolute tw-left-16 tw-right-16 -tw-z-10 tw-overflow-hidden tw-break-all tw-text-inherit tw-opacity-0"
      >
        {children}
      </div>
      {isToolTip || title ? (
        isMobile() ? (
          <div
            className={cn(
              `tw-line-clamp-1 tw-cursor-pointer tw-overflow-hidden tw-break-all tw-text-inherit`,
              {
                'tw-line-clamp-2': row === 2,
              },
            )}
          >
            {children}
          </div>
        ) : (
          <Tooltip
            id={v4()}
            placement="topLeft"
            trigger="hover"
            color="#fff"
            title={title ? title : children}
            overlayInnerStyle={{
              color: '#3F494F',
            }}
            {...props}
          >
            <div
              className={cn(
                `tw-line-clamp-1 tw-cursor-pointer tw-overflow-hidden tw-break-all tw-text-inherit`,
                {
                  'tw-line-clamp-2': row === 2,
                },
              )}
            >
              {children}
            </div>
          </Tooltip>
        )
      ) : (
        <div className="tw-text-left tw-text-inherit">{children}</div>
      )}
    </>
  );
};
