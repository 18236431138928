import { createLinkButtonClass } from '@common/atoms/button';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { ManagementTemplate } from '@layouts/templates';
import React, { FC, useContext } from 'react';

export const F04: FC = () => {
  const {
    text: { F04 },
  } = React.useContext(LanguageContext)!;
  const { user } = useContext(AuthContext)!;
  const title = user.isPresidingBank ? F04.label.higoBank : user.departmentName;
  return (
    <ManagementTemplate title={title} breadcrumb={false}>
      <div className="tw-flex tw-h-full tw-items-start tw-justify-center tw-rounded-16 tw-bg-white">
        <div className="tw-flex tw-flex-col tw-gap-48 tw-pl-20 tw-pr-20">
          <h1 className="tw-mt-60 tw-text-center tw-font-bold">{F04.title}</h1>
          <p>{F04.description}</p>
          <p>
            <a
              className={createLinkButtonClass()}
              href={
                'https://higobank.syd1.qualtrics.com/jfe/form/SV_8jZQZTpYk2HLu7Q'
              }
              target="_blank"
            >
              {F04.linkContent}
            </a>
            {F04.linkDescription}
          </p>
        </div>
      </div>
    </ManagementTemplate>
  );
};
