import { HgbInlineLogo } from '@common/logos';
import { DivProps } from '@common/types';
import { COLORS } from '@constants/colors';
import * as React from 'react';

export const TemporaryTemplate: React.FunctionComponent<
  DivProps & { title?: string }
> = ({ className = '', title = '', children, ...props }) => {
  return (
    <div
      className={`tw-grid tw-min-h-screen tw-min-w-[375px] tw-grid-rows-[auto_1fr] file:${className}`}
      {...props}
      style={{backgroundColor: COLORS.mainBackground}}
    >
      <section className="tw-grid tw-grid-rows-[auto_1fr] tw-py-24">
        <HgbInlineLogo className="tw-mx-auto tw-mb-12 tw-h-64" />
        <h1 className="tw-m-auto tw-pb-16 tw-text-center tw-font-bold pc:tw-pb-24">
          {title}
        </h1>
        <div>{children}</div>
      </section>
    </div>
  );
};
