import { PlusOutlined } from '@ant-design/icons';
import {
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '@common/atoms/button';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import { MANUAL_NAMEDDESTS } from '@pages/Management/Support';
export const ButtonDemo = () => {
  return (
    <div className="tw-p-16">
      <div className="tw-space-y-16 tw-border tw-border-solid tw-p-16">
        <PrimaryButton leftIcon={<PlusOutlined />}>
          Primary Button
        </PrimaryButton>
        <PrimaryButton disabled leftIcon={<PlusOutlined />}>
          Primary Button
        </PrimaryButton>
        <PrimaryButton loading leftIcon={<PlusOutlined />}>
          Primary Button
        </PrimaryButton>
        <PrimaryButton
          endIcon={
            <HgbInfoLink
              to={'/support/manual/' + MANUAL_NAMEDDESTS.about_upload_file}
              target="_blank"
            />
          }
        >
          Primary Button
        </PrimaryButton>

        <SecondaryButton leftIcon={<PlusOutlined />}>
          Secondary Button
        </SecondaryButton>

        <TextButton>Text Button</TextButton>

        <LinkButton to={''}>Link Button</LinkButton>
      </div>
    </div>
  );
};
