import { Size, SvgProps } from '@common/types';
import { cn } from '@utils/cn';
import { FC, ReactNode } from 'react';

export const HgbModeOutlinedIcon: FC<SvgProps> = ({
  className = '',
  size = '20',
  ...props
}) => {
  const Icons: Record<Size, ReactNode> = {
    '16': (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M9.3726 6.01333L9.98593 6.62667L3.94593 12.6667H3.3326V12.0533L9.3726 6.01333ZM11.7726 2C11.6059 2 11.4326 2.06667 11.3059 2.19333L10.0859 3.41333L12.5859 5.91333L13.8059 4.69333C14.0659 4.43333 14.0659 4.01333 13.8059 3.75333L12.2459 2.19333C12.1126 2.06 11.9459 2 11.7726 2ZM9.3726 4.12667L1.99927 11.5V14H4.49927L11.8726 6.62667L9.3726 4.12667Z"
          fill="currentColor"
        />
      </svg>
    ),
    '20': (
      <svg
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
    '24': (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M9.3726 6.01333L9.98593 6.62667L3.94593 12.6667H3.3326V12.0533L9.3726 6.01333ZM11.7726 2C11.6059 2 11.4326 2.06667 11.3059 2.19333L10.0859 3.41333L12.5859 5.91333L13.8059 4.69333C14.0659 4.43333 14.0659 4.01333 13.8059 3.75333L12.2459 2.19333C12.1126 2.06 11.9459 2 11.7726 2ZM9.3726 4.12667L1.99927 11.5V14H4.49927L11.8726 6.62667L9.3726 4.12667Z"
          fill="currentColor"
        />
      </svg>
    ),
  };
  return <>{Icons[size]}</>;
};
