import { RefObject, useEffect, useState } from 'react';
const evalObject = {
  greatThan: '>=',
};
export const useMatchWidth = (
  exp: keyof typeof evalObject,
  initialWidth: number,
  ref?: RefObject<HTMLElement>,
): boolean => {
  const getResult = (): boolean => {
    let w = ref?.current?.clientWidth || window.innerWidth;
    let rs = true;
    eval(`rs= w ${evalObject[exp as 'greatThan']} initialWidth`);
    return rs;
  };
  const [isMatch, setIsMatch] = useState(true);
  let timeoutId: any = null;
  const resizeHandler = () => {
    timeoutId = setTimeout(() => {
      setIsMatch(getResult());
      clearTimeout(timeoutId);
    }, 100);
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return isMatch;
};
