import {
    CellTooltip,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    ModalTitle,
    Paragraph,
    TinyText,
} from '@common/atoms/typography';

export const TypographyDemo = () => {
  return (
    <div className="tw-p-16">
      <div className="tw-space-y-16 tw-border tw-border-solid tw-p-16 ">
        <Heading1>Typography</Heading1>
        <div className="tw-grid tw-grid-cols-[auto_1fr] tw-items-baseline tw-gap-16 tw-bg-primary-0 tw-p-16">
          <Paragraph>ModalTitle</Paragraph>
          <ModalTitle>
            世だかこぜ出辛ゃイ記金やごド民受ルノキユ目18治83政ケラ
          </ModalTitle>
          <Paragraph>Heading1</Paragraph>
          <Heading1>
            世だかこぜ出辛ゃイ記金やごド民受ルノキユ目18治83政ケラ
          </Heading1>
          <Paragraph>Heading2</Paragraph>
          <Heading2>
            世だかこぜ出辛ゃイ記金やごド民受ルノキユ目18治83政ケラ
          </Heading2>
          <Paragraph>Heading3</Paragraph>
          <Heading3>
            世だかこぜ出辛ゃイ記金やごド民受ルノキユ目18治83政ケラ
          </Heading3>
          <Paragraph>Heading4</Paragraph>
          <Heading4>
            世だかこぜ出辛ゃイ記金やごド民受ルノキユ目18治83政ケラ
          </Heading4>
          <Paragraph>Paragraph</Paragraph>
          <Paragraph>
            世だかこぜ出辛ゃイ記金やごド民受ルノキユ目18治83政ケラ
          </Paragraph>
          <Paragraph>TinyText</Paragraph>
          <TinyText>
            世だかこぜ出辛ゃイ記金やごド民受ルノキユ目18治83政ケラ
          </TinyText>
          <Paragraph>CellTooltip</Paragraph>
          <CellTooltip>
            世だかこぜ出辛ゃイ記金やごド民受ルノキユ目18治83政ケラ
          </CellTooltip>
        </div>
      </div>
    </div>
  );
};
