import {
  CreateBaseRequest,
  GetBaseOrganizationRequest,
  UpdateBaseRequest,
} from './base';

export const createBaseRequestDefault: CreateBaseRequest = {
  baseName: '',
  classification: '',
  parentOrganizationId: -1,
  parentBaseId: -1,
  displayOrder: '',
};
export const updateBaseRequestDefault: UpdateBaseRequest = {
  id: -1,
  baseName: '',
  classification: '',
  parentOrganizationId: -1,
  parentBaseId: -1,
  displayOrder: '',
};
export const getBaseOrganizationRequestDefault: GetBaseOrganizationRequest = {
  enterpriseId: '',
  baseName: '',
  classification: '',
  bankId: '',
};
