import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import { LanguageContext } from '@contexts/LanguageContext';
import { cn } from '@utils/cn';
import { parseFullWidthToHalfWidth } from '@utils/text';
import type { TreeSelectProps as AntdTreeSelectProps } from 'antd';
import { TreeSelect } from 'antd';
import { forwardRef, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';
import styles from './HgbTreeSelect.module.scss';

export type TreeSelectProps = {
  title: string;
  value: string;
};
export type HgbTreeSelectOption = TreeSelectProps & {
  children: TreeSelectProps[];
};

interface HgbTreeSelectProps extends AntdTreeSelectProps {
  name: string;
  label?: string;
  treeData: HgbTreeSelectOption[];
  subLabel?: string;
  required?: boolean;
  defaultActiveFirstOption?: boolean;
  onChangeValue?: (name: string, value: string) => void;
  autoInitValue?: boolean;
}

export const HgbTreeSelect = forwardRef<any, HgbTreeSelectProps>(
  (
    {
      name,
      label,
      treeData,
      subLabel = '',
      required,
      value: bien,
      status,
      className,
      onChange,
      onChangeValue,
      autoInitValue,
      defaultActiveFirstOption,
      allowClear = false,
      ...props
    },
    ref,
  ) => {
    const {
      setValue,
      watch,
      formState: { errors },
      clearErrors,
    } = useFormContext();
    const value = watch(name);
    const selectId = v4();
    const error = errors[name];
    const {
      text: {
        common: { message },
      },
    } = useContext(LanguageContext)!;

    useEffect(() => {
      if (autoInitValue && treeData.length > 0) {
        setValue(name, treeData[0].value?.toString());
      }
    }, [treeData, autoInitValue]);

    useEffect(() => {
      if (defaultActiveFirstOption && treeData.length > 0 && !value) {
        setValue(name, treeData[0].value?.toString());
      }
    }, [treeData, defaultActiveFirstOption, value]);
    return (
      <div className={cn(`${styles.container} ${className}`)}>
        {label && (
          <label
            htmlFor={selectId}
            className="tw-mb-4 tw-block tw-select-none tw-text-paragraph tw-font-bold"
          >
            <span className="tw-mr-4 tw-text-paragraph tw-font-bold">
              {label}
            </span>
            {required && (
              <span className="tw-text-paragraph tw-font-bold tw-text-error-7">
                *
              </span>
            )}
            {subLabel && (
              <span className="tw-ml-4 tw-text-error-7">{subLabel}</span>
            )}
          </label>
        )}
        <TreeSelect
          notFoundContent={<HgbAntdEmpty description={message.noData} />}
          size="large"
          id={selectId}
          onClick={() => {
            clearErrors([name]);
          }}
          className="tw-w-full [&>.ant-select-selection-item]:!tw-leading-[38px]"
          status={error ? 'error' : status}
          treeData={treeData}
          // value={(value ?? '').toString()}
          value={
            value === '' || treeData.length === 0
              ? undefined
              : value?.toString()
          }
          ref={ref}
          allowClear={allowClear}
          treeDefaultExpandAll
          filterTreeNode={(input, option) => {
            return parseFullWidthToHalfWidth(
              (option?.title ?? '').toString().toLocaleLowerCase(),
            ).includes(
              parseFullWidthToHalfWidth(input?.trim().toLocaleLowerCase()),
            );
          }}
          onChange={(value) => {
            setValue(name, value);
            onChangeValue?.(name, value);
          }}
          {...props}
        />
        {error && <HgbFieldError>{error.message?.toString()}</HgbFieldError>}
      </div>
    );
  },
);
