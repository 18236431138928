import { Size, SvgProps } from '@common/types';
import { cn } from '@utils/cn';
import { FC, ReactNode } from 'react';

export const HgbMaintainIcon: FC<SvgProps> = ({
  size = '16',
  className,
  ...props
}) => {
  const Icons: Record<Size, ReactNode> = {
    '16': (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M0.666748 14.8333H15.3334L8.00008 2.16663L0.666748 14.8333ZM8.66675 12.8333H7.33341V11.5H8.66675V12.8333ZM8.66675 10.1666H7.33341V7.49996H8.66675V10.1666Z"
          fill="#F59E0B"
        />
      </svg>
    ),
    '20': (
      <svg
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
    '24': (
      <svg
        width={36}
        height={36}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M0.666748 14.8333H15.3334L8.00008 2.16663L0.666748 14.8333ZM8.66675 12.8333H7.33341V11.5H8.66675V12.8333ZM8.66675 10.1666H7.33341V7.49996H8.66675V10.1666Z"
          fill="#F59E0B"
        />
      </svg>
    ),
  };
  return <>{Icons[size]}</>;
};
