export const codeMajors = [
  {
    sicCode: 'A',
    sicName: '農業，林業',
  },
  {
    sicCode: 'B',
    sicName: '漁業',
  },
  {
    sicCode: 'C',
    sicName: '鉱業，採石業，砂利採取業',
  },
  {
    sicCode: 'D',
    sicName: '建設業',
  },
  {
    sicCode: 'E',
    sicName: '製造業',
  },
  {
    sicCode: 'F',
    sicName: '電気・ガス・熱供給・水道業',
  },
  {
    sicCode: 'G',
    sicName: '情報通信業',
  },
  {
    sicCode: 'H',
    sicName: '運輸業，郵便業',
  },
  {
    sicCode: 'I',
    sicName: '卸売業，小売業',
  },
  {
    sicCode: 'J',
    sicName: '金融業，保険業',
  },
  {
    sicCode: 'K',
    sicName: '不動産業，物品賃貸業',
  },
  {
    sicCode: 'L',
    sicName: '学術研究，専門・技術サービス業',
  },
  {
    sicCode: 'M',
    sicName: '宿泊業，飲食サービス業',
  },
  {
    sicCode: 'N',
    sicName: '生活関連サービス業，娯楽業',
  },
  {
    sicCode: 'O',
    sicName: '教育，学習支援業',
  },
  {
    sicCode: 'P',
    sicName: '医療，福祉',
  },
  {
    sicCode: 'Q',
    sicName: '複合サービス事業',
  },
  {
    sicCode: 'R',
    sicName: 'サービス業（他に分類されないもの）',
  },
  {
    sicCode: 'S',
    sicName: '公務（他に分類されるものを除く）',
  },
  {
    sicCode: 'T',
    sicName: '分類不能の産業',
  },
];
export const codeMediums = [
  {
    simcCode: '01',
    sicCode: 'A',
    simcName: '農業',
  },
  {
    simcCode: '02',
    sicCode: 'A',
    simcName: '林業',
  },
  {
    simcCode: '03',
    sicCode: 'B',
    simcName: '漁業（水産養殖業を除く）',
  },
  {
    simcCode: '04',
    sicCode: 'B',
    simcName: '水産養殖業',
  },
  {
    simcCode: '05',
    sicCode: 'C',
    simcName: '鉱業，採石業，砂利採取業',
  },
  {
    simcCode: '06',
    sicCode: 'D',
    simcName: '総合工事業',
  },
  {
    simcCode: '07',
    sicCode: 'D',
    simcName: '職別工事業(設備工事業を除く)',
  },
  {
    simcCode: '08',
    sicCode: 'D',
    simcName: '設備工事業',
  },
  {
    simcCode: '09',
    sicCode: 'E',
    simcName: '食料品製造業',
  },
  {
    simcCode: '10',
    sicCode: 'E',
    simcName: '飲料・たばこ・飼料製造業',
  },
  {
    simcCode: '11',
    sicCode: 'E',
    simcName: '繊維工業',
  },
  {
    simcCode: '12',
    sicCode: 'E',
    simcName: '木材・木製品製造業（家具を除く）',
  },
  {
    simcCode: '13',
    sicCode: 'E',
    simcName: '家具・装備品製造業',
  },
  {
    simcCode: '14',
    sicCode: 'E',
    simcName: 'パルプ・紙・紙加工品製造業',
  },
  {
    simcCode: '15',
    sicCode: 'E',
    simcName: '印刷・同関連業',
  },
  {
    simcCode: '16',
    sicCode: 'E',
    simcName: '化学工業',
  },
  {
    simcCode: '17',
    sicCode: 'E',
    simcName: '石油製品・石炭製品製造業',
  },
  {
    simcCode: '18',
    sicCode: 'E',
    simcName: 'プラスチック製品製造業（別掲を除く）',
  },
  {
    simcCode: '19',
    sicCode: 'E',
    simcName: 'ゴム製品製造業',
  },
  {
    simcCode: '20',
    sicCode: 'E',
    simcName: 'なめし革・同製品・毛皮製造業',
  },
  {
    simcCode: '21',
    sicCode: 'E',
    simcName: '窯業・土石製品製造業',
  },
  {
    simcCode: '22',
    sicCode: 'E',
    simcName: '鉄鋼業',
  },
  {
    simcCode: '23',
    sicCode: 'E',
    simcName: '非鉄金属製造業',
  },
  {
    simcCode: '24',
    sicCode: 'E',
    simcName: '金属製品製造業',
  },
  {
    simcCode: '25',
    sicCode: 'E',
    simcName: 'はん用機械器具製造業',
  },
  {
    simcCode: '26',
    sicCode: 'E',
    simcName: '生産用機械器具製造業',
  },
  {
    simcCode: '27',
    sicCode: 'E',
    simcName: '業務用機械器具製造業',
  },
  {
    simcCode: '28',
    sicCode: 'E',
    simcName: '電子部品・デバイス・電子回路製造業',
  },
  {
    simcCode: '29',
    sicCode: 'E',
    simcName: '電気機械器具製造業',
  },
  {
    simcCode: '30',
    sicCode: 'E',
    simcName: '情報通信機械器具製造業',
  },
  {
    simcCode: '31',
    sicCode: 'E',
    simcName: '輸送用機械器具製造業',
  },
  {
    simcCode: '32',
    sicCode: 'E',
    simcName: 'その他の製造業',
  },
  {
    simcCode: '33',
    sicCode: 'F',
    simcName: '電気業',
  },
  {
    simcCode: '34',
    sicCode: 'F',
    simcName: 'ガス業',
  },
  {
    simcCode: '35',
    sicCode: 'F',
    simcName: '熱供給業',
  },
  {
    simcCode: '36',
    sicCode: 'F',
    simcName: '水道業',
  },
  {
    simcCode: '37',
    sicCode: 'G',
    simcName: '通信業',
  },
  {
    simcCode: '38',
    sicCode: 'G',
    simcName: '放送業',
  },
  {
    simcCode: '39',
    sicCode: 'G',
    simcName: '情報サービス業',
  },
  {
    simcCode: '40',
    sicCode: 'G',
    simcName: 'インターネット附随サービス業',
  },
  {
    simcCode: '41',
    sicCode: 'G',
    simcName: '映像・音声・文字情報制作業',
  },
  {
    simcCode: '42',
    sicCode: 'H',
    simcName: '鉄道業',
  },
  {
    simcCode: '43',
    sicCode: 'H',
    simcName: '道路旅客運送業',
  },
  {
    simcCode: '44',
    sicCode: 'H',
    simcName: '道路貨物運送業',
  },
  {
    simcCode: '45',
    sicCode: 'H',
    simcName: '水運業',
  },
  {
    simcCode: '46',
    sicCode: 'H',
    simcName: '航空運輸業',
  },
  {
    simcCode: '47',
    sicCode: 'H',
    simcName: '倉庫業',
  },
  {
    simcCode: '48',
    sicCode: 'H',
    simcName: '運輸に附帯するサービス業',
  },
  {
    simcCode: '49',
    sicCode: 'H',
    simcName: '郵便業（信書便事業を含む）',
  },
  {
    simcCode: '50',
    sicCode: 'I',
    simcName: '各種商品卸売業',
  },
  {
    simcCode: '51',
    sicCode: 'I',
    simcName: '繊維・衣服等卸売業',
  },
  {
    simcCode: '52',
    sicCode: 'I',
    simcName: '飲食料品卸売業',
  },
  {
    simcCode: '53',
    sicCode: 'I',
    simcName: '建築材料，鉱物・金属材料等卸売業',
  },
  {
    simcCode: '54',
    sicCode: 'I',
    simcName: '機械器具卸売業',
  },
  {
    simcCode: '55',
    sicCode: 'I',
    simcName: 'その他の卸売業',
  },
  {
    simcCode: '56',
    sicCode: 'I',
    simcName: '各種商品小売業',
  },
  {
    simcCode: '57',
    sicCode: 'I',
    simcName: '織物・衣服・身の回り品小売業',
  },
  {
    simcCode: '58',
    sicCode: 'I',
    simcName: '飲食料品小売業',
  },
  {
    simcCode: '59',
    sicCode: 'I',
    simcName: '機械器具小売業',
  },
  {
    simcCode: '60',
    sicCode: 'I',
    simcName: 'その他の小売業',
  },
  {
    simcCode: '61',
    sicCode: 'I',
    simcName: '無店舗小売業',
  },
  {
    simcCode: '62',
    sicCode: 'J',
    simcName: '銀行業',
  },
  {
    simcCode: '63',
    sicCode: 'J',
    simcName: '協同組織金融業',
  },
  {
    simcCode: '64',
    sicCode: 'J',
    simcName: '貸金業，クレジットカード業等非預金信用機関',
  },
  {
    simcCode: '65',
    sicCode: 'J',
    simcName: '金融商品取引業，商品先物取引業',
  },
  {
    simcCode: '66',
    sicCode: 'J',
    simcName: '補助的金融業等',
  },
  {
    simcCode: '67',
    sicCode: 'J',
    simcName: '保険業（保険媒介代理業，保険サービス業を含む）',
  },
  {
    simcCode: '68',
    sicCode: 'K',
    simcName: '不動産取引業',
  },
  {
    simcCode: '69',
    sicCode: 'K',
    simcName: '不動産賃貸業・管理業',
  },
  {
    simcCode: '70',
    sicCode: 'K',
    simcName: '物品賃貸業',
  },
  {
    simcCode: '71',
    sicCode: 'L',
    simcName: '学術・開発研究機関',
  },
  {
    simcCode: '72',
    sicCode: 'L',
    simcName: '専門サービス業（他に分類されないもの）',
  },
  {
    simcCode: '73',
    sicCode: 'L',
    simcName: '広告業',
  },
  {
    simcCode: '74',
    sicCode: 'L',
    simcName: '技術サービス業（他に分類されないもの）',
  },
  {
    simcCode: '75',
    sicCode: 'M',
    simcName: '宿泊業',
  },
  {
    simcCode: '76',
    sicCode: 'M',
    simcName: '飲食店',
  },
  {
    simcCode: '77',
    sicCode: 'M',
    simcName: '持ち帰り・配達飲食サービス業',
  },
  {
    simcCode: '78',
    sicCode: 'N',
    simcName: '洗濯・理容・美容・浴場業',
  },
  {
    simcCode: '79',
    sicCode: 'N',
    simcName: 'その他の生活関連サービス業',
  },
  {
    simcCode: '80',
    sicCode: 'N',
    simcName: '娯楽業',
  },
  {
    simcCode: '81',
    sicCode: 'O',
    simcName: '学校教育',
  },
  {
    simcCode: '82',
    sicCode: 'O',
    simcName: 'その他の教育，学習支援業',
  },
  {
    simcCode: '83',
    sicCode: 'P',
    simcName: '医療業',
  },
  {
    simcCode: '84',
    sicCode: 'P',
    simcName: '保健衛生',
  },
  {
    simcCode: '85',
    sicCode: 'P',
    simcName: '社会保険・社会福祉・介護事業',
  },
  {
    simcCode: '86',
    sicCode: 'Q',
    simcName: '郵便局',
  },
  {
    simcCode: '87',
    sicCode: 'Q',
    simcName: '協同組合（他に分類されないもの）',
  },
  {
    simcCode: '88',
    sicCode: 'R',
    simcName: '廃棄物処理業',
  },
  {
    simcCode: '89',
    sicCode: 'R',
    simcName: '自動車整備業',
  },
  {
    simcCode: '90',
    sicCode: 'R',
    simcName: '機械等修理業（別掲を除く）',
  },
  {
    simcCode: '91',
    sicCode: 'R',
    simcName: '職業紹介・労働者派遣業',
  },
  {
    simcCode: '92',
    sicCode: 'R',
    simcName: 'その他の事業サービス業',
  },
  {
    simcCode: '93',
    sicCode: 'R',
    simcName: '政治・経済・文化団体',
  },
  {
    simcCode: '94',
    sicCode: 'R',
    simcName: '宗教',
  },
  {
    simcCode: '95',
    sicCode: 'R',
    simcName: 'その他のサービス業',
  },
  {
    simcCode: '96',
    sicCode: 'R',
    simcName: '外国公務',
  },
  {
    simcCode: '97',
    sicCode: 'S',
    simcName: '国家公務',
  },
  {
    simcCode: '98',
    sicCode: 'S',
    simcName: '地方公務',
  },
  {
    simcCode: '99',
    sicCode: 'T',
    simcName: '分類不能の産業',
  },
];
