import { cn } from '@utils/cn';
import * as React from 'react';

const HgbAntdModalFormFooter: React.FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <div
      className={cn('tw-flex tw-gap-24 tw-py-24 tw-px-32 tw-justify-center', className)}
      {...props}
    />
  );
};

export { HgbAntdModalFormFooter };

