import { cn } from '@utils/cn';
import * as React from 'react';

const HgbAntdModalFormBody: React.FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <div
      className={cn(
        'tw-flex tw-flex-auto tw-flex-col tw-gap-y-16 tw-overflow-y-scroll tw-px-24',
        className,
      )}
      {...props}
    />
  );
};

export { HgbAntdModalFormBody };

