import { cn } from '@utils/cn';
import React, { ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export interface HgbNavLinkProps extends NavLinkProps {
  startIcon?: ReactNode;
  children: ReactNode;
  endIcon?: ReactNode;
}
export const HgbNavLink: React.FC<HgbNavLinkProps> = ({
  className = '',
  children,
  startIcon,
  endIcon,
  ...props
}) => (
  <NavLink
    tabIndex={-1}
    {...props}
    className={({ isActive }) =>
      cn(
        `tw-flex tw-shrink-0 tw-items-center tw-gap-8 tw-px-16 tw-py-8 tw-text-paragraph tw-no-underline tw-duration-300 hover:tw-text-primary-3`,
        {
          'tw-font-bold tw-text-primary-5': isActive,
        },
        className,
      )
    }
  >
    {startIcon && (
      <div className="tw-flex tw-h-20 tw-w-20 tw-shrink-0 tw-items-center tw-text-inherit">
        {startIcon}
      </div>
    )}
    <div className="tw-flex-auto tw-text-inherit">{children}</div>
    {endIcon && (
      <div className=" tw-flex tw-h-20 tw-w-20 tw-shrink-0 tw-items-center tw-text-inherit">
        {endIcon}
      </div>
    )}
  </NavLink>
);
