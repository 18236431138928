import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbAntdInput } from '@common/forms';
import { LanguageContext } from '@contexts/LanguageContext';
import { BooleanState } from '@hooks/useBooleanState';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SupplyOwner } from './D15';

interface ViewSupplyOwnerModalProps {
  modalState: BooleanState;
  data?: SupplyOwner;
}

const ViewSupplyOwnerModal: React.FunctionComponent<
  ViewSupplyOwnerModalProps
> = ({ modalState, data }) => {
  const {
    text: { common, D15 },
  } = React.useContext(LanguageContext)!;
  const viewSupplyOwnerMethods = useForm<SupplyOwner>({ values: data });
  return (
    <>
      <FormProvider {...viewSupplyOwnerMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={<HgbAntdModalTitle>{D15.titleViewModal}</HgbAntdModalTitle>}
          open={modalState.value}
          onCancel={modalState.turnOff}
          destroyOnClose={true}
          footer={null}
          closable={true}
          maskClosable={false}
        >
          <HgbAntdModalForm>
            <HgbAntdModalFormBody>
              <HgbAntdInput
                {...viewSupplyOwnerMethods.register('supplyChainName')}
                readOnly
                required
                label={common.columns.supplyChainGroupName}
              />
              <HgbAntdInput
                {...viewSupplyOwnerMethods.register('supplyChainDescription')}
                type='area'
                readOnly
                label={D15.columns.note}
                rows={2}
              />
              <div className="tw-space-y-4">
                <label className="tw-text-paragraph tw-font-bold">
                  {D15.label.inviteCode}
                </label>
                <p>{viewSupplyOwnerMethods.getValues('inviteCode')}</p>
              </div>
            </HgbAntdModalFormBody>
            <HgbAntdModalFormFooter>
              <HgbAntdButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
                htmlType="button"
              >
                {common.button.cancel}
              </HgbAntdButton>
            </HgbAntdModalFormFooter>
          </HgbAntdModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default ViewSupplyOwnerModal;
