import { QuestionCircleFilled } from '@ant-design/icons';
import * as React from 'react';
import { Tooltip, TooltipProps } from 'antd';

// interface IHgbInfoTootipProps extends TooltipProps {}

const HgbInfoTooltip: React.FunctionComponent<TooltipProps> = (props) => {
  return (
    <Tooltip
      trigger="hover"
      color="#fff"
      overlayInnerStyle={{
        color: '#3F494F',
      }}
      {...props}
    >
      <QuestionCircleFilled />
    </Tooltip>
  );
};

export default HgbInfoTooltip;
