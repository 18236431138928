import { HgbNavbar } from '@common/navigations';
import { API_URLS } from '@constants/API_URLS';
import { isMobileOs } from '@utils/os.util';
import { Spin } from 'antd';
import { useMemo } from 'react';
import { MobilePDFReader } from 'react-read-pdf';
import { useParams } from 'react-router';
import { useHgbQuery } from 'services/common/query';
import './F01.scss';

export const MANUAL_NAMEDDESTS = {
  about_input_base: 'about_input_base',
  about_input_organization: 'about_input_organization',
  about_upload_file: 'about_upload_file',
  about_import_csv: 'about_import_csv',
  about_input_direct: 'about_input_direct',
  about_reload_emission: 'about_reload_emission',
  about_market_location_base: 'about_market_location_base',
  about_upload_invoice: 'about_upload_invoice',
  about_upload_offset: 'about_upload_offset',

  about_freemium: 'about_freemium',
  about_payment: 'about_payment',
  about_account: 'about_account',
  about_cif: 'about_cif',
  about_investment: 'about_investment',
  about_enterprise_service_status: 'about_enterprise_service_status',
} as const;
export const F01 = () => {
  const { manualFile } = useParams();

  const { isFetching, data } = useHgbQuery<
    any,
    {
      manualFile: string;
    }
  >(
    API_URLS.MANUAL_API_URL,
    {
      manualFile: ['1', '2', '3'].includes(manualFile!) ? manualFile! : '0',
    },
    {
      type: 'blob',
      enabled: (manualFile || '') !== '',
      queryKey: [API_URLS.MANUAL_API_URL, manualFile],
    },
  );

  const manualName = useMemo(() => {
    switch (manualFile) {
      case '1':
        return 'co2_presiding_bank_manual.pdf';
      case '2':
        return 'co2_partner_bank_manual.pdf';
      case '3':
        return 'supply_chain_manual.pdf';
      default:
        return 'co2_system_manual.pdf';
    }
  }, [manualFile]);

  const url = `${URL.createObjectURL(
    new Blob([data as BlobPart], { type: 'application/pdf' }),
  )}#nameddest=${manualFile}`;

  return (
    <div className="tw-relative tw-flex tw-h-screen tw-flex-col">
      <HgbNavbar />
      {isFetching ? (
        <Spin className="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" />
      ) : null}
      {!isFetching && data ? (
        <>
          <h3 className="tw-px-20 tw-py-8 tw-font-bold">{manualName}</h3>
          <div className="tw-relative tw-flex tw-flex-auto tw-flex-col tw-items-center">
            {isMobileOs ? (
              <MobilePDFReader
                className="tw-h-full"
                url={url}
                showAllPage
                isShowFooter={false}
                isShowHeader={false}
              />
            ) : (
              <iframe
                title="co2_system_manual"
                className="tw-flex-auto tw-self-stretch"
                src={url}
              />
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};
