import { DivProps } from '@common/types';
import { cn } from '@utils/cn';
import React from 'react';

export const HgbBulletItem: React.FC<DivProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={cn(`tw-relative tw-text-h4 ${className}`)} {...props}>
      <span className="tw-relative tw-top-[-4px] tw-mr-4 tw-text-[28px]">
        .
      </span>
      <span>{children}</span>
    </div>
  );
};
