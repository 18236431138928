import { cn } from '@utils/cn';
import { Spin, SpinProps } from 'antd';
import * as React from 'react';

export const HgbAntdSpin: React.FC<SpinProps & { placement?: 'middle' }> = ({
  className = '',
  placement,
  ...props
}) => {
  return (
    <Spin
      className={cn(
        'tw-grid tw-place-content-center tw-py-24 ',
        {
          'tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2':
            placement === 'middle',
        },
        className,
      )}
      {...props}
    />
  );
};
