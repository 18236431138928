import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import {
  HgbAntdRadioGroup,
  HgbAntdRadioOption,
} from '@common/antd/HgbAntdRadioGroup';
import { HgbResponsive } from '@common/components';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import {
  HgbDatePicker,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { validateMonthRange } from '@utils/date.util';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { emptyToUndefined } from '@utils/text';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbQuery, useHgbQueryDownload } from 'services/common/query';
import {
  GetBankListBoxRequest,
  GetBankListBoxResponse,
} from 'services/types/bank';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetEnterpriseListBoxRequest,
  GetEnterpriseListBoxResponse,
} from 'services/types/enterprise';
import { GetSupplyChainListBoxResponse } from 'services/types/supply_chain';
import {
  GetEmissionYearListBoxRequest,
  GetEmissionYearListBoxResponse,
  GetFiscalYearMonthListBoxRequest,
  GetFiscalYearMonthListBoxResponse,
} from 'services/types/year';

type DateType = 'LCIDB_YEAR' | 'FISCAL_YEAR';
type ExportType = 'EXCEL' | 'CSV';

const validateFiscalYearRange = (startMonth: string, endMonth: string) => {
  if (startMonth === '' || endMonth === '') return 0;
  const start = dayjs(startMonth);
  const end = dayjs(endMonth);
  if (end.diff(start) < 0) return 1;
  if (end.diff(start, 'month') > 11) return 2;
};

type FormData = {
  bankId: string;
  enterpriseId: string;
  supplierId: string;
  baseId: string;
  exportType: ExportType;
  dateType: DateType;
  startMonth: string;
  endMonth: string;
  targetYear: string;
};

type DownloadRequest = {
  exportType?: string | ExportType[0];
  targetYear?: string;
  enterpriseId?: string;
  baseId: string;
  fromYearMonth?: string;
  toYearMonth?: string;
  supplierId?: string;
};

export const E01: React.FunctionComponent = (props) => {
  const { user } = React.useContext(AuthContext)!;
  const {
    text: { E01, common, E0049, E0100 },
    language,
  } = React.useContext(LanguageContext)!;
  const { addMessage } = React.useContext(PortalContext)!;

  const methods = useForm<FormData>({
    values: {
      bankId: '',
      enterpriseId: '',
      supplierId: '',
      baseId: '',
      exportType: 'EXCEL',
      dateType: 'LCIDB_YEAR',
      startMonth: '',
      endMonth: '',
      targetYear: '',
    },
  });

  const { register, watch, setValue } = methods;
  const formValues = watch();

  const { data: banksQuery, isFetchedAfterMount: isBankLBFetchedAfterMount } =
    useHgbQuery<GetBankListBoxResponse, GetBankListBoxRequest>(
      API_URLS.BANK_LIST_BOX_API_URL,
      undefined,
      {
        enabled: user.isPresidingBank,
      },
    );

  const {
    data: enterprisesQuery,
    isFetchedAfterMount: isEnterpriseLBFetchedAfterMount,
  } = useHgbQuery<GetEnterpriseListBoxResponse, GetEnterpriseListBoxRequest>(
    API_URLS.ENTERPRISE_LIST_BOX_API_URL,
    {
      bankId: emptyToUndefined(formValues.bankId),
    },
    {
      queryKey: [API_URLS.ENTERPRISE_LIST_BOX_API_URL, formValues.bankId],
      enabled:
        user.isManagementBank ||
        (user.isPresidingBank && formValues.bankId !== ''),
    },
  );

  //  Query supply chain list

  const supplyChainListboxQuery = useHgbQuery<
    GetSupplyChainListBoxResponse,
    undefined
  >(API_URLS.LISTBOX_COMPANY_SUPPLIER, undefined, {
    enabled: user.isEnterpriseAdmin && user.isBuyer,
    queryKey: [API_URLS.LISTBOX_COMPANY_SUPPLIER],
  });

  const { refetch: downloadReport, isLoading: downloadReportLoading } =
    useHgbQueryDownload<any, DownloadRequest>(
      API_URLS.EMISSION_OUTPUT_REPORT,
      {
        baseId: formValues.baseId,
        enterpriseId: formValues.enterpriseId,
        exportType: formValues.exportType,
        fromYearMonth: formValues.startMonth.replace('/', '').trim(),
        toYearMonth: formValues.endMonth.replace('/', '').trim(),
        supplierId: formValues.supplierId,
      },
      {
        enabled: false,
        onSuccess: (data) => {
          const { file, fileName } = data ?? {};
          if (!data) {
            addMessage('error', common.message.noResult);
            return;
          }
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([file]));
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      },
    );

  const { data: bosQuery } = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_BASE,
    {
      enterpriseId: emptyToUndefined(formValues.enterpriseId),
      supplierId: formValues.supplierId,
      isLoadAllOption: true,
    },
    {
      queryKey: [
        formValues.enterpriseId,
        API_URLS.BASE_LISTBOX_API_URL,
        formValues.supplierId,
      ],
      enabled:
        user.isEnterpriseAdmin ||
        user.isEnterpriseUser ||
        formValues.enterpriseId !== '',
    },
  );

  const { data: fiscalYearsQuery } = useHgbQuery<
    GetEmissionYearListBoxResponse,
    GetEmissionYearListBoxRequest
  >(
    API_URLS.GET_CURRENT_YEAR,
    {
      enterpriseId:
        formValues.enterpriseId === '' ? undefined : formValues.enterpriseId,
      withOffSet: true,
      bankId: user.isPresidingBank ? formValues.bankId : '',
    },
    {
      queryKey: [API_URLS.GET_CURRENT_YEAR, formValues.enterpriseId, language],
    },
  );

  useHgbQuery<
    GetFiscalYearMonthListBoxResponse,
    GetFiscalYearMonthListBoxRequest
  >(
    API_URLS.FISCAL_YEAR_MONTH,
    {
      targetYear: formValues.targetYear,
      isLCIDBYear: formValues.dateType === 'LCIDB_YEAR' ? true : false,
      enterpriseId: formValues.enterpriseId,
    },
    {
      queryKey: [
        formValues.targetYear,
        formValues.dateType,
        formValues.enterpriseId,
        API_URLS.FISCAL_YEAR_MONTH,
      ],
      enabled:
        (user.isEnterpriseAdmin && !isEmpty(formValues.targetYear)) ||
        (user.isEnterpriseUser && !isEmpty(formValues.targetYear)) ||
        formValues.enterpriseId !== '',
      onSuccess(data) {
        if (!data?.result) return;
        setValue('startMonth', data?.result[0]?.name.replace('-', '/'));
        setValue(
          'endMonth',
          data?.result[data?.result.length - 1]?.name.replace('-', '/'),
        );
      },
    },
  );

  const bankIdOptions = parseToHgbSelectOptions(banksQuery?.result);
  const enterpriseIdOptions = parseToHgbSelectOptions(enterprisesQuery?.result);

  const supplyChainOptions = React.useMemo(() => {
    const rs = parseToHgbSelectOptions(supplyChainListboxQuery.data?.result);
    setValue('supplierId', rs?.[0]?.value);
    return rs;
  }, [supplyChainListboxQuery.data?.result]);

  const baseIdOptions = React.useMemo(() => {
    if (
      formValues.supplierId !== '0' &&
      formValues.supplierId !== '-1' &&
      formValues.supplierId !== undefined
    ) {
      setValue('baseId', '');
      return [];
    }
    const bases = bosQuery?.result ?? [];
    return convertObjectToTree(
      bases.filter((item) => !getParentKey(item.hierarchicalPath ?? '')),
      bases,
    );
  }, [bosQuery?.result]);

  const fiscalYearOptions = React.useMemo(() => {
    return parseToHgbSelectOptions(fiscalYearsQuery?.result);
  }, [fiscalYearsQuery?.result]);

  const timeRangeError = React.useMemo(() => {
    if (formValues.dateType === 'LCIDB_YEAR') {
      const value = validateMonthRange(
        formValues.startMonth,
        formValues.endMonth,
      );

      if (formValues.startMonth === '' || formValues.endMonth === '') return '';

      if (value !== 'PASS') {
        if (value === 'ORDER') {
          return E0049;
        } else return E0100;
      }
    } else {
      if (
        validateFiscalYearRange(formValues.startMonth, formValues.endMonth) ===
        1
      ) {
        return E01.message.startMonthendMonth;
      }
      if (
        validateFiscalYearRange(formValues.startMonth, formValues.endMonth) ===
        2
      ) {
        return E01.message.timeBetweenExceedOneYear;
      }
    }
  }, [formValues.startMonth, formValues.endMonth, language]);

  const [confirmModal, setConfirmModal] = React.useState(false);

  const emptyDescription = React.useMemo(() => {
    if (user.isPresidingBank) {
      if (isEmpty(banksQuery?.result) && isBankLBFetchedAfterMount)
        return common.message.partnerBanksEmpty;
      if (isEmpty(formValues.bankId))
        return common.message.choosePartnerBankAndCompany;
      if (isEmpty(enterprisesQuery?.result) && isEnterpriseLBFetchedAfterMount)
        return common.message.companyEmpty;
      if (isEmpty(formValues.enterpriseId)) return common.message.chooseCompany;
    }

    if (user.isManagementBank) {
      if (isEmpty(enterprisesQuery?.result) && isEnterpriseLBFetchedAfterMount)
        return common.message.companyEmpty;
      if (isEmpty(formValues.enterpriseId)) return common.message.chooseCompany;
    }
  }, [
    user.isPresidingBank,
    user.isManagementBank,
    banksQuery?.result,
    isBankLBFetchedAfterMount,
    common.message.partnerBanksEmpty,
    common.message.choosePartnerBankAndCompany,
    common.message.companyEmpty,
    common.message.chooseCompany,
    formValues.bankId,
    formValues.enterpriseId,
    enterprisesQuery?.result,
    isEnterpriseLBFetchedAfterMount,
  ]);

  const disableSubmit = React.useMemo(() => {
    return (
      (formValues.baseId === '' &&
        (formValues.supplierId === '0' ||
          formValues.supplierId === '-1' ||
          formValues.supplierId === undefined)) ||
      (formValues.dateType === 'FISCAL_YEAR' &&
        !(
          validateFiscalYearRange(
            formValues.startMonth,
            formValues.endMonth,
          ) === undefined &&
          validateFiscalYearRange(
            formValues.startMonth,
            formValues.endMonth,
          ) !== 0
        )) ||
      (formValues.dateType === 'LCIDB_YEAR' &&
        !(
          validateMonthRange(formValues.startMonth, formValues.endMonth) ===
          'PASS'
        ))
    );
  }, [formValues]);

  const exportTypeOptions = React.useMemo(
    () => [
      {
        label: E01.label.excel,
        value: 'EXCEL',
      },
      {
        label: E01.label.csv,
        value: 'CSV',
      },
    ],
    [language],
  );

  const dateTypeOptions: HgbAntdRadioOption<DateType>[] = [
    {
      label: E01.label.LCIDBYear,
      value: 'LCIDB_YEAR',
    },
    {
      label: E01.label.fiscalYear,
      value: 'FISCAL_YEAR',
    },
  ];

  return (
    <ManagementTemplate>
      <DataTableTemplate title={E01.pageTitle}>
        <div className="tw-grid tw-grid-rows-[auto_1fr] tw-gap-24">
          <form className="tw-mx-auto tw-grid tw-gap-24 tw-self-start tw-pt-24 pc:tw-min-w-584">
            <FormProvider {...methods}>
              <HgbResponsive
                gap
                className="tw-grid pc:tw-grid-cols-[repeat(auto-fill,minmax(220px,auto))]"
              >
                {(user.isPresidingBank || user.isManagementBank) && (
                  <>
                    {user.isPresidingBank && (
                      <HgbSelect
                        {...register('bankId')}
                        options={bankIdOptions}
                        label={common.placeholder.bankId}
                        placeholder={E01.placeholder.bankId}
                        disabled={isEmpty(bankIdOptions)}
                        showSearch
                      />
                    )}
                    <HgbSelect
                      {...register('enterpriseId')}
                      options={enterpriseIdOptions}
                      label={E01.label.enterpriseId}
                      placeholder={E01.placeholder.enterpriseId}
                      disabled={isEmpty(enterpriseIdOptions)}
                      onChangeValue={() => {
                        setValue('baseId', baseIdOptions?.[0]?.value);
                      }}
                      showSearch
                    />
                  </>
                )}
                {user.isEnterpriseAdmin && user.isBuyer && (
                  <HgbSelect
                    {...register('supplierId')}
                    options={supplyChainOptions}
                    label={E01.label.enterpriseId}
                    placeholder={E01.placeholder.enterpriseId}
                    disabled={isEmpty(supplyChainOptions)}
                    showSearch
                  />
                )}
              </HgbResponsive>
              {(user.isPresidingBank || user.isManagementBank) && (
                <div className="tw-space-y-8">
                  <h4 className="tw-font-bold">{E01.label.outputType}</h4>
                  <HgbAntdRadioGroup
                    options={exportTypeOptions}
                    {...register('exportType')}
                    disabled={
                      (user.isPresidingBank || user.isManagementBank) &&
                      isEmpty(formValues.enterpriseId)
                    }
                  />
                </div>
              )}

              <div className="tw-space-y-8">
                <h4 className="tw-font-bold">{E01.label.outputDisplayRange}</h4>

                <div className="tw-flex tw-flex-col tw-gap-x-16 pc:tw-flex-row pc:tw-items-start">
                  <div className="tw-flex tw-flex-col tw-gap-x-16">
                    <HgbAntdRadioGroup
                      options={[dateTypeOptions[0]]}
                      {...register('dateType')}
                      disabled={
                        (user.isPresidingBank || user.isManagementBank) &&
                        isEmpty(formValues.enterpriseId)
                      }
                    />
                    <HgbSelect
                      {...register('targetYear')}
                      defaultActiveFirstOption
                      placeholder={E01.placeholder.fiscalYear}
                      options={fiscalYearOptions}
                      disabled={
                        (user.isPresidingBank || user.isManagementBank) &&
                        isEmpty(formValues.enterpriseId)
                      }
                    />
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-x-16">
                    <HgbAntdRadioGroup
                      options={[dateTypeOptions[1]]}
                      {...register('dateType')}
                      disabled={
                        (user.isPresidingBank || user.isManagementBank) &&
                        isEmpty(formValues.enterpriseId)
                      }
                      className="tw-shrink-0"
                    />
                    <div className="tw-grid">
                      <div className="tw-flex tw-gap-x-8">
                        <HgbDatePicker
                          disabled={
                            (user.isPresidingBank || user.isManagementBank) &&
                            isEmpty(formValues.enterpriseId)
                          }
                          picker="month"
                          {...register('startMonth')}
                          placeholder={E01.placeholder.targetYearMonthStart}
                          format="YYYY/MM"
                          className="tw-w-120"
                        />
                        <div className="tw-mt-20 tw-h-1 tw-w-8 tw-shrink-0 tw-bg-neutral-8 focus:tw-cursor-none" />
                        <HgbDatePicker
                          disabled={
                            (user.isPresidingBank || user.isManagementBank) &&
                            isEmpty(formValues.enterpriseId)
                          }
                          picker="month"
                          {...register('endMonth')}
                          placeholder={E01.placeholder.targetYearMonthEnd}
                          format="YYYY/MM"
                          className="tw-w-120"
                        />
                      </div>
                      {timeRangeError ? (
                        <HgbFieldError>{timeRangeError}</HgbFieldError>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-grid tw-gap-24 pc:tw-grid-cols-[repeat(2,220px)]">
                <HgbTreeSelect
                  {...register('baseId')}
                  treeData={baseIdOptions}
                  label={E01.label.baseId}
                  placeholder={common.label.organizationBaseName}
                  disabled={isEmpty(baseIdOptions)}
                  defaultActiveFirstOption
                  showSearch
                />
              </div>
              <HgbAntdButton
                className="tw-mx-auto"
                type="primary"
                htmlType="button"
                disabled={disableSubmit}
                onClick={() => {
                  setConfirmModal(true);
                }}
                loading={downloadReportLoading}
              >
                {common.button.export}
              </HgbAntdButton>
            </FormProvider>
            <HgbAntdModal
              okText={common.button.export}
              cancelText={common.button.cancel}
              title={E01.modal.exportConfirmation}
              open={confirmModal}
              onCancel={() => setConfirmModal(false)}
              formProps={{
                onSubmit: () => {
                  downloadReport();
                  setConfirmModal(false);
                },
              }}
            >
              <p className="tw-text-center">
                {E01.modal.exportConfirmationQuestion}
              </p>
              <p className="tw-text-center">
                {E01.modal.exportConfirmationWarning}
              </p>
            </HgbAntdModal>
          </form>
          {emptyDescription && <HgbAntdEmpty description={emptyDescription} />}
        </div>
      </DataTableTemplate>
    </ManagementTemplate>
  );
};
