import { REACT_APP_ENV } from '@constants/consts';

export const debug = {
  log(...params: any) {
    if (REACT_APP_ENV === 'development') {
      console.log(...params);
    }
  },
  error(...params: any) {
    if (REACT_APP_ENV === 'development') {
      console.error(...params);
    }
  },
};
