import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdEmpty } from '@common/antd/HgbAntdEmpty';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import HgbAntdUpload from '@common/antd/HgbAntdUpload';
import { API_URLS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { isMobileOs } from '@utils/os.util';
import Encoding from 'encoding-japanese';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FunctionComponent, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQueryDownload } from 'services/common/query';

type ModalStatus = {
  isOpen: boolean;
  numberOfTotalRecords: string;
  numberOfErrorRecords: string;
  byteArrayResource: string;
  fileName: string;
};
type ImportCsvResponse = {
  byteArrayResource: string;
  numberOfTotalRecords: string;
  numberOfErrorRecords: string;
};

const C03: FunctionComponent = (props) => {
  const {
    text: { C01, E01, common },
  } = useContext(LanguageContext)!;
  const methods = useForm();
  const { watch } = methods;
  const { addMessage } = useContext(PortalContext)!;

  const [confirmModal, setConfirmModal] = useState(false);
  const [statusModal, setStatusModal] = useState<ModalStatus>({
    isOpen: false,
    numberOfErrorRecords: '',
    numberOfTotalRecords: '',
    byteArrayResource: '',
    fileName: '',
  });
  const navigate = useNavigate();

  const { refetch: downloadTemplate } = useHgbQueryDownload<any, any>(
    API_URLS.CSV_TEMPLATE_DOWNLOAD_API_URL,
    undefined,
    {
      enabled: false,
      onSuccess: (data) => {
        if (!data.file) {
          addMessage('error', common.message.noResult);
          return;
        }
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data.file]));
        link.setAttribute('download', 'emission_input.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
  );

  const { mutate, isLoading } = useHgbMutationPost<ImportCsvResponse, FormData>(
    API_URLS.CSV_UPLOAD_API_URL,
    {
      onSuccess: (data) => {
        if (data?.statusCode?.toString() === '10011') {
          addMessage('success', data.message ?? '');
          return navigate(routs_en['/data-entry'].link);
        } else if (data?.statusCode?.toString() === '10101') {
          return setStatusModal((prev) => ({
            ...prev,
            isOpen: true,
            byteArrayResource: data.result?.byteArrayResource ?? '',
            numberOfErrorRecords: data.result?.numberOfErrorRecords ?? '',
            numberOfTotalRecords: data.result?.numberOfTotalRecords ?? '',
          }));
        } else {
          return addMessage('error', data.message ?? '');
        }
      },
      isAlert: false,
    },
  );

  const submitFile = async () => {
    setConfirmModal(false);
    setStatusModal((prev) => ({ ...prev, fileName: watch('file').name }));
    const formData = new FormData();
    formData.append('file', watch('file'));
    mutate(formData);
  };

  return (
    <ManagementTemplate>
      <DataTableTemplate title={C01.label.csvUpload}>
        <FormProvider {...methods}>
          {isMobileOs ? (
            <HgbAntdEmpty description={common.message.device_not_match} />
          ) : (
            <form className="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-40 tw-py-24">
              <HgbAntdUpload
                accept=".csv"
                className={`tw-w-300 pc:tw-w-400`}
                name="file"
              />
              <div className="tw-flex tw-flex-col tw-gap-16">
                <HgbAntdButton
                  loading={isLoading}
                  disabled={isEmpty(watch('file'))}
                  onClick={() => {
                    setConfirmModal(true);
                  }}
                  type="primary"
                >
                  {C01.label.import}
                </HgbAntdButton>
                <HgbAntdButton
                  onClick={() => {
                    downloadTemplate();
                  }}
                  type="primary"
                  ghost
                >
                  {C01.label.download}
                </HgbAntdButton>
                <HgbAntdButton
                  onClick={() => {
                    return navigate(routs_en['/data-entry'].link);
                  }}
                  type="primary"
                  ghost
                >
                  {C01.label.returnToDataList}
                </HgbAntdButton>
              </div>
            </form>
          )}
          <HgbAntdModal
            okText={C01.modal.csvConfirmModalOkButton}
            cancelText={common.button.cancel}
            title={C01.modal.csvConfirmModalTitle}
            open={confirmModal}
            onCancel={() => setConfirmModal(false)}
            formProps={{
              onSubmit: () => {
                submitFile();
              },
            }}
          >
            <p className="tw-text-center">{C01.modal.csvConfirmModalContent}</p>
          </HgbAntdModal>
          <HgbAntdModal
            okText={C01.modal.csvErrorModalCancelButton}
            cancelText={common.button.close}
            title={C01.modal.csvErrorModalTitle}
            open={statusModal.isOpen}
            onCancel={() => {
              setStatusModal((prev) => ({ ...prev, isOpen: false }));
            }}
            onOk={() => {
              const unicodeArray = Encoding.stringToCode(
                statusModal.byteArrayResource,
              ); // Convert string to code array
              const sjisArray = Encoding.convert(unicodeArray, {
                to: 'SJIS',
                from: 'UNICODE',
              });
              const uint8_array = new Uint8Array(sjisArray);
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(
                new Blob([uint8_array], { type: 'text/csv' }),
              );
              const date = new Date();
              const fullName = statusModal.fileName.split('.');
              link.setAttribute(
                'download',
                fullName[0] +
                  '_' +
                  moment(date).format('YYYYMMDDHHmmss') +
                  '.' +
                  fullName[1],
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            errorModal
          >
            <div className="tw-flex tw-flex-col tw-items-center">
              <p className="tw-text-center">
                {C01.modal.csvErrorModalContent1} <br />
                {C01.modal.csvErrorModalContent2}
              </p>
              <p>
                {C01.modal.numberImported.replace(
                  '$',
                  statusModal.numberOfTotalRecords,
                )}
              </p>
              <p>
                {C01.modal.numberFail.replace(
                  '$',
                  statusModal.numberOfErrorRecords,
                )}
              </p>
            </div>
          </HgbAntdModal>
        </FormProvider>
      </DataTableTemplate>
    </ManagementTemplate>
  );
};

export default C03;
