import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import { HgbSelect, parseToHgbSelectOptions } from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import {
  DashboardRequest,
  DashboardResponse,
} from '@pages/Dashboard/B02/types';
import { MANUAL_NAMEDDESTS } from '@pages/Management/Support';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { LocalDataClass } from 'data-class/LocalDataClass';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHgbQuery } from 'services/common/query';
import {
  GetBankListBoxRequest,
  GetBankListBoxResponse,
} from 'services/types/bank';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetEnterpriseListBoxRequest,
  GetEnterpriseListBoxResponse,
} from 'services/types/enterprise';
import { GetMarketLocationRadioButtonResponse } from 'services/types/market_location';
import { GetSupplyChainListBoxResponse } from 'services/types/supply_chain';
import {
  GetEmissionYearListBoxRequest,
  GetEmissionYearListBoxResponse,
} from 'services/types/year';

export function useDashboardFormData() {
  const { user } = useContext(AuthContext)!;
  const {
    text: { common, B01 },
  } = React.useContext(LanguageContext)!;

  const checkBuyerQuery = useHgbQuery<GetEnterpriseListBoxResponse, undefined>(
    API_URLS.CHECK_BUYER,
    undefined,
    {
      queryKey: [API_URLS.CHECK_BUYER],
    },
  );

  useEffect(() => {
    LocalDataClass.user = {
      ...LocalDataClass.user,
      isBuyer: !!checkBuyerQuery.data?.result,
    };
  }, [checkBuyerQuery.data?.result]);

  const dashboardMethods = useForm<DashboardRequest>({
    values: {
      targetYear: '',
      enterpriseId: '',
      baseId: '',
      bankId: '',
      scope2MethodSelected: 'ML0001',
      supplierId: '',
    },
  });

  // Query market location
  const scope2MarketLocationQuery =
    useHgbQuery<GetMarketLocationRadioButtonResponse>(
      API_URLS.SCOPE2_MARKET_LOCATION,
      undefined,
      {
        enabled: true,
        queryKey: [API_URLS.SCOPE2_MARKET_LOCATION],
      },
    );

  // Query enterprise list
  const enterpriseListboxQuery = useHgbQuery<
    GetEnterpriseListBoxResponse,
    GetEnterpriseListBoxRequest
  >(
    API_URLS.ENTERPRISE_LIST_BOX_API_URL,
    {
      bankId: dashboardMethods.watch('bankId'),
      isLoadAllOption: true,
    },
    {
      enabled: dashboardMethods.watch('bankId') !== '' || user.isManagementBank,
      queryKey: [
        dashboardMethods.watch('bankId'),
        user.isManagementBank,
        API_URLS.ENTERPRISE_LIST_BOX_API_URL,
      ],
    },
  );

  const emissionYearListboxQueryEnabled = () => {
    if (user.isPresidingBank) {
      return (
        (dashboardMethods.watch('bankId') || '') !== '' &&
        dashboardMethods.watch('enterpriseId') !== ''
      );
    }
    if (user.isManagementBank) {
      return dashboardMethods.watch('enterpriseId') !== '';
    }
    if (user.isEnterpriseAdmin || user.isEnterpriseUser) {
      return true;
    }
  };
  // Query emission year list
  const emissionYearListboxQuery = useHgbQuery<
    GetEmissionYearListBoxResponse,
    GetEmissionYearListBoxRequest
  >(
    API_URLS.EMISSION_DATA_YEAR_LISTBOX,
    {
      enterpriseId: dashboardMethods.watch('enterpriseId'),
      bankId: dashboardMethods.watch('bankId'),
    },
    {
      enabled: emissionYearListboxQueryEnabled(),
      queryKey: [
        dashboardMethods.watch('enterpriseId'),
        dashboardMethods.watch('bankId'),
        API_URLS.EMISSION_DATA_YEAR_LISTBOX,
      ],
    },
  );

  //  Query supply chain list
  const supplyChainListboxQuery = useHgbQuery<
    GetSupplyChainListBoxResponse,
    undefined
  >(API_URLS.LISTBOX_COMPANY_SUPPLIER, undefined, {
    enabled: user.isEnterpriseAdmin && user.isBuyer,
    queryKey: [API_URLS.LISTBOX_COMPANY_SUPPLIER],
  });

  // Query base/organization list
  const organizationListboxQuery = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_BASE,
    {
      enterpriseId: dashboardMethods.watch('enterpriseId'),
      supplierId:
        user.isBuyer && user.isEnterpriseAdmin
          ? dashboardMethods.watch('supplierId')
          : '',
      isLoadAllOption: true,
    },
    {
      enabled:
        user.isEnterpriseAdmin ||
        user.isEnterpriseUser ||
        dashboardMethods.watch('enterpriseId') !== '',
      queryKey: [
        dashboardMethods.watch('enterpriseId'),
        dashboardMethods.watch('supplierId'),
        API_URLS.BASE_LISTBOX_API_URL,
        user.isBuyer && user.isEnterpriseAdmin,
      ],
    },
  );

  const dashboardQueryEnabled = () => {
    const targetYear = (dashboardMethods.watch('targetYear') || '').trim();
    const enterpriseId = (dashboardMethods.watch('enterpriseId') || '').trim();
    const baseId = (dashboardMethods.watch('baseId') || '').trim();
    const scope2MethodSelected = (
      dashboardMethods.watch('scope2MethodSelected') || ''
    ).trim();
    if (user.isManagementBank) {
      return (
        enterpriseId !== '' &&
        // baseId !== '' &&
        scope2MethodSelected !== ''
      );
    }
    return true;
  };

  // Query dashboard data
  const dashboardQuery = useHgbQuery<DashboardResponse, DashboardRequest>(
    API_URLS.DASHBOARD_API_URL,
    {
      ...dashboardMethods.watch(),
      baseId: dashboardMethods.watch('baseId') || '0',
      supplierId:
        user.isBuyer && user.isEnterpriseAdmin
          ? dashboardMethods.watch('supplierId')
          : '',
    },
    {
      enabled: dashboardQueryEnabled(),
      queryKey: [API_URLS.DASHBOARD_API_URL, dashboardMethods.watch()],
    },
  );

  // Query bank list
  const bankListboxQuery = useHgbQuery<
    GetBankListBoxResponse,
    GetBankListBoxRequest
  >(API_URLS.BANK_LIST_BOX_API_URL, undefined, {
    enabled: user.isPresidingBank,
    queryKey: [API_URLS.BANK_LIST_BOX_API_URL],
  });

  const bankOptions = useMemo(() => {
    dashboardMethods.setValue('enterpriseId', '');
    return parseToHgbSelectOptions(bankListboxQuery.data?.result);
  }, [bankListboxQuery.data?.result]);

  const enterpriseIdOptions = useMemo(() => {
    dashboardMethods.setValue('targetYear', '');
    dashboardMethods.setValue('baseId', '');
    const rs = parseToHgbSelectOptions(enterpriseListboxQuery.data?.result);
    dashboardMethods.setValue('enterpriseId', rs?.[0]?.value);
    return rs;
  }, [enterpriseListboxQuery.data?.result, dashboardMethods.watch('bankId')]);

  const fiscalYearOptions = useMemo(() => {
    const rs = parseToHgbSelectOptions(emissionYearListboxQuery.data?.result);
    dashboardMethods.setValue('targetYear', rs?.[0]?.value);
    return rs;
  }, [
    emissionYearListboxQuery.data?.result,
    dashboardMethods.watch('enterpriseId'),
    dashboardMethods.watch('bankId'),
  ]);

  const supplyChainOptions = useMemo(() => {
    const rs = parseToHgbSelectOptions(supplyChainListboxQuery.data?.result);
    dashboardMethods.setValue('supplierId', rs?.[0]?.value);
    return rs;
  }, [supplyChainListboxQuery.data?.result]);

  const baseIdOptions = useMemo(() => {
    if (
      dashboardMethods.watch('supplierId') !== '0' &&
      dashboardMethods.watch('supplierId') !== '-1' &&
      dashboardMethods.watch('supplierId') !== undefined &&
      dashboardMethods.watch('supplierId') !== ''
    ) {
      dashboardMethods.setValue('baseId', '');
      return [];
    }
    const BOsData = organizationListboxQuery.data?.result ?? [];
    const rs = convertObjectToTree(
      BOsData.filter((item) => !getParentKey(item.hierarchicalPath)),
      BOsData,
    );
    dashboardMethods.setValue('baseId', rs?.[0]?.value);
    return rs;
  }, [
    organizationListboxQuery.data?.result,
    dashboardMethods.watch('enterpriseId'),
    dashboardMethods.watch('bankId'),
    dashboardMethods.watch('supplierId'),
  ]);

  const marketLocationOptions = useMemo(() => {
    const rs = (scope2MarketLocationQuery.data?.result ?? []).map(
      ({ value, name }) => ({ value, label: name }),
    );
    return rs;
  }, [
    scope2MarketLocationQuery.data?.result,
    dashboardMethods.watch('enterpriseId'),
    dashboardMethods.watch('bankId'),
  ]);

  const emptyText = () => {
    if (user.isPresidingBank) {
      if (bankListboxQuery.isFetchedAfterMount) {
        if (isEmpty(bankListboxQuery.data?.result)) {
          return common.message.partnerBanksEmpty;
        }
        if (isEmpty(dashboardMethods.watch('bankId'))) {
          return common.message.choosePartnerBankAndCompany;
        }
      }
      if (enterpriseListboxQuery.isFetchedAfterMount) {
        if (isEmpty(enterpriseListboxQuery.data?.result)) {
          return common.message.companyEmpty;
        }
        if (isEmpty(dashboardMethods.watch('enterpriseId'))) {
          return common.message.chooseCompany;
        }
      }
    }

    if (user.isManagementBank) {
      if (enterpriseListboxQuery.isFetchedAfterMount) {
        if (isEmpty(enterpriseListboxQuery.data?.result)) {
          return common.message.companyEmpty;
        }
        if (isEmpty(dashboardMethods.watch('enterpriseId'))) {
          return common.message.chooseCompany;
        }
      }
    }
    return undefined;
  };

  const BankIdsSelectbox = useCallback(
    ({ className, label }: { className?: string; label?: string }) => {
      if (!user.isPresidingBank) return null;
      return (
        <HgbSelect
          {...dashboardMethods.register('bankId')}
          disabled={isEmpty(bankOptions)}
          options={bankOptions}
          placeholder={common.placeholder.bankId}
          hidden={!user.isPresidingBank}
          showSearch
          className={className}
          label={label}
        />
      );
    },
    [bankOptions, common.placeholder.bankId, user.isPresidingBank],
  );

  const EnterprisesSelectbox = useCallback(
    ({ className, label }: { className?: string; label?: string }) => {
      if (!user.isPresidingBank && !user.isManagementBank) return null;
      return (
        <HgbSelect
          {...dashboardMethods.register('enterpriseId')}
          disabled={isEmpty(enterpriseIdOptions)}
          options={enterpriseIdOptions}
          placeholder={B01.placeholder.enterpriseId}
          showSearch
          className={className}
          label={label}
        />
      );
    },
    [
      enterpriseIdOptions,
      user.isManagementBank,
      user.isPresidingBank,
      B01.placeholder.enterpriseId,
    ],
  );
  const SupplyChainSelectbox = useCallback(
    ({ className, label }: { className?: string; label?: string }) => {
      if (!(user.isEnterpriseAdmin && user.isBuyer)) return null;
      return (
        <HgbSelect
          {...dashboardMethods.register('supplierId')}
          disabled={isEmpty(supplyChainOptions)}
          options={supplyChainOptions}
          placeholder={B01.placeholder.enterpriseId}
          showSearch
          className={className}
          label={label}
        />
      );
    },
    [
      JSON.stringify(supplyChainOptions),
      B01.placeholder.enterpriseId,
      JSON.stringify(user),
    ],
  );

  const TargetYearSelectbox = useCallback(
    ({ className, label }: { className?: string; label?: string }) => {
      return (
        <HgbSelect
          {...dashboardMethods.register('targetYear')}
          disabled={isEmpty(fiscalYearOptions)}
          options={fiscalYearOptions}
          placeholder={B01.placeholder.targetYear}
          className={className}
          label={label}
        />
      );
    },
    [fiscalYearOptions, B01.placeholder.targetYear],
  );

  const BaseSelectbox = useCallback(
    ({ className, label }: { className?: string; label?: string }) => {
      return (
        <HgbTreeSelect
          {...dashboardMethods.register('baseId')}
          disabled={isEmpty(baseIdOptions)}
          treeData={baseIdOptions}
          placeholder={B01.placeholder.baseId}
          showSearch
          className={className}
          label={label}
        />
      );
    },
    [
      baseIdOptions,
      B01.placeholder.baseId,
      dashboardMethods.watch('supplierId'),
    ],
  );

  const MarketLocationRadioGroup = useCallback(
    ({ className }: { className?: string }) => {
      return (
        <HgbAntdRadioGroup
          {...dashboardMethods.register('scope2MethodSelected')}
          options={marketLocationOptions}
          label={B01.label.scope2MethodSelected}
          info={`/support/manual/${MANUAL_NAMEDDESTS.about_market_location_base}`}
          className={className}
        />
      );
    },
    [marketLocationOptions, B01.label.scope2MethodSelected],
  );

  return {
    dashboardMethods,
    dashboardQuery,
    emptyText: emptyText(),
    BankIdsSelectbox,
    EnterprisesSelectbox,
    TargetYearSelectbox,
    BaseSelectbox,
    MarketLocationRadioGroup,
    SupplyChainSelectbox,
    isLoading:
      scope2MarketLocationQuery.isLoading ||
      enterpriseListboxQuery.isLoading ||
      emissionYearListboxQuery.isLoading ||
      supplyChainListboxQuery.isLoading ||
      organizationListboxQuery.isLoading ||
      dashboardQuery.isLoading ||
      bankListboxQuery.isLoading,
  };
}
