import { HgbErrorFilledIcon } from '@common/icons/filled';
import { LabelProps } from '@common/types';
import { cn } from '@utils/cn';
import * as React from 'react';

interface HgbFieldErrorProps extends LabelProps {}

export const HgbFieldError: React.FunctionComponent<HgbFieldErrorProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <label
      className={cn(
        `tw-mt-4 tw-flex tw-items-center tw-text-tiny tw-font-medium tw-text-error-7 ${className}`,
      )}
      {...props}
    >
      <div className=" tw-text-error-7">
        <HgbErrorFilledIcon className="tw-mr-4 tw-mt-4" size="16" />
      </div>
      <p className="tw-text-tiny tw-font-medium tw-text-error-7">{children}</p>
    </label>
  );
};
