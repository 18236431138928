import { HgbSelectOption } from '@common/forms';

type CodeMedium = {
  simcCode: string;
  sicCode: string;
  simcName: string;
};

type CodeMajor = {
  sicName: string;
  sicCode: string;
};
export const createMediumOptions = (
  codeMediums: CodeMedium[],
  sicCode: string,
): HgbSelectOption[] => {
  return codeMediums
    .filter((item) => item.sicCode === sicCode)
    .map(({ simcName: label, simcCode: value }) => ({
      label: value + ' ' + label,
      value,
    }));
};

export const createMajorOptions = (
  codeMajors: CodeMajor[],
): HgbSelectOption[] => {
  return codeMajors.map(({ sicName: label, sicCode: value }) => ({
    label: value + ' ' + label,
    value,
  }));
};
