import { Size, SvgProps } from '@common/types';
import { FC, ReactNode } from 'react';

export const HgbCheckedModalOutlinedIcon: FC<SvgProps> = ({
  className = '',
  size = '24',
  ...props
}) => {
  const Icons: Record<Size, ReactNode> = {
    '16': (
      <svg
        width={16}
        height={17}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.9998 4.66602C15.1198 4.66602 4.6665 15.1193 4.6665 27.9993C4.6665 40.8793 15.1198 51.3327 27.9998 51.3327C40.8798 51.3327 51.3332 40.8793 51.3332 27.9993C51.3332 15.1193 40.8798 4.66602 27.9998 4.66602ZM27.9998 46.666C17.7098 46.666 9.33317 38.2893 9.33317 27.9993C9.33317 17.7093 17.7098 9.33268 27.9998 9.33268C38.2898 9.33268 46.6665 17.7093 46.6665 27.9993C46.6665 38.2893 38.2898 46.666 27.9998 46.666ZM38.7098 17.686L23.3332 33.0627L17.2898 27.0427L13.9998 30.3327L23.3332 39.666L41.9998 20.9993L38.7098 17.686Z"
          fill="#81B646"
        />
      </svg>
    ),
    '20': (
      <svg
        width={20}
        height={20}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.9998 4.66602C15.1198 4.66602 4.6665 15.1193 4.6665 27.9993C4.6665 40.8793 15.1198 51.3327 27.9998 51.3327C40.8798 51.3327 51.3332 40.8793 51.3332 27.9993C51.3332 15.1193 40.8798 4.66602 27.9998 4.66602ZM27.9998 46.666C17.7098 46.666 9.33317 38.2893 9.33317 27.9993C9.33317 17.7093 17.7098 9.33268 27.9998 9.33268C38.2898 9.33268 46.6665 17.7093 46.6665 27.9993C46.6665 38.2893 38.2898 46.666 27.9998 46.666ZM38.7098 17.686L23.3332 33.0627L17.2898 27.0427L13.9998 30.3327L23.3332 39.666L41.9998 20.9993L38.7098 17.686Z"
          fill="#81B646"
        />
      </svg>
    ),
    '24': (
      <svg
        width={56}
        height={56}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.9998 4.66602C15.1198 4.66602 4.6665 15.1193 4.6665 27.9993C4.6665 40.8793 15.1198 51.3327 27.9998 51.3327C40.8798 51.3327 51.3332 40.8793 51.3332 27.9993C51.3332 15.1193 40.8798 4.66602 27.9998 4.66602ZM27.9998 46.666C17.7098 46.666 9.33317 38.2893 9.33317 27.9993C9.33317 17.7093 17.7098 9.33268 27.9998 9.33268C38.2898 9.33268 46.6665 17.7093 46.6665 27.9993C46.6665 38.2893 38.2898 46.666 27.9998 46.666ZM38.7098 17.686L23.3332 33.0627L17.2898 27.0427L13.9998 30.3327L23.3332 39.666L41.9998 20.9993L38.7098 17.686Z"
          fill="#81B646"
        />
      </svg>
    ),
  };
  return <>{Icons[size]}</>;
};
