import { HgbSelectOption } from '@common/forms';

export type AreaOptionType = {
  childrens: HgbSelectOption[];
} & HgbSelectOption;

export const areaOptions: AreaOptionType[] = [
  {
    label: '北海道',
    value: '北海道',
    childrens: [{ label: '北海道', value: '北海道' }],
  },
  {
    label: '東北',
    value: '東北',
    childrens: [
      { label: '青森', value: '青森' },
      { label: '岩手', value: '岩手' },
      { label: '宮城', value: '宮城' },
      { label: '秋田', value: '秋田' },
      { label: '山形', value: '山形' },
      { label: '福島', value: '福島' },
    ],
  },
  {
    label: '北関東',
    value: '北関東',
    childrens: [
      { label: '茨城', value: '茨城' },
      { label: '栃木', value: '栃木' },
      { label: '群馬', value: '群馬' },
    ],
  },
  {
    label: '南関東',
    value: '南関東',
    childrens: [
      { label: '埼玉', value: '埼玉' },
      { label: '千葉', value: '千葉' },
      { label: '東京', value: '東京' },
      { label: '神奈川', value: '神奈川' },
    ],
  },
  {
    label: '甲信越',
    value: '甲信越',
    childrens: [
      { label: '新潟', value: '新潟' },
      { label: '山梨', value: '山梨' },
      { label: '長野', value: '長野' },
    ],
  },
  {
    label: '東海',
    value: '東海',
    childrens: [
      { label: '静岡', value: '静岡' },
      { label: '岐阜', value: '岐阜' },
      { label: '愛知', value: '愛知' },
      { label: '三重', value: '三重' },
    ],
  },
  {
    label: '北陸',
    value: '北陸',
    childrens: [
      { label: '富山', value: '富山' },
      { label: '石川', value: '石川' },
      { label: '福井', value: '福井' },
    ],
  },
  {
    label: '近畿',
    value: '近畿',
    childrens: [
      { label: '滋賀', value: '滋賀' },
      { label: '京都', value: '京都' },
      { label: '大阪', value: '大阪' },
      { label: '兵庫', value: '兵庫' },
      { label: '奈良', value: '奈良' },
      { label: '和歌山', value: '和歌山' },
    ],
  },
  {
    label: '中国',
    value: '中国',
    childrens: [
      { label: '鳥取', value: '鳥取' },
      { label: '島根', value: '島根' },
      { label: '岡山', value: '岡山' },
      { label: '広島', value: '広島' },
      { label: '山口', value: '山口' },
    ],
  },
  {
    label: '四国',
    value: '四国',
    childrens: [
      { label: '徳島', value: '徳島' },
      { label: '香川', value: '香川' },
      { label: '愛媛', value: '愛媛' },
      { label: '高知', value: '高知' },
    ],
  },
  {
    label: '九州',
    value: '九州',
    childrens: [
      { label: '福岡', value: '福岡' },
      { label: '佐賀', value: '佐賀' },
      { label: '長崎', value: '長崎' },
      { label: '熊本', value: '熊本' },
      { label: '大分', value: '大分' },
      { label: '宮崎', value: '宮崎' },
      { label: '鹿児島', value: '鹿児島' },
    ],
  },
  {
    label: '沖縄',
    value: '沖縄',
    childrens: [{ label: '沖縄', value: '沖縄' }],
  },
];
