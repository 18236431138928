import { Size, SvgProps } from '@common/types';
import { cn } from '@utils/cn';
import { FC, ReactNode } from 'react';

export const HgbCloseOutlinedIcon: FC<SvgProps> = ({
  className = '',
  size = '20',
  ...props
}) => {
  const Icons: Record<Size, ReactNode> = {
    '16': (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M4 12L12 4M4 4L12 12"
          stroke="currentColor"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    '20': (
      <svg
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
    '24': (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
          fill="currentColor"
        />
      </svg>
    ),
  };
  return <>{Icons[size]}</>;
};
