import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbAntdInput } from '@common/forms';
import { LanguageContext } from '@contexts/LanguageContext';
import { BooleanState } from '@hooks/useBooleanState';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Supplier } from './D17';

interface ViewSupplierModalProps {
  modalState: BooleanState;
  data?: Supplier;
}

const ViewSupplierModal: React.FunctionComponent<ViewSupplierModalProps> = ({
  modalState,
  data,
}) => {
  const {
    text: { common, D15, D17 },
  } = React.useContext(LanguageContext)!;
  const viewSupplierMethods = useForm<Supplier>({ values: data });
  return (
    <>
      <FormProvider {...viewSupplierMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={<HgbAntdModalTitle>{D17.titleViewModal}</HgbAntdModalTitle>}
          open={modalState.value}
          onCancel={modalState.turnOff}
          destroyOnClose={true}
          footer={null}
          closable={true}
          maskClosable={false}
        >
          <HgbAntdModalForm>
            <HgbAntdModalFormBody>
              <HgbAntdInput
                {...viewSupplierMethods.register('inviteCode')}
                required
                label={D15.label.inviteCode}
                readOnly
              />
              <HgbAntdInput
                {...viewSupplierMethods.register('buyerName')}
                type={'K'}
                label={D17.label.supplyChainGroupBuyer}
                readOnly
              />
              <HgbAntdInput
                {...viewSupplierMethods.register('supplyChainName')}
                type={'K'}
                label={common.columns.supplyChainGroupName}
                readOnly
              />
            </HgbAntdModalFormBody>
            <HgbAntdModalFormFooter>
              <HgbAntdButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
                htmlType="button"
              >
                {common.button.cancel}
              </HgbAntdButton>
            </HgbAntdModalFormFooter>
          </HgbAntdModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default ViewSupplierModal;
