import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbAntdInput } from '@common/forms';
import { LanguageContext } from '@contexts/LanguageContext';
import { BooleanState } from '@hooks/useBooleanState';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Introducer } from './D22';

interface IViewIntroducerModalProps {
  modalState: BooleanState;
  data?: Introducer;
}

const ViewIntroducerModal: React.FunctionComponent<
  IViewIntroducerModalProps
> = ({ modalState, data }) => {
  const {
    text: { common, D22 },
  } = React.useContext(LanguageContext)!;
  const addIntroducerMethods = useForm<Introducer>({ values: data });
  return (
    <>
      <FormProvider {...addIntroducerMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={
            <HgbAntdModalTitle>{D22.label.viewIntroducer}</HgbAntdModalTitle>
          }
          open={modalState.value}
          onCancel={modalState.turnOff}
          destroyOnClose={true}
          footer={null}
          closable={true}
          maskClosable={false}
        >
          <HgbAntdModalForm>
            <HgbAntdModalFormBody>
              <HgbAntdInput
                {...addIntroducerMethods.register('introducerName')}
                label={D22.label.introducerName}
                readOnly
                required
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('introducerNameKana')}
                label={D22.label.introducerNameKana}
                readOnly
                required
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('postalCode')}
                label={common.label.postalCode}
                readOnly
                required
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('address')}
                label={common.label.fullAddress}
                readOnly
                required
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('buildingName')}
                label={D22.label.buildingName}
                readOnly
                required
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('phoneNumber')}
                label={D22.label.phoneNumber}
                readOnly
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('email')}
                label={D22.label.PICEmail}
                readOnly
                required
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('picName')}
                label={D22.label.PICName}
                readOnly
                required
              />
              <HgbAntdInput
                {...addIntroducerMethods.register('picNameKana')}
                label={D22.label.PICNameKana}
                readOnly
                required
              />
              <div className="tw-space-y-4">
                <label className="tw-text-paragraph tw-font-bold">
                  {D22.label.supportCode}
                </label>
                <p>{addIntroducerMethods.watch('inviteCode')}</p>
              </div>
            </HgbAntdModalFormBody>
            <HgbAntdModalFormFooter>
              <HgbAntdButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
                htmlType="button"
              >
                {common.button.cancel}
              </HgbAntdButton>
            </HgbAntdModalFormFooter>
          </HgbAntdModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default ViewIntroducerModal;
