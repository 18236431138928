import { useCallback, useState } from 'react';

export type BooleanState = {
  value: boolean;
  toggle: () => void;
  turnOn: () => void;
  turnOff: () => void;
};
export const useBooleanState = (initValue: boolean): BooleanState => {
  const [value, setValue] = useState(initValue);

  const toggle = useCallback(() => {
    setValue((prev) => !prev);
  }, []);

  const turnOn = useCallback(() => {
    setValue(true);
  }, []);

  const turnOff = useCallback(() => {
    setValue(false);
  }, []);

  return { value, toggle, turnOn, turnOff };
};
