import { HgbSelectOption } from '@common/forms';

export const MONTH_OF_YEAR_OPTIONS: HgbSelectOption[] = Array.from(
  { length: 12 },
  (_, key) => ({
    value: `${key < 9 ? '0' : ''}${key + 1}`,
    label: `${key + 1}月`,
  }),
);


