import { HgbBoxShadow } from '@common/contents';
import { DivProps } from '@common/types';
import { LanguageContext } from '@contexts/LanguageContext';
import { DataTableTemplate } from '@layouts/templates';
import { cn } from '@utils/cn';
import { toJPFormat } from '@utils/number';
import * as React from 'react';

interface IGeneralProps {
  total3Scopes?: number;
  totalOffset?: number;
  total?: number;
  target?: number;
  compareToLastYear?: number | string;
  companySupplier: string;
}

export const General: React.FunctionComponent<IGeneralProps> = React.memo(
  ({
    total3Scopes = 0,
    totalOffset = 0,
    total = 0,
    target = 0,
    compareToLastYear = '-',
    companySupplier,
  }) => {
    const {
      text: { B01, common },
    } = React.useContext(LanguageContext)!;
    return (
      <DataTableTemplate className="tw-scroll-mt-64" title={B01.label.general}>
        <div className="tw-space-y-32">
          <div className="tw-grid  tw-gap-24 large:tw-grid-cols-[1fr_auto_1fr_auto_1fr]">
            <GeneralItem className="tw-justify-between tw-text-center">
              <div className="tw-space-y-4">
                <h3 className="tw-font-bold">{B01.label.total3Scopes1}</h3>
                <p>{B01.label.total3Scopes2}</p>
              </div>
              <h2 className="tw-break-all tw-font-bold tw-text-primary-5">
                {toJPFormat(total3Scopes)} tCO2
              </h2>
            </GeneralItem>
            <h1 className="tw-grid tw-place-content-center tw-py-16 tw-text-[60px]">
              -
            </h1>
            <GeneralItem className="tw-justify-between tw-text-center">
              <h3 className="tw-font-bold">{common.label.offset}</h3>
              <h2 className="tw-break-all tw-font-bold tw-text-primary-5">
                {toJPFormat(totalOffset)} tCO2
              </h2>
            </GeneralItem>
            <h1 className="tw-grid tw-place-content-center tw-py-16 tw-text-[40px]">
              =
            </h1>
            <GeneralItem className="tw-justify-between tw-text-center">
              <div className="tw-space-y-4">
                <h3 className="tw-font-bold">{B01.label.total1}</h3>
                <p>{B01.label.total2}</p>
              </div>
              <h2 className="tw-break-all tw-font-bold tw-text-primary-5">
                {toJPFormat(total)} tCO2
              </h2>
            </GeneralItem>
          </div>
          {companySupplier === '-1' ||
          companySupplier === '0' ||
          companySupplier === undefined ? (
            <div className="tw-grid tw-gap-24 large:tw-grid-cols-2">
              <GeneralItem className="tw-justify-between tw-text-center">
                <div className="tw-space-y-4">
                  <h3 className="tw-font-bold">{B01.label.target1}</h3>
                  <p>{B01.label.target2}</p>
                </div>
                <h2 className="tw-break-all tw-font-bold tw-text-primary-5">
                  {toJPFormat(target || 0, 3)} tCO2
                </h2>
              </GeneralItem>
              <GeneralItem className="tw-justify-between tw-text-center">
                <div className="tw-space-y-4">
                  <h3 className="tw-font-bold">
                    {B01.label.compareToLastYear1}
                  </h3>
                  <p>{B01.label.compareToLastYear2}</p>
                </div>
                <h2 className="tw-break-all tw-font-bold tw-text-primary-5">
                  {compareToLastYear === '-'
                    ? '-'
                    : `${toJPFormat(compareToLastYear || 0, 1, false)} %`}
                </h2>
              </GeneralItem>
            </div>
          ) : null}
        </div>
      </DataTableTemplate>
    );
  },
);

const GeneralItem: React.FC<DivProps> = ({ className = '', children }) => {
  return (
    <HgbBoxShadow
      className={cn(
        'tw-flex tw-min-h-160 tw-flex-col tw-gap-16 tw-rounded-6 tw-p-24',
        className,
      )}
    >
      {children}
    </HgbBoxShadow>
  );
};
