import { HgbLoading } from '@common/components';
import { message } from 'antd';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react';

interface PortalContextType {
  isLoading: boolean;
  setLoading: (load: boolean) => void;
  addMessage: (
    type: 'success' | 'error' | 'info' | 'warning',
    content: string,
  ) => void;
  setFileName: Dispatch<SetStateAction<string>>;
  fileName: string;
}

export const PortalContext = createContext<PortalContextType | null>(null);
export const PortalProvider = ({ children }: { children: ReactNode }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');

  const setLoading = (load: boolean) => {
    setIsLoading(load);
  };
  const addMessage = (
    type: 'success' | 'error' | 'info' | 'warning',
    content: string,
  ) => {
    messageApi.open({
      type,
      content,
      duration: 5,
      style: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      onClick: () => {
        messageApi.destroy();
      },
    });
  };

  return (
    <PortalContext.Provider
      value={{
        isLoading,
        setLoading,
        addMessage,
        setFileName,
        fileName,
      }}
    >
      {contextHolder}
      {isLoading && <HgbLoading />}
      {children}
    </PortalContext.Provider>
  );
};
