import { Size, SvgProps } from '@common/types';
import { cn } from '@utils/cn';
import { FC, ReactNode } from 'react';

export const HgbInformationIcon: FC<SvgProps> = ({
  size = '16',
  className,
  ...props
}) => {
  const Icons: Record<Size, ReactNode> = {
    '16': (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M7.99992 1.83337C4.31992 1.83337 1.33325 4.82004 1.33325 8.50004C1.33325 12.18 4.31992 15.1667 7.99992 15.1667C11.6799 15.1667 14.6666 12.18 14.6666 8.50004C14.6666 4.82004 11.6799 1.83337 7.99992 1.83337ZM8.66659 11.8334H7.33325V7.83337H8.66659V11.8334ZM8.66659 6.50004H7.33325V5.16671H8.66659V6.50004Z"
          fill="#049AD5"
        />
      </svg>
    ),
    '20': (
      <svg
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
    '24': (
      <svg
        width={36}
        height={36}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M7.99992 1.83337C4.31992 1.83337 1.33325 4.82004 1.33325 8.50004C1.33325 12.18 4.31992 15.1667 7.99992 15.1667C11.6799 15.1667 14.6666 12.18 14.6666 8.50004C14.6666 4.82004 11.6799 1.83337 7.99992 1.83337ZM8.66659 11.8334H7.33325V7.83337H8.66659V11.8334ZM8.66659 6.50004H7.33325V5.16671H8.66659V6.50004Z"
          fill="#049AD5"
        />
      </svg>
    ),
  };
  return <>{Icons[size]}</>;
};
