import { Size, SvgProps } from '@common/types';
import { cn } from '@utils/cn';
import { FC, ReactNode } from 'react';

export const HgbErrorFilledIcon: FC<SvgProps> = ({
  className = '',
  size = '20',
  ...props
}) => {
  const Icons: Record<Size, ReactNode> = {
    '16': (
      <svg
        width={10}
        height={10}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      >
        <path
          d="M5 7.5C5.14167 7.5 5.2605 7.452 5.3565 7.356C5.45217 7.26033 5.5 7.14167 5.5 7C5.5 6.85833 5.45217 6.7395 5.3565 6.6435C5.2605 6.54783 5.14167 6.5 5 6.5C4.85833 6.5 4.73967 6.54783 4.644 6.6435C4.548 6.7395 4.5 6.85833 4.5 7C4.5 7.14167 4.548 7.26033 4.644 7.356C4.73967 7.452 4.85833 7.5 5 7.5ZM4.5 5.5H5.5V2.5H4.5V5.5ZM5 10C4.30833 10 3.65833 9.86867 3.05 9.606C2.44167 9.34367 1.9125 8.9875 1.4625 8.5375C1.0125 8.0875 0.656333 7.55833 0.394 6.95C0.131333 6.34167 0 5.69167 0 5C0 4.30833 0.131333 3.65833 0.394 3.05C0.656333 2.44167 1.0125 1.9125 1.4625 1.4625C1.9125 1.0125 2.44167 0.656167 3.05 0.3935C3.65833 0.131167 4.30833 0 5 0C5.69167 0 6.34167 0.131167 6.95 0.3935C7.55833 0.656167 8.0875 1.0125 8.5375 1.4625C8.9875 1.9125 9.34367 2.44167 9.606 3.05C9.86867 3.65833 10 4.30833 10 5C10 5.69167 9.86867 6.34167 9.606 6.95C9.34367 7.55833 8.9875 8.0875 8.5375 8.5375C8.0875 8.9875 7.55833 9.34367 6.95 9.606C6.34167 9.86867 5.69167 10 5 10Z"
          fill="currentColor"
        />
      </svg>
    ),
    '20': (
      <svg
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
    '24': (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          { 'tw-flex-shrink-0 tw-cursor-pointer': props.onClick },
          className,
        )}
        {...props}
      ></svg>
    ),
  };
  return <>{Icons[size]}</>;
};
