import { HgbAntdSpin } from '@common/antd/HgbAntdSpin';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export function AuthGuard() {
  const { routs } = useContext(LanguageContext)!;
  const { user } = useContext(AuthContext)!;
  const [isReady, setIsReady] = useState(false);
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setIsReady(true);
    }, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (user.isMaintenance) {
      navigate('/maintenance');
      return;
    }
    switch (user?.authenStatus) {
      case 'FIRST_GENERATE_PASSWORD':
      case 'MUST_CHANGE_PASSWORD':
      case 'SHOULD_CHANGE_PASSWORD':
        navigate(routs['/change-password'].link, { replace: true });
        break;
      case 'SUCCESS':
        let pathNameCustom = pathname;
        if ([routs['/signin'].link, '/'].includes(pathname)) {
          pathNameCustom = routs['/dashboard'].link;
        }
        navigate(pathNameCustom, { replace: true, state });
        break;
      case 'LOGIN':
        const param =
          (user.bankCode || '0000') === '0000'
            ? ''
            : '?bankCode=' + user.bankCode;
        if (pathname !== routs['/signin'].link) {
          navigate(routs['/signin'].link + param, { replace: true });
        }
        break;
      case 'LOGOUT':
        navigate(routs['/logout'].link);
        break;
      case 'SESSION_TIMEOUT':
        navigate(routs['/session-timeout'].link);
        break;
      default:
        break;
    }
  }, [pathname, user?.bankCode, user?.authenStatus, user.isMaintenance]);

  return <>{isReady ? <Outlet /> : <HgbAntdSpin placement="middle" />}</>;
}
