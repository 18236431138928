import { REACT_APP_ENV } from '@constants/consts';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const EnvironmentBouding = ({ children }: { children: ReactNode }) => {
  if (REACT_APP_ENV === 'development')
    return <React.StrictMode>{children}</React.StrictMode>;
  return <>{children}</>;
};

root.render(
  <EnvironmentBouding>
    <App />
  </EnvironmentBouding>,
);
