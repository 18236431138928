import { ImgProps } from '@common/types';
import React from 'react';

export const HgbInlineLogo: React.FC<ImgProps> = ({
  className = '',
  ...props
}) => {
  return (
    <img
      src="/background/hgb-logo.png"
      className={`${className}`}
      alt=""
      {...props}
    />
  );
};
