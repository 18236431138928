import { DivProps } from '@common/types';
import { cn } from '@utils/cn';
import * as React from 'react';

interface HgbResponsiveProps extends DivProps {
  gap?: boolean;
  padding?: boolean;
}
export const HgbResponsive: React.FunctionComponent<HgbResponsiveProps> = ({
  className = '',
  padding,
  gap,
  ...props
}) => {
  return (
    <div
      className={cn(
        { 'tw-gap-16 pc:tw-gap-24': gap, 'tw-p-16 pc:tw-p-24': padding },
        className,
      )}
      {...props}
    />
  );
};
